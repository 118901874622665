<template>
    <div>
        <base-header class="pb-6">
            <b-row class="py-4">
                <b-col cols="6">
                    <h6 class="h2 text-white d-inline-block mb-0">{{ title }}</h6>
                </b-col>
                <b-col cols="6" class="text-right">
                    <base-button size="sm" type="neutral" @click="$router.replace('EformListing')">Back</base-button>
                </b-col>
            </b-row>
        </base-header>

        <b-container fluid class="mt--6">
            <div class="card-wrapper">
                <card>
                    <!-- Card header -->
                    <h3 slot="header" class="mb-0">New Template</h3>
                    <validation-observer v-slot="{ handleSubmit }">
                        <form class="needs-validation" @submit.prevent="handleSubmit(onSubmit)">

                            <b-form-row>
                                <b-col>
                                    <validation-provider name="Name" rules="required" v-slot="{ classes, errors }">
                                        <b-form-group label="Name" :invalid-feedback="errors[0]">
                                            <input type="text" class="form-control" :class="classes" v-model="model.name" maxlength="100">
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>

                                <b-col>
                                    <validation-provider name="Module" rules="required" v-slot="{ classes, errors }">
                                        <b-form-group label="Module" :invalid-feedback="errors[0]">
                                            <select class="form-control" v-model="model.type">
                                                <option Selected disabled value="x">-- Please Select --</option>
                                                <option value="evaluation">Evaluation</option>
                                                <option value="assessment">Assessment</option>
                                                <option value="approval">Approval</option>
                                            </select>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                            </b-form-row>

                            <b-form-row>
                                <b-col>
                                    <validation-provider name="Submitter" rules="required" v-slot="{ classes, errors }">
                                        <b-form-group label="Submitter" :invalid-feedback="errors[0]">
                                            <select class="form-control" v-model="model.role_id">
                                                <option Selected disabled value="x">-- Please Select --</option>
                                                <option v-for="item in roles" :key="item.id" v-bind:value="item.id">{{ item.name }}</option>
                                            </select>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                            </b-form-row>

                            <b-form-row>
                                <b-col>
                                    <validation-provider name="Form Template" rules="required" v-slot="{ classes, errors }">
                                        <b-form-group label="From Template" :invalid-feedback="errors[0]">
                                            <select class="form-control" v-model="model.template_id">
                                                <option Selected disabled value="x">-- Please Select --</option>
                                                <option v-for="item in templates" :key="item.id" v-bind:value="item.id">{{ item.name }}</option>
                                            </select>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                            </b-form-row>

                            <b-form-row>
                                <b-col>
                                    <b-form-group label="Required Appover">
                                        <b-form-checkbox
                                                v-model="model.required_approver"
                                                name="Required Appover"
                                                value="1"
                                                unchecked-value="0">
                                            <b>Required Approver?</b>
                                        </b-form-checkbox>
                                    </b-form-group>
                                </b-col>
                            </b-form-row>
                            <br>

                            <button class="btn btn-primary" v-if="access.create">Create</button>
                            <button class="btn btn-primary" type="button" @click="$router.replace('EformListing')">Cancel</button>
                        </form>
                    </validation-observer>
                </card>
            </div>
        </b-container>
    </div>
</template>

<script>
    import ErrorFunctionMixin from '@/assets/js/errorFunctionMix.js'
    import axios from '@/util/axios'
    import { getAccessByModule } from '@/util/access'

    export default {
        mixins: [ErrorFunctionMixin],

        data() {
            return {
                title: 'New Eform',
                model: {
                },
                access: {},
                templates: [],
                roles: [],
            }
        },

        created() {
            this.access = getAccessByModule('EFORM')
            this.getAllRoles()
            this.getTemplateOptions();
        },

        methods: {
            onSubmit() {
                axios.post('e_forms/eform', this.model)
                    .then(response => {
                        this.$notify({
                            message: 'New Eform Created',
                            timeout: 10000,
                            icon: 'ni ni-bell-55',
                            type: 'default',
                        });
                        this.$router.replace('EformListing')
                    })
                    .catch(error => {
                        this.errorFunction(error, this.title)
                    });
            },
            getTemplateOptions(){
                axios.get('e_forms/get_type_options')
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.templates = response.data.data
                        }
                    })
                    .catch ( error => {
                        this.errorFunction(error, this.title)
                    } );
            },
            getAllRoles(){
                axios.get('lookup/role_list')
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.roles = response.data.data
                        }
                    })
                    .catch ( error => {
                        console.log(error.response.status)
                        this.errorFunction(error, "Role Listing")
                    } );
            }

        },
    }
</script>
