<template>
  <div>
    <base-header class="pb-6">
      <b-row class="py-4">
        <b-col lg="12" cols="7" >
          <h6 class="h2 text-white d-inline-block mb-0">Safety Inspection</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </b-col>
        <b-col lg="12" cols="5" class="text-right">
          <base-button size="sm" type="neutral" @click="$router.back()">Back</base-button>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col>
          <div class="card-wrapper">
            <safety-observation-view-form></safety-observation-view-form>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import {LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate} from '@/assets/js/config.js'
  import BaseHeader from '@/components/BaseHeader';
  import SafetyObservationViewForm from './SafetyObservationViewForm';
  import axios from 'axios';

  export default {
    created(){
      this.safety_observation_id = this.$route.params.id;
      //alert(this.$route.params.id);
      axios.get(apiDomain + 'health_safety/safety_observation/' + this.safety_observation_id, { headers: getHeader() })
        .then ( response => {
        if (response.status === 200)
        { 
           //console.log("success query")
           if(response.data.data[0].project_id == 738)
           {
              //console.log("YES UAM");
              this.$router.replace('/UAM/HSEView/' + this.safety_observation_id);
           }
        }
        })
        .catch ( error => {
          console.log(error.response.status)
          this.errorFunction(error, "Safety Observation Information")
        });
    },

    components: {
      BaseHeader,
      RouteBreadCrumb,
      SafetyObservationViewForm,
    },
    data() {

      return {

      };
    }
  };
</script>
<style></style>
