<template>
  <!-- <b-row>
    <b-col md="12">
      <div>
        <div style="width: 30px; height: 20px; background-color: #5ABAB9; border-radius: 8px;">
        </div>
        <div class="ml-1 mr-3">Positives (ObsTypes)</div>
        <div style="width: 30px; height: 20px; background-color: #3F92D3; border-radius: 8px;">
        </div>
        <div class="ml-1">Negative (ObsTypes)</div>
      </div>
    </b-col>
  </b-row>
  <b-row>
    <b-col md="12">
      
    </b-col>
  </b-row> -->
  <vue-highcharts :options="obj_chart.observationsOption" ref="chartObservations"></vue-highcharts>
  <!-- <vue-highcharts :options="obj_chart.findingOption" ref="chartFindings"></vue-highcharts> -->
</template>


<script>
import axios from '@/util/axios';
import { LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate, } from "@/assets/js/config.js";
import VueHighcharts from 'vue2-highcharts'
import moment from 'moment'

export default {
  props: ['siteName'],
  components: {
    VueHighcharts,
  },
  data() {
    return {
      obj_chart: {
        observationsOption: null,
        findingOption: null,
      },
      siteListingData: null,
      pid: null,
      sid: null,
    }
  },
  created() {
    // this.getSafetyData();
  },
  watch: {
    siteName() {
      this.getSafetyData()
    },
  },
  methods: {
    getSafetyData() {
      /*
          developers [1, 2]
              1: sembcorp sg
              2: sembcorp id
              
          safety_observation_type [1, 3]
              1: positive
              3: good practice
              4: unsafe act
              5: unsafe condition
              6: near miss
      */

      let currDate = getcurrentDate();

      //_ format date: yyyy-mm-dd
      let filter = {
        // from_date: moment(currDate).startOf('month').format('YYYY-MM-DD'),
        from_date: '2019-01-01',
        to_date: currDate,
        developers: [1, 2],
        safety_observation_type: [1, 3, 4, 5, 6],

        project_name: null,
        project_site_contractors: [],
        site_name: this.siteName,
        // site_name: null,
        project_safety: [],
        safety_inspection_submitter: [],
        safety_observation_category: [],
        safety_observation_priority: [],
        target_date_from_date: null,
        target_date_to_date: null,
      };

      axios
        .post(apiDomain + "health_safety/safety_observation.show_by_filter", filter, {
          headers: getHeader(),
        })
        .then(response => {
          if (response.status === 200) {
            let objResp = response.data.data;

            //_> draw & populate observations graph

            // get the data to be use in the graph
            let obs_positive = this.filteringDataBySafetyObservation(objResp, 1);
            let obs_good_practice = this.filteringDataBySafetyObservation(objResp, 3);
            let obs_unsafe_act = this.filteringDataBySafetyObservation(objResp, 4);
            let obs_unsafe_condition = this.filteringDataBySafetyObservation(objResp, 5);
            let obs_near_miss = this.filteringDataBySafetyObservation(objResp, 6);

            // console.log('positive: ' + obs_positive.length);
            // console.log('good practice: ' + obs_good_practice.length);
            // console.log('unsafe act:' + obs_unsafe_act.length);
            // console.log('unsafe condition: ' + obs_unsafe_condition.length);
            // console.log('near miss: ' + obs_near_miss.length);

            let chart_template_observations = {
              credits: {
                enabled: false,
              },
              series: [
                {
                  name: "Total",
                  colorByPoint: true,
                  dataLabels: {
                    enabled: true,
                    style: {
                      textOutline: 'none',
                      fontSize: '14px',
                      fontFamily: "Open Sans, sans-serif",
                      fontWeight: "bold",
                      color: '#ffffff'
                    }
                  },
                  data: [],
                },
              ],
              chart: {
                height: '60%',
                type: "pie",
                backgroundColor: "#23454A",
                // reflow: true,
              },
              plotOptions: {
                pie: {
                  showInLegend: true,
                  dataLabels: {
                    enabled: true,
                    // format: '<b>{point.name}</b>:<br>{point.percentage:.1f} %<br>value: {point.y}',
                    format: '<b>{point.name}</b>: {point.y}',
                  }
                }
              },
              legend: {
                itemStyle: {
                  color: '#FFFFFF'
                },
                itemHoverStyle: {
                  color: '#c1c1c1'
                },
              },
              title: {
                text: "",
                style: {
                  color: "white",
                  fontWeight: "bold",
                },
              },
            };
            this.obj_chart.observationsOption = chart_template_observations;
            let chart_data_observation = [
              {
                name: "Positive",
                y: obs_positive.length,
                color: "#5ABAB9",
              },
              {
                name: "Good Practice",
                y: obs_good_practice.length,
                color: "#5ABAB9",
              },
              {
                name: "Unsafe Condition",
                y: obs_unsafe_condition.length,
                color: "#3F92D3",
              },
              {
                name: "Unsafe Act",
                y: obs_unsafe_act.length,
                color: "#3F92D3",
              },
              {
                name: "Near Miss",
                y: obs_near_miss.length,
                color: "#3F92D3",
              },
            ];
            this.obj_chart.observationsOption.series[0].data = [];
            for (let index = 0; index < chart_data_observation.length; index++) {
              this.obj_chart.observationsOption.series[0].data.push({
                name: chart_data_observation[index].name,
                y: chart_data_observation[index].y,
                color: chart_data_observation[index].color,
              });
            }

            //_> draw & populate finding graph

            //_ count how many open and close
            //  NOTE: we use this way right now, but I don't like the way we get the result
            //          it should be hit the api and get the final result / count, instead of get the data list and processing it.
            var iOpen = 0, iClose = 0;
            objResp.forEach(function (data_entry) {
              data_entry.safety_observation_items.forEach(function (entry_observation) {
                if (entry_observation.safety_observation_status.status_id == 2) {
                  iOpen++;
                }

                if (entry_observation.safety_observation_status.status_id == 3) {
                  iClose++;
                }
              });
            });
            // console.log('open: ' + iOpen + ' vs close: ' + iClose);

            let chart_template_findings = {
              credits: {
                enabled: false,
              },
              series: [
                {
                  name: "Total",
                  colorByPoint: true,
                  dataLabels: {
                    enabled: true,
                    style: {
                      textOutline: 'none',
                      fontSize: '14px',
                      fontFamily: "Open Sans, sans-serif",
                      fontWeight: "bold",
                      color: '#ffffff'
                    }
                  },
                  data: [],
                },
              ],
              chart: {
                type: "pie",
                backgroundColor: "#23454A",
              },
              title: {
                text: "",
                style: {
                  color: "white",
                  fontWeight: "bold",
                },
              },
            };
            this.obj_chart.findingOption = chart_template_findings;
            let chart_data_finding = [
              {
                name: 'Findings Close',
                y: iClose,
                color: '#5ABAB9'
              },
              {
                name: 'Findings Open',
                y: iOpen,
                color: '#3F92D3'
              },
            ];
            this.obj_chart.findingOption.series[0].data = [];
            for (let index = 0; index < chart_data_finding.length; index++) {
              this.obj_chart.findingOption.series[0].data.push({
                name: chart_data_finding[index].name,
                y: chart_data_finding[index].y,
                color: chart_data_finding[index].color,
              });
            }

            // console.log(this.obj_chart.findingOption);
          }
        })
        .catch(error => {
          this.errorFunction(error, "Search Safety Inspection Listing")
        });
    },
    //_ method that we use to calculate how many data based on the observation type that is given.
    filteringDataBySafetyObservation(objData, safety_observation_type) {
      /*
          1: positive
          3: good practice
          4: unsafe act
          5: unsafe condition
          6: near miss
      */
      var filteredArray = objData
        .filter(element => element.safety_observation_items
          .some(subElement => subElement.safety_observation_type.safety_observation_type_id === safety_observation_type)
          // .some(subElement => subElement.safety_observation_type.safety_observation_type_code === value)
        )
        .map(element => {
          let n = Object.assign({}, element, {
            'subElements': element.safety_observation_items.filter(
              subElement => subElement.safety_observation_type.safety_observation_type_id === safety_observation_type
              // subElement => subElement.safety_observation_type.safety_observation_type_code === value
            )
          })
          return n;
        })

      return filteredArray;
    },
  },
};
</script>
