<template>
	<div class="bg-default-dashboard">
		<div class="py-7 py-lg-8 pt-lg-5">
			<b-container fluid style="width: 100%; position: relative;">
				<b-row>
					<b-col>
						<div class="grid-widget site-map">
							<smart-widget-grid :layout="layout">
								<!-- site information -->
								<smart-widget slot="0" title="Site Information" fullscreen>
									<div class="layout-center">
										<div class="block">
											<!-- map -->
											<div class="background" id="mapDashboard" ref="mapRef"
												style="min-height:100%; height: 100vh; width:100%;"></div>

											<!-- client gate -->
											<div class="foreground" v-show="oDashboard.isShowMapOverlay">
												<div class="dashboard-container-gate-info-parent">
													<div class="card dashboard-container-gate-info-card">
														<div class="row no-gutters dashboard-container-gate-info">
															<div class="col-md-12">
																<img :src="oProject.map_data.data_client.img_gate"
																	style="display: block; margin-left: auto; margin-right: auto; max-width: 100%;"
																	@click="showGallery();">
															</div>
														</div>
													</div>
												</div>
											</div>

											<!-- logo & client info -->
											<div class="foreground" v-show="oDashboard.isShowMapOverlay">
												<div class="dashboard-container-client-info-parent">
													<div class="card dashboard-container-client-info-card">
														<div class="row no-gutters dashboard-container-client-info">
															<div class="col-md-12">
																<div class="row dashboard-container-client-info-row-header">
																	<div class="col-md-12">
																		<!-- <img :src="`/img/clients/` + oProject.map_data.data_client.img_logo" -->
																		<img :src="oProject.map_data.data_client.img_logo"
																			style="display: block; margin-left: auto; margin-right: auto; width: 150px;"
																			@click="showEmergencyContactInformation();">
																	</div>
																</div>
																<div class="row dashboard-container-client-info-row-content">
																	<div class="col-md-12 info-label">PIC</div>
																	<div class="col-md-12"><b>{{
																		oProject.map_data.data_client.pic }}</b>
																	</div>
																</div>
																<div class="row dashboard-container-client-info-row-content">
																	<div class="col-md-12 info-label">Contact</div>
																	<div class="col-md-12"><b>{{
																		oProject.map_data.data_client.contact }}</b>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>

										</div>
									</div>
								</smart-widget>
								<!-- sembcorp pic -->
								<smart-widget slot="1" title="Sembcorp PIC">
									<div class="layout-center">
										<div class="accordion" role="tablist" v-for="(item, index) in oProject.pic">
											<b-card no-body class="mb-1">
												<b-card-header header-tag="header" class="p-1" role="tab">
													<b-button block v-b-toggle="'accordion-' + index" variant="info">{{
														item.title }}</b-button>
												</b-card-header>
												<b-collapse :id="`accordion-` + index" visible accordion="my-accordion" role="tabpanel">
													<b-card-body>
														<b-row class="sc_pic_row">
															<b-col md="2" class="sc_pic_col">
																<div class="sc_pic_label">Name</div>
															</b-col>
															<b-col md="10" class="sc_pic_col">
																<div class="sc_pic_value">{{ item.name }}</div>
															</b-col>
														</b-row>
														<b-row class="sc_pic_row">
															<b-col md="2" class="sc_pic_col">
																<div class="sc_pic_label">Phone</div>
															</b-col>
															<b-col md="10" class="sc_pic_col">
																<div class="sc_pic_value">{{ item.phone }}</div>
															</b-col>
														</b-row>
														<b-row class="sc_pic_row">
															<b-col md="2" class="sc_pic_col">
																<div class="sc_pic_label">Email</div>
															</b-col>
															<b-col md="10" class="sc_pic_col">
																<div class="sc_pic_value">{{ item.email }}</div>
															</b-col>
														</b-row>
													</b-card-body>
												</b-collapse>
											</b-card>
										</div>
									</div>
								</smart-widget>
								<!-- soc site -->
								<smart-widget slot="2" title="SOC Site">
									<div class="layout-center">
										<template v-for="(item, key, index) in oProject.map_data.data_soc">
											<b-row class="sc_soc_row">
												<b-col md="4" class="sc_soc_col">
													<div class="sc_soc_label">{{ item.code }}</div>
												</b-col>
												<b-col md="8" class="sc_soc_col">
													<div class="sc_soc_value">{{ item.value }}</div>
												</b-col>
											</b-row>
										</template>
									</div>
								</smart-widget>
								<!-- asset information -->
								<smart-widget slot="3" title="Asset Information">
									<div class="layout-center">
										<template v-for="(item, key, index) in oProject.map_data.data_inventory">
											<b-row class="sc_ai_row">
												<b-col md="4" class="sc_ai_col">
													<div class="sc_ai_label">{{ item.code }}</div>
												</b-col>
												<b-col md="8" class="sc_ai_col">
													<div class="sc_ai_value">{{ item.value }}</div>
												</b-col>
											</b-row>
										</template>
									</div>
								</smart-widget>
								<!-- site monitoring -->
								<smart-widget slot="4" title="Site Monitoring">
									<div class="layout-center">
										<b-row style="margin-left: auto; margin-right: auto; margin-top: 25px">
											<b-col md="4" class="sc_as_col"><img :src="oProject.map_data.data_cctv.cam1"
													class="sc_as_thumbnail" @click="showCCTVdisplay(oProject.map_data.data_cctv.cam1)" /></b-col>
											<b-col md="4" class="sc_as_col"><img :src="oProject.map_data.data_cctv.cam2"
													class="sc_as_thumbnail" @click="showCCTVdisplay(oProject.map_data.data_cctv.cam2)" /></b-col>
											<b-col md="4" class="sc_as_col"><img :src="oProject.map_data.data_cctv.cam3"
													class="sc_as_thumbnail" @click="showCCTVdisplay(oProject.map_data.data_cctv.cam3)" /></b-col>
										</b-row>
									</div>
								</smart-widget>
								<!-- safety status -->
								<smart-widget slot="5" title="Safety Status" fullscreen>
									<div class="layout-center">
										<page-safety-status :site-name="oApiContainer.site.site_name" />
									</div>
								</smart-widget>
								<!-- budget status -->
								<smart-widget slot="6" title="Budget Status" fullscreen>
									<div class="layout-center">
										<!-- budget status -->
										<div v-show="oDashboard.showBudgetStatus" style="margin-bottom: 0px; padding-bottom: 10px;">
											<b-row>
												<b-col md="11">
													<page-budget :budget-data="oDashboard.budget_usage" />
												</b-col>
												<b-col md="1">
													<span class="icon-locked"
														style="height: 15px; width: 15px; margin-top: 5px; margin-right: 10px;"
														@click="budgetShowDetail('showBudgetPasscode')"></span>
												</b-col>
											</b-row>
											<!-- <b-row class="sc_row_end"><b-col md="12">&nbsp;</b-col></b-row> -->
										</div>

										<!-- budget detail -->
										<div v-show="oDashboard.showBudgetDetail" style="margin-bottom: 0px; padding-bottom: 10px;">
											<!-- <b-row v-show="oDashboard.showBudgetPasscode" style="height: 150px"> -->
											<template v-if="oDashboard.showBudgetPasscode">
												<b-row>
													<b-col md="12" class="budgetPCmain">
														<div class="budgetPCitemInput"><input type="password" placeholder="Passcode required !!!"
																v-model="oDashboard.budgetPasscode"
																v-on:keyup.enter="budgetShowDetail('submitPasscode')"></div>
														<div class="budgetPCitemButton">
															<span class="icon-locked" style="height: 15px; width: 15px;"
																@click="budgetShowDetail('submitPasscode')"></span>
														</div>
													</b-col>
												</b-row>
												<b-row>
													<b-col md="4">
														<span class="icon-close-box"
															style="height: 20px; width: 20px; margin-top: 5px; margin-left: 15px;"
															@click="budgetShowStatus()"></span>
													</b-col>
													<b-col md="8" v-show="oDashboard.incorrectPasscode">
														<div class="budgetPCitemMessage">
															<span style="color: red">Incorrect passcode.</span>
														</div>
													</b-col>
												</b-row>
											</template>

											<div v-show="oDashboard.allowBudgetDetail" style="margin-bottom: 0px; padding-bottom: 10px;">
												<b-row>
													<b-col md="11" style="margin-top: 5px">
														<span style="margin-left: 10px;">Budget: {{ Number(oProject.map_data.data_budget.budget) }}</span>
													</b-col>
													<b-col md="1">
														<span class="icon-close-box"
														style="height: 20px; width: 20px; margin-top: 5px; margin-left: 0px;"
														@click="budgetShowStatus()"></span>
													</b-col>
												</b-row>
												<b-row>
													<b-col md="12">
														<page-budget-detail :budget-data="oDashboard.budget_usage" />
													</b-col>
												</b-row>
											</div>

											<!-- <template v-if="oDashboard.allowBudgetDetail"
												v-for="(item, key, index) in oProject.map_data.data_budget">
												<b-row class="sc_bsi_row" v-if="key != 'passcode'">
													<b-col md="4" class="sc_bsi_col">
														<div class="sc_bsi_label">{{ key }}</div>
													</b-col>
													<b-col md="8" class="sc_bsi_col">
														<div class="sc_bsi_value" style="float: right;">{{
															item.toLocaleString('en') }}</div>
													</b-col>
												</b-row>
											</template>
											-->
										</div>
									</div>
								</smart-widget>
								<!-- document status -->
								<smart-widget slot="7" title="Document Status" fullscreen>
									<div class="layout-center">
										<page-document-status />
									</div>
								</smart-widget>
								<!-- project timeline -->
								<smart-widget slot="8" title="Project Timeline">
									<div class="layout-center">
										<b-row class="sc_pt_row">
											<b-col md="4" class="sc_pt_header_desc">Description</b-col>
											<b-col md="4" class="sc_pt_header_value">Plan</b-col>
											<b-col md="4" class="sc_pt_header_value">Actual</b-col>
										</b-row>
										<template v-for="(item, key, index) in oProject.map_data.data_project_info">
											<b-row class="sc_pt_row">
												<b-col md="4" class="sc_pt_col">
													<div class="sc_pt_label">
														<i v-if="validateProjectTimeline(item.value_plan, item.value_actual) == 'ontime'"
															class="fa fa-xs fa-circle mr-1" style="color: #00ff56;"></i>
														<i v-else-if="validateProjectTimeline(item.value_plan, item.value_actual) == 'delay'"
															class="fa fa-xs fa-circle mr-1" style="color: #ff0000;"></i>
														<i v-else class="fa fa-xs fa-circle mr-1" style="color: #ffffff;"></i>
														{{ item.code }}
													</div>
												</b-col>
												<b-col md="4" class="sc_pt_col">
													<div class="sc_pt_value">{{ item.value_plan }}</div>
												</b-col>
												<b-col md="4" class="sc_pt_col">
													<!-- <div class="sc_pt_value" >{{ project_timeline_actual_info(item.value_plan, item.value_actual) }}</div> -->
													<div class="sc_pt_value"
														v-html="project_timeline_actual_info(item.value_plan, item.value_actual)">
													</div>
												</b-col>
											</b-row>
										</template>
									</div>
								</smart-widget>
								<!-- project status -->
								<smart-widget slot="9" title="Project Status" fullscreen>
									<div class="layout-center">
										<page-project-status :site-id="oDashboard.code_site" />
									</div>
								</smart-widget>
								<!-- daily activity -->
								<smart-widget slot="10" title="Daily Activity">
									<div class="layout-center">
										<page-calendar />
									</div>
								</smart-widget>
							</smart-widget-grid>
						</div>
					</b-col>
				</b-row>

			</b-container>
		</div>

		<!-- modal show CCTV -->
		<modal :show.sync="oDashboard.isCCTVDisplayVisible" size="xl" body-classes="p-0" title="Image">
			<card type="secondary" header-classes="bg-transparent pb-5" body-classes="px-lg-5 py-lg-5" class="border-0 mb-0">
				<!-- style="max-width:250px;max-height:250px;" -->
				<div class="text-center">
					<img :src="oDashboard.largeCctvSrc" style="max-width: 100%" />
				</div>
			</card>
		</modal>

		<!-- modal gallery -->
		<modal :show.sync="oDashboard.isVisibleGallery" modal-classes="gallery-modal" size="xl" body-classes="p-0"
			title="Site Gallery">
			<card type="" header-classes="bg-transparent pb-5" body-classes="px-lg-5 py-lg-5" class="border-0 mb-0"
				style="background-color: #375f65;">
				<template>
					<page-gallery :gallery-slides="oDashboard.gallery_slides" />
				</template>
			</card>
		</modal>

		<modal :show.sync="oDashboard.isVisibleEmergencyContact" modal-classes="emergency-contact-modal" size="xl"
			body-classes="p-0" title="Emergency Contact Numbers">
			<card type="" header-classes="bg-transparent pb-5" body-classes="px-lg-5 py-lg-5" class="border-0 mb-0"
				style="background-color: #375f65;">
				<template>
					<tab-content-navigation />
					<div class="text-center text-muted mb-4">
						<h1 class="text-white"><b>Emergency Contact Numbers</b></h1>
					</div>
					<div class="emergency-contact-main">
						<table>
							<tr>
								<th>Unit</th>
								<th>PIC</th>
								<th>Contact</th>
								<th>Notes</th>
							</tr>
							<tr v-for="(item, key, index) in oProject.emergency_contacts">
								<td style="width: 30%;">{{ item.title }}</td>
								<td style="width: 10%;">{{ item.pic }}</td>
								<td style="width: 25%;">{{ item.contact }}</td>
								<td style="width: 35%;">{{ item.notes }}</td>
							</tr>
						</table>
					</div>
				</template>
			</card>
		</modal>

	</div>
</template>

<script>
// import { Table, TableColumn, Select, Option } from "element-ui";
import { API_KEY } from '@/views/Maps/API_KEY';
import GoogleMapsLoader from 'google-maps';
GoogleMapsLoader.KEY = API_KEY;
GoogleMapsLoader.LIBRARIES = ['geometry'];
import { LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate } from "@/assets/js/config.js";
// import axios from "axios";
import axios from '@/util/axios';
import moment from "moment";

import PageBudget from './MapDashboardSiteDetail/budget.vue';
import PageBudgetDetail from './MapDashboardSiteDetail/budget_detail.vue';
import PageCalendar from './MapDashboardSiteDetail/calendar.vue';
import PageSafetyStatus from './MapDashboardSiteDetail/safety_status.vue';
import PageDocumentStatus from './MapDashboardSiteDetail/document_status.vue';
import PageGallery from './MapDashboardSiteDetail/gallery.vue';
import PageProjectStatus from './MapDashboardSiteDetail/project_status.vue';
import { isDate } from 'lodash';

export default {
	name: "GridWidget",
	components: {
		PageBudget,
		PageBudgetDetail,
		PageCalendar,
		PageSafetyStatus,
		PageDocumentStatus,
		PageGallery,
		PageProjectStatus,
	},
	data() {
		return {
			model: {
				project_site_additional_info_id: null,
				client_pic_name: '',
				client_pic_contact: null,
				client_pic_gate: null,
				client_pic_logo: null
			},
			layout: [
				{ x: 3, y: 0, w: 6, h: 10, i: "0" },         //_ map         
				{ x: 0, y: 0, w: 3, h: 6, i: "1" },         //_ sembcorp pic
				{ x: 0, y: 6, w: 3, h: 4, i: "2" },         //_ soc site
				{ x: 9, y: 0, w: 3, h: 6, i: "3" },         //_ asset information
				{ x: 9, y: 6, w: 3, h: 4, i: "4" },         //_ site monitoring
				{ x: 0, y: 11, w: 4, h: 7, i: "5" },         //_ chart> safety status
				{ x: 4, y: 11, w: 4, h: 7, i: "6" },         //_ chart> budget status
				{ x: 8, y: 11, w: 4, h: 7, i: "7" },         //_ chart> document status
				{ x: 0, y: 18, w: 4, h: 11, i: "8" },         //_ project timeline
				{ x: 4, y: 18, w: 8, h: 11, i: "9" },         //_ chart> project status
				{ x: 0, y: 27, w: 12, h: 10, i: "10" },        //_ calendar> daily activity
			],
			oDashboard: {
				code_proj: null,
				code_site: null,
				map: null,
				marker: [],
				isShowMapOverlay: true,
				budget_usage: 0,
				showBudgetStatus: true,
				showBudgetPasscode: false,
				incorrectPasscode: false,
				budgetPasscode: null,
				allowBudgetDetail: false,
				showBudgetDetail: false,
				isCCTVDisplayVisible: false,
				largeCctvSrc: null,
				isVisibleGallery: false,
				isVisibleEmergencyContact: false,
				gallery_slides: [],
			},
			oProject: {
				map: {
					lat: 0,
					lng: 0,
					title: ''
				},
				info: [
					{ title: 'PPA Signed Capacity', content: 'default' },
					{ title: 'PPA Signed Date', content: 'default' },
					{ title: 'Joint Venture With', content: 'default' },
					{ title: 'Contractor', content: 'default' },
				],
				pic: [
					{ avatar: '/img/users/default_avatar_male.png', title: 'Project Engineer', name: 'N/A', phone: '', email: '', other: '' },
					{ avatar: '/img/users/default_avatar_male.png', title: 'Engineer', name: 'N/A', phone: '', email: '', other: '' },
					{ avatar: '/img/users/default_avatar_male.png', title: 'Business Development', name: 'N/A', phone: '', email: '', other: '' },
					{ avatar: '/img/users/default_avatar_male.png', title: 'Safety Officer', name: 'N/A', phone: '', email: '', other: '' },
				],
				epc: { name: 'default', pic: 'default', contact: 'default' },
				emergency_contacts: [
					{ title: '', pic: '', contact: '', notes: '' },
					{ title: '', pic: '', contact: '', notes: '' },
					{ title: '', pic: '', contact: '', notes: '' },
					{ title: '', pic: '', contact: '', notes: '' },
					{ title: '', pic: '', contact: '', notes: '' },
				],
				// map_data: []
				map_data: {
					data_client: { img_gate: '/img/default/loading_v1_100x100.gif', img_logo: '/img/default/loading_v1_100x100.gif' },
					data_cctv: { cam1: '/img/default/loading_v1.gif', cam2: '/img/default/loading_v1.gif', cam3: '/img/default/loading_v1.gif' },
					data_project_info: [
						{ code: 'PPA Sign', value_plan: '', value_actual: '' },
						{ code: 'ITB', value_plan: '', value_actual: '' },
						{ code: 'PLN Submission', value_plan: '', value_actual: '' },
						{ code: 'PLN Permit', value_plan: '', value_actual: '' },
						{ code: 'LOA Sign', value_plan: '', value_actual: '' },
						{ code: 'NTP', value_plan: '', value_actual: '' },
						{ code: 'KOM', value_plan: '', value_actual: '' },
						{ code: 'QAQC', value_plan: '', value_actual: '' },
						{ code: 'T&C', value_plan: '', value_actual: '' },
						{ code: 'ULO', value_plan: '', value_actual: '' },
						{ code: 'SLO', value_plan: '', value_actual: '' },
						{ code: 'COD', value_plan: '', value_actual: '' },
						{ code: 'PAC', value_plan: '', value_actual: '' },
						{ code: 'DLP', value_plan: '', value_actual: '' },
						{ code: 'Energy Guarantee', value_plan: '', value_actual: '' },
					],
					data_budget: { budget: 0, epc: 0, spent: 0, balance: 0, remaining: 0, passcode: 'sembcorp' },
				},
			},
			oApiContainer: {
				project: null,
				site: { site_name: null },
				site_additional_info: null,
				project_manager: null,
				project_engineer: null,
				project_business_development: null,
				project_safety: null,
			},
			oDummyDB: {
				map_data: [
					{
						site_code: '6272',
						data_cctv: { code: '569507', cam1: '/img/default/loading_v1.gif', cam2: '/img/default/loading_v1.gif', cam3: '/img/default/loading_v1.gif' },
						data_soc: [
							{ code: 'Skylight', value: 'x10' },
							{ code: 'Roof Tilt', value: '10 degrees' },
						],
						data_inventory: [
							{ code: 'Inverter', value: 'Huawei / SUN 2000-330KTL' },
							{ code: 'Module', value: 'Jinko N Type Bifacial 625Wp' },
							{ code: 'BESS', value: '' },
						],
					},
					{
						site_code: '6273',
						data_cctv: { code: '318877', cam1: '/img/default/loading_v1.gif', cam2: '/img/default/loading_v1.gif', cam3: '/img/default/loading_v1.gif' },
						data_soc: [
							{ code: 'Skylight', value: 'x10' },
							{ code: 'Roof Tilt', value: '8 degrees' },
						],
						data_inventory: [
							{ code: 'Inverter', value: 'Huawei / SUN 2000-330KTL' },
							{ code: 'Module', value: 'Jinko N Type Bifacial 625Wp' },
							{ code: 'BESS', value: '' },
						],
					},
					{
						site_code: '6278',
						data_cctv: { code: '650219', cam1: '/img/default/loading_v1.gif', cam2: '/img/default/loading_v1.gif', cam3: '/img/default/loading_v1.gif' },
						data_soc: [
							{ code: 'Skylight', value: 'x12' },
							{ code: 'Roof Tilt', value: '14 degrees' },
						],
						data_inventory: [
							{ code: 'Inverter', value: 'Huawei / SUN 2000-330KTL' },
							{ code: 'Module', value: 'Jinko N Type Bifacial 625Wp' },
							{ code: 'BESS', value: '' },
						],
					},
					{
						site_code: '7371',
						data_cctv: { code: '650215', cam1: '/img/default/loading_v1.gif', cam2: '/img/default/loading_v1.gif', cam3: '/img/default/loading_v1.gif' },
						data_soc: [
							{ code: 'Skylight', value: 'x15' },
							{ code: 'Roof Tilt', value: '13 degrees' },
						],
						data_inventory: [
							{ code: 'Inverter', value: 'Huawei / SUN 2000-330KTL' },
							{ code: 'Module', value: 'Jinko N Type Bifacial 625Wp' },
							{ code: 'BESS', value: '' },
						],
					},
					{
						site_code: '7372',
						data_cctv: { code: '210046', cam1: '/img/default/loading_v1.gif', cam2: '/img/default/loading_v1.gif', cam3: '/img/default/loading_v1.gif' },
						data_soc: [
							{ code: 'Skylight', value: 'x10' },
							{ code: 'Roof Tilt', value: '13 degrees' },
						],
						data_inventory: [
							{ code: 'Inverter', value: 'Huawei / SUN 2000-330KTL' },
							{ code: 'Module', value: 'Jinko N Type Bifacial 625Wp' },
							{ code: 'BESS', value: '' },
						],
					},
				],

			}
		};
	},
	created() {
		const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
		this.myUserID = LoginUser;

		// this.oDashboard.code_proj = this.$route.params.id;
		this.oDashboard.code_site = this.$route.params.id;
	},
	beforeMount() {
		// this.getDailyProgressListing();
		this.getSiteListing();
	},
	methods: {
		getSiteListing() {
			// console.log(this.oDashboard.code_site);
			axios
				.get(apiDomain + 'project/project_site/' + this.oDashboard.code_site, { headers: getHeader() })
				.then(response => {
					if (response.status === 200) {
						// console.log('getSiteListing');
						// console.log(response);

						this.oApiContainer.site = response.data.data[0];
					}
				})
				.catch(error => {
					console.log(error.response.status)
				})
				.finally(() => {
					this.getDashboardData(this.oApiContainer.site.project.project_id);
					// this.getContractorFromSite();
				});
		},
		getDashboardData(code_proj) {
			axios
				.get(apiDomain + "project/project/" + code_proj, { headers: getHeader() })
				.then((response) => {
					if (response.status === 200) {
						// console.log('getDashboardData');
						// console.log(response);
						this.oApiContainer.project = response.data.data[0];
					}
				})
				.catch((error) => {
					console.log(error.response.status);
				})
				.finally(() => {
					this.getSiteAdditionalInformation();
				});
		},
		//_ Get project site additional information 
		getSiteAdditionalInformation() {
			axios
				.get(apiDomain + 'project/project_site_additional_info/' + this.$route.params.id, { headers: getHeader() })
				.then(response => {
					// console.log('[MapDashboardDetail.vue] > func: getSiteAdditionalInformation')
					// console.log(response)
					this.oApiContainer.site_additional_info = response.data.data[0];
				})
				.catch(error => {
					this.errorFunction(error, "Get project site additional information")
				})
				.finally(() => {
					this.constructDataForUI();
					this.loadGoogleMap();
				});
		},
		constructDataForUI() {
			// console.log('constructDataForUI')
			this.oDashboard.code_proj = this.oApiContainer.site.project.project_id
			this.oProject.map.lat = Number(this.oApiContainer.site.site_latitude)
			this.oProject.map.lng = Number(this.oApiContainer.site.site_longitude)

			this.oProject.map.title = this.oApiContainer.project.project_name;


			//_ will be remove later on, still needed for Asset information | soc site
			this.getDummyInfoBySite(this.oDummyDB.map_data, this.oDashboard.code_site)


			//_ start to USE data from API here

			//_ set client information
			this.setClientInformation();

			//_ set project information
			this.setDataProjectInformation();

			//_ set budget
			this.setDataBudget();

			//_ set sembcorp's PIC
			this.setPic();

			//_ emergency contact
			this.setEmergencyContact();
		},

		//_ google map
		loadGoogleMap() {
			GoogleMapsLoader.load(google => {
				this.initMap(google);
			});
		},
		initMap(google) {
			let map, lat = 0.995151, lng = 116.639650, color = "#5e72e4", zoomLevel = 17;

			map = document.getElementById('mapDashboard');
			// let myLatlng = new google.maps.LatLng( lat, lng);
			let myLatlng = new google.maps.LatLng(this.oProject.map.lat, this.oProject.map.lng);
			let mapOptions = {
				zoom: zoomLevel,
				center: myLatlng,
				mapTypeId: google.maps.MapTypeId.SATELLITE,
				mapTypeControl: false,
				rotateControl: true,
				styles: [
					{
						"elementType": "geometry",
						"stylers": [
							{
								"color": "#242f3e"
							}
						]
					},
					{
						"elementType": "labels.text.fill",
						"stylers": [
							{
								"color": "#746855"
							}
						]
					},
					{
						"elementType": "labels.text.stroke",
						"stylers": [
							{
								"color": "#242f3e"
							}
						]
					},
					{
						"featureType": "administrative.locality",
						"elementType": "labels.text.fill",
						"stylers": [
							{
								"color": "#d59563"
							}
						]
					},
					{
						"featureType": "poi",
						"elementType": "labels.text.fill",
						"stylers": [
							{
								"color": "#d59563"
							}
						]
					},
					{
						"featureType": "poi.park",
						"elementType": "geometry",
						"stylers": [
							{
								"color": "#263c3f"
							}
						]
					},
					{
						"featureType": "poi.park",
						"elementType": "labels.text.fill",
						"stylers": [
							{
								"color": "#6b9a76"
							}
						]
					},
					{
						"featureType": "road",
						"elementType": "geometry",
						"stylers": [
							{
								"color": "#38414e"
							}
						]
					},
					{
						"featureType": "road",
						"elementType": "geometry.stroke",
						"stylers": [
							{
								"color": "#212a37"
							}
						]
					},
					{
						"featureType": "road",
						"elementType": "labels.text.fill",
						"stylers": [
							{
								"color": "#9ca5b3"
							}
						]
					},
					{
						"featureType": "road.highway",
						"elementType": "geometry",
						"stylers": [
							{
								"color": "#746855"
							}
						]
					},
					{
						"featureType": "road.highway",
						"elementType": "geometry.stroke",
						"stylers": [
							{
								"color": "#1f2835"
							}
						]
					},
					{
						"featureType": "road.highway",
						"elementType": "labels.text.fill",
						"stylers": [
							{
								"color": "#f3d19c"
							}
						]
					},
					{
						"featureType": "transit",
						"elementType": "geometry",
						"stylers": [
							{
								"color": "#2f3948"
							}
						]
					},
					{
						"featureType": "transit.station",
						"elementType": "labels.text.fill",
						"stylers": [
							{
								"color": "#d59563"
							}
						]
					},
					{
						"featureType": "water",
						"elementType": "geometry",
						"stylers": [
							{
								"color": "#17263c"
							}
						]
					},
					{
						"featureType": "water",
						"elementType": "labels.text.fill",
						"stylers": [
							{
								"color": "#515c6d"
							}
						]
					},
					{
						"featureType": "water",
						"elementType": "labels.text.stroke",
						"stylers": [
							{
								"color": "#17263c"
							}
						]
					}
				],
				disableDefaultUI: true,
				scrollwheel: true,
				zoomControl: false,
				fullscreenControl: false,
				keyboardShortcuts: false,
				// mapId: "SEMBCORP_MAP_DASHBOARD",
			}

			map = new google.maps.Map(map, mapOptions);
			this.oDashboard.map = map;
			this.drawMarker();

			//_ workaround to show the map to be center on the marker, somehow using this layout the center a bit off, maybe because it's using the full screen, instead of the smaller default window
			google.maps.event.addListenerOnce(map, 'tilesloaded', function () {
				map.panBy(0, 200)
			})
		},
		drawMarker() {
			const inProgressIcon = '/img/theme/general/pin-progress.png';
			const completedIcon = '/img/theme/general/pin-complete.png';

			// const position = { lat: 0.995151, lng: 116.639650 };
			const position = { lat: this.oProject.map.lat, lng: this.oProject.map.lng };
			let markerIcon = inProgressIcon;

			const map = this.oDashboard.map;

			const marker = new google.maps.Marker({
				position: position,
				title: this.oProject.map.title,
				icon: markerIcon,
				label: {
					text: this.oProject.map.title,
					color: 'white',
					fontSize: '20px',
					fontWeight: 'bold',
					className: 'custom-marker-label',
					zIndex: 1010,
				},
			});

			marker.setMap(this.oDashboard.map);
			this.oDashboard.marker.push(marker);
		},

		//_ sembcorp's pic
		setPic() {
			this.getPicUserProfile(this.oProject.pic[0], this.oApiContainer.project.project_manager, 'Project Engineer');
			this.getPicUserProfile(this.oProject.pic[1], this.oApiContainer.project.project_engineer, 'Engineer');
			this.getPicUserProfile(this.oProject.pic[2], this.oApiContainer.project.project_business_development, 'Business Development');
			this.getPicUserProfile(this.oProject.pic[3], this.oApiContainer.project.project_safety, 'Safety Officer');
		},
		getPicUserProfile(ui_data = null, user_data = '', user_title = '') {
			ui_data.title = user_title;
			ui_data.name = user_data.name;
			ui_data.email = user_data.email;
			ui_data.phone = this.getPicContact(user_data.id)
		},
		getPicContact(user_id = '') {
			axios
				.get(apiDomain + 'setup/user/' + user_id, { headers: getHeader() })
				.then(response => {
					if (response.status === 200) {
						return response.data.data[0].phone_number
					}
				})
				.catch(error => {
					this.errorFunction(error, "Create New User")
				});
		},

		//_ client information
		setClientInformation() {
			this.oProject.map_data.data_client = {
				name: this.oApiContainer.site_additional_info.site.site_name,
				pic: this.oApiContainer.site_additional_info.client_pic_name,
				contact: this.oApiContainer.site_additional_info.client_pic_contact,
				img_logo: this.validateDisplayImage(this.oApiContainer.site_additional_info.client_pic_logo_image, 'logo'),
				img_gate: this.validateDisplayImage(this.oApiContainer.site_additional_info.client_pic_gate_image, 'gate'),
			}
		},

		//_ project information
		setDataProjectInformation() {
			this.oProject.map_data.data_project_info = [
				{ code: 'PPA Sign', value_plan: 'N/A', value_actual: this.libFormatDateTime(this.oApiContainer.project.project_ppa_start_date) },
				{ code: 'ITB', value_plan: this.libFormatDateTime(this.oApiContainer.site_additional_info.itb_plan_date), value_actual: this.libFormatDateTime(this.oApiContainer.site_additional_info.itb_actual_date) },
				{ code: 'PLN Submission', value_plan: this.libFormatDateTime(this.oApiContainer.site_additional_info.pln_submission_plan_date), value_actual: this.libFormatDateTime(this.oApiContainer.site_additional_info.pln_submission_actual_date) },
				{ code: 'PLN Permit', value_plan: this.libFormatDateTime(this.oApiContainer.site_additional_info.pln_permit_plan_date), value_actual: this.libFormatDateTime(this.oApiContainer.site_additional_info.pln_permit_actual_date) },
				{ code: 'LOA Sign', value_plan: this.libFormatDateTime(this.oApiContainer.site_additional_info.loa_sign_plan_date), value_actual: this.libFormatDateTime(this.oApiContainer.site_additional_info.loa_sign_actual_date) },
				{ code: 'NTP', value_plan: this.libFormatDateTime(this.oApiContainer.site_additional_info.ntp_plan_date), value_actual: this.libFormatDateTime(this.oApiContainer.site_additional_info.ntp_issue_date) },
				{ code: 'KOM', value_plan: this.libFormatDateTime(this.oApiContainer.site_additional_info.kom_plan_date), value_actual: this.libFormatDateTime(this.oApiContainer.site_additional_info.kick_off_meeting_date) },
				{ code: 'QAQC', value_plan: this.libFormatDateTime(this.oApiContainer.site_additional_info.qaqc_plan_date), value_actual: this.libFormatDateTime(this.oApiContainer.site_additional_info.qaqc_actual_date) },
				{ code: 'T&C', value_plan: this.libFormatDateTime(this.oApiContainer.site_additional_info.tnc_plan_date), value_actual: this.libFormatDateTime(this.oApiContainer.site_additional_info.tnc_actual_date) },
				{ code: 'ULO', value_plan: this.libFormatDateTime(this.oApiContainer.site_additional_info.ulo_plan_date), value_actual: this.libFormatDateTime(this.oApiContainer.site_additional_info.ulo_actual_date) },
				{ code: 'SLO', value_plan: this.libFormatDateTime(this.oApiContainer.site_additional_info.slo_plan_date), value_actual: this.libFormatDateTime(this.oApiContainer.site_additional_info.slo_actual_date) },
				{ code: 'COD', value_plan: this.libFormatDateTime(this.oApiContainer.site_additional_info.cod_plan_date), value_actual: this.libFormatDateTime(this.oApiContainer.site_additional_info.cod_actual_date) },
				{ code: 'PAC', value_plan: this.libFormatDateTime(this.oApiContainer.site_additional_info.pac_plan_date), value_actual: this.libFormatDateTime(this.oApiContainer.site_additional_info.pac_actual_date) },
				{ code: 'DLP', value_plan: this.libFormatDateTime(this.oApiContainer.site_additional_info.dlp_plan_date), value_actual: this.libFormatDateTime(this.oApiContainer.site_additional_info.dlp_actual_date) },
				{ code: 'Energy Guarantee', value_plan: '', value_actual: this.oApiContainer.site_additional_info.energy_guarantee },
			];
		},

		//_ budget
		setDataBudget() {
			this.oProject.map_data.data_budget = {
				budget: this.oApiContainer.site_additional_info.budget_total,
				epc: this.oApiContainer.site_additional_info.budget_epc,
				spent: this.oApiContainer.site_additional_info.budget_spent,
				balance: this.oApiContainer.site_additional_info.budget_balance,
				remaining: this.oApiContainer.site_additional_info.budget_remaining,
				passcode: this.oApiContainer.site_additional_info.budget_passcode
			};

			// this.oDashboard.budget_usage = this.setBudgetChartData();
			this.oDashboard.budget_usage = this.oProject.map_data.data_budget;
		},
		budgetShowDetail(whichDetail = 'showBudgetPasscode') {
			if (whichDetail == 'detail') {
				this.oDashboard.showBudgetStatus = false;
				this.oDashboard.showBudgetPasscode = false;
				this.oDashboard.showBudgetDetail = true;
				this.oDashboard.allowBudgetDetail = false;
			} else if (whichDetail == 'submitPasscode') {
				// console.log( this.oDashboard.budgetPasscode + '<vs>' + this.oProject.map_data.data_budget.passcode)
				if (this.oDashboard.budgetPasscode != this.oProject.map_data.data_budget.passcode) {
					this.oDashboard.incorrectPasscode = true;
				} else {
					this.oDashboard.showBudgetStatus = false;
					this.oDashboard.showBudgetPasscode = false;
					this.oDashboard.showBudgetDetail = true;
					this.oDashboard.allowBudgetDetail = true;
					this.oDashboard.incorrectPasscode = false;
					this.oDashboard.budgetPasscode = null;
				}
			}
			else {
				this.oDashboard.showBudgetStatus = false;
				this.oDashboard.showBudgetPasscode = true;
				this.oDashboard.showBudgetDetail = true;
				this.oDashboard.allowBudgetDetail = false;
			}
		},
		budgetShowStatus() {
			this.oDashboard.showBudgetStatus = true;
			this.oDashboard.showBudgetPasscode = false;
			this.oDashboard.showBudgetDetail = false;
			this.oDashboard.allowBudgetDetail = false;
		},
		setBudgetChartData() {
			//data_budget: { budget: 100, epc: 90, spent: 50, balance: 40, remaining: 10 },
			let budget_total = this.oProject.map_data.data_budget.budget;
			let budget_usage = this.oProject.map_data.data_budget.epc
			let budget_remaining = this.oProject.map_data.data_budget.remaining;

			let budget_percentage_available = (budget_remaining / budget_total) * 100;
			let budget_percentage_usage = (budget_usage / budget_total) * 100;

			return Math.round(budget_percentage_usage);
		},

		//_ emergency contact
		setEmergencyContact() {
			this.oProject.emergency_contacts = [
				{
					title: this.oApiContainer.site_additional_info.emergency_contact_unit,
					pic: this.oApiContainer.site_additional_info.emergency_contact_pic,
					contact: this.oApiContainer.site_additional_info.emergency_contact_number,
					notes: this.oApiContainer.site_additional_info.emergency_contact_notes
				},
			];
		},

		//_ daily progress to be feed into gallery
		getDailyProgressListing() {
			axios
				.get(apiDomain + 'project/project_site_daily_progress_list/' + this.$route.params.id, { headers: getHeader() })
				.then(response => {
					if (response.status === 200) {
						// console.log('[MapDashboardDetail.vue] > func: getDailyProgressListing')
						// console.log(response);
						// console.log(response.data);
						// console.log(response.data.length);

						if (response.data.data.length > 0) {
							// if ('do not go' == 'here') {
							let tmp_mainContainer = response.data.data.filter(entry => entry.type != 'ptw');

							let arrDaily = [];
							arrDaily[0] = tmp_mainContainer[0];

							// console.log('arrDaily[0]');
							// console.log(arrDaily[0]);

							// Loop through attributes and fetch images for each
							const promises = arrDaily.map((attribute, index) => {
								//_ just 1st entry enough, no need all for now
								if (tmp_mainContainer[0].attachments.length > 0) {
									//_ loop through the attachments on each of entry

									tmp_mainContainer[0].attachments.forEach(element => {
										// console.log('element')
										// console.log(element)

										// let imageId = attribute.key
										let imageId = element.id
										// return axios.post(`project/project_site_token?value=${tmp_id}`)
										return axios
											.post(`project/project_site_token?value=${imageId}`)
											.then(tokenResponse => {
												const token = tokenResponse.data;

												const url = `${apiDomain}project/download/${imageId}?token=${token}`;

												// Fetch the image as a blob
												return axios.get(url, { responseType: 'blob' });
											})
											.then(blobResponse => {
												// Store the image blob inside customData for each attribute
												// console.log(index)
												const imageUrl = URL.createObjectURL(blobResponse.data);

												this.oDashboard.gallery_slides.push({ image: imageUrl });
											});
									});
								}
							});

							// Wait for all image downloads to complete
							return Promise.all(promises);
						}
					}
				})
				.catch(error => {
					this.errorFunction(error, "Project Site Daily Progress Listing")
				})
				.finally(() => {

				});
		},


		getDummyInfoBySite(obj, value) {
			let indexEC = Object.keys(obj).find(key => obj[key].site_code === value);
			if (indexEC !== undefined) {
				// console.log('>> 1st')
				// console.log(obj[indexEC].data)
				this.oProject.map_data = obj[indexEC]

				// this.oDashboard.budget_usage = this.setBudgetChartData();

				this.get_cctv_data();
			}
		},

		get_cctv_data() {
			// console.log('get_cctv_data');

			var api_config = {
				method: 'GET',
				mode: 'no-cors',
				headers: {
					'Access-Control-Allow-Origin': '*',
					'Content-Type': 'application/json',
					"Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
					"Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
				},
				// crossdomain: true
			};
			axios
				.get('https://integraconnect.sembcorp.com/system/webdev/ams/get_cams')
				.then(response => {
					if (response.status === 200) {
						var filteredArray = Object.entries(response.data).filter((row) => row[0] == this.oProject.map_data.data_cctv.code)
						// var filteredArray = Object.entries(response.data).filter((row) => row[0] == '00' )
						// console.log(filteredArray)

						if (filteredArray.length > 0) {
							this.oProject.map_data.data_cctv.cam1 = this.validateCCTVimage(filteredArray[0][1].cam1)
							this.oProject.map_data.data_cctv.cam2 = this.validateCCTVimage(filteredArray[0][1].cam2)
							this.oProject.map_data.data_cctv.cam3 = this.validateCCTVimage(filteredArray[0][1].cam3)
						} else {
							this.oProject.map_data.data_cctv.cam1 = '/img/default/img_not_available.png'
							this.oProject.map_data.data_cctv.cam2 = '/img/default/img_not_available.png'
							this.oProject.map_data.data_cctv.cam3 = '/img/default/img_not_available.png'
						}
					}
				})
				.catch(error => {
					console.log(error)
				})
				.finally(() => {
					// console.log(this.oProject)
				});
		},
		validateCCTVimage(imageData) {
			if (imageData === null) {
				return '/img/default/cctv_no_snapshot.png'
			} else {
				return imageData;
			}
		},
		showCCTVdisplay(imageSrc) {
			this.oDashboard.largeCctvSrc = imageSrc;
			this.oDashboard.isCCTVDisplayVisible = true;
		},
		showGallery() {
			// console.log('gallery open')
			this.oDashboard.isVisibleGallery = true;

			// console.log('[MapDashboardDetail] func: showGallery')
			// console.log(this.oDashboard.gallery_slides)

			//_ workaround to display the carousel, recalculate height of the carousel container
			//  the carousel not display when the modal expanded, because the modal was hidden, and the height of carousel set to 0, 
			//  that's why carousel cannot be seen.
			setTimeout(() => {
				window.dispatchEvent(new Event("resize"));
			}, 250);
		},
		showEmergencyContactInformation() {
			this.oDashboard.isVisibleEmergencyContact = true;
		},
		validateDisplayImage(imageData = null, which_img = '') {
			let retVal = '/img/default/img_not_available.png';
			if (imageData == null || imageData == '') {
				retVal = '/img/default/img_not_available.png'

				return retVal;
			} else {
				// retVal = imageData;
				// retVal = apiDomain + imageData;
				// retVal = `data:${imageData};base64,${imageData}`;

				// console.log('validateDisplayImage')
				// console.log(imageData)
				let tmpPath = imageData['url'].substring(1)
				// console.log (apiDomain + tmpPath)
				axios
					.get(apiDomain + tmpPath, { headers: getHeader() })
					.then(response => {
						if (response.status === 200) {
							// console.log('validateDisplayImage: ' + which_img)
							// console.log (response)
							if (response.data.data.mime && response.data.data.image) {
								retVal = `data:${response.data.data.mime};base64,${response.data.data.image}`;

								(which_img == 'logo') ? this.oProject.map_data.data_client.img_logo = retVal : '';
								(which_img == 'gate') ? this.oProject.map_data.data_client.img_gate = retVal : '';
							} else {
								retVal = null;
							}

							// console.log(retVal);
							return retVal;
						}
					})
			}


		},


		validateProjectTimeline(plan = '', actual = '') {
			let retVal = ''
			if (plan == '') {
				retVal = ''
			} else if (actual == '' || actual == 'N/A') {
				let date1 = this.parseDate(plan);
				let date2 = new Date();
				date2.setHours(0, 0, 0, 0);			//_ this is current date but set hour:min:sec to 00:00:00

				if (date1 > date2) {
					//_ plan > curr, so it mean still ontime
					retVal = 'ontime'
				} else {
					// plan < curr, so it's late
					retVal = 'delay'
				}
			} else {
				retVal = ''
			}
			return retVal;
		},
		project_timeline_actual_info(date_plan, date_actual) {
			let tmp_date_plan = this.format_datetime_to_unix_timestamp(date_plan);
			let tmp_date_actual = this.format_datetime_to_unix_timestamp(date_actual);

			//_ if actual = empty, then compare it with current date
			if (date_actual == '' || date_actual == 'N/A') {
				let tmp_date_current = this.format_datetime_to_unix_timestamp(new Date());

				if (tmp_date_current > tmp_date_plan) {
					let tmp_duration = this.duration_difference_between_date(tmp_date_current, tmp_date_plan, 'day');
					return '<span class="sc_pt_late">(+' + tmp_duration + 'd)</span>';
				}
			} else {
				if (tmp_date_actual > tmp_date_plan) {
					// + ('(+2d)');
					let tmp_duration = this.duration_difference_between_date(tmp_date_actual, tmp_date_plan, 'day');
					return '<span class="sc_pt_late">(+' + tmp_duration + 'd)</span>' + date_actual
					// return date_actual + '<span class="sc_pt_late">(+' + tmp_duration + 'd)</span>'
				} else {
					return date_actual
				}
			}

		},


		//_ custom func, 
		libFormatDateTime(dateSelected) {
			let this_date = (dateSelected !== null) ? dateSelected : "N/A";

			if (this_date !== "N/A") {
				let convert_date = new Date(this_date);
				const year = convert_date.getFullYear();
				const month = convert_date.toLocaleString("en-US", { month: "short" });
				const day = convert_date.toLocaleString("en-US", { day: "2-digit", });
				// this_date = `${day}-${month}-${year}`;
				this_date = `${day} ${month} ${year}`;
			}

			return this_date;
		},
		// Function to convert 'DD MMM YYYY' to Date object
		parseDate(dateStr) {
			const [day, month, year] = dateStr.split(' ');
			return new Date(`${month} ${day}, ${year}`);
		},
		format_datetime_to_unix_timestamp(tmp_value) {
			// console.log('format_datetime_to_unix_timestamp');

			// IMPORTANT: highchart using months in this calculation are zero-based - 0 month is January.  <-- I'm so whattttt ???
			// so what we need to do, the month MINUS 1

			if (tmp_value === null) {
				return 0;
			} else {
				let tmpDD = moment(tmp_value).format('DD');
				let tmpMM = moment(tmp_value).format('MM');
				let tmpYY = moment(tmp_value).format('YYYY');

				return Date.UTC(tmpYY, tmpMM - 1, tmpDD);
			}

			// Date.UTC()
		},
		duration_difference_between_date(timestamp1, timestamp2, convertTo = 'day') {
			// Unix timestamps (in milliseconds), example:
			// const timestamp1 = 1722556800000;
			// const timestamp2 = 1732924800000;

			let retVal = 0;

			if (timestamp1 === null && timestamp2 === null) {
				return retVal;
			} else {
				const differenceInMs = timestamp1 - timestamp2;

				if (convertTo == 'second') {
					retVal = Math.floor(differenceInMs / 1000);
				} else if (convertTo == 'minute') {
					retVal = Math.floor(differenceInMs / (1000 * 60));
				} else if (convertTo == 'hour') {
					retVal = Math.floor(differenceInMs / (1000 * 60 * 60));
				} else {
					retVal = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
				}

				return retVal;
			}

		},
	}
};
</script>


<!-- CSS for map and it's content -->
<style scoped>
/* plz do not tamper required :) it'll mess up the map */
/deep/ .block {
	/* margin: 10px 50px; */
	display: flex;
	/* required */
	flex-flow: row nowrap;
	/* required */
}

/deep/ .block .background,
.block .foreground {
	box-sizing: border-box;
	/* required */
	width: 100%;
	/* required */
	flex: none;
	/* required */
}

/deep/ .block .background {
	/* background: #9ff;
  color: #fff;
  padding: 15px;
  font-size: 30px; */
}

/deep/ .block .foreground {
	padding: 15px;
	border: solid 0px;
	margin-left: -100%;
	/* required */
}

/deep/ .block .foreground .outside {
	position: absolute;
	top: 5px;
	left: 8px;
}

/deep/ #bodyContent:hover>img {
	cursor: pointer;
}
</style>

<!-- draggable layout -->
<style scoped>
/deep/ .grid-widget {
	/* padding-top: 50px !important; */
	color: white;
}

/deep/ .grid-widget svg>path {
	fill: white;
}

/deep/ .smartwidget {
	background-color: #23454a;
	border-radius: 0.25em;
}

/deep/ .is-always-shadow {
	-webkit-box-shadow: 0 0 10px 0 #23454a !important;
	box-shadow: 0 0 10px 0 #23454a !important;
}

/deep/ .widget-header {
	background-color: #2C545A;
}

/deep/ .widget-header__title {
	font-weight: 900 !important;
}
</style>

<!-- map specific -->
<style scoped>
/deep/ .site-map .widget-body__content {
	padding: 0 !important;
}
</style>

<!-- sembcorp PIC -->
<style scoped>
/deep/ .sc_pic_row {
	border-bottom: 1px solid #bdbdbd;
	margin: 0px 5px 0px 5px;
	padding: 3px 10px 3px 10px;
}

/deep/ .sc_pic_col {
	padding-left: 2px !important;
	padding-right: 2px !important;
}

/deep/ .sc_pic_label {
	color: #bdbdbd;
	font-size: 15px;
}

/deep/ .sc_pic_value {
	color: #ffffff;
	font-size: 15px;
	text-align: right;
}
</style>

<!-- SOC -->
<style scoped>
/deep/ .sc_soc_row {
	border-bottom: 1px solid #bdbdbd;
	margin: 0px 5px 0px 5px;
	padding: 5px 10px 3px 10px;
}

/deep/ .sc_soc_col {
	padding-left: 2px !important;
	padding-right: 2px !important;
}

/deep/ .sc_soc_label {
	color: #bdbdbd;
	font-size: 15px;
}

/deep/ .sc_soc_value {
	color: #ffffff;
	font-size: 15px;
	text-align: right;
}
</style>

<!-- asset information -->
<style scoped>
/deep/ .sc_ai_row {
	border-bottom: 1px solid #bdbdbd;
	margin: 0px 5px 0px 5px;
	padding: 5px 10px 3px 10px;
}

/deep/ .sc_ai_col {
	padding-left: 2px !important;
	padding-right: 2px !important;
}

/deep/ .sc_ai_label {
	color: #bdbdbd;
	font-size: 15px;
}

/deep/ .sc_ai_value {
	color: #ffffff;
	font-size: 15px;
	text-align: right
}
</style>

<!-- project timeline -->
<style scoped>
/deep/ .sc_pt_header_desc {
	text-align: left;
	padding: 0 2px 0 2px;
}

/deep/ .sc_pt_header_value {
	text-align: right;
	padding: 0 2px 0 2px;
}

/deep/ .sc_pt_row {
	border-bottom: 1px solid #bdbdbd;
	margin: 0px 5px 0px 5px;
	padding: 5px 10px 3px 10px;
}

/deep/ .sc_pt_col {
	padding-left: 2px !important;
	padding-right: 2px !important;
}

/deep/ .sc_pt_label {
	color: #bdbdbd;
	font-size: 15px;
}

/deep/ .sc_pt_value {
	color: #ffffff;
	font-size: 15px;
	text-align: right
}

/deep/ .sc_pt_late {
	color: #ff0000;
	font-weight: 800;
	margin-right: 5px;
}
</style>

<!-- budget status -->
<style scoped>
/deep/ .sc_bsi_row {
	border-bottom: 1px solid #bdbdbd;
	margin: 0px 5px 0px 5px;
	padding: 5px 10px 3px 10px;
}

/deep/ .sc_bsi_col {
	padding-left: 2px !important;
	padding-right: 2px !important;
}

/deep/ .sc_bsi_label {
	color: #bdbdbd;
	font-size: 15px;
}

/deep/ .sc_bsi_value {
	color: #ffffff;
	font-size: 15px;
	text-align: right
}
</style>

<!-- asset status -->
<style scoped>
/deep/ .sc_as_col {
	/* padding: 0px 5px 0px 5px !important; */
}

/deep/ .sc_as_thumbnail {
	/* height: 100px; 
  width: 100px;  */
	height: 100%;
	width: 100%;
	border-radius: 0.5em;
}
</style>


<!-- budget passcode -->
<style scoped>
/deep/ .budgetPCmain {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 30px;
}

/deep/ .budgetPCitemInput {
	flex-grow: 2;
	height: 0px;

	input {
		width: 90%;
		font-size: 0.75em;
	}
}

/deep/ .budgetPCitemButton {
	flex-grow: 1;
	height: 0px;
}

/deep/ .budgetPCitemMessage {
	flex-grow: 3;
}
</style>

<!-- accordion - disable spin when collapsing, dunno which css / component that causing it -->
<style scoped>
/deep/ .collapsed {
	animation-name: none !important;
	-webkit-animation-name: none !important;
}
</style>

<!-- accordion -->
<style scoped>
/deep/ .accordion {
	background-color: #23454a;
}

/deep/ .accordion .button {}

/deep/ .card {}

/deep/ .card-header {
	background-color: #23454a;
}

/deep/ .card-header:first-child {
	border-radius: 0 !important;
}

/deep/ .card-body {
	background-color: #27484d;
	padding-top: 2px;
	padding-bottom: 5px;
	padding-left: 10px;
	padding-right: 10px;
}

/deep/ .collapsed {
	color: #ffffff;
}

/deep/ .btn-info {
	padding: .375rem .75rem !important;
	text-align: left;
}

/deep/ .btn-info,
.btn-info:hover {
	color: #fff;
	background-color: #23454a !important;
	border-color: #fff !important;
}

/deep/ .btn-info.focus,
.btn-info:focus {
	-webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08), 0 0 0 3px rgb(35 69 74) !important;
	box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08), 0 0 0 3px rgb(35 69 74) !important;
	background: #23464b !important;
}
</style>

<!-- [inside map] css for gate information -->
<style scoped>
/deep/ .dashboard-container-gate-info-parent {
	/* width: 550px; */
	width: 30%;
	float: right;
}

/deep/ .dashboard-container-gate-info {
	background-color: #375f65;
	/* border-color: white; */
	/* width: 552px; */
	width: 100%;
	border: 1px solid white;
	border-radius: 5px;
	/* height: 50px; */
	min-height: 170px;
	align-content: center;
	padding: 10px;
	color: white;
	font-size: 15px;
}

/deep/ .dashboard-container-gate-info-card {
	margin-bottom: 10px;
}
</style>

<!-- [inside map] css for client information & logo -->
<style scoped>
/deep/ .dashboard-container-client-info-parent {
	/* width: 400px; */
	width: 30%;
	/* height: 200px; */
	/* min-height: 300px; */

}

/deep/ .dashboard-container-client-info {
	background-color: #375f65;
	/* border-color: white; */
	/* width: 402px; */
	width: 100%;
	border: 1px solid white;
	border-radius: 5px;
	/* height: 200px; */
	min-height: 150px;
	align-content: center;
	/* padding-left: 20px; */
	color: white;
	font-size: 15px;
}

/deep/ .dashboard-container-client-info-card {
	margin-bottom: 10px;
}

/deep/ .dashboard-container-client-info-row-header {
	margin-top: 15px;
	margin-bottom: 30px;
}

/deep/ .dashboard-container-client-info-row-content {
	margin-bottom: 8px;
	padding-left: 20px;
}

/deep/ .info-label {
	font-size: 11px;
	color: #bdbdbd;
}
</style>

<!-- css for emergency contact -->
<style scoped>
/deep/ .bg-emergency-contact {
	background-color: #375f65 !important;
}

/deep/ .modal-content {
	border: 1px solid white;
}

/deep/ .emergency-contact-modal .modal-dialog .modal-content {
	height: 100% !important;
	border: 0 !important;
	border-radius: 0 !important;
	/* background-color: transparent !important; */
	/* background: rgba(0,0,0,0.5); */
	border: none !important;
}

/deep/ .emergency-contact-main {
	color: white;
}

/deep/ .emergency-contact-main table,
th,
td {
	border: 1px solid white;
}

/deep/ .emergency-contact-main th,
td {
	padding-left: 8px;
	padding-right: 8px;
}

/deep/ .emergency-contact-main th {
	background-color: #18393e;
}
</style>