<template>
  <div>
    <div class=" py-7 py-lg-8 pt-lg-5">
      <b-container>
        <div class="header-body mt-6">
          <b-row>
            <b-col md="6">
              <card class="bg-card-dashboard" >
                <!-- Card body -->
                <b-link to="mainDashboardSiteDetail/8252" target="_blank">
                  <b-img src="img/theme/dashboard/globe_v1.png"
                    class="img-center " style="width: 97px;" />
                  <div class="pt-4 text-center">
                    <h5 class="h3 title">
                      <span class="d-block text-cyan mb-1">Dashboard</span>
                    </h5>
                  </div>
                </b-link>
              </card>
            </b-col>
            <b-col md="6">
              <card class="bg-card-dashboard" >
                <!-- Card body -->
                <b-link to="KranjiTask" target="_blank">
                  <b-img src="img/theme/dashboard/project.png" class="img-center " style="width: 88px;" />
                  <div class="pt-4 text-center">
                    <h5 class="h3 title">
                      <span class="d-block text-cyan mb-1">Project Tracking</span>
                    </h5>
                  </div>
              </b-link>
              </card>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <card class="bg-card-dashboard" >
                <!-- Card body -->
                <b-link to="KranjiDocRepo" target="_blank">
                  <b-img src="img/theme/dashboard/document.png" class="img-center " style="width: 88px;" />
                  <div class="pt-4 text-center">
                    <h5 class="h3 title">
                      <span class="d-block text-cyan mb-1">Document Repository</span>
                    </h5>
                  </div>
              </b-link>
              </card>
            </b-col>
            <b-col md="6">
              <card class="bg-card-dashboard" >
                <!-- Card body -->
                <b-link to="KranjiSafety" target="_blank">
                  <b-img src="img/theme/dashboard/healtsafety.png" class="img-center " style="width: 88px;" />
                  <div class="pt-4 text-center">
                    <h5 class="h3 title">
                      <span class="d-block text-cyan mb-1">Health & Safety</span>
                    </h5>
                  </div>
              </b-link>
              </card>
            </b-col>
          </b-row>  
        </div>
      </b-container>

    </div>


  </div>
</template>
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';

  // Components
  import BaseProgress from '@/components/BaseProgress';
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';

  // Lists
  import ActivityFeed from './ActivityFeed';
  import TaskList from './TaskList';
  import UserList from './UserList';
  import ProgressTrackList from './ProgressTrackList';

  // Tables
  import LightTable from './LightTable';
  import SocialTrafficTable from './SocialTrafficTable';
  import PageVisitsTable from './PageVisitsTable';

  export default {
    components: {
      // ActivityFeed,
      // LineChart,
      // BarChart,
      // BaseProgress,
      // RouteBreadCrumb,
      // StatsCard,
      // TaskList,
      // PageVisitsTable,
      // SocialTrafficTable,
      // LightTable,
      // UserList,
      // ProgressTrackList
    },
    data() {
      return {
        bigLineChart: {
          allData: [
            [0, 20, 10, 30, 15, 40, 20, 60, 60],
            [0, 20, 5, 25, 10, 30, 15, 40, 40]
          ],
          activeIndex: 0,
          chartData: {
            datasets: [
              {
                label: 'Performance',
                data: [0, 20, 10, 30, 15, 40, 20, 60, 60],
              }
            ],
            labels: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          },
          extraOptions: chartConfigs.blueChartOptions,
        },
        redBarChart: {
          chartData: {
            labels: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            datasets: [{
              label: 'Sales',
              data: [25, 20, 30, 22, 17, 29]
            }]
          },
          extraOptions: chartConfigs.blueChartOptions
        }
      };
    },
    methods: {
      initBigChart(index) {
        let chartData = {
          datasets: [
            {
              label: 'Performance',
              data: this.bigLineChart.allData[index]
            }
          ],
          labels: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        };
        this.bigLineChart.chartData = chartData;
        this.bigLineChart.activeIndex = index;
      }
    },
    mounted() {
      this.initBigChart(0);
    }
  };
</script>
<style>
.bg-card-dashboard {
  background-color: #23454A;
}
</style>
