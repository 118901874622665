<template>
  <div class="bg-default-dashboard">
    <div class=" py-lg-8 pt-lg-5">
      <b-container fluid style="width: 90%; position: relative;">
        <b-row class="mb-1">
          <b-col md="12">
            <card class="bg-card-dashboard">
              <b-row>
                <b-col md="6">
                  <span style="color: white">site name: {{ site_name }} - additional info id: {{ site_additional_info_id
                    }}</span>
                </b-col>
              </b-row>
            </card>
          </b-col>
        </b-row>

        <!-- Security, and this is actually very very important, only showing the page for admin only -->
        <!-- should apply this kind of mechanism on all page / maybe from router if possible -->
        <div class="layout-center" v-if="myUserID.role_id == '3'">
          <div class="accordion" role="tablist">
            <!-- template update (hardcoded) -->
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color: red">
                <b-button block v-b-toggle.accordion-1 variant="info">Partial Update using Fix Template Data (hardcoded
                  value) - NOT all fields</b-button>
              </b-card-header>
              <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <base-button type="danger" @click="setSiteAdditionalInformation()">Update Site Additional Information
                    from template - ARE YOU SURE ?</base-button>
                </b-card-body>
              </b-collapse>
            </b-card>

            <!-- client PIC update : name, contact, logo, gate -->
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 variant="info">Client PIC (name, contact, logo, gate)</b-button>
              </b-card-header>
              <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-row class="mb-3">
                    <b-col md="3"><label for="" class="text-white">PIC name</label></b-col>
                    <b-col md="9">
                      <b-form-input name="client_pic_name" placeholder="" v-model="model_client_info.client_pic_name"
                        class="form-control"></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col md="3"><label for="" class="text-white">PIC contact number</label></b-col>
                    <b-col md="9">
                      <b-form-input name="client_pic_contact" placeholder=""
                        v-model="model_client_info.client_pic_contact" class="form-control"></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col md="3"><label for="" class="text-white">Client Logo</label></b-col>
                    <b-col md="9">
                      <!-- <input v-show="false" id="files-upload" type="file" ref="file" style="display:none"
                        accept="image/*" @change="fileChange($event)" />
                      <base-button size="md" type="primary" @click.prevent="uploadAttachment('files-upload')">Add
                        Attachment v.DPI</base-button>

                      <input v-show="false" type="file" id="imageFile" ref="imageFile"
                        accept="image/jpeg, image/png, image/gif, image/webp" v-on:change="handleImageFileUpload()" />
                      <base-button style="float: right; padding: 0px 10px; height: 35px;" type="primary"
                        @click="$refs.imageFile.click()">Add Photo v.HSE</base-button> -->

                      <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%;"
                        @click.prevent="$refs.fileLogo.click()">Select File</base-button>
                      <input type="file" ref="fileLogo" style="display:none" @change="onChangeLogo" />
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col md="3"><label for="" class="text-white">Client Gate</label></b-col>
                    <b-col md="9">
                      <base-button size="md" class="p-1 m-2" style="background-color: #375623; width: 100%;"
                        @click.prevent="$refs.fileGate.click()">Select File</base-button>
                      <input type="file" ref="fileGate" style="display:none" @change="onChangeGate" />
                    </b-col>
                  </b-row>

                  <base-button type="primary" @click="updateClientInformation()">Update Client Information</base-button>
                </b-card-body>
              </b-collapse>
            </b-card>

            <!-- budget -->
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-3 variant="info">Budget</b-button>
              </b-card-header>
              <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-row class="mb-3">
                    <b-col md="3"><label for="" class="text-white">Passcode</label></b-col>
                    <b-col md="9">
                      <b-form-input name="budget_passcode" placeholder="" v-model="model_budget.budget_passcode"
                        class="form-control"></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col md="3"><label for="" class="text-white">Total</label></b-col>
                    <b-col md="9">
                      <b-form-input name="budget_total" placeholder="" v-model="model_budget.budget_total"
                        class="form-control"></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col md="3"><label for="" class="text-white">EPC</label></b-col>
                    <b-col md="9">
                      <b-form-input name="budget_epc" placeholder="" v-model="model_budget.budget_epc"
                        class="form-control"></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col md="3"><label for="" class="text-white">Spent</label></b-col>
                    <b-col md="9">
                      <b-form-input name="budget_spent" placeholder="" v-model="model_budget.budget_spent"
                        class="form-control"></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col md="3"><label for="" class="text-white">Balance</label></b-col>
                    <b-col md="9">
                      <b-form-input name="budget_balance" placeholder="" v-model="model_budget.budget_balance"
                        class="form-control"></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col md="3"><label for="" class="text-white">Remaining</label></b-col>
                    <b-col md="9">
                      <b-form-input name="budget_remaining" placeholder="" v-model="model_budget.budget_remaining"
                        class="form-control"></b-form-input>
                    </b-col>
                  </b-row>

                  <base-button type="primary" @click="updateBudget()">Update Budget</base-button>
                </b-card-body>
              </b-collapse>
            </b-card>

            <!-- emergency contact -->
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-4 variant="info">Emergency Contact</b-button>
              </b-card-header>
              <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-row class="mb-3">
                    <b-col md="3"><label for="" class="text-white">Unit Name</label></b-col>
                    <b-col md="9">
                      <b-form-input name="emergency_contact_unit" placeholder=""
                        v-model="model_emergency_contact.emergency_contact_unit" class="form-control"></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col md="3"><label for="" class="text-white">Person in Charge</label></b-col>
                    <b-col md="9">
                      <b-form-input name="emergency_contact_pic" placeholder=""
                        v-model="model_emergency_contact.emergency_contact_pic" class="form-control"></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col md="3"><label for="" class="text-white">Contact Number</label></b-col>
                    <b-col md="9">
                      <b-form-input name="emergency_contact_number" placeholder=""
                        v-model="model_emergency_contact.emergency_contact_number" class="form-control"></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col md="3"><label for="" class="text-white">Notes</label></b-col>
                    <b-col md="9">
                      <b-form-input name="emergency_contact_notes" placeholder=""
                        v-model="model_emergency_contact.emergency_contact_notes" class="form-control"></b-form-input>
                    </b-col>
                  </b-row>

                  <base-button type="primary" @click="updateEmergencyContact()">Update Emergency Contact</base-button>
                </b-card-body>
              </b-collapse>
            </b-card>

            <!-- project timeline -->
            <b-card no-body class="mb-1">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-5 variant="info">Project Timeline</b-button>
              </b-card-header>
              <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <b-row class="mb-3">
                    <b-col md="3"><label for="" class="text-white">Energy Guarantee</label></b-col>
                    <b-col md="6">
                      <b-form-input name="energy_guarantee" placeholder=""
                        v-model="model_project_timeline.energy_guarantee" class="form-control"></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col md="3"><label for="" class="text-white">ITB</label></b-col>
                    <b-col md="3">
                      <b-form-input name="itb_plan_date" placeholder="" v-model="model_project_timeline.itb_plan_date"
                        class="form-control"></b-form-input>
                    </b-col>
                    <b-col md="3">
                      <b-form-input name="itb_actual_date" placeholder=""
                        v-model="model_project_timeline.itb_actual_date" class="form-control"></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col md="3"><label for="" class="text-white">PLN Submission</label></b-col>
                    <b-col md="3">
                      <b-form-input name="pln_submission_plan_date" placeholder=""
                        v-model="model_project_timeline.pln_submission_plan_date" class="form-control"></b-form-input>
                    </b-col>
                    <b-col md="3">
                      <b-form-input name="pln_submission_actual_date" placeholder=""
                        v-model="model_project_timeline.pln_submission_actual_date" class="form-control"></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col md="3"><label for="" class="text-white">PLN Permit</label></b-col>
                    <b-col md="3">
                      <b-form-input name="pln_permit_plan_date" placeholder=""
                        v-model="model_project_timeline.pln_permit_plan_date" class="form-control"></b-form-input>
                    </b-col>
                    <b-col md="3">
                      <b-form-input name="pln_permit_actual_date" placeholder=""
                        v-model="model_project_timeline.pln_permit_actual_date" class="form-control"></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col md="3"><label for="" class="text-white">LOA</label></b-col>
                    <b-col md="3">
                      <b-form-input name="loa_sign_plan_date" placeholder=""
                        v-model="model_project_timeline.loa_sign_plan_date" class="form-control"></b-form-input>
                    </b-col>
                    <b-col md="3">
                      <b-form-input name="loa_sign_actual_date" placeholder=""
                        v-model="model_project_timeline.loa_sign_actual_date" class="form-control"></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col md="3"><label for="" class="text-white">NTP</label></b-col>
                    <b-col md="3">
                      <b-form-input name="ntp_plan_date" placeholder="" v-model="model_project_timeline.ntp_plan_date"
                        class="form-control"></b-form-input>
                    </b-col>
                    <b-col md="3">
                      <b-form-input name="ntp_issue_date" placeholder="" v-model="model_project_timeline.ntp_issue_date"
                        class="form-control"></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col md="3"><label for="" class="text-white">KOM</label></b-col>
                    <b-col md="3">
                      <b-form-input name="kom_plan_date" placeholder="" v-model="model_project_timeline.kom_plan_date"
                        class="form-control"></b-form-input>
                    </b-col>
                    <b-col md="3">
                      <b-form-input name="kick_off_meeting_date" placeholder=""
                        v-model="model_project_timeline.kick_off_meeting_date" class="form-control"></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col md="3"><label for="" class="text-white">QAQC</label></b-col>
                    <b-col md="3">
                      <b-form-input name="qaqc_plan_date" placeholder="" v-model="model_project_timeline.qaqc_plan_date"
                        class="form-control"></b-form-input>
                    </b-col>
                    <b-col md="3">
                      <b-form-input name="qaqc_actual_date" placeholder=""
                        v-model="model_project_timeline.qaqc_actual_date" class="form-control"></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col md="3"><label for="" class="text-white">T&C</label></b-col>
                    <b-col md="3">
                      <b-form-input name="tnc_plan_date" placeholder="" v-model="model_project_timeline.tnc_plan_date"
                        class="form-control"></b-form-input>
                    </b-col>
                    <b-col md="3">
                      <b-form-input name="tnc_actual_date" placeholder=""
                        v-model="model_project_timeline.tnc_actual_date" class="form-control"></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col md="3"><label for="" class="text-white">ULO</label></b-col>
                    <b-col md="3">
                      <b-form-input name="ulo_plan_date" placeholder="" v-model="model_project_timeline.ulo_plan_date"
                        class="form-control"></b-form-input>
                    </b-col>
                    <b-col md="3">
                      <b-form-input name="ulo_actual_date" placeholder=""
                        v-model="model_project_timeline.ulo_actual_date" class="form-control"></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col md="3"><label for="" class="text-white">SLO</label></b-col>
                    <b-col md="3">
                      <b-form-input name="slo_plan_date" placeholder="" v-model="model_project_timeline.slo_plan_date"
                        class="form-control"></b-form-input>
                    </b-col>
                    <b-col md="3">
                      <b-form-input name="slo_actual_date" placeholder=""
                        v-model="model_project_timeline.slo_actual_date" class="form-control"></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col md="3"><label for="" class="text-white">COD</label></b-col>
                    <b-col md="3">
                      <b-form-input name="cod_plan_date" placeholder="" v-model="model_project_timeline.cod_plan_date"
                        class="form-control"></b-form-input>
                    </b-col>
                    <b-col md="3">
                      <b-form-input name="cod_actual_date" placeholder=""
                        v-model="model_project_timeline.cod_actual_date" class="form-control"></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col md="3"><label for="" class="text-white">PAC</label></b-col>
                    <b-col md="3">
                      <b-form-input name="pac_plan_date" placeholder="" v-model="model_project_timeline.pac_plan_date"
                        class="form-control"></b-form-input>
                    </b-col>
                    <b-col md="3">
                      <b-form-input name="pac_actual_date" placeholder=""
                        v-model="model_project_timeline.pac_actual_date" class="form-control"></b-form-input>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col md="3"><label for="" class="text-white">DLP</label></b-col>
                    <b-col md="3">
                      <b-form-input name="dlp_plan_date" placeholder="" v-model="model_project_timeline.dlp_plan_date"
                        class="form-control"></b-form-input>
                    </b-col>
                    <b-col md="3">
                      <b-form-input name="dlp_actual_date" placeholder=""
                        v-model="model_project_timeline.dlp_actual_date" class="form-control"></b-form-input>
                    </b-col>
                  </b-row>
                  <base-button type="primary" @click="updateProjectTimeline()">Update Project Timeline</base-button>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>

      </b-container>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate, } from "@/assets/js/config.js";
import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";
// import moment from "moment";
import axios from '@/util/axios';
import jsonFormData from 'json-form-data';

export default {
  name: "EditSiteAdditionalInformation",
  mixins: [ErrorFunctionMixin],
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  created() {
    const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
    this.myUserID = LoginUser;

    this.getSiteListing();
    this.getSiteAdditionalInformation();
  },
  data() {
    return {
      site_name: null,
      site_additional_info_id: null,
      att_logo: [],
      model_client_info: {
        site_id: null,
        project_site_additional_info_id: null,
        client_pic_name: '',
        client_pic_contact: null,
        client_pic_gate: [],
        client_pic_logo: [],
        // client_pic_logo_image: [],
      },
      model_budget: {
        site_id: null,
        project_site_additional_info_id: null,
        budget_passcode: '',
        budget_total: null,
        budget_epc: null,
        budget_spent: null,
        budget_balance: null,
        budget_remaining: null
      },
      model_emergency_contact: {
        site_id: null,
        project_site_additional_info_id: null,
        emergency_contact_unit: '',
        emergency_contact_pic: null,
        emergency_contact_number: null,
        emergency_contact_notes: null
      },
      model_project_timeline: {
        site_id: null,
        project_site_additional_info_id: null,
        itb_plan_date: null, itb_actual_date: null,
        pln_submission_plan_date: null, pln_submission_actual_date: null,
        pln_permit_plan_date: null, pln_permit_actual_date: null,
        loa_sign_plan_date: null, loa_sign_actual_date: null,
        ntp_plan_date: null, ntp_issue_date: null,
        kom_plan_date: null, kick_off_meeting_date: null,
        qaqc_plan_date: null, qaqc_actual_date: null,
        tnc_plan_date: null, tnc_actual_date: null,
        ulo_plan_date: null, ulo_actual_date: null,
        slo_plan_date: null, slo_actual_date: null,
        cod_plan_date: null, cod_actual_date: null,
        pac_plan_date: null, pac_actual_date: null,
        dlp_plan_date: null, dlp_actual_date: null,
        energy_guarantee: null
      },
    };
  },
  methods: {
    getSiteListing() {
      axios
        .get(apiDomain + 'project/project_site/' + this.$route.params.id, { headers: getHeader() })
        .then(response => {
          if (response.status === 200) {
            // console.log('getSiteListing');
            // console.log(response);

            this.site_name = response.data.data[0].project.project_name;

            // this.oDashboard.code_site = response.data.data[0].site_id
            // this.oDashboard.code_proj = response.data.data[0].project.project_id
            // this.oProject.map.lat = Number(response.data.data[0].site_latitude)
            // this.oProject.map.lng = Number(response.data.data[0].site_longitude)
          }
        })
        .catch(error => {
          console.log(error.response.status)
        })
        .finally(() => {
          // this.getDashboardData(this.oApiContainer.site.project.project_id);
          // this.getContractorFromSite();
        });
    },
    getSiteAdditionalInformation() {
      axios
        .get(apiDomain + 'project/project_site_additional_info/' + this.$route.params.id, { headers: getHeader() })
        .then(response => {
          // console.log('[MapDashboardDetail.vue] > func: getSiteAdditionalInformation')
          // console.log(response)
          this.site_additional_info_id = response.data.data[0].project_site_additional_info_id;

          // client information
          this.model_client_info.client_pic_name = response.data.data[0].client_pic_name;
          this.model_client_info.client_pic_contact = response.data.data[0].client_pic_contact;

          // budget
          this.model_budget.budget_passcode = response.data.data[0].budget_passcode;
          this.model_budget.budget_total = response.data.data[0].budget_total;
          this.model_budget.budget_epc = response.data.data[0].budget_epc;
          this.model_budget.budget_spent = response.data.data[0].budget_spent;
          this.model_budget.budget_balance = response.data.data[0].budget_balance;
          this.model_budget.budget_remaining = response.data.data[0].budget_remaining;

          // emergency contact
          this.model_emergency_contact.emergency_contact_unit = response.data.data[0].emergency_contact_unit;
          this.model_emergency_contact.emergency_contact_pic = response.data.data[0].emergency_contact_pic;
          this.model_emergency_contact.emergency_contact_number = response.data.data[0].emergency_contact_number;
          this.model_emergency_contact.emergency_contact_notes = response.data.data[0].emergency_contact_notes;

          // project timeline
          this.model_project_timeline.itb_plan_date = response.data.data[0].itb_plan_date;
          this.model_project_timeline.itb_actual_date = response.data.data[0].itb_actual_date;
          this.model_project_timeline.pln_submission_plan_date = response.data.data[0].pln_submission_plan_date;
          this.model_project_timeline.pln_submission_actual_date = response.data.data[0].pln_submission_actual_date;
          this.model_project_timeline.pln_permit_plan_date = response.data.data[0].pln_permit_plan_date;
          this.model_project_timeline.pln_permit_actual_date = response.data.data[0].pln_permit_actual_date;
          this.model_project_timeline.loa_sign_plan_date = response.data.data[0].loa_sign_plan_date;
          this.model_project_timeline.loa_sign_actual_date = response.data.data[0].loa_sign_actual_date;
          this.model_project_timeline.ntp_plan_date = response.data.data[0].ntp_plan_date;
          this.model_project_timeline.ntp_issue_date = response.data.data[0].ntp_issue_date;
          this.model_project_timeline.kom_plan_date = response.data.data[0].kom_plan_date;
          this.model_project_timeline.kick_off_meeting_date = response.data.data[0].kick_off_meeting_date;
          this.model_project_timeline.qaqc_plan_date = response.data.data[0].qaqc_plan_date;
          this.model_project_timeline.qaqc_actual_date = response.data.data[0].qaqc_actual_date;
          this.model_project_timeline.tnc_plan_date = response.data.data[0].tnc_plan_date;
          this.model_project_timeline.tnc_actual_date = response.data.data[0].tnc_actual_date;
          this.model_project_timeline.ulo_plan_date = response.data.data[0].ulo_plan_date;
          this.model_project_timeline.ulo_actual_date = response.data.data[0].ulo_actual_date;
          this.model_project_timeline.slo_plan_date = response.data.data[0].slo_plan_date;
          this.model_project_timeline.slo_actual_date = response.data.data[0].slo_actual_date;
          this.model_project_timeline.cod_plan_date = response.data.data[0].cod_plan_date;
          this.model_project_timeline.cod_actual_date = response.data.data[0].cod_actual_date;
          this.model_project_timeline.pac_plan_date = response.data.data[0].pac_plan_date;
          this.model_project_timeline.pac_actual_date = response.data.data[0].pac_actual_date;
          this.model_project_timeline.dlp_plan_date = response.data.data[0].dlp_plan_date;
          this.model_project_timeline.dlp_actual_date = response.data.data[0].dlp_actual_date;
          this.model_project_timeline.energy_guarantee = response.data.data[0].energy_guarantee;
        })
        .catch(error => {
          this.errorFunction(error, "Get project site additional information")
        })
        .finally(() => {
        });
    },
    onChangeLogo(event) {
      this.model_client_info.client_pic_logo = event.target.files ? event.target.files[0] : null;
    },
    onChangeGate(event) {
      this.model_client_info.client_pic_gate = event.target.files ? event.target.files[0] : null;
    },
    updateClientInformation_new() {
      this.model_client_info.site_id = this.$route.params.id;
      this.model_client_info.project_site_additional_info_id = this.site_additional_info_id;
      this.model_client_info.client_pic_logo = this.att_logo;

      let target_url = '';
      target_url = apiDomain + 'project/update_project_site_additional_info/' + this.$route.params.id;

      let formData = jsonFormData(this.model_client_info);

      console.log('updateClientInformation')
      console.log(formData)

      axios
        .post(target_url, formData)
        .then(response => {
          this.$notify({
              message:
                '<b>Update Client Information</b><br><br> Successfully Updated.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
        })
        .catch(error => {
          this.errorFunction(error, "Update client information")
        }).
        finally(() => {
          //_
        });
    },
    updateClientInformation() {
      this.model_client_info.site_id = this.$route.params.id;
      this.model_client_info.project_site_additional_info_id = this.site_additional_info_id;
      // console.log(target_url);

      console.log('updateClientInformation')
      console.log(this.model_client_info)

      let formData = jsonFormData(this.model_client_info);
      // console.log(formData) 

      axios
        .post(apiDomain + 'project/update_project_site_additional_info/' + this.$route.params.id, formData, { headers: getHeader() })
        .then(response => {
          if (response.status === 200) {
            this.$notify({
              message:
                '<b>Update Client Information</b><br><br> Successfully Updated.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
            //   this.$router.back()

          }
        })
        .catch(error => {
          this.errorFunction(error, "Update client information")
        });
    },
    updateBudget() {
      this.model_budget.site_id = this.$route.params.id;
      this.model_budget.project_site_additional_info_id = this.site_additional_info_id;
      // console.log(target_url);
      // console.log(this.model)

      axios
        .post(apiDomain + 'project/update_project_site_additional_info/' + this.$route.params.id, this.model_budget, { headers: getHeader() })
        .then(response => {
          if (response.status === 200) {
            this.$notify({
              message:
                '<b>Update Budget</b><br><br> Successfully Updated.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
            //   this.$router.back()

          }
        })
        .catch(error => {
          this.errorFunction(error, "Update Budget")
        });
    },
    updateEmergencyContact() {
      this.model_emergency_contact.site_id = this.$route.params.id;
      this.model_emergency_contact.project_site_additional_info_id = this.site_additional_info_id;
      // console.log(target_url);
      // console.log(this.model)

      axios
        .post(apiDomain + 'project/update_project_site_additional_info/' + this.$route.params.id, this.model_emergency_contact, { headers: getHeader() })
        .then(response => {
          if (response.status === 200) {
            this.$notify({
              message:
                '<b>Update Emergency Contact</b><br><br> Successfully Updated.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
            //   this.$router.back()

          }
        })
        .catch(error => {
          this.errorFunction(error, "Update Emergency Contact")
        });
    },
    updateProjectTimeline() {
      this.model_project_timeline.site_id = this.$route.params.id;
      this.model_project_timeline.project_site_additional_info_id = this.site_additional_info_id;

      axios
        .post(apiDomain + 'project/update_project_site_additional_info/' + this.$route.params.id, this.model_project_timeline, { headers: getHeader() })
        .then(response => {
          if (response.status === 200) {
            this.$notify({
              message:
                '<b>Update Project Timeline</b><br><br> Successfully Updated.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
            //   this.$router.back()

          }
        })
        .catch(error => {
          this.errorFunction(error, "Update Project Timeline")
        });
    },
    setSiteAdditionalInformation() {
      let tmpData = {
        //_ required
        site_id: this.$route.params.id,
        project_site_additional_info_id: this.site_additional_info_id,

        //_ optional
        itb_plan_date: '2024-08-29',
        itb_actual_date: '',
        pln_submission_plan_date: '2024-07-23',
        pln_submission_actual_date: '',
        pln_permit_plan_date: '2024-08-02',
        pln_permit_actual_date: '',
        loa_sign_plan_date: '2024-08-22',
        loa_sign_actual_date: '',
        ntp_plan_date: '2024-09-19',
        kom_plan_date: '2024-09-10',
        qaqc_plan_date: '2024-12-26',
        qaqc_actual_date: '',
        tnc_plan_date: '2025-01-01',
        tnc_actual_date: '',
        ulo_plan_date: '2025-01-03',
        ulo_actual_date: '',
        slo_plan_date: '2025-01-17',
        slo_actual_date: '',
        cod_plan_date: '2025-01-03',
        cod_actual_date: '',
        pac_plan_date: '2025-01-17',
        pac_actual_date: '',
        dlp_plan_date: '2025-01-17',
        dlp_actual_date: '',
        pv1_letter_date: '',
        pv2_letter_date: '',
        maa_submission_date: '',
        maa_approval_date: '',
        pc_planned_date: '',
        pso_submission_date: '',
        igf_submission_date: '',
        igf_approval_date: '',
        sa_received_date: '',
        planning_start_date: '',
        pb_start_date: '',
        pb_end_date: '',
        cs1_submission_date: '',
        cod_formula_date: '',
        energy_guarantee: '81.13',
        bess: '',
        budget_passcode: 'pass#dmst3',
        budget_total: 1500,
        budget_epc: 900,
        budget_spent: 750,
        budget_balance: 50,
        budget_remaining: 600,
        // client_pic_name: '',
        // client_pic_contact: null,
        // client_pic_gate_image: null,
        // client_pic_logo_image: null
      }

      // tmpData.client_pic_name = this.model.client_pic_name;
      // tmpData.client_pic_contact = this.model.client_pic_contact;
      // tmpData.client_pic_gate_image = this.model.client_pic_gate;
      // tmpData.client_pic_logo_image = this.model.client_pic_logo;

      axios
        .post(apiDomain + 'project/update_project_site_additional_info/' + this.$route.params.id, tmpData, { headers: getHeader() })
        .then(response => {
          if (response.status === 200) {
            this.$notify({
              message:
                '<b>Update Project Site Additional Information</b><br><br> Successfully Updated.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
            //   this.$router.back()

          }
        })
        .catch(error => {
          console.log(error.response.data.errors)
          this.errorFunction(error, "Update project site additional information")
        });
    },



    //_ not use anymore

    uploadAttachment(id) {
      // console.log('uploadAttachment')
      document.getElementById(id).click()
    },
    fileChange(event) {
      let file = event.target.files[0];

      // Remove type validation, allow any file type
      let newArray = { uploaded_file: file };
      console.log(newArray)

      // Add file to the model and documents
      this.model_client_info.client_pic_logo.push(newArray);
      // this.model_client_info.client_pic_logo_image.push(newArray);
      // this.att_logo.push(newArray);
    },
    handleImageFileUpload() {
      let reader = new FileReader()
      reader.onload = (event) => {
        this.model_client_info.client_pic_logo = event.target.result
        // this.model_client_info.client_pic_logo_image = event.target.result
      }
      reader.readAsDataURL(this.$refs.imageFile.files[0])
    },
    handleImageFileUpload_hse() {
      let vm = this;
      // this.model.safety_observation_photos.push({
      //   safety_observation_item_file_type_id: 2,
      //   photoFile: {},
      //   photoPreviewSrc: "",
      //   photoRemarks: "",
      // });
      // this.model_client_info.client_pic_logo_image.push({ photoFile: {} })
      console.log(this.$refs.imageFile.files[0]);
      this.model_client_info.client_pic_logo = this.$refs.imageFile.files[0];
      // this.model_client_info.client_pic_logo_image = this.$refs.imageFile.files[0];

      // var reader = new FileReader();
      // reader.onload = function (e) {
      //   vm.model.safety_observation_photos.at(-1).photoPreviewSrc =
      //     e.target.result;
      // };

      // reader.readAsDataURL(
      //   this.model.safety_observation_photos.at(-1).photoFile
      // );

      var reader = new FileReader();
      reader.onload = function (e) {
        vm.model_client_info.client_pic_logo = e.target.result;
      };
      reader.readAsDataURL(
        this.model_client_info.client_pic_logo
      );
    },    
  },
  mounted() {
  }
};
</script>


<!-- filter tabulation -->
<style scoped>
/deep/ .nav-pills .nav-link {
  /* padding: 0.75rem 1rem; */
  color: #cdcfdb;
  font-weight: 500;
  font-size: 0.875rem;
  -webkit-box-shadow: 0 0px 0px rgba(50, 50, 93, 0.11), 0 0px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0px 0px rgba(50, 50, 93, 0.11), 0 0px 0px rgba(0, 0, 0, 0.08);
  background-color: #3d697100;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;

  &:hover {
    color: white;
  }
}

/deep/ .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: #175f6be3;
  border-left: 3px solid #85BCCE;
  width: 100%;

}

/deep/ .nav-pills .nav-item:not(:last-child) {
  padding-right: 0rem;
}


/deep/ .card-header {
  background-color: #3d6971d9;
  padding: 20px 0 20px 0;

}
</style>

<style scoped>
.card {
  background-color: #082a2f;
}

.btn {
  padding: .45rem .75rem;
}

.btn-dark,
.btn-dark:hover {
  color: #fff;
  background-color: #2c545a;
  border-color: #2c545a;
}

/* .modal-content {
  background-color: #306b74 !important;
}

.modal-header {
  padding-bottom: 0px;
} */
</style>

<style scoped>
/deep/ .modal-content {
  background-color: #306b74 !important;
}

/deep/ .modal-body {
  background-color: #306b74 !important;
}

/deep/ .modal-header {
  padding-bottom: 0px;
}

table thead th {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.65rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: #3a656b;
  color: white;
  padding: 20px;
  border-bottom: 0px solid #082A2F;
  border-top: 0px solid #e9ecef;
}

.th-table-modal-new {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
}

.tr-td-table-modal-new {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
}

.th-table-new {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 20px;
}

.tr-td-table-new {
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 20px;
}

.form-control {
  background-color: #2C545A;
  border-color: #2C545A;
  color: white;

  &:focus {
    background-color: #2C545A;
    border-color: #2C545A;
    color: white;
  }

  &::placeholder {
    color: white;
  }
}

.form-control {
  &:focus {
    &::placeholder {
      color: white;
    }
  }
}

/deep/ .form-control {
  background-color: #2C545A;
  border-color: #2C545A;
  color: white;

  &:focus {
    background-color: #2C545A;
    border-color: #2C545A;
    /* color: white; */
  }

  &::placeholder {
    color: white;
  }
}

/deep/ .form-control {
  &:focus {
    &::placeholder {
      color: white;
    }
  }
}

/deep/ .form-control,
.form-row,
select option,
textarea {
  color: #6d96a5 !important;
}

select {
  background: url("data:image/svg+xml,<svg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.02552 6.35057L0.320068 0.46875H9.73098L5.02552 6.35057Z' fill='white'/></svg>") no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  padding-right: 2rem !important;
}
</style>

<!-- pagination style -->
<style scoped>
.pagination {
  color: #8898aa;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
</style>

<!-- dataset -->
<style scoped>
/deep/ div.dataset-style select {
  color: #ffffff;
  background-color: #2c545a;
  border-color: #2c545a;
}

/deep/ div.dataset-style select.form-control {
  color: #ffffff;
  background-color: #2c545a;
  border-color: #2c545a;
}

/deep/ div.dataset-style select>option {
  color: #ffffff;
  background-color: #2c545a;
  border-color: #2c545a;
}
</style>

<!-- grid observation item -->
<style scoped>
/deep/ .grid-obs-item thead {
  background-color: rgb(82 146 155);
  color: white;
  font-size: small;
  border-bottom: 20px solid #306b74;
}

/deep/ .th-table-new-mdl {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  font-size: small;
}

.list-group-item {
  background-color: #23454A;
  border-bottom: 1px solid black;
}
</style>

<style scoped>
div.dataset-style select {
  color: black;
  background-color: #2C545A;
  border-color: #2C545A;
}
</style>

<!-- accordion - disable spin when collapsing, dunno which css / component that causing it globally, it's annoying -->
<style scoped>
/deep/ .collapsed {
  animation-name: none !important;
  -webkit-animation-name: none !important;
}
</style>

<!-- accordion -->
<style scoped>
/deep/ .accordion {
  background-color: #23454a;
}

/deep/ .accordion .button {}

/deep/ .card {}

/deep/ .card-header {
  background-color: #23454a;
}

/deep/ .card-header:first-child {
  border-radius: 0 !important;
}

/deep/ .card-body {
  background-color: #27484d;
  padding-top: 2px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

/deep/ .collapsed {
  color: #ffffff;
}

/deep/ .btn-info {
  padding: .375rem .75rem !important;
  text-align: left;
}

/deep/ .btn-info,
.btn-info:hover {
  color: #fff;
  background-color: #23454a !important;
  border-color: #fff !important;
}

/deep/ .btn-info.focus,
.btn-info:focus {
  -webkit-box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08), 0 0 0 3px rgb(35 69 74) !important;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08), 0 0 0 3px rgb(35 69 74) !important;
  background: #23464b !important;
}
</style>