<template>
  <div class="bg-default-dashboard">
      <div class=" py-lg-8 pt-lg-5">
          <b-container fluid style="width: 90%;">
              <!-- <div class="header-body "  > -->
              <b-row class="mb-2">
                  <b-col>
                      <h1 class="text-white">Safety Inspection List</h1>
                  </b-col>
              </b-row>
              <b-row>
                  <div class="d-flex flex-column h-100 w-100 p-2">
                      <div class="d-flex flex-column h-100 w-100 flex-grow-1 flex-shrink-1">
                          <div style="padding: 4px;">
                              <div style="float: right;" class="d-flex">
                                  <input class="mr-2 form-control d-inline-block" @keyup="onQuickFilterChanged"
                                      type="text" id="quickFilterInput" placeholder="Type text to filter..." />
                              </div>
                          </div>
                          <div style="clear: both;"></div>
                          <div v-if="showGrid" class="d-flex flex-column flex-grow-1 flex-shrink-1">
                              <div style="padding: 4px;" class="btn-toolbar">
                                  <span>
                                        <button class="btn btn-primary mx-1" @click="api.selectAll()">Select
                                          All</button>
                                        <button class="btn btn-primary mx-1" @click="api.deselectAll()">Clear
                                          Selection</button>
                                        <button class="btn btn-primary mx-1" v-on:click="toggleSideBar()">
                                            Show Side Bar
                                        </button>
                                        <base-button type="dark" @click="action_on_modal('show', 'modal-filter', false)">
                      <span><i class="icon-filter-search-cs mr-1"></i></span>
                      <span class=""><strong>Filter</strong></span>
                      <span><i class="icon-sort-down-cs ml-6" style="font-size: large;"></i></span>
                    </base-button>
                                  </span>
                                </div>
                              <ag-grid-vue style="width: 100%;" class="flex-grow-1 flex-shrink-1 ag-theme-alpine"
                                  :columnDefs="columnDefs" :rowData="rowData" :sideBar="sideBar" :modules="modules"
                                  :defaultColDef="{
                                      sortable: true,
                                      resizable: true,
                                      filter: true,
                                      floatingFilter: true,
                                  }" 
                                  :statusBar="statusBar" 
                                  :groupHeaders="true" :suppressRowClickSelection="true"
                                  :allowContextMenuWithControlKey="true" rowSelection="multiple" @grid-ready="onReady"
                                  @cell-clicked="onCellClicked" @cell-double-clicked="onCellDoubleClicked"
                                  @cell-context-menu="onCellContextMenu" @cell-value-changed="onCellValueChanged"
                                  @cell-focused="onCellFocused" @row-selected="onRowSelected"
                                  @selection-changed="onSelectionChanged" @filter-modified="onFilterModified"
                                  @virtual-row-removed="onVirtualRowRemoved" @row-clicked="onRowClicked"
                                  @column-everything-changed="onColumnEvent" @column-row-group-changed="onColumnEvent"
                                  @column-value-Changed="onColumnEvent" @column-moved="onColumnEvent"
                                  @column-visible="onColumnEvent" @column-group-Opened="onColumnEvent"
                                  @column-resized="onColumnEvent" @column-pinned-count-changed="onColumnEvent"
                                  :getContextMenuItems="getContextMenuItems" :rowClassRules="rowClassRules">
                              </ag-grid-vue>
                          </div>
                      </div>
                  </div>
              </b-row>

                      <!-- Modal filter -->
        <b-modal ref="modal-filter" size="lg" centered hide-footer hide-header title="">
          <div class="d-block">
            <!-- date | time | project name | site name -->
            <b-row class="mb-3">
              <b-col>
                <!-- <b-form-datepicker placeholder="Form Date" v-model="valuePMN" class="text-white mb-2"></b-form-datepicker> -->
                <base-input name="From Date" type="date" v-model="filter.from_date"></base-input>
              </b-col>
              <b-col>
                <!-- <b-form-datepicker placeholder="Form Date" v-model="valuePMN" class="text-white mb-2"></b-form-datepicker> -->
                <base-input name="To Date" type="date" v-model="filter.to_date"></base-input>
              </b-col>
            </b-row>
            <!-- detail filter selection -->
            <b-row>
              <b-col class="text-right">
                <!-- <base-button type="success">Search</base-button> -->
                <base-button type="success" @click="searchSafetyObservationListingFilter(true)">Search</base-button>
              </b-col>
            </b-row>
          </div>
        </b-modal>

        <modal :show.sync="isLoadingInProgress" ref="modal-loading" size="lg" modal-classes="in-progress-modal-lg" :no-close-on-backdrop="true" body-classes="p-0" >
          <card type="secondary" header-classes="bg-transparent pb-5" body-classes="px-lg-5 py-lg-5" class="border-0 mb-0">
            <template>
              <div class="text-center text-muted mb-4">
                <h1 class="text-white"><b>Loading in progress</b></h1>
              </div>
              <div class="text-center">
                <div style="color: #ffffff;">
                  <!-- <span class="fa fa-spinner fa-spin fa-3x"></span> -->
                  <span class="icon-spinner-cs"></span>
                </div>
              </div>
            </template>
          </card>
        </modal>

          </b-container>

      </div>


  </div>
</template>

<script>
import { AgGridVue } from "@ag-grid-community/vue";


// import DateComponent from './DateComponent.vue';
import HeaderGroupComponent from './HeaderGroupComponent.vue';
import Fuse from "fuse.js";
import axios from '@/util/axios';
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { SparklinesModule } from "@ag-grid-enterprise/sparklines";
import { GridChartsModule } from "@ag-grid-enterprise/charts";
import { MasterDetailModule } from "@ag-grid-enterprise/master-detail";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { MultiFilterModule } from "@ag-grid-enterprise/multi-filter";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
import { RichSelectModule } from "@ag-grid-enterprise/rich-select";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { SideBarModule } from "@ag-grid-enterprise/side-bar";
import { StatusBarModule } from "@ag-grid-enterprise/status-bar";
import { ViewportRowModelModule } from "@ag-grid-enterprise/viewport-row-model";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate } from '@/assets/js/config.js';
import "@ag-grid-enterprise/core";
import moment from "moment";

// const api = shallowRef();

export default {
  created() {
    this.setDefaultMonthRange();

    var safety_observation_access = JSON.parse(
      window.localStorage.getItem("safety_observation_access")
    );

    const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
    this.myUserID = LoginUser;
    const userInfo = JSON.parse(window.localStorage.getItem("loginUser"));
    this.user_role = userInfo.role_id;
    this.user_id = userInfo.id;
    if (this.user_role == 12) {
      this.getContractorId();
    } else {
      this.getUserList();
    }

    var safety_observation_access = JSON.parse(
      window.localStorage.getItem("safety_observation_access")
    );

    this.getDataToShow();

    
    
      //this.getProjectTypeListing();
      //this.getProjectManagerListing();
      //this.getProjectEngineerListing();
      //this.getBusinessDevelopmentListing();
      //this.getSafetyOfficerListing();

 },
  data() {
      return {
        user_role: null,
        user_id: null,
        contractor_id: null,
          model: {
              project_id: null,
              developer_id: "x",
              developer_code: null,
              developer_name: null,
              project_type_id: "x",
              project_type_name: null,
              project_manager: "x",
              project_engineer: "x",
              project_business_development: "x",
              project_safety: "x",
              project_name: null,
              project_ppa_counter_party: null,
              project_ppa_start_date: null,
              project_ppa_approved_capacity: null,
              project_watt_class: null,
              project_tag: null,
              project_industry_category_id: "x",
          },
          industry_category: [],
          industry_category2:[],
          api: null,
          columnDefs: null,
          savedFilterModel: null,
          showGrid: false,
          rowClassRules: null,
          rowCount: null,
          modules: [
              ClientSideRowModelModule,
              ColumnsToolPanelModule,
              ExcelExportModule,
              FiltersToolPanelModule,
              SparklinesModule,
              GridChartsModule,
              MasterDetailModule,
              MenuModule,
              MultiFilterModule,
              RangeSelectionModule,
              RichSelectModule,
              RowGroupingModule,
              ServerSideRowModelModule,
              SetFilterModule,
              SideBarModule,
              StatusBarModule,
              ViewportRowModelModule,
              ClipboardModule
          ],

          rowData: [],
          sideBar: false,
          project_types: [],
          project_managers: [],
          project_managers_index: [],
          project_engineers: [],
          project_engineers_index: [],
          project_business_development: [],
          project_business_development_index: [],
          project_safety: [],
          project_safety_index: [],
          project_business_development_options: [],
          project_safety_options: [],
          developer_options:[],
          developer_index:[],
          statusBar:
          {
            statusPanels: [
              { statusPanel: "agTotalAndFilteredRowCountComponent" },
              {statusPanel: "agTotalRowCountComponent" },
              {statusPanel: "agFilteredRowCountComponent" },
              {statusPanel: "agSelectedRowCountComponent" },
              {statusPanel: "agAggregationComponent" },
            ],
          },
          filter: {
            developers: [],
            project_name: null,
            project_site_contractors: [],
            site_name: null,
            project_safety: [],
            safety_inspection_submitter: [],

            from_date: null,
            to_date: null,

            safety_observation_type: [],
            safety_observation_category: [],
            safety_observation_priority: [],

            target_date_from_date: null,
            target_date_to_date: null,

            },
          myUserID : null,
          myUserDetail:null,
          filter_site: {
        developers: [],
        project_name: null,
        project_ppa_sign_date_from_date: null,
        project_ppa_sign_date_to_date: null,
        project_ppa_capacity_greater_equal: null,
        project_ppa_capacity_lesser_equal: null,

        project_type: [],
        project_managers: [],
        project_engineers: [],
        project_business_development: [],
        project_safety: [],

        site_name: null,
        project_site_contractors: [],
        project_site_statuses: [],
        site_postal_code: null,
        site_code: null,
        roof_handover_date_from_date: null,
        roof_handover_date_to_date: null,
        site_cod_date_from_date: null,
        site_cod_date_to_date: null,
        site_target_turn_on_date_from_date: null,
        site_target_turn_on_date_to_date: null,
        site_actual_turn_on_date_from_date: null,
        site_actual_turn_on_date_to_date: null,
        site_total_capacity_greater_equal: null,
        site_total_capacity_lesser_equal: null,
        site_as_built_capacity_greater_equal: null,
        site_as_built_capacity_lesser_equal: null,
        site_module_brand_ids: [],
        site_module_quantity_greater_equal: null,
        site_module_quantity_lesser_equal: null,
        site_module_capacity_greater_equal: null,
        site_module_capacity_lesser_equal: null,
        site_module_allocation_total_capacity_greater_equal: null,
        site_module_allocation_total_capacity_lesser_equal: null,
        site_drawing_module_brand_ids: [],
        site_drawing_module_quantity_greater_equal: null,
        site_drawing_module_quantity_lesser_equal: null,
        site_drawing_module_capacity_greater_equal: null,
        site_drawing_module_capacity_lesser_equal: null,
        site_drawing_total_capacity_greater_equal: null,
        site_drawing_total_capacity_lesser_equal: null,
        site_drawing_ifa_date_from_date: null,
        site_drawing_ifa_date_to_date: null,
        site_drawing_ifc_date_from_date: null,
        site_drawing_ifc_date_to_date: null,
        ntp_applicable: null,
        ntp_issued: null,
        ntp_issue_date_from_date: null,
        ntp_issue_date_to_date: null,
        internal_project_handover_completed: null,
        internal_project_handover_date_from_date: null,
        internal_project_handover_date_to_date: null,
        kick_off_meeting_completed: null,
        kick_off_meeting_date_from_date: null,
        kick_off_meeting_date_to_date: null,
      },
      obj_inspection: {
        is_editing_inspection: false,
        safety_observation_id: null,
        previous_status: null,
        obs_item_fields: [
          // 'no.',
          // {
          //   key: index,
          //   label: 'no.',
          //   class: "th-table-new-mdl "
          // },
          // Type
          {
            key: 'safety_observation_type_id',
            label: 'Type',
            formatter: (value, key, item) => {
              var findObj = this.safety_observation_type.find(obj => { return obj.safety_observation_type_id === value });
              if (typeof findObj !== 'undefined') {
                return findObj.safety_observation_type_code;
              } else {
                return value;
              }
            },
            class: "th-table-new-mdl"
          },
          // Category
          {
            key: 'safety_observation_category_id',
            label: 'Category',
            formatter: (value, key, item) => {
              var findObj = this.safety_observation_category.find(obj => { return obj.safety_observation_category_id === value });
              if (typeof findObj !== 'undefined') {
                return findObj.safety_observation_category;
              } else {
                return value;
              }
            },
            class: "th-table-new-mdl"
          },
          // Status
          {
            key: 'safety_observation_status_id',
            label: 'Status',
            formatter: (value, key, item) => {
              var findObj = this.safety_observation_status.find(obj => { return obj.status_id === value });
              if (typeof findObj !== 'undefined') {
                return findObj.status_code;
              } else {
                return value;
              }
            },
            class: "th-table-new-mdl",
          },
          // Priority
          {
            key: 'safety_observation_priority_id',
            label: 'Priority',
            formatter: (value, key, item) => {
              var findObj = this.safety_observation_priority.find(obj => { return obj.safety_observation_priority_id === value });
              if (typeof findObj !== 'undefined') {
                return findObj.safety_observation_priority_name;
              } else {
                return value;
              }
            },
            class: "th-table-new-mdl",
          },
          // Actions
          { key: 'actions', label: 'Actions', class: "th-table-new-mdl", },
        ],
      },
      obj_obs_main: {
        users: [],
        conducting_users: [],
        contractor_info: null,
        isFutureDate: false,
        inspector_users: [],
        submit_clicked: false,
        isSubmissionWarningVisible: false,
      },      
      obj_obs_item: {
        isNewObservationItemVisible: false,
        safety_observation_item_mode: 'create',
        current_item_index: null,
        max_uploaded_photos: 5,     // maxFiles in src\components\Inputs\DropzoneFileUploadObs.vue also need to be adjust
        signal_reset_file_upload: false,
        showUploadArea: true,
      },
      model_to_db: {
        project_id: 'x',
        group_id: 'x',
        site_id: 'x',
        contractor_id: 'x',
        project_status: 'ongoing',
        safety_observation_create_date: null,
        safety_observation_create_time: null,
        time_spent_hours: 1,
        safety_observation_items: [],
        conducting_users: null,
        status: null,
      },
      new_observation: {
        observation_description: null,
        safety_observation_type_id: "x",
        safety_observation_category_id: "x",
        // correction_photos_required: "x",
        correction_photos_required: true,
        safety_observation_priority_id: "x",
        safety_observation_photos: [],
        safety_observation_status_id: 1,
        // repeated_observation: 0,
        repeated_observation: false,
      },
      tableData: [],
      obj_data_grid: {
        data: [],
        cols: [
          {
            name: "Project Name",
            field: "project.project_name",
          },
          {
            name: "EPC",
            field: "contractor.contractor_code",
          },
          {
            name: "Site Name",
            field: "site_name",
          },
          {
            name: "Inspection Date",
            field: "safety_observation_create_date_web",
          },
          {
            name: "Open",
            field: "open",
          },
          {
            name: "Close",
            field: "close",
          },
          {
            name: "Status",
            field: "status",
          },
          {
            name: "Submitted By",
            field: "created_by.name",
          },
          {},
          // {
          //   name: 'Actions',
          //   field: '',
          // },
        ],
      },
      isLoadingInProgress: false,
      isFromDateAfterToDate: false,
      propsToSearch: [
        "project.project_name",
        "contractor.contractor_code",
        "site_name",
        "safety_observation_create_date_web",
        "created_by.name",
      ],
          
      }
  },
  components: {
      AgGridVue,
  },

  methods: {

        errorFunction(err,info) {
            this.error = `An error occurred: ${err.message}`;
            console.error(err, info);
            return false; // prevents the error from propagating further
        },

        action_on_modal(what_action, what_modal, isReset = true) {
      // this.isLoadingInProgress = true;

      if (what_action == 'show') {
        this.$refs[what_modal].show()
      } else {
        this.$refs[what_modal].hide();
      };
    },

    getContractorId() {
      axios
        .get(
          apiDomain + "lookup/contractor.contractor_id/" + this.myUserID.id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            this.contractor_id = response.data.contractor_id;
            //this.filter.project_site_contractors.push(this.contractor_id);
            // this.getSafetyObservationListingRange();
            //this.searchSafetyObservationListingFilter(); -- Comment by Andrew
          }
        })
        .catch((error) => {
          this.errorFunction(error, "Contractor Id");
        })
        .finally(() => {
          //this.getUserList(); -- Comment by Andrew
        });
    },

        openPlannerLandingPage()
        {
            this.$router.push('plannerLandingPage');
        },
        openPlannerSite()
        {
            this.$router.push('plannersite');
        },

        getContractorList() {
        axios
        .get(apiDomain + "setup/contractor", { headers: getHeader() })
        .then((response) => {
          if (response.status === 200) {
            this.contractors = response.data.data;
          }
        })

        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Contractor Listing");
        });
        },

        getUserList() {
            if (this.user_role == 12) {
                if (this.contractor_id != null) {
                    this.getUserListContractor();
                }
            } else {
                this.getUserListNormal();
            }
        },

        getUserListNormal() {
        axios.get(apiDomain + 'lookup/user_list', { headers: getHeader() })
        .then(response => {
          if (response.status === 200) {
            this.obj_obs_main.inspector_users = response.data.data;
            this.addUserToConducting();
          }
        })
        .catch(error => {
          this.errorFunction(error, 'User Listing')
        });
        },

        getUserListContractor() {
        axios.get(apiDomain + 'lookup/user_list.contractor/' + this.contractor_id, { headers: getHeader() })
        .then(response => {
          if (response.status === 200) {
            this.obj_obs_main.inspector_users = response.data.data;
            this.addUserToConducting();
          }
        })
        .catch(error => {
          this.errorFunction(error, 'User Listing')
        });
    },

    setDefaultMonthRange() {
            var currentDate = getcurrentDate();
            var localCurrentDate = new Date(currentDate);

            // get current date in yyyy-mm-dd format
            const currentYear = localCurrentDate.getFullYear();
            const currentMonth = String(localCurrentDate.getMonth() + 1).padStart(2, "0");
            const currentDay = String(localCurrentDate.getDate()).padStart(2, "0");
            this.filter.to_date = currentYear + "-" + currentMonth + "-" + currentDay;

            // get first day of last month in yyyy-mm-dd format
            var fromDate = new Date(localCurrentDate.setMonth(localCurrentDate.getMonth()));
            fromDate = new Date(fromDate.setDate(1));
            const fromYear = fromDate.getFullYear();
            const fromMonth = String(fromDate.getMonth() + 1).padStart(2, "0");
            const fromDay = String(fromDate.getDate()).padStart(2, "0");
            this.filter.from_date = fromYear + "-" + fromMonth + "-" + fromDay;
            this.filter.project_name = "Kranji Meta Floating PV";

            console.log("Range Date");
            console.log(this.filter);
            },

    addUserToConducting() {
      // console.log('addUserToConducting');
      this.obj_obs_main.conducting_users.push({ userId: this.user_id, previousSelection: this.user_id });
      var tempUserId = this.user_id;
      tempUserId = tempUserId.toString();
      var tempEvent = {
        target: {
          value: tempUserId
        }
      };

      this.removeFromList(tempEvent);
    },

    removeFromList(event) {
      this.obj_obs_main.conducting_users.map((e_rev) => {
        if (e_rev.userId == event.target.value) {
          if (e_rev.previousSelection != "") {
            this.obj_obs_main.inspector_users.map((e_rol) => {
              if (e_rol.id == e_rev.previousSelection) {
                e_rol["disabled"] = false;
                e_rol["v_hide"] = false;
              }
            });
          }
        }
        this.obj_obs_main.inspector_users.map((e_rol) => {
          if (e_rol.id == event.target.value) {
            e_rol["disabled"] = true;
            e_rol["v_hide"] = true;
          }
        });
        e_rev.previousSelection = e_rev.userId;
      });
    },

    searchSafetyObservationListingFilter(isFilter) {
      console.log("FINAL FILTER");
      console.log(this.filter);
      this.isLoadingInProgress = true;

      this.tempButtonStatus = true;
      if (this.user_role == 12) {
        this.getSafetyObservationListingRange(isFilter);
      } else {
        axios
          .post(apiDomain + "health_safety/safety_observation.show_by_filter", this.filter, { headers: getHeader(), } )
          .then((response) => {
            if (response.status === 200) {
              console.log('have data');
              this.tableData = response.data.data;
              this.populateDSSafety();
              this.computeOpenClosedItems();
              this.fuseData();
            }
          })
          .catch((error) => {
            this.errorFunction(error, "Search Safety Inspection Listing");
          })

          .finally(() => { 
            console.log('Table Data');
            console.log(this.tableData);
            this.createRowData(isFilter);
          });
      }
    },

    fuseData() {
      // this.fuseSearch = new Fuse(this.tableData, {
      // keys: this.propsToSearch,
      // threshold: 0.3
      // });
      if (this.myUserID.role_id == 9) {
        this.filterByUser();
      }
      this.fuseSearch = new Fuse(this.tableData, {
        keys: this.propsToSearch,
        threshold: 0.3,
      });
    },

    validateDateTime() {
      if (this.filter.from_date == "" || this.filter.to_date == "") {
        return false;
      } else {
        const fromDate = new Date(this.filter.from_date);
        const toDate = new Date(this.filter.to_date);
        this.isFromDateAfterToDate = fromDate > toDate;
        return true;
      }
    },

    getSafetyObservationListingRange(isFilter) {
      // console.log("func: getSafetyObservationListingRange");

      if (this.validateDateTime()) {
        if (!this.isFromDateAfterToDate) {
          
          if (this.user_role == 12) {
            if (this.contractor_id != null) {
              axios
                .get( apiDomain + "health_safety/safety_observation.show_by_contractor/" + this.contractor_id + "?from_date=" + this.filter.from_date + "&to_date=" + this.filter.to_date, { headers: getHeader() } )
                .then((response) => {
                  if (response.status === 200) {
                    this.tableData = response.data.data;
                    this.populateDSSafety();
                    this.computeOpenClosedItems();
                    this.fuseData();
                  }
                })
                .catch((error) => {
                  console.log(error.response.status);
                  this.errorFunction(
                    error,
                    "Safety Inspection Listing Information"
                  );
                })
                .finally(()=>{
                  console.log('Table Data Contractor');
                  console.log(this.tableData);
                  this.createRowData(isFilter);
                });
            }
          } else {
            axios
              .get( apiDomain + "health_safety/safety_observation.show_by_dates" + "?from_date=" + this.filter.from_date + "&to_date=" + this.filter.to_date, { headers: getHeader() } )
              .then((response) => {
                if (response.status === 200) {
                  // console.log(response);
                  this.tableData = response.data.data;
                  this.populateDSSafety();
                  this.computeOpenClosedItems();
                  // this.filterByContractor()
                  // this.filterByONM()
                  this.fuseData();
                  // console.log(this.tableData);
                }
              })
              .catch((error) => {
                console.log(error.response.status);
                this.errorFunction(
                  error,
                  "Safety Inspection Listing Information"
                );
              })
              .finally(()=>{
                console.log('Table Data');
                console.log(this.tableData); 
                this.createRowData(isFilter);
              });
          }
        } else {
          this.redErrorNotify(
            "<b>Invalid Input : From Date is greater than To Date </b>"
          );
        }
      } else {
        this.redErrorNotify(
          "<b>Invalid Input : From Date or To Date is invalid </b>"
        );
      }
    },

    getSafetyObservationListing() {
      axios
        .get(apiDomain + "health_safety/safety_observation", {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {
            this.tableData = response.data.data;
            this.computeOpenClosedItems();
            this.filterByContractor();
            this.filterByONM();
            this.fuseData();
            this.updateFilterMonthYear();
            // this.createNewTable();
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Project Information");
        });
    },

    populateDSSafety() {
      //_ populate data grid
      //  why not just use this.tableData ? because the search not working if the data is nested, e.x project.project_name
      this.obj_data_grid.data = this.tableData.reduce((res, item) => {
        res.push({
          safety_observation_id: item.safety_observation_id,
          project_name: item.project.project_name,
          contractor_code: item.contractor.contractor_code,
          site_name: item.site_name,
          safety_observation_create_date_web: item.safety_observation_create_date_web,
          safety_observation_items: item.safety_observation_items,
          status: item.status,
          created_by: item.created_by.name,
        });
        return res;
      }, []);
    },

    computeOpenClosedItems() {
      this.tableData.forEach((e) => {
        var openCounter = 0;
        var closedCounter = 0;
        e.safety_observation_items.forEach((e_inner) => {
          if (e_inner.safety_observation_status_id != 3) {
            openCounter++;
          } else {
            closedCounter++;
          }
        });
        e.safety_observation_items_open = openCounter;
        e.safety_observation_items_closed = closedCounter;
      });
    },
      //01. To provide all the options

      //01.01 GET BD LIST
      getBusinessDevelopmentListing() {
        axios.get(apiDomain + 'lookup/user_list/Business_Development', { headers: getHeader() })
          .then ( response => {
              if (response.status === 200)
              {
                for (let i = 0; i < response.data.data.length; i++) {
                  this.project_business_development_options.push(response.data.data[i].name);
                  this.project_business_development_index.push({id:response.data.data[i].id,value:response.data.data[i].name})
                }
                //console.log(this.project_business_development_options);
              }
          })
          .catch ( error => {
              console.log(error.response.status)
              this.errorFunction(error, "Business Developer Information")
          } );
      },



      //01.03 GET PROJECT TYPE LIST
      getProjectTypeListing() {
          axios
              .get(apiDomain + "setup/project_type", { headers: getHeader() })
              .then((response) => {
                  if (response.status === 200) {
                      for (let i = 0; i < response.data.data.length; i++) {
                          this.project_types.push(response.data.data[i].project_type);
                      }

                      // console.log(this.project_types);
                  }
              })
              .catch((error) => {
                  console.log(error.response.status);
                  this.errorFunction(error, "Project Type");
              });
      },

      //01.04 GET PROJECT MANAGER TYPE LIST
      getProjectManagerListing() {
          axios.get(apiDomain + 'lookup/user_list/Project_Manager', { headers: getHeader() })
              .then(response => {
                  if (response.status === 200) {
                      for (let i = 0; i < response.data.data.length; i++) {
                          this.project_managers.push(response.data.data[i].name);
                          this.project_managers_index.push({id:response.data.data[i].id,value:response.data.data[i].name})
                      }
                      // console.log("Project Managers");
                      // console.log(this.project_managers_index);
                  }
              })
              .catch(error => {
                  console.log(error.response.status)
                  this.errorFunction(error, "Project Manager")
              });
      },

      //01.05 GET ENGINEER LIST
      getProjectEngineerListing() {
          axios.get(apiDomain + 'lookup/user_list/Engineer', { headers: getHeader() })
              .then(response => {
                  if (response.status === 200) {
                      for (let i = 0; i < response.data.data.length; i++) {
                          this.project_engineers.push(response.data.data[i].name);
                          this.project_engineers_index.push({id:response.data.data[i].id,value:response.data.data[i].name});
                      }
                      //console.log(this.project_engineers);
                  }
              })
              .catch(error => {
                  console.log(error.response.status)
                  this.errorFunction(error, "Engineer Information")
              });
      },

      //01.06 GET SAFETY OFFICER LIST
      getSafetyOfficerListing() {
        axios.get(apiDomain + 'lookup/user_list/Safety', { headers: getHeader() })
        .then ( response => {
            if (response.status === 200)
            {
              this.project_safety = response.data.data;
              for (let i = 0; i < this.project_safety.length; i++) {
                this.project_safety_options.push(this.project_safety[i].name);
                this.project_safety_index.push({id:response.data.data[i].id,value:response.data.data[i].name});
              }
              //console.log(this.project_safety_options);
            }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Safety Officer Information")
        } );
      },

      //01.07 SAVE THE RECORD
      onCellValueChanged(event) {
          this.model.project_id = event.data.project_id;
          this.model.project_name = event.data.project_name;
          this.model.project_manager = this.searchKeyword(event.data.project_manager.name,this.project_managers_index);
          this.model.project_engineer = this.searchKeyword(event.data.project_engineer.name,this.project_engineers_index);
          this.model.project_business_development = this.searchKeyword(event.data.project_business_development.name,this.project_business_development_index);
          this.model.project_safety = this.searchKeyword(event.data.project_safety.name,this.project_safety_index);
          this.model.project_type_id = event.data.project_type.project_type_id;
          this.model.developer_id = event.data.developer.developer_id;
          this.model.project_ppa_start_date = event.data.project_ppa_start_date;
          this.model.project_ppa_approved_capacity = event.data.project_ppa_approved_capacity;
          //this.model.project_ppa_approved_capacity = parseFloat(event.data.project_ppa_approved_capacity.replace(/,/g, ""));
          this.model.project_industry_category_id = event.data.project_industry_category_id;
          if (event.data.project_watt_class !== null) {
              this.model.project_watt_class = parseFloat(event.data.project_watt_class.replace(/,/g, ""));
          }

          axios.put(apiDomain + 'project/project/' + event.data.project_id, this.model, { headers: getHeader() })
              .then(response => {
                  if (response.status === 200) {
                      this.$notify({
                          message:
                              '<b>Update Project : ' + this.model.project_name + '</b> - Successfully Updated.',
                          timeout: 10000,
                          icon: 'ni ni-bell-55',
                          type: 'default',
                      });
                      //   this.$router.back()

                  }
              })
              .catch(error => {
                  console.log(error.response.data.errors)
                  this.errorFunction(error, "Project Information")
              });

      },

      //01.08 TO SEARCH THE ID BASED ON KEYWORD SEARCH IN VALUE PROPERTY
      searchKeyword(strSearch,arrToSearch) 
      {
        //console.log("keyword : " + strSearch);
        //console.log(arrToSearch);
        const result = arrToSearch.find(item =>
          item.value.includes(strSearch)
        );
        let foundIndex = result ? result.id : null;
        //console.log("foundIndex : " + foundIndex);
        return foundIndex;
      },

      searchKeywordDeveloper(strSearch,arrToSearch) 
      {
        //console.log("keyword : " + strSearch);
        //console.log(arrToSearch);
        const result = arrToSearch.find(item =>
          item.value.includes(strSearch)
        );
        let foundIndex = result ? result.id : null;
        //console.log("foundIndex : " + foundIndex);
        return foundIndex;
      },

      saveFilterModel() {

          this.savedFilterModel = this.api.getFilterModel();
          var keys = Object.keys(this.savedFilterModel);
          var savedFilters = keys.length > 0 ? keys.join(", ") : "(none)";
          document.querySelector("#savedFilters").textContent = savedFilters;
      },

      restoreFilterModel() {
          this.api.setFilterModel(this.savedFilterModel);
      },
      clearFilters() {
          this.api.setFilterModel(null);
          document.querySelector("#savedFilters").textContent = "(none)";
      },
      rowClassRule() {
          //this.rowClassRules = {
              // row style function
              //"sick-days-warning": (params) => {
                  // console.log("this", params.data.project_start_date);
                  //   var numSickDays = params.data.sickDays;
                //  return params.data.project_start_date === "2024-09-30"
              //},
              // row style expression
              // "sick-days-breach": "data.sickDays >= 8",
          //};
      },

      getDataToShow() {
      //_ role planner display all project and sites
      //_ role project manager leader - list all project and site for all project manager under him/her
      //_ role project manager - list all project and site only for his/her own
      // console.log('>>> func getDataToShow');
      // console.log(this.obj_user.info);

      axios.get(apiDomain+'api/get_user_project_developer/'+this.myUserID.id,{headers: getHeader()})
            .then ( response => {
                this.myUserDetail = response.data.data;
                for(let i = 0; i < this.myUserDetail.developers.length; i++)
                {
                  this.filter.developers.push(this.myUserDetail.developers[i].developer.developer_id);
                }
                console.log('Filter developer');
                console.log(this.filter.developers);
              })

            .catch(error => {
              this.errorFunction(error, this.title)
            })

            .finally(() => {

            //this.filter.project_type.push(1);
            //this.createRowData();
            this.searchSafetyObservationListingFilter(false);

        });
    },
      
    createRowData(isFilter) {

        const data = this.tableData;
        console.log("Data loaded:", data);
        var role = '';
        var repeated = '';

        this.rowData = [];

        // Check if data has items to process
        if (data && data.length > 0) {
          console.log("Processing data...");
          for (let i = 0; i < data.length; i++) {
            for(let j = 0; j< data[i].safety_observation_items.length;j++)
            {
              if(data[i].created_by.role_id != 12)
              {
                role = 'Safety Officer';
              }
              else
              {
                role = 'EPC';
              }
              if(data[i].safety_observation_items[j].repeated_observation == 1)
              {
                repeated = 'YES';
              }
              else
              {
                repeated = 'NO';
              }
              this.rowData.push({
              safety_observation_id : data[i].safety_observation_id,
              created_by_role: role,
              repeated : repeated,
              safety_observation_item : data[i].safety_observation_items[j],
              project : data[i].project,
              group: data[i].group,
              site : data[i].site,
              contractor : data[i].contractor,
              project_status : data[i].project_status,
              safety_observation_create_date : data[i].safety_observation_create_date,
              safety_observation_create_time : data[i].safety_observation_create_time,
              time_spent_hours: data[i].time_spent_hours,
              safety_observation_submitted_at : data[i].safety_observation_submitted_at,
              created_by: data[i].created_by,
              created_at: data[i].craeted_at,
              updated_at: data[i].updated_at,
            });
            }
          }

        console.log("Final rowData:", this.rowData);
        if(isFilter) 
        {
          this.api.updateGridOptions({rowData:this.rowData});
          this.action_on_modal('hide', 'modal-filter');         
        }
        else
        {
          this.createColumnDefs();
        }
        this.isLoadingInProgress = false;
       } 
      },


      createColumnDefs() {
          this.columnDefs = [
              {
                  headerName: '#', minWidth: 60, width: 60, checkboxSelection: true, sortable: false,
                  suppressHeaderMenuButton: true, pinned: true
              },
              {
                headerName: "Inspection Information",
                width: 200,
                children :
                [
                  {
                    headerName: "Site Name",
                    field:"site.site_name",
                    enableRowGroup:true,
                    editable: false,
                    floatingFilter: true,
                    width: 200,
                  },
                  {
                    headerName: "Contractor",
                    field:"contractor.contractor_name",
                    enableRowGroup:true,
                    editable: false,
                    floatingFilter: true,
                    width: 200,
                  },
                  {
                    headerName: "Inspected By",
                    field:"created_by.name",
                    enableRowGroup:true,
                    editable: false,
                    floatingFilter: true,
                    width: 200,
                  },
                  {
                    headerName: "Role",
                    field:"created_by_role",
                    enableRowGroup:true,
                    editable: false,
                    floatingFilter: true,
                    width: 200,
                  },
                  {
                    headerName: "Date",
                    field: "safety_observation_create_date", 
                    width: 150,
                    enableRowGroup:true,
                    editable: false,
                    floatingFilter: true,
                    cellRenderer: (data) => { return (data.value) ? moment(data.value).format('DD MMM YYYY') : '' }
                  },
                ]
              },
              {
                headerName: "Observation Item Information",
                width: 200,
                children :
                [
                  {
                    headerName: "Type",
                    field:"safety_observation_item.safety_observation_type.safety_observation_type_code",
                    enableRowGroup:true,
                    editable: false,
                    floatingFilter: true,
                    width: 200,
                  },
                  {
                    headerName: "Category",
                    field:"safety_observation_item.safety_observation_category.safety_observation_category",
                    enableRowGroup:true,
                    editable: false,
                    floatingFilter: true,
                    width: 200,
                  },
                  {
                    headerName: "Description",
                    field:"safety_observation_item.observation_description",
                    editable: false,
                    floatingFilter: true,
                    width: 200,
                  },
                  {
                    headerName: "Priority Level",
                    field:"safety_observation_item.safety_observation_priority.safety_observation_priority_name",
                    enableRowGroup:true,
                    editable: false,
                    floatingFilter: true,
                    width: 200,
                  },
                  {
                    headerName: "Need to close in(days)",
                    field:"safety_observation_item.safety_observation_priority.safety_observation_priority_duration",
                    enableRowGroup:true,
                    editable: false,
                    floatingFilter: true,
                    width: 200,
                  },
                  {
                    headerName: "Target Close Date",
                    field:"safety_observation_item.target_date",
                    enableRowGroup:true,
                    editable: false,
                    floatingFilter: true,
                    width: 200,
                    cellRenderer: (data) => { return (data.value) ? moment(data.value).format('DD MMM YYYY') : '' }
                  },
                  {
                    headerName: "Resolved Date",
                    field:"safety_observation_item.resolved_at",
                    enableRowGroup:true,
                    editable: false,
                    floatingFilter: true,
                    width: 200,
                    cellRenderer: (data) => { return (data.value) ? moment(data.value).format('DD MMM YYYY') : '' }
                  },
                  {
                    headerName: "Status",
                    field:"safety_observation_item.safety_observation_status.status_code",
                    enableRowGroup:true,
                    editable: false,
                    floatingFilter: true,
                    width: 200,
                  },
                  {
                    headerName: "Repeated",
                    field:"repeated",
                    enableRowGroup:true,
                    editable: false,
                    floatingFilter: true,
                    width: 200,
                  },
                ]
              },

          ];


      },
 


      pad(num, totalStringSize) {
          let asString = num + "";
          while (asString.length < totalStringSize) asString = "0" + asString;
          return asString;
      },

      onReady(params) {
          //console.log('onReady');
          this.api = params.api;
          this.api.sizeColumnsToFit();
          // params.api.getToolPanelInstance("filters").expandFilters();
      },
      // onBtnUpdate(){
      //     document.querySelector("#csvResult").value = this.api.getDataAsCsv({
      //         processCellCallback: function(cell) {
      //       // Manipulate the value however you need.
      //       return cell.value;
      //     },
      //     });
      // },

      onCellClicked(event) {
          //     this.model.project_id=event.data.project_id;
          //     this.model.project_name=event.data.project_name;
          //     this.model.project_manager=event.data.project_manager.id;
          //     this.model.project_engineer=event.data.project_engineer.id;
          //     this.model.project_business_development=event.data.project_business_development.id;
          //     this.model.project_safety=event.data.project_safety.id;
          //     this.model.project_type_id=event.data.project_type.project_type_id;
          //     this.model.developer_id=event.data.developer.developer_id;
          //     this.model.project_ppa_start_date=event.data.project_ppa_start_date;
          //     this.model.project_ppa_approved_capacity=parseFloat(event.data.project_ppa_approved_capacity.replace(/,/g, ""));
          //     this.model.project_industry_category_id=event.data.project_industry_category_id;
          //         if (event.data.project_watt_class !== null) {
          //         this.model.project_watt_class=parseFloat(event.data.project_watt_class.replace(/,/g, ""));
          //         }
          // //console.log(event);


          //console.log('onCellClicked: ' + event.rowIndex + ' ' + event.colDef.field);
      },

      // onCellValueChanged(event) {
      //     this.model.project_id = event.data.project_id;
      //     this.model.project_name = event.data.project_name;
      //     this.model.project_manager = event.data.project_manager.id;
      //     this.model.project_engineer = event.data.project_engineer.id;
      //     this.model.project_business_development = event.data.project_business_development.id;
      //     this.model.project_safety = event.data.project_safety.id;
      //     this.model.project_type_id = event.data.project_type.project_type_id;
      //     this.model.developer_id = event.data.developer.developer_id;
      //     this.model.project_ppa_start_date = event.data.project_ppa_start_date;
      //     this.model.project_ppa_approved_capacity = parseFloat(event.data.project_ppa_approved_capacity.replace(/,/g, ""));
      //     this.model.project_industry_category_id = event.data.project_industry_category_id;
      //     if (event.data.project_watt_class !== null) {
      //         this.model.project_watt_class = parseFloat(event.data.project_watt_class.replace(/,/g, ""));
      //     }

      //     //console.log('onCellValueChanged: ' + event.oldValue + ' to ' + event.newValue);
      //     //console.log(event.data);

      //     axios.put(apiDomain + 'project/project/' + event.data.project_id, this.model, { headers: getHeader() })
      //         .then(response => {
      //             if (response.status === 200) {
      //                 this.$notify({
      //                     message:
      //                         '<b>Update Project : ' + this.model.project_name + '</b> - Successfully Updated.',
      //                     timeout: 10000,
      //                     icon: 'ni ni-bell-55',
      //                     type: 'default',
      //                 });
      //                 //   this.$router.back()

      //             }
      //         })
      //         .catch(error => {
      //             console.log(error.response.data.errors)
      //             this.errorFunction(error, "Project Information")
      //         });

      // },

      onCellDoubleClicked(event) {
          //console.log('onCellDoubleClicked: ' + event.rowIndex + ' ' + event.colDef.field);
      },

      onCellContextMenu(event) {

          //console.log('onCellContextMenu: ' + event.rowIndex + ' ' + event.colDef.field);

      },

      onCellFocused(event) {

          //console.log('onCellFocused: (' + event.rowIndex + ',' + event.colIndex + ')');
      },

      // taking out, as when we 'select all', it prints to much to the console!!
      // eslint-disable-next-line
      onRowSelected(event) {
          // console.log('onRowSelected: ' + event.node.data.name);
      },

      onSelectionChanged() {
          //console.log('selectionChanged');
      },

      onFilterModified() {
          //console.log('onFilterModified');
      },

      // eslint-disable-next-line
      onVirtualRowRemoved(event) {
          // because this event gets fired LOTS of times, we don't print it to the
          // console. if you want to see it, just uncomment out this line
          // console.log('onVirtualRowRemoved: ' + event.rowIndex);
      },

      onRowClicked(event) {
          this.api.processCellCallback = function (cell) {
              // Manipulate the value however you need.
              //console.log("cell" + cell.value);
              return cell.value;
          };
          //console.log('onRowClicked: ' + event.node.data.name);
      },

      onQuickFilterChanged(event) {
          this.api.setQuickFilter(event.target.value);
      },

      // here we use one generic event to handle all the column type events.
      // the method just prints the event name
      onColumnEvent(event) {
          //console.log('onColumnEvent: ' + event);
      },
      toggleSideBar() {
          this.sideBar = !this.sideBar;
          this.api.setSideBar(this.sideBar);
      },
      getContextMenuItems(event) {
          return [
              'copy',
              'copyWithHeaders',
              'paste',
              'separator',
              {
                  name: 'Export',
                  subMenu: [
                      {
                          name: 'CSV Export',
                          action: () => {
                              this.api.exportDataAsCsv({
                                  // skipColumnGroupHeaders:true,
                                  onlySelected: true
                              });
                          },
                          icon: '<span class="ag-icon ag-icon-csv" unselectable="on" role="presentation"></span>',
                      },
                      {
                          name: 'Excel Export',
                          action: () => { this.api.exportDataAsExcel({ onlySelected: true }) },
                          icon: '<span class="ag-icon ag-icon-excel" unselectable="on" role="presentation"></span>',
                      },
                  ],
                  icon: '<span class="ag-icon ag-icon-save" unselectable="on" role="presentation"></span>',
              },
              'separator',
              'chartRange',
              'pivotChart',
              'separator',
              'resetColumns',
              'separator',
              'toolPanel',
          ];
      },
  },
  beforeMount() {
      this.api = {};
      //this.getDataToShow();
      //this.createRowData();
      //this.createColumnDefs();
      //this.rowClassRule();
      this.showGrid = true;

  }
}

// function skillsCellRenderer(params) {
//     let data = params.data;
//     let skills = [];
//     RefData.IT_SKILLS.forEach(function (skill) {
//         if (data && data.skills && data.skills[skill]) {
//             skills.push('<img src="https://www.ag-grid.com/example-assets/skills/' + skill + '.png" width="16px" title="' + skill + '" />');
//         }
//     });
//     return skills.join(' ');
// }
function lookupValue(mappings, key) {
  //    console.log("thiss",mappings[key])
     
  // return mappings[key];

  mappings.forEach(element => {
      if (element.id===key)
  {
//console.log("key",key)
//console.log("element id",element.id)
//console.log("element.name",element.name)
      return "hello"
      
  }
 
});
  
};
var dateFilterParams = {
  browserDatePicker: true,
  buttons: ['reset', 'apply', 'cancel'],
  closeOnApply: true,

  comparator: (filterLocalDateAtMidnight, cellValue) => {
      //console.log("cellValue", cellValue)
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split("-");
      var cellDate = new Date(
          Number(dateParts[0]),
          Number(dateParts[1]) - 1,
          Number(dateParts[2]),
      );
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
          return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
          return 1;
      }
      return 0;
  },

}


function createRandomPhoneNumber() {
  let result = '+';
  for (let i = 0; i < 12; i++) {
      result += Math.round(Math.random() * 10);
      if (i === 2 || i === 5 || i === 8) {
          result += ' ';
      }
  }
  return result;
}


</script>

<style>
.sick-days-warning {
  background-color: #cc693344;
}

.sick-days-breach {
  background-color: #cc333344;
}

.ag-root-wrapper-body.ag-layout-normal {
  height: 700px
}

.ag-cell {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

label {
  font-weight: normal !important;
}

.bg-default-dashboard {
  background-color: #082A2F;
  /* background-color: #f1e5e9; */
  /* background-size: cover; */
  /* position:fixed; */
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* .ag-theme-alpine {
min-height: 500px;
height: 500px;
} */
.div-percent-bar {
  display: inline-block;
  height: 100%;
  position: relative;
  z-index: 0;
}

.div-percent-value {
  position: absolute;
  padding-left: 4px;
  font-weight: bold;
  font-size: 13px;
  z-index: 100;
}

.div-outer-div {
  display: inline-block;
  height: 100%;
  width: 100%;
}

.ag-menu {
  z-index: 200;
}

.toolbar button {
  margin-left: 5px;
  margin-right: 5px;
  padding: 2px;
}
</style>