var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-header',{staticClass:"pb-6"},[_c('b-row',{staticClass:"py-4"},[_c('b-col',{attrs:{"cols":"6"}},[_c('h6',{staticClass:"h2 text-white d-inline-block mb-0"},[_vm._v(_vm._s(_vm.title))])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('base-button',{attrs:{"size":"sm","type":"neutral"},on:{"click":function($event){return _vm.$router.replace('EformListing')}}},[_vm._v("Back")])],1)],1)],1),_c('b-container',{staticClass:"mt--6",attrs:{"fluid":""}},[_c('div',{staticClass:"card-wrapper"},[_c('card',[_c('h3',{staticClass:"mb-0",attrs:{"slot":"header"},slot:"header"},[_vm._v("New Template")]),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-form-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Name","invalid-feedback":errors[0]}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.name),expression:"model.name"}],staticClass:"form-control",class:classes,attrs:{"type":"text","maxlength":"100"},domProps:{"value":(_vm.model.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "name", $event.target.value)}}})])]}}],null,true)})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"Module","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Module","invalid-feedback":errors[0]}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.type),expression:"model.type"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"Selected":"","disabled":"","value":"x"}},[_vm._v("-- Please Select --")]),_c('option',{attrs:{"value":"evaluation"}},[_vm._v("Evaluation")]),_c('option',{attrs:{"value":"assessment"}},[_vm._v("Assessment")]),_c('option',{attrs:{"value":"approval"}},[_vm._v("Approval")])])])]}}],null,true)})],1)],1),_c('b-form-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"Submitter","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Submitter","invalid-feedback":errors[0]}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.role_id),expression:"model.role_id"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "role_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"Selected":"","disabled":"","value":"x"}},[_vm._v("-- Please Select --")]),_vm._l((_vm.roles),function(item){return _c('option',{key:item.id,domProps:{"value":item.id}},[_vm._v(_vm._s(item.name))])})],2)])]}}],null,true)})],1)],1),_c('b-form-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"Form Template","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"From Template","invalid-feedback":errors[0]}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.template_id),expression:"model.template_id"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "template_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"Selected":"","disabled":"","value":"x"}},[_vm._v("-- Please Select --")]),_vm._l((_vm.templates),function(item){return _c('option',{key:item.id,domProps:{"value":item.id}},[_vm._v(_vm._s(item.name))])})],2)])]}}],null,true)})],1)],1),_c('b-form-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Required Appover"}},[_c('b-form-checkbox',{attrs:{"name":"Required Appover","value":"1","unchecked-value":"0"},model:{value:(_vm.model.required_approver),callback:function ($$v) {_vm.$set(_vm.model, "required_approver", $$v)},expression:"model.required_approver"}},[_c('b',[_vm._v("Required Approver?")])])],1)],1)],1),_c('br'),(_vm.access.create)?_c('button',{staticClass:"btn btn-primary"},[_vm._v("Create")]):_vm._e(),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.replace('EformListing')}}},[_vm._v("Cancel")])],1)]}}])})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }