<template>
  <!-- <highcharts :options="chartOptions" :callback="chartCallback"></highcharts> -->
  <vue-highcharts :options="obj_chart.docStatisticOption" ref="chartBudgetDetail"></vue-highcharts>
</template>


<script>
import axios from '@/util/axios';
import { LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate, } from "@/assets/js/config.js";
import VueHighcharts from 'vue2-highcharts'

export default {
  props: ['budgetData'],
  components: {
    VueHighcharts,
  },
  data() {
    return {
      obj_chart: {
        // chartOptionDocStatistic: null,
        docStatisticOption: null,
        docStatisticData: null,
      },
      siteListingData: null,
      pid: null,
      sid: null,
    }
  },
  created() {
  },
  methods: {
    getDocumentStatus() {
      this.sid = this.$route.params.id;

      axios
        .get(apiDomain + "project/project_site/" + this.sid, {
          headers: getHeader(),
        })
        .then((response) => {
          if (response.status === 200) {

            this.siteListingData = response.data.data[0];
            // console.log(this.siteListingData);
            // this.access = getAccessByModule('DOC_REPO_REVIEW');
            this.pid = this.siteListingData.project.project_id;
          }
        })
        .catch((error) => {
          //console.log(error.response.status);
          if (error.response.status == 401) {
            this.$router.push("/login");
          }
        })
        .finally(() => {
          const options = {
            params: {
              category1_id: null,
              category2_id: null,
              category3_id: null,
              status: null
            },
          }

          let tmpArr = [];
          axios
            .get(`doc_repo/p/${this.pid}/s/${this.sid}/document`, options)
            .then(response => {
              // this.documents = response.data.data;
              tmpArr = response.data.data;
            })
            .catch(error => {
              this.errorFunction(error, this.title)
            })
            .finally(() => {
              //_ 

              console.log('getDocumentStatus');
              console.log(tmpArr);

              /*        
                Completed
                  APPROVED

                In Progress
                  NEW_REVIEW | REJECTED | PENDING_REVIEW

                Late

                Not Started
                  EMPTY
              */
              let tmpArr10 = this.changeStatusDescription(tmpArr, 'NEW_REVIEW', 'PENDING_REVIEW');
              let tmpArr11 = this.changeStatusDescription(tmpArr10, 'ACCEPTED', 'PENDING_REVIEW');
              // let tmpArr12 = this.changeStatusDescription(tmpArr11, 'REJECTED', 'PENDING_REVIEW');
              
              this.documents = this.changeStatusDescription(tmpArr11, 'NEW_REVISION', 'EMPTY');

              this.obj_chart.docStatisticData = this.prepDocumentStatistic(this.documents);

              this.graphDocumentStatistic();
            })
        });
    },
    changeStatusDescription(tmpArr, findWhat, changeToWhat) {
      const newArr = tmpArr.map(obj => {
        if (obj.status === findWhat) {
          return { ...obj, status: changeToWhat };
        }

        return obj;
      });

      return newArr
    },
    prepDocumentStatistic(theData) {
      var counts = theData.reduce((p, c) => {
        // console.log(p)
        // console.log(c)

        var name = c.status;
        if (!p.hasOwnProperty(name)) {
          p[name] = 0;
        }

        //_ since data has already "tweaked" when loaded, then no need to do this anymore
        // if (name == 'NEW_REVISION') { name = 'EMPTY' }
        // if (name == 'ACCEPTED') { name = 'PENDING_REVIEW' }

        p[name]++;
        return p;
      }, {});

      // console.log(counts);

      return counts;
    },
    graphBudgetSecure() {
      let chart_template = {
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "Total",
            colorByPoint: true,
            dataLabels: {
              enabled: true,
              style: {
                textOutline: 'none',
                fontSize: '14px',
                fontFamily: "Open Sans, sans-serif",
                fontWeight: "bold",
                color: '#ffffff'
              }
            },
            data: [],
          },
        ],
        chart: {
          type: "pie",
          backgroundColor: "#23454A",
          // width: '590',
          // height: '350',
          height: '60%',
          align: 'center',
          spacingLeft: 5,
          spacingRight: 5,
        },
        plotOptions: {
          pie: {
            showInLegend: true,
            dataLabels: {
              enabled: true,
              // format: '<b>{point.name}</b>:<br>{point.percentage:.1f} %<br>value: {point.y}',
              format: '<b>{point.name}</b>:<br>{point.percentage:.1f} %',
            }
          }
        },
        legend: {
          itemStyle: {
            color: '#FFFFFF'
          },
          itemHoverStyle: {
            color: '#c1c1c1'
          },
        },
        title: {
          text: "",
          style: {
            color: "white",
            fontWeight: "bold",
          },
        },
      };
      this.obj_chart.docStatisticOption = chart_template;
      let chart_data_doc_statistic = [
        // {
        //   name: "EMPTY",
        //   y: this.validateDocStatisticData(this.obj_chart.docStatisticData.EMPTY),
        //   color: "#ffffff",
        // },
        // {
        //   name: "COMPLETELY ACKNOWLEDGED",
        //   y: this.validateDocStatisticData(this.obj_chart.docStatisticData.APPROVED),
        //   color: "#46d275",
        // },
        // {
        //   name: "PENDING TO BE ACKNOWLEDGED",
        //   y: this.validateDocStatisticData(this.obj_chart.docStatisticData.PENDING_REVIEW),
        //   color: "#ff9a3d",
        // },
        // {
        //   name: "NOT ACKNOWLEDGED",
        //   y: this.validateDocStatisticData(this.obj_chart.docStatisticData.REJECTED),
        //   color: "#ff3232",
        // },
        // {
        //   name: "BEING REVIEWED",
        //   y: this.validateDocStatisticData(this.obj_chart.docStatisticData.NEW_REVIEW),
        //   color: "#11cdef",
        // },
        {
          name: "EPC Cost",
          y: Number(this.budgetData.epc),
          color: "#ffffff",
        },
        {
          name: "Spent",
          y: Number(this.budgetData.spent),
          color: "#46d275",
        },
        {
          name: "Balance",
          y: Number(this.budgetData.balance),
          color: "#ff9a3d",
        },
        {
          name: "Remaining",
          y: Number(this.budgetData.remaining),
          color: "#ff3232",
        },
      ];
      this.obj_chart.docStatisticOption.series[0].data = [];
      for (let index = 0; index < chart_data_doc_statistic.length; index++) {
        this.obj_chart.docStatisticOption.series[0].data.push({
          name: chart_data_doc_statistic[index].name,
          y: chart_data_doc_statistic[index].y,
          color: chart_data_doc_statistic[index].color,
        });
      }

    },
    graphBudgetDetail() {
      let chart_template = {
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "Total",
            colorByPoint: true,
            dataLabels: {
              enabled: true,
              style: {
                textOutline: 'none',
                fontSize: '14px',
                fontFamily: "Open Sans, sans-serif",
                fontWeight: "bold",
                color: '#ffffff'
              }
            },
            data: [],
          },
        ],
        chart: {
          type: "pie",
          backgroundColor: "#23454A",
          // width: '590',
          // height: '350',
          height: '60%',
          align: 'center',
          spacingLeft: 5,
          spacingRight: 5,
        },
        plotOptions: {
          pie: {
            showInLegend: true,
            dataLabels: {
              enabled: true,
              // format: '<b>{point.name}</b>:<br>{point.percentage:.1f} %<br>value: {point.y}',
              format: '<b>{point.name}</b>:<br>{point.y} ({point.percentage:.1f} %)',
            }
          }
        },
        legend: {
          itemStyle: {
            color: '#FFFFFF'
          },
          itemHoverStyle: {
            color: '#c1c1c1'
          },
        },
        title: {
          text: "",
          style: {
            color: "white",
            fontWeight: "bold",
          },
        },
      };
      this.obj_chart.docStatisticOption = chart_template;
      let chart_data_doc_statistic = [
        {
          name: "EPC Cost",
          y: Number(this.budgetData.epc),
          color: "#ffffff",
        },
        {
          name: "Spent",
          y: Number(this.budgetData.spent),
          color: "#46d275",
        },
        {
          name: "Balance",
          y: Number(this.budgetData.balance),
          color: "#ff9a3d",
        },
        {
          name: "Remaining",
          y: Number(this.budgetData.remaining),
          color: "#ff3232",
        },
      ];
      this.obj_chart.docStatisticOption.series[0].data = [];
      for (let index = 0; index < chart_data_doc_statistic.length; index++) {
        this.obj_chart.docStatisticOption.series[0].data.push({
          name: chart_data_doc_statistic[index].name,
          y: chart_data_doc_statistic[index].y,
          color: chart_data_doc_statistic[index].color,
        });
      }

    },
  },
  watch: {
    budgetData() {
      // console.log('budget neh di watch2:')
      // console.log(this.budgetData)
      if ( this.budgetData != 0 ) {
        // this.getDocumentStatus();
        this.graphBudgetDetail();
        // if ( this.displayType == 'secure' ) {
        //   this.graphBudgetSecure();
        // } else {
        //   this.graphBudgetDetail();
        // }
        
      }
    }
  },
};
</script>
