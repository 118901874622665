<template>
  <!-- <highcharts :options="chartOptions" :callback="chartCallback"></highcharts> -->
  <vue-highcharts :options="obj_chart.docStatisticOption" ref="chartBudgetSecure"></vue-highcharts>
</template>


<script>
import axios from '@/util/axios';
import { LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate, } from "@/assets/js/config.js";
import VueHighcharts from 'vue2-highcharts'

export default {
  props: ['budgetData'],
  components: {
    VueHighcharts,
  },
  data() {
    return {
      obj_chart: {
        // chartOptionDocStatistic: null,
        docStatisticOption: null,
        docStatisticData: null,
      },
      siteListingData: null,
      pid: null,
      sid: null,
    }
  },
  created() {
  },
  methods: {
    graphBudgetSecure() {
      let chart_template = {
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "Total",
            colorByPoint: true,
            dataLabels: {
              // connectorWidth: 0,
              enabled: true,
              style: {
                textOutline: 'none',
                fontSize: '14px',
                fontFamily: "Open Sans, sans-serif",
                fontWeight: "bold",
                color: '#ffffff'
              }
            },
            data: [],
          },
        ],
        chart: {
          type: "pie",
          backgroundColor: "#23454A",
          // width: '590',
          // height: '350',
          height: '60%',
          align: 'center',
          spacingLeft: 5,
          spacingRight: 5,
        },
        plotOptions: {
          pie: {
            showInLegend: true,
            dataLabels: {
              // distance: 0,
              enabled: true,
              // format: '<b>{point.name}</b>:<br>{point.percentage:.1f} %<br>value: {point.y}',
              format: '<b>{point.name}</b>:<br>{point.percentage:.1f} %',
            }
          }
        },
        tooltip: {
          enabled: true,
          formatter: function () {
            return '';
          }
        },
        legend: {
          itemStyle: {
            color: '#FFFFFF'
          },
          itemHoverStyle: {
            color: '#c1c1c1'
          },
        },
        title: {
          text: "",
          style: {
            color: "white",
            fontWeight: "bold",
          },
        },
      };
      this.obj_chart.docStatisticOption = chart_template;
      let chart_data_doc_statistic = [
        // {
        //   name: "EMPTY",
        //   y: this.validateDocStatisticData(this.obj_chart.docStatisticData.EMPTY),
        //   color: "#ffffff",
        // },
        // {
        //   name: "COMPLETELY ACKNOWLEDGED",
        //   y: this.validateDocStatisticData(this.obj_chart.docStatisticData.APPROVED),
        //   color: "#46d275",
        // },
        // {
        //   name: "PENDING TO BE ACKNOWLEDGED",
        //   y: this.validateDocStatisticData(this.obj_chart.docStatisticData.PENDING_REVIEW),
        //   color: "#ff9a3d",
        // },
        // {
        //   name: "NOT ACKNOWLEDGED",
        //   y: this.validateDocStatisticData(this.obj_chart.docStatisticData.REJECTED),
        //   color: "#ff3232",
        // },
        // {
        //   name: "BEING REVIEWED",
        //   y: this.validateDocStatisticData(this.obj_chart.docStatisticData.NEW_REVIEW),
        //   color: "#11cdef",
        // },
        {
          name: "EPC Cost",
          y: Number(this.budgetData.epc),
          color: "#ffffff",
        },
        {
          name: "Spent",
          y: Number(this.budgetData.spent),
          color: "#46d275",
        },
        {
          name: "Balance",
          y: Number(this.budgetData.balance),
          color: "#ff9a3d",
        },
        {
          name: "Remaining",
          y: Number(this.budgetData.remaining),
          color: "#ff3232",
        },
      ];
      this.obj_chart.docStatisticOption.series[0].data = [];
      for (let index = 0; index < chart_data_doc_statistic.length; index++) {
        this.obj_chart.docStatisticOption.series[0].data.push({
          name: chart_data_doc_statistic[index].name,
          y: chart_data_doc_statistic[index].y,
          color: chart_data_doc_statistic[index].color,
        });
      }

    },
  },
  watch: {
    budgetData() {
      // console.log('budget neh di watch:')
      // console.log(this.budgetData)
      if (this.budgetData != 0) {
        this.graphBudgetSecure();

        // this.getDocumentStatus();
        // if ( this.displayType == 'secure' ) {
        //   this.graphBudgetSecure();
        // } else {
        //   this.graphBudgetDetail();
        // }

      }
    }
  },
};
</script>
