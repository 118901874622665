<template>
  <div>
    <carousel-3d id="gallery-3d" :autoplay="true" :width="450" :height="650" :display="5">
    <!-- <carousel-3d id="gallery-3d" :autoplay="true" :display="5"> -->
      <slide v-for="(slide, i) in gallerySlides" :index="i" :key="i">
        <template slot-scope="{ index, isCurrent, leftIndex, rightIndex }">
          <img :data-index="index" :class="{ current: isCurrent, onLeft: (leftIndex >= 0), onRight: (rightIndex >= 0) }"
            :src="slide.image">
        </template>
      </slide>
    </carousel-3d>
  </div>
</template>

<script>
import { Select, Option, Table, TableColumn } from "element-ui";
import { Carousel3d, Slide } from 'vue-carousel-3d';

export default {
  props: ['gallerySlides'],
  name: "Detail",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Carousel3d,
    Slide
  },

  created() {
    const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));
    this.myUserID = LoginUser;
  },
  beforeMount() {

  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style scoped>
/deep/ .carousel-3d-container {
  min-height: 350px;
  /* height: 205px !important; */
}

/deep/ .carousel-3d-slider {
  min-height: 350px;
  /* height: 202px !important; */
}

/deep/ .carousel-3d-slide img {
  height: 100% !important;
  width: 100% !important;
}
</style>

<!-- CSS for image slides -->
<style scoped>
#slider {
  width: 100%;
}

.slider {
  margin: 0 auto;
  padding: 0;
  /* width: 800px; */
  height: 450px;
  position: relative;
  overflow: hidden;

  ul.slides {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: table;
    position: absolute;
    top: 0;
    transition: left 800ms;

    li {
      list-style-type: none;
      display: table-cell;

      img {
        width: 800px;
      }
    }
  }

  ul.bullets {
    width: inherit;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0;
    margin: 0 0 10px 0;
    text-align: center;
    z-index: 1;

    li {
      list-style-type: none;
      display: inline;
      color: #fff;
      cursor: pointer;
      padding: 0 5px;
      font-size: 20px;
      font-family: sans-serif;
    }
  }

  .prev,
  .next {
    text-decoration: none;
    color: #fff;
    position: absolute;
    z-index: 1;
    font-size: 42px;
    top: 43%;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  }

  .prev {
    left: 20px;
  }

  .next {
    right: 20px;
  }
}

.thumbnails {
  margin: auto;
  max-width: 80%;
}

.thumbnails .vueperslide {
  box-sizing: border-box;
  border: 1px solid #fff;
  transition: 0.3s ease-in-out;
  opacity: 0.7;
  cursor: pointer;
}

.thumbnails .vueperslide--active {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  opacity: 1;
  border-color: #000;
}
</style>