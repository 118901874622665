const dataCert = [
  {
    owner: "NN",
    cert_no:
      "{N:9685689.373,E:396726.189}, {N:9685689.373,E:396826.189}, {N:9685589.373,E:396826.189}, {N:9685589.373,E:396726.189}",
    // isVerified: false,
    // isValid: false,
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685689.373, E: 396726.189 },
      { N: 9685689.373, E: 396826.189 },
      { N: 9685589.373, E: 396826.189 },
      { N: 9685589.373, E: 396726.189 },
    ],
    coord: [],
  },
  {
    owner: "NN",
    cert_no:
      "{N:9685489.373,E:396626.189}, {N:9685489.373,E:396726.189}, {N:9685389.373,E:396726.189}, {N:9685389.373,E:396626.189}",
    // isVerified: false,
    // isValid: false,
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685489.373, E: 396626.189 },
      { N: 9685489.373, E: 396726.189 },
      { N: 9685389.373, E: 396726.189 },
      { N: 9685389.373, E: 396626.189 },
    ],
    coord: [],
  },
  {
    owner: "NN",
    cert_no:
      "{N:9685089.373,E:398326.189}. {N:9685089.373,E:398426.189}, {N:9684989.373,E:398426.189}, {N:9684989.373,E:398326.189}",
    // isVerified: false,
    // isValid: false,
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685089.373, E: 398326.189 },
      { N: 9685089.373, E: 398426.189 },
      { N: 9684989.373, E: 398426.189 },
      { N: 9684989.373, E: 398326.189 },
    ],
    coord: [],
  },
  {
    owner: "NN",
    cert_no:
      "{N:9685089.373,E:398526.189}, {N:9685089.373,E:398626.189}, {N:9684989.373,E:398626.189}, {N:9684989.373,E:398526.189}",
    // isVerified: false,
    // isValid: false,
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685089.373, E: 398526.189 },
      { N: 9685089.373, E: 398626.189 },
      { N: 9684989.373, E: 398626.189 },
      { N: 9684989.373, E: 398526.189 },
    ],
    coord: [],
  },
  {
    owner: "NN",
    cert_no:
      "{N:9684889.373,E:398026.189}, {N:9684889.373,E:398126.189}, {N:9684789.373,E:398126.189}, {N:9684789.373,E:398026.189}",
    // isVerified: false,
    // isValid: false,
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684889.373, E: 398026.189 },
      { N: 9684889.373, E: 398126.189 },
      { N: 9684789.373, E: 398126.189 },
      { N: 9684789.373, E: 398026.189 },
    ],
    coord: [],
  },
  {
    owner: "NN",
    cert_no:
      "{N:9684689.373,E:398126.189}, {N:9684689.373,E:398226.189}, {N:9684589.373,E:398226.189}, {N:9684589.373,E:398126.189}",
    // isVerified: false,
    // isValid: false,
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684689.373, E: 398126.189 },
      { N: 9684689.373, E: 398226.189 },
      { N: 9684589.373, E: 398226.189 },
      { N: 9684589.373, E: 398126.189 },
    ],
    coord: [],
  },
  {
    owner: "NN",
    cert_no:
      "{N:9684589.373,E:398026.189}, {N:9684589.373,E:398226.189}, {N:9684575.503,E:398226.189}, {N:9684502.586,E:398048.892}",
    // isVerified: false,
    // isValid: false,
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684589.373, E: 398026.189 },
      { N: 9684589.373, E: 398226.189 },
      { N: 9684575.503, E: 398226.189 },
      { N: 9684502.586, E: 398048.892 },
    ],
    coord: [],
  },
  {
    owner: "NN",
    cert_no:
      "{N:9685154.907, E:398726.189}, {N:9685148.434, E:398825.983}, {N:9685028.820, E:398828.750}, {N:9684981.954, E:398688.929}, {N:9685088.410, E:398692.980}, {N:9685089.650, E:398725.210},",
    // isVerified: false,
    // isValid: false,
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685154.907, E: 398726.189 },
      { N: 9685148.434, E: 398825.983 },
      { N: 9685028.82, E: 398828.75 },
      { N: 9684981.954, E: 398688.929 },
      { N: 9685088.41, E: 398692.98 },
      { N: 9685089.65, E: 398725.21 },
    ],
    coord: [],
  },
  {
    owner: "NN",
    cert_no:
      "{N:9684689.460,E:397325.460}, {N:9684689.460,E:397361.140}, {N:9684608.350,E:397359.420}, {N:9684614.840,E:397325.520}",
    // isVerified: false,
    // isValid: false,
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684689.46, E: 397325.46 },
      { N: 9684689.46, E: 397361.14 },
      { N: 9684608.35, E: 397359.42 },
      { N: 9684614.84, E: 397325.52 },
    ],
    coord: [],
  },
  {
    owner: "Ratmin",
    cert_no: "593.83.1618.BHDP.VII.2022",
    // isVerified: false,
    // isValid: false,
    isVerified: true,
    isValid: true,
    NEcoord: [
      // { N: 9686989.373, E: 398126.189 },
      // { N: 9686989.373, E: 398126.189 },
      // { N: 9686889.373, E: 398126.189 },
      // { N: 9686889.373, E: 398026.189 },
      { N: 9686989.373, E: 398026.189 },
      { N: 9686989.373, E: 398126.189 },
      { N: 9686889.373, E: 398126.189 },
      { N: 9686889.373, E: 398026.189 },
    ],
    coord: [],
  },
  {
    owner: "Nasrudin Namlu",
    cert_no: "593.83.1619.BHDP.VII.2022",
    // isVerified: false,
    // isValid: false,
    isVerified: true,
    isValid: true,
    NEcoord: [
      // { N: 9686989.373, E: 398126.189 },
      // { N: 9686889.373, E: 398126.189 },
      // { N: 9686789.373, E: 398126.189 },
      // { N: 9686789.373, E: 398026.189 },      
      { N: 9686889.36, E: 398026.30 },
      { N: 9686888.77, E: 398126.45 },
      { N: 9686789.373, E: 398126.189 },
      { N: 9686789.373, E: 398026.189 },      
    ],
    coord: [],
  },  
  {
    owner: "Erwin H. Genda",
    cert_no: "593.83.1624.BHDP.VII.2022",
    // isVerified: false,
    // isValid: false,
    isVerified: true,
    isValid: true,
    NEcoord: [
      // { N: 9686989.373, E: 398126.189 },
      // { N: 9686989.373, E: 398126.189 },
      // { N: 9686889.373, E: 398226.189 },
      // { N: 9686889.373, E: 398126.189 },
      { N: 9686989.373, E: 398126.189 },
      { N: 9686989.373, E: 398226.189 },
      { N: 9686889.373, E: 398226.189 },
      { N: 9686889.373, E: 398126.189 },
    ],
    coord: [],
  },  
  {
    owner: "Mukh Efendi, SKom",
    cert_no: "593.83.1498.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685589.373, E: 396826.189 },
      { N: 9685589.373, E: 396926.189 },
      { N: 9685489.373, E: 396926.189 },
      { N: 9685489.373, E: 396826.189 },
    ],
    coord: [],
  },
  {
    owner: "Arpin",
    cert_no: "593.83.1499.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685689.373, E: 396626.189 },
      { N: 9685689.373, E: 396726.189 },
      { N: 9685589.373, E: 396726.189 },
      { N: 9685589.373, E: 396626.189 },
    ],
    coord: [],
  },
  {
    owner: " Carto",
    cert_no: "593.83.1500.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685189.373, E: 396926.189 },
      { N: 9685189.373, E: 397026.189 },
      { N: 9685089.373, E: 397026.189 },
      { N: 9685089.373, E: 396926.189 },
    ],
    coord: [],
  },
  {
    owner: " Atman",
    cert_no: "593.83.1501.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685189.373, E: 396726.189 },
      { N: 9685189.373, E: 396826.189 },
      { N: 9685089.373, E: 396826.189 },
      { N: 9685089.373, E: 396726.189 },
    ],
    coord: [],
  },
  {
    owner: " Abd Azis",
    cert_no: "593.83.1502.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685289.373, E: 396626.189 },
      { N: 9685289.373, E: 396726.189 },
      { N: 9685189.373, E: 396726.189 },
      { N: 9685189.373, E: 396626.189 },
    ],
    coord: [],
  },
  {
    owner: " Habri Barri",
    cert_no: "593.83.1503.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685389.373, E: 396626.189 },
      { N: 9685389.373, E: 396726.189 },
      { N: 9685289.373, E: 396726.189 },
      { N: 9685289.373, E: 396626.189 },
    ],
    coord: [],
  },
  {
    owner: " Darwin",
    cert_no: "593.83.1504.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685689.373, E: 396826.189 },
      { N: 9685689.373, E: 396926.189 },
      { N: 9685589.373, E: 396926.189 },
      { N: 9685589.373, E: 396826.189 },
    ],
    coord: [],
  },
  {
    owner: " Edi Aksan",
    cert_no: "593.83.1505.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685489.373, E: 396626.189 },
      { N: 9685489.373, E: 396726.189 },
    ],
    coord: [],
  },
  {
    owner: " Muhammad Ihwan",
    cert_no: "593.83.1506.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685389.373, E: 396826.189 },
      { N: 9685389.373, E: 396926.189 },
      { N: 9685289.373, E: 396926.189 },
      { N: 9685289.373, E: 396826.189 },
    ],
    coord: [],
  },
  {
    owner: " Hasmudin",
    cert_no: "593.83.1507.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685689.373, E: 396526.189 },
      { N: 9685689.373, E: 396626.189 },
      { N: 9685589.373, E: 396626.189 },
      { N: 9685589.373, E: 396526.189 },
    ],
    coord: [],
  },
  {
    owner: " Fandi Risaldi",
    cert_no: "593.83.1508.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685489.373, E: 396826.189 },
      { N: 9685489.373, E: 396926.189 },
      { N: 9685389.373, E: 396926.189 },
      { N: 9685389.373, E: 396826.189 },
    ],
    coord: [],
  },
  {
    owner: " Erasmus Kukung",
    cert_no: "593.83.1509.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685489.373, E: 396526.189 },
      { N: 9685489.373, E: 396626.189 },
      { N: 9685389.373, E: 396626.189 },
      { N: 9685389.373, E: 396526.189 },
    ],
    coord: [],
  },
  {
    owner: " Fazlurrahiman Nugraha, SP.",
    cert_no: "593.83.1510.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685689.373, E: 396926.189 },
      { N: 9685689.373, E: 397026.189 },
      { N: 9685589.373, E: 397026.189 },
      { N: 9685589.373, E: 396926.189 },
    ],
    coord: [],
  },
  {
    owner: " Antan",
    cert_no: "593.83.1511.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685189.373, E: 396826.189 },
      { N: 9685189.373, E: 396926.189 },
      { N: 9685089.373, E: 396926.189 },
      { N: 9685089.373, E: 396826.189 },
    ],
    coord: [],
  },
  {
    owner: " Hamdan",
    cert_no: "593.83.1512.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685589.373, E: 396526.189 },
      { N: 9685589.373, E: 396626.189 },
      { N: 9685489.373, E: 396626.189 },
      { N: 9685489.373, E: 396526.189 },
    ],
    coord: [],
  },
  {
    owner: " Habil",
    cert_no: "593.83.1513.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685289.373, E: 396726.189 },
      { N: 9685289.373, E: 396826.189 },
      { N: 9685189.373, E: 396826.189 },
      { N: 9685189.373, E: 396726.189 },
    ],
    coord: [],
  },
  {
    owner: " Kardianto",
    cert_no: "593.83.1514.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685189.373, E: 396626.189 },
      { N: 9685189.373, E: 396726.189 },
      { N: 9685089.373, E: 396726.189 },
      { N: 9685089.373, E: 396626.189 },
    ],
    coord: [],
  },
  {
    owner: " Muh. Ridwan",
    cert_no: "593.83.1515.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685289.373, E: 396826.189 },
      { N: 9685289.373, E: 396926.189 },
      { N: 9685189.373, E: 396926.189 },
      { N: 9685189.373, E: 396826.189 },
    ],
    coord: [],
  },
  {
    owner: " Faldi",
    cert_no: "593.83.1516.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685389.373, E: 396726.189 },
      { N: 9685389.373, E: 396826.189 },
      { N: 9685289.373, E: 396826.189 },
      { N: 9685289.373, E: 396726.189 },
    ],
    coord: [],
  },
  {
    owner: " Muhrin",
    cert_no: "593.83.1517.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685389.373, E: 396526.189 },
      { N: 9685389.373, E: 396626.189 },
      { N: 9685289.373, E: 396626.189 },
      { N: 9685289.373, E: 396526.189 },
    ],
    coord: [],
  },
  {
    owner: " Sukardi",
    cert_no: "593.83.1518.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685589.373, E: 396626.189 },
      { N: 9685589.373, E: 396726.189 },
      { N: 9685489.373, E: 396726.189 },
      { N: 9685489.373, E: 396626.189 },
    ],
    coord: [],
  },
  {
    owner: " Yusran Afandi",
    cert_no: "593.83.1519.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685289.373, E: 396926.189 },
      { N: 9685289.373, E: 397026.189 },
      { N: 9685189.373, E: 397026.189 },
      { N: 9685189.373, E: 396926.189 },
    ],
    coord: [],
  },
  {
    owner: " Sahabudin",
    cert_no: "593.83.1521.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685189.373, E: 396526.189 },
      { N: 9685189.373, E: 396626.189 },
      { N: 9685089.373, E: 396626.189 },
      { N: 9685089.373, E: 396526.189 },
    ],
    coord: [],
  },
  {
    owner: " Mursalim",
    cert_no: "593.83.1522.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685589.373, E: 396926.189 },
      { N: 9685589.373, E: 397026.189 },
      { N: 9685489.373, E: 397026.189 },
      { N: 9685489.373, E: 396926.189 },
    ],
    coord: [],
  },
  {
    owner: " Murhadi Asmin",
    cert_no: "593.83.1523.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685489.373, E: 397026.189 },
      { N: 9685489.373, E: 396926.189 },
      { N: 9685389.373, E: 396926.189 },
      { N: 9685389.373, E: 397026.189 },
    ],
    coord: [],
  },
  {
    owner: " Parman Sitanjak",
    cert_no: "593.83.1524.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685589.373, E: 396726.189 },
      { N: 9685589.373, E: 396826.189 },
      { N: 9685489.373, E: 396826.189 },
      { N: 9685489.373, E: 396726.189 },
    ],
    coord: [],
  },
  {
    owner: " Nur Afni",
    cert_no: "593.83.1525.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685289.373, E: 396526.189 },
      { N: 9685289.373, E: 396626.189 },
      { N: 9685189.373, E: 396626.189 },
      { N: 9685189.373, E: 396526.189 },
    ],
    coord: [],
  },
  {
    owner: " Rizal Onami",
    cert_no: "593.83.1526.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685389.373, E: 396926.189 },
      { N: 9685389.373, E: 397026.189 },
      { N: 9685289.373, E: 397026.189 },
      { N: 9685289.373, E: 396926.189 },
    ],
    coord: [],
  },
  {
    owner: " Yunus M",
    cert_no: "593.83.1527.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685189.373, E: 397426.189 },
      { N: 9685189.373, E: 397526.189 },
      { N: 9685089.373, E: 397526.189 },
      { N: 9685089.373, E: 397426.189 },
    ],
    coord: [],
  },
  {
    owner: " Rajid D",
    cert_no: "593.83.1528.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685289.373, E: 397326.189 },
      { N: 9685289.373, E: 397426.189 },
      { N: 9685189.373, E: 397426.189 },
      { N: 9685189.373, E: 397326.189 },
    ],
    coord: [],
  },
  {
    owner: " Sumardi",
    cert_no: "593.83.1529.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685089.373, E: 397426.189 },
      { N: 9685089.373, E: 397526.189 },
      { N: 9684989.373, E: 397526.189 },
      { N: 9684989.373, E: 397426.189 },
    ],
    coord: [],
  },
  {
    owner: " Ramli",
    cert_no: "593.83.1530.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685489.373, E: 397126.189 },
      { N: 9685489.373, E: 397226.189 },
      { N: 9685389.373, E: 397226.189 },
      { N: 9685389.373, E: 397126.189 },
    ],
    coord: [],
  },
  {
    owner: " Sukri",
    cert_no: "593.83.1531.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685189.373, E: 397326.189 },
      { N: 9685189.373, E: 397426.189 },
      { N: 9685089.373, E: 397426.189 },
      { N: 9685089.373, E: 397326.189 },
    ],
    coord: [],
  },
  {
    owner: " Rahmat",
    cert_no: "593.83.1532.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685589.373, E: 397326.189 },
      { N: 9685589.373, E: 397426.189 },
      { N: 9685489.373, E: 397426.189 },
      { N: 9685489.373, E: 397326.189 },
    ],
    coord: [],
  },
  {
    owner: " Tasrim",
    cert_no: "593.83.1533.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685489.373, E: 397026.189 },
      { N: 9685489.373, E: 397126.189 },
      { N: 9685389.373, E: 397126.189 },
      { N: 9685389.373, E: 397026.189 },
    ],
    coord: [],
  },
  {
    owner: " Rudi T",
    cert_no: "593.83.1534.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685589.373, E: 397126.189 },
      { N: 9685589.373, E: 397226.189 },
      { N: 9685489.373, E: 397226.189 },
      { N: 9685489.373, E: 397126.189 },
    ],
    coord: [],
  },
  {
    owner: " Alimudin",
    cert_no: "593.83.1535.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685489.373, E: 397326.189 },
      { N: 9685489.373, E: 397426.189 },
      { N: 9685389.373, E: 397426.189 },
      { N: 9685389.373, E: 397326.189 },
    ],
    coord: [],
  },
  {
    owner: " Arman Amir Injil",
    cert_no: "593.83.1536.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685289.373, E: 397126.189 },
      { N: 9685289.373, E: 397226.189 },
      { N: 9685189.373, E: 397226.189 },
      { N: 9685189.373, E: 397126.189 },
    ],
    coord: [],
  },
  {
    owner: " Arman Nasir",
    cert_no: "593.83.1537.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685389.373, E: 397326.189 },
      { N: 9685389.373, E: 397426.189 },
      { N: 9685289.373, E: 397426.189 },
      { N: 9685289.373, E: 397326.189 },
    ],
    coord: [],
  },
  {
    owner: " Asnawi L",
    cert_no: "593.83.1538.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685089.373, E: 397326.189 },
      { N: 9685089.373, E: 397426.189 },
      { N: 9684989.373, E: 397426.189 },
      { N: 9684989.373, E: 397326.189 },
    ],
    coord: [],
  },
  {
    owner: " Awit",
    cert_no: "593.83.1539.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685289.373, E: 397226.189 },
      { N: 9685289.373, E: 397326.189 },
      { N: 9685189.373, E: 397326.189 },
      { N: 9685189.373, E: 397226.189 },
    ],
    coord: [],
  },
  {
    owner: " Burhan Lamido",
    cert_no: "593.83.1540.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685689.373, E: 397026.189 },
      { N: 9685689.373, E: 397126.189 },
      { N: 9685589.373, E: 397126.189 },
      { N: 9685589.373, E: 397026.189 },
    ],
    coord: [],
  },
  {
    owner: " Daeria",
    cert_no: "593.83.1541.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685689.373, E: 397226.189 },
      { N: 9685689.373, E: 397326.189 },
      { N: 9685589.373, E: 397326.189 },
      { N: 9685589.373, E: 397226.189 },
    ],
    coord: [],
  },
  {
    owner: " Dafrin",
    cert_no: "593.83.1542.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685489.373, E: 397426.189 },
      { N: 9685489.373, E: 397526.189 },
      { N: 9685389.373, E: 397526.189 },
      { N: 9685389.373, E: 397426.189 },
    ],
    coord: [],
  },
  {
    owner: " Hastin",
    cert_no: "593.83.1543.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685389.373, E: 397226.189 },
      { N: 9685389.373, E: 397326.189 },
      { N: 9685289.373, E: 397326.189 },
      { N: 9685289.373, E: 397226.189 },
    ],
    coord: [],
  },
  {
    owner: " Jafudin",
    cert_no: "593.83.1544.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685489.373, E: 397226.189 },
      { N: 9685489.373, E: 397326.189 },
      { N: 9685389.373, E: 397326.189 },
      { N: 9685389.373, E: 397226.189 },
    ],
    coord: [],
  },
  {
    owner: " Mukmin",
    cert_no: "593.83.1545.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685389.373, E: 397426.189 },
      { N: 9685389.373, E: 397526.189 },
      { N: 9685289.373, E: 397526.189 },
      { N: 9685289.373, E: 397426.189 },
    ],
    coord: [],
  },
  {
    owner: " Rocky Mandiri Putra",
    cert_no: "593.83.1546.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685189.373, E: 397126.189 },
      { N: 9685189.373, E: 397226.189 },
      { N: 9685089.373, E: 397226.189 },
      { N: 9685089.373, E: 397126.189 },
    ],
    coord: [],
  },
  {
    owner: " Gusrif Risal",
    cert_no: "593.83.1547.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685589.373, E: 397226.189 },
      { N: 9685589.373, E: 397326.189 },
      { N: 9685489.373, E: 397326.189 },
      { N: 9685489.373, E: 397226.189 },
    ],
    coord: [],
  },
  {
    owner: " Kenarti",
    cert_no: "593.83.1548.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685389.373, E: 397026.189 },
      { N: 9685389.373, E: 397126.189 },
      { N: 9685289.373, E: 397126.189 },
      { N: 9685289.373, E: 397026.189 },
    ],
    coord: [],
  },
  {
    owner: " Junaedin",
    cert_no: "593.83.1549.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685589.373, E: 397026.189 },
      { N: 9685589.373, E: 397126.189 },
      { N: 9685489.373, E: 397126.189 },
      { N: 9685489.373, E: 397026.189 },
    ],
    coord: [],
  },
  {
    owner: " Jupri M",
    cert_no: "593.83.1550.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685689.373, E: 397326.189 },
      { N: 9685689.373, E: 397426.189 },
      { N: 9685589.373, E: 397426.189 },
      { N: 9685589.373, E: 397326.189 },
    ],
    coord: [],
  },
  {
    owner: " Junaeda",
    cert_no: "593.83.1551.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685289.373, E: 397026.189 },
      { N: 9685289.373, E: 397126.189 },
      { N: 9685189.373, E: 397126.189 },
      { N: 9685189.373, E: 397026.189 },
    ],
    coord: [],
  },
  {
    owner: " Ertiany Rara",
    cert_no: "593.83.1552.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685189.373, E: 397226.189 },
      { N: 9685189.373, E: 397326.189 },
      { N: 9685089.373, E: 397326.189 },
      { N: 9685089.373, E: 397226.189 },
    ],
    coord: [],
  },
  {
    owner: " Jafar Fajeku",
    cert_no: "593.83.1553.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685389.373, E: 397126.189 },
      { N: 9685389.373, E: 397226.189 },
      { N: 9685289.373, E: 397226.189 },
      { N: 9685289.373, E: 397126.189 },
    ],
    coord: [],
  },
  {
    owner: " Mariana Udin",
    cert_no: "593.83.1554.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685689.373, E: 397126.189 },
      { N: 9685689.373, E: 397226.189 },
      { N: 9685589.373, E: 397226.189 },
      { N: 9685589.373, E: 397126.189 },
    ],
    coord: [],
  },
  {
    owner: " Abd. Hamid",
    cert_no: "593.83.1555.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685289.373, E: 397426.189 },
      { N: 9685289.373, E: 397526.189 },
      { N: 9685189.373, E: 397526.189 },
      { N: 9685189.373, E: 397426.189 },
    ],
    coord: [],
  },
  {
    owner: " Adaria",
    cert_no: "593.83.1556.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685189.373, E: 397026.189 },
      { N: 9685189.373, E: 397126.189 },
      { N: 9685089.373, E: 397126.189 },
      { N: 9685089.373, E: 397026.189 },
    ],
    coord: [],
  },
  {
    owner: " Misba",
    cert_no: "593.83.1557.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685589.373, E: 398226.189 },
      { N: 9685589.373, E: 398326.189 },
      { N: 9685489.373, E: 398326.189 },
      { N: 9685489.373, E: 398226.189 },
    ],
    coord: [],
  },
  {
    owner: " Rama Dheo G. Y",
    cert_no: "593.83.1558.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685589.373, E: 398426.189 },
      { N: 9685589.373, E: 398526.189 },
      { N: 9685489.373, E: 398526.189 },
      { N: 9685489.373, E: 398426.189 },
    ],
    coord: [],
  },
  {
    owner: " Mukrim Yusup",
    cert_no: "593.83.1559.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685489.373, E: 398326.189 },
      { N: 9685489.373, E: 398426.189 },
      { N: 9685389.373, E: 398426.189 },
      { N: 9685389.373, E: 398326.189 },
    ],
    coord: [],
  },
  {
    owner: " Patarudin",
    cert_no: "593.83.1560.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685759.047, E: 398426.189 },
      { N: 9685761.455, E: 398486.32 },
      { N: 9685714.399, E: 398501.526 },
      { N: 9685677.196, E: 398519.444 },
      { N: 9685652.839, E: 398520.582 },
      { N: 9685637.225, E: 398526.613 },
      { N: 9685639.373, E: 398426.189 },
    ],
    coord: [],
  },
  {
    owner: " Ramadan Tandi Sasong",
    cert_no: "593.83.1561.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685789.373, E: 398326.189 },
      { N: 9685789.373, E: 398426.189 },
      { N: 9685689.373, E: 398426.189 },
      { N: 9685689.373, E: 398326.189 },
    ],
    coord: [],
  },
  {
    owner: " Irfan Mahmud, SH",
    cert_no: "593.83.1562.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685789.373, E: 397926.189 },
      { N: 9685789.373, E: 398026.189 },
      { N: 9685689.373, E: 398026.189 },
      { N: 9685689.373, E: 397926.189 },
    ],
    coord: [],
  },
  {
    owner: " Nuripa",
    cert_no: "593.83.1563.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685789.373, E: 398026.189 },
      { N: 9685789.373, E: 398126.189 },
      { N: 9685689.373, E: 398126.189 },
      { N: 9685689.373, E: 398026.189 },
    ],
    coord: [],
  },
  {
    owner: " Sandi Pratama",
    cert_no: "593.83.1564.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685789.373, E: 398126.189 },
      { N: 9685789.373, E: 398226.189 },
      { N: 9685689.373, E: 398226.189 },
      { N: 9685689.373, E: 398126.189 },
    ],
    coord: [],
  },
  {
    owner: " Supriadi",
    cert_no: "593.83.1565.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685489.373, E: 398526.189 },
      { N: 9685495.397, E: 398609.201 },
      { N: 9685467.78, E: 398642.595 },
      { N: 9685440.959, E: 398654.915 },
      { N: 9685440.959, E: 398607.449 },
      { N: 9685389.373, E: 398608.51 },
      { N: 9685389.373, E: 398526.189 },
    ],
    coord: [],
  },
  {
    owner: " Suaela",
    cert_no: "593.83.1566.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685889.373, E: 397926.189 },
      { N: 9685889.373, E: 398026.189 },
      { N: 9685789.189, E: 398026.189 },
      { N: 9685789.189, E: 397926.189 },
    ],
    coord: [],
  },
  {
    owner: " Syarifuddin",
    cert_no: "593.83.1567.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685789.373, E: 398226.189 },
      { N: 9685789.373, E: 398326.189 },
      { N: 9685689.373, E: 398326.189 },
      { N: 9685689.373, E: 398226.189 },
    ],
    coord: [],
  },
  {
    owner: " Sanuddin",
    cert_no: "593.83.1568.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685689.373, E: 398226.189 },
      { N: 9685689.373, E: 398326.189 },
      { N: 9685589.373, E: 398326.189 },
      { N: 9685589.373, E: 398226.189 },
    ],
    coord: [],
  },
  {
    owner: " Suradi",
    cert_no: "593.83.1569.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685589.373, E: 398326.189 },
      { N: 9685589.373, E: 398426.189 },
      { N: 9685489.373, E: 398426.189 },
      { N: 9685489.373, E: 398326.189 },
    ],
    coord: [],
  },
  {
    owner: " Syarifudin Udin",
    cert_no: "593.83.1570.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685989.373, E: 398026.189 },
      { N: 9685989.373, E: 398126.189 },
      { N: 9685889.373, E: 398126.189 },
      { N: 9685889.373, E: 398026.189 },
    ],
    coord: [],
  },
  {
    owner: " Rustam Efendi",
    cert_no: "593.83.1571.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685857.013, E: 398326.189 },
      { N: 9685855.605, E: 398432.834 },
      { N: 9685761.455, E: 398486.32 },
      { N: 9685759.047, E: 398426.189 },
      { N: 9685789.373, E: 398426.189 },
      { N: 9685789.373, E: 398326.189 },
    ],
    coord: [],
  },
  {
    owner: " Rahmayanti",
    cert_no: "593.83.1572.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685489.373, E: 398226.189 },
      { N: 9685489.373, E: 398326.189 },
      { N: 9685389.373, E: 398326.189 },
      { N: 9685389.373, E: 398226.189 },
    ],
    coord: [],
  },
  {
    owner: " Julfikar Bualo",
    cert_no: "593.83.1573.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685989.373, E: 398126.189 },
      { N: 9685989.373, E: 398226.189 },
      { N: 9685889.373, E: 398226.189 },
      { N: 9685889.373, E: 398126.189 },
    ],
    coord: [],
  },
  {
    owner: " Arni Hube",
    cert_no: "593.83.1576.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685639.373, E: 398426.189 },
      { N: 9685637.225, E: 398526.613 },
      { N: 9685582.132, E: 398552.845 },
      { N: 9685555.114, E: 398556.547 },
      { N: 9685495.397, E: 398609.201 },
      { N: 9685489.373, E: 398526.189 },
      { N: 9685589.373, E: 398526.189 },
      { N: 9685589.373, E: 398426.189 },
    ],
    coord: [],
  },
  {
    owner: " Arminzal",
    cert_no: "593.83.1577.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685889.373, E: 398126.189 },
      { N: 9685889.373, E: 398226.189 },
      { N: 9685789.373, E: 398226.189 },
      { N: 9685789.373, E: 398126.189 },
    ],
    coord: [],
  },
  {
    owner: " Asnaldin, S. Sos",
    cert_no: "593.83.1578.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685689.373, E: 398126.189 },
      { N: 9685689.373, E: 398226.189 },
      { N: 9685589.373, E: 398226.189 },
      { N: 9685589.373, E: 398126.189 },
    ],
    coord: [],
  },
  {
    owner: " Arwin Samrun",
    cert_no: "593.83.1579.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685489.373, E: 398426.189 },
      { N: 9685489.373, E: 398526.189 },
      { N: 9685389.373, E: 398526.189 },
      { N: 9685389.373, E: 398426.189 },
    ],
    coord: [],
  },
  {
    owner: "Herwin Paallo",
    cert_no: "593.83.1580.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685689.373, E: 398326.189 },
      { N: 9685689.373, E: 398426.189 },
      { N: 9685589.373, E: 398426.189 },
      { N: 9685589.373, E: 398326.189 },
    ],
    coord: [],
  },
  {
    owner: "Asrul H. Mahmud",
    cert_no: "593.83.1581.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685989.373, E: 397826.189 },
      { N: 9685989.373, E: 397926.189 },
      { N: 9685889.373, E: 397926.189 },
      { N: 9685889.373, E: 397826.189 },
    ],
    coord: [],
  },
  {
    owner: "Armin U",
    cert_no: "593.83.1582.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685978.027, E: 398326.189 },
      { N: 9685988.004, E: 398364.48 },
      { N: 9685943.428, E: 398395.455 },
      { N: 9685855.605, E: 398432.834 },
      { N: 9685857.013, E: 398326.189 },
    ],
    coord: [],
  },
  {
    owner: " Abdul Wahid Labaso",
    cert_no: "593.83.1583.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685989.373, E: 397926.189 },
      { N: 9685989.373, E: 398026.189 },
      { N: 9685889.373, E: 398026.189 },
      { N: 9685889.373, E: 397926.189 },
    ],
    coord: [],
  },
  {
    owner: " Arsin B",
    cert_no: "593.83.1584.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685889.373, E: 398226.189 },
      { N: 9685889.373, E: 398326.189 },
      { N: 9685789.373, E: 398326.189 },
      { N: 9685789.373, E: 398226.189 },
    ],
    coord: [],
  },
  {
    owner: " Arafik",
    cert_no: "593.83.1585.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685789.373, E: 397826.189 },
      { N: 9685789.373, E: 397926.189 },
      { N: 9685689.373, E: 397926.189 },
      { N: 9685689.373, E: 397826.189 },
    ],
    coord: [],
  },
  {
    owner: " Udin Syamsudin",
    cert_no: "593.83.1586.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685989.373, E: 398226.189 },
      { N: 9685989.373, E: 398326.189 },
      { N: 9685889.373, E: 398326.189 },
      { N: 9685889.373, E: 398226.189 },
    ],
    coord: [],
  },
  {
    owner: " Dian Saputra",
    cert_no: "593.83.1587.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684889.373, E: 397326.189 },
      { N: 9684889.373, E: 397426.189 },
      { N: 9684789.373, E: 397426.189 },
      { N: 9684789.373, E: 397326.189 },
    ],
    coord: [],
  },
  {
    owner: " Isa",
    cert_no: "593.83.1588.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684889.373, E: 396897.886 },
      { N: 9684889.373, E: 396926.189 },
      { N: 9684789.373, E: 396926.189 },
      { N: 9684789.373, E: 397066.128 },
      { N: 9684705.552, E: 397066.768 },
      { N: 9684747.551, E: 396970.574 },
      { N: 9684795.768, E: 396899.499 },
    ],
    coord: [],
  },
  {
    owner: " Jusman",
    cert_no: "593.83.1589.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684989.373, E: 397326.189 },
      { N: 9684989.373, E: 397426.189 },
      { N: 9684889.373, E: 397426.189 },
      { N: 9684889.373, E: 397326.189 },
    ],
    coord: [],
  },
  {
    owner: " Haryono",
    cert_no: "593.83.1590.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685089.373, E: 397126.189 },
      { N: 9685089.373, E: 397226.189 },
      { N: 9684989.373, E: 397226.189 },
      { N: 9684989.373, E: 397126.189 },
    ],
    coord: [],
  },
  {
    owner: " Harifin",
    cert_no: "593.83.1591.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684789.373, E: 397226.189 },
      { N: 9684789.373, E: 397326.189 },
      { N: 9684689.373, E: 397326.189 },
      { N: 9684689.373, E: 397226.189 },
    ],
    coord: [],
  },
  {
    owner: " Irma",
    cert_no: "593.83.1592.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684989.373, E: 397226.189 },
      { N: 9684989.373, E: 397326.189 },
      { N: 9684889.373, E: 397326.189 },
      { N: 9684889.373, E: 397226.189 },
    ],
    coord: [],
  },
  {
    owner: " Kamarudin",
    cert_no: "593.83.1593.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684789.373, E: 397326.189 },
      { N: 9684789.373, E: 397426.189 },
      { N: 9684689.373, E: 397426.189 },
      { N: 9684689.373, E: 397326.189 },
    ],
    coord: [],
  },
  {
    owner: " Iskandar",
    cert_no: "593.83.1594.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684989.373, E: 397026.189 },
      { N: 9684989.373, E: 397126.189 },
      { N: 9684889.373, E: 397126.189 },
      { N: 9684889.373, E: 397026.189 },
    ],
    coord: [],
  },
  {
    owner: " Kasmat",
    cert_no: "593.83.1595.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685089.373, E: 397026.189 },
      { N: 9685089.373, E: 397126.189 },
      { N: 9684989.373, E: 397126.189 },
      { N: 9684989.373, E: 397026.189 },
    ],
    coord: [],
  },
  {
    owner: " Alwan Jaya",
    cert_no: "593.83.1596.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684989.373, E: 397126.189 },
      { N: 9684989.373, E: 397226.189 },
      { N: 9684889.373, E: 397226.189 },
      { N: 9684889.373, E: 397126.189 },
    ],
    coord: [],
  },
  {
    owner: " Muhammad Fidyan Arfin",
    cert_no: "593.83.1597.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685089.373, E: 396926.189 },
      { N: 9685089.373, E: 397026.189 },
      { N: 9684989.373, E: 397026.189 },
      { N: 9684989.373, E: 396926.189 },
    ],
    coord: [],
  },
  {
    owner: " Mas_adu",
    cert_no: "593.83.1598.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685089.373, E: 396726.189 },
      { N: 9685089.373, E: 396826.189 },
      { N: 9684989.373, E: 396826.189 },
      { N: 9684989.373, E: 396726.189 },
    ],
    coord: [],
  },
  {
    owner: " Nurhayat Moh Alwi Arsad",
    cert_no: "593.83.1599.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684889.373, E: 397026.189 },
      { N: 9684889.373, E: 397126.189 },
      { N: 9684789.373, E: 397126.189 },
      { N: 9684789.373, E: 397026.189 },
    ],
    coord: [],
  },
  {
    owner: " Rahman",
    cert_no: "593.83.1600.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684889.373, E: 396926.189 },
      { N: 9684889.373, E: 397026.189 },
      { N: 9684789.373, E: 397026.189 },
      { N: 9684789.373, E: 396926.189 },
    ],
    coord: [],
  },
  {
    owner: " Samsu Daris",
    cert_no: "593.83.1601.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684889.373, E: 397126.189 },
      { N: 9684889.373, E: 397226.189 },
      { N: 9684789.373, E: 397226.189 },
      { N: 9684789.373, E: 397126.189 },
    ],
    coord: [],
  },
  {
    owner: " Yasma",
    cert_no: "593.83.1602.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684989.373, E: 396926.189 },
      { N: 9684989.373, E: 397026.189 },
      { N: 9684889.373, E: 397026.189 },
      { N: 9684889.373, E: 396926.189 },
    ],
    coord: [],
  },
  {
    owner: " Johanis",
    cert_no: "593.83.1603.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685089.373, E: 396326.189 },
      { N: 9685089.373, E: 396481.052 },
      { N: 9684986.103, E: 396481.001 },
      { N: 9685016.527, E: 396448.112 },
      { N: 9685029.172, E: 396349.215 },
    ],
    coord: [],
  },
  {
    owner: " Fadli Salam",
    cert_no: "593.83.1604.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684889.373, E: 397226.189 },
      { N: 9684889.373, E: 397326.189 },
      { N: 9684789.373, E: 397326.189 },
      { N: 9684789.373, E: 397226.189 },
    ],
    coord: [],
  },
  {
    owner: " Arifin Alwi",
    cert_no: "593.83.1605.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684989.373, E: 396826.189 },
      { N: 9684989.373, E: 396926.189 },
      { N: 9684889.373, E: 396926.189 },
      { N: 9684889.373, E: 396826.189 },
    ],
    coord: [],
  },
  {
    owner: " Almin",
    cert_no: "593.83.1606.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684789.373, E: 397066.128 },
      { N: 9684789.373, E: 397126.189 },
      { N: 9684689.373, E: 397126.189 },
      { N: 9684689.373, E: 397226.189 },
      { N: 9684631.814, E: 397222.158 },
      { N: 9684658.522, E: 397147.585 },
      { N: 9684705.552, E: 397066.768 },
    ],
    coord: [],
  },
  {
    owner: " Amirudin",
    cert_no: "593.83.1607.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685089.373, E: 397226.189 },
      { N: 9685089.373, E: 397326.189 },
      { N: 9684989.373, E: 397326.189 },
      { N: 9684989.373, E: 397226.189 },
    ],
    coord: [],
  },
  {
    owner: " Aksar",
    cert_no: "593.83.1608.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684789.373, E: 397126.189 },
      { N: 9684789.373, E: 397226.189 },
      { N: 9684689.373, E: 397226.189 },
      { N: 9684689.373, E: 397126.189 },
    ],
    coord: [],
  },
  {
    owner: "Ahdin Roma",
    cert_no: "593.83.1609.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684989.373, E: 397426.189 },
      { N: 9684989.373, E: 397526.189 },
      { N: 9684889.373, E: 397526.189 },
      { N: 9684889.373, E: 397426.189 },
    ],
    coord: [],
  },
  {
    owner: "Ahdin Roma",
    cert_no: "593.83.1610.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684689.373, E: 397226.189 },
      { N: 9684689.373, E: 397326.189 },
      { N: 9684607.207, E: 397326.189 },
      { N: 9684631.814, E: 397222.158 },
    ],
    coord: [],
  },
  {
    owner: "Ahdin Roma",
    cert_no: "593.83.1611.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684989.373, E: 396692.36 },
      { N: 9684989.373, E: 396826.189 },
      { N: 9684912.525, E: 396826.189 },
      { N: 9684916.223, E: 396720.104 },
      { N: 9684948.499, E: 396678.808 },
    ],
    coord: [],
  },
  {
    owner: "Albert",
    cert_no: "593.83.1610.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685089.373, E: 396481.052 },
      { N: 9685089.373, E: 396526.189 },
      { N: 9684989.373, E: 396526.189 },
      { N: 9684989.373, E: 396692.36 },
      { N: 9684948.499, E: 396678.808 },
      { N: 9684958.006, E: 396668.893 },
      { N: 9684961.5, E: 396598.273 },
      { N: 9684971.675, E: 396495.116 },
      { N: 9684986.103, E: 396481.001 },
    ],
    coord: [],
  },
  {
    owner: "Ahdin Roma",
    cert_no: "593.83.1613.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685089.373, E: 396526.189 },
      { N: 9685089.373, E: 396626.189 },
      { N: 9684989.373, E: 396626.189 },
      { N: 9684989.373, E: 396526.189 },
    ],
    coord: [],
  },
  {
    owner: "Oscar Mandala",
    cert_no: "593.83.1614.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685089.373, E: 396626.189 },
      { N: 9685089.373, E: 396726.189 },
      { N: 9684989.373, E: 396726.189 },
      { N: 9684989.373, E: 396626.189 },
    ],
    coord: [],
  },
  {
    owner: "Asis Turangan",
    cert_no: "593.83.1615.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9687061.562, E: 397823.279 },
      { N: 9686989.373, E: 397891.807 },
      { N: 9686989.373, E: 397826.189 },
      { N: 9686889.373, E: 397826.189 },
      { N: 9686889.373, E: 397753.904 },
      { N: 9686925.372, E: 397740.073 },
      { N: 9686962.554, E: 397765.144 },
      { N: 9687004.337, E: 397805.868 },
    ],
    coord: [],
  },
  {
    owner: "Ajmain Yusdin",
    cert_no: "593.83.1616.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686789.373, E: 398226.189 },
      { N: 9686789.373, E: 398326.189 },
      { N: 9686689.373, E: 398326.189 },
      { N: 9686689.373, E: 398226.189 },
    ],
    coord: [],
  },
  {
    owner: "Rianto Rantong",
    cert_no: "593.83.1617.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686989.373, E: 397826.189 },
      { N: 9686989.373, E: 397926.189 },
      { N: 9686889.373, E: 397926.189 },
      { N: 9686889.373, E: 397826.189 },
    ],
    coord: [],
  },
  {
    owner: "Ramadhan",
    cert_no: "593.83.1620.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686989.373, E: 397926.189 },
      { N: 9686989.373, E: 398026.189 },
      { N: 9686889.373, E: 398026.189 },
      { N: 9686889.373, E: 397926.189 },
    ],
    coord: [],
  },
  {
    owner: "Jon Sabara",
    cert_no: "593.83.1621.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686789.373, E: 398026.189 },
      { N: 9686789.373, E: 398126.189 },
      { N: 9686689.373, E: 398126.189 },
      { N: 9686689.373, E: 398026.189 },
    ],
    coord: [],
  },
  {
    owner: "Sahar",
    cert_no: "593.83.1622.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686889.373, E: 398126.189 },
      { N: 9686889.373, E: 398226.189 },
      { N: 9686789.373, E: 398226.189 },
      { N: 9686789.373, E: 398126.189 },
    ],
    coord: [],
  },
  {
    owner: "Widyawati Janudin",
    cert_no: "593.83.1623.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686789.373, E: 398126.189 },
      { N: 9686789.373, E: 398226.189 },
      { N: 9686689.373, E: 398226.189 },
      { N: 9686689.373, E: 398126.189 },
    ],
    coord: [],
  },
  {
    owner: "Ilham",
    cert_no: "593.83.1625.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684889.373, E: 397826.189 },
      { N: 9684889.373, E: 397926.189 },
      { N: 9684789.373, E: 397926.189 },
      { N: 9684789.373, E: 397826.189 },
    ],
    coord: [],
  },
  {
    owner: "Wahyudin",
    cert_no: "593.83.1626.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684789.373, E: 397626.189 },
      { N: 9684789.373, E: 397726.189 },
      { N: 9684689.373, E: 397726.189 },
      { N: 9684689.373, E: 397626.189 },
    ],
    coord: [],
  },
  {
    owner: "Siti Asrini Junaedo",
    cert_no: "593.83.1627.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684989.373, E: 397626.189 },
      { N: 9684989.373, E: 397726.189 },
      { N: 9684889.373, E: 397726.189 },
      { N: 9684889.373, E: 397626.189 },
    ],
    coord: [],
  },
  {
    owner: "Desmin",
    cert_no: "593.83.1628.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684789.373, E: 397726.189 },
      { N: 9684789.373, E: 397826.189 },
      { N: 9684689.373, E: 397826.189 },
      { N: 9684689.373, E: 397726.189 },
    ],
    coord: [],
  },
  {
    owner: "Ibrahim",
    cert_no: "593.83.1629.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684689.373, E: 397526.189 },
      { N: 9684689.373, E: 397626.189 },
      { N: 9684589.373, E: 397626.189 },
      { N: 9684589.373, E: 397526.189 },
    ],
    coord: [],
  },
  {
    owner: "Efendi",
    cert_no: "593.83.1630.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684789.373, E: 397826.189 },
      { N: 9684789.373, E: 397926.189 },
      { N: 9684689.373, E: 397926.189 },
      { N: 9684689.373, E: 397826.189 },
    ],
    coord: [],
  },
  {
    owner: "Ihwan Moh. Thaiyeb",
    cert_no: "593.83.1631.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684689.373, E: 397426.189 },
      { N: 9684689.373, E: 397526.189 },
      { N: 9684589.373, E: 397526.189 },
      { N: 9684589.373, E: 397426.189 },
    ],
    coord: [],
  },
  {
    owner: "Sirajudin Aga",
    cert_no: "593.83.1632.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685089.373, E: 397826.189 },
      { N: 9685089.373, E: 397926.189 },
      { N: 9684989.373, E: 397926.189 },
      { N: 9684989.373, E: 397826.189 },
    ],
    coord: [],
  },
  {
    owner: "Kobra",
    cert_no: "593.83.1633.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684889.373, E: 397626.189 },
      { N: 9684889.373, E: 397726.189 },
      { N: 9684789.373, E: 397726.189 },
      { N: 9684789.373, E: 397626.189 },
    ],
    coord: [],
  },
  {
    owner: "Ahyudin Masempo",
    cert_no: "593.83.1634.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685089.373, E: 397726.189 },
      { N: 9685089.373, E: 397826.189 },
      { N: 9684989.373, E: 397826.189 },
      { N: 9684989.373, E: 397726.189 },
    ],
    coord: [],
  },
  {
    owner: "Hasnawi",
    cert_no: "593.83.1635.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684589.373, E: 397497.144 },
      { N: 9684589.373, E: 397626.189 },
      { N: 9684495.989, E: 397644.437 },
      { N: 9684519.95, E: 397539.264 },
      { N: 9684542.358, E: 397495.653 },
    ],
    coord: [],
  },
  {
    owner: "Afidin",
    cert_no: "593.83.1636.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685089.373, E: 397626.189 },
      { N: 9685089.373, E: 397726.189 },
      { N: 9684989.373, E: 397726.189 },
      { N: 9684989.373, E: 397626.189 },
    ],
    coord: [],
  },
  {
    owner: "Abd. Malik",
    cert_no: "593.83.1637.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684589.373, E: 397826.189 },
      { N: 9684589.373, E: 397926.189 },
      { N: 9684499.258, E: 397940.38 },
      { N: 9684495.336, E: 397844.364 },
      { N: 9684475.276, E: 397813.745 },
    ],
    coord: [],
  },
  {
    owner: "Darwis Musa",
    cert_no: "593.83.1638.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684689.373, E: 397626.189 },
      { N: 9684689.373, E: 397726.189 },
      { N: 9684589.373, E: 397726.189 },
      { N: 9684589.373, E: 397626.189 },
    ],
    coord: [],
  },
  {
    owner: "Harlin",
    cert_no: "593.83.1639.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684689.373, E: 397826.189 },
      { N: 9684689.373, E: 397926.189 },
      { N: 9684589.373, E: 397926.189 },
      { N: 9684589.373, E: 397826.189 },
    ],
    coord: [],
  },
  {
    owner: "Jamaludin",
    cert_no: "593.83.1640.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685089.373, E: 397926.189 },
      { N: 9685089.373, E: 398026.189 },
      { N: 9684989.373, E: 398026.189 },
      { N: 9684989.373, E: 397926.189 },
    ],
    coord: [],
  },
  {
    owner: "Misra",
    cert_no: "593.83.1641.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684989.373, E: 397726.189 },
      { N: 9684989.373, E: 397826.189 },
      { N: 9684889.373, E: 397826.189 },
      { N: 9684889.373, E: 397726.189 },
    ],
    coord: [],
  },
  {
    owner: "Lubis",
    cert_no: "593.83.1642.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684889.373, E: 397526.189 },
      { N: 9684889.373, E: 397626.189 },
      { N: 9684789.373, E: 397626.189 },
      { N: 9684789.373, E: 397526.189 },
    ],
    coord: [],
  },
  {
    owner: "Iswanto",
    cert_no: "593.83.1643.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684989.373, E: 397926.189 },
      { N: 9684989.373, E: 398026.189 },
      { N: 9684889.373, E: 398026.189 },
      { N: 9684889.373, E: 397926.189 },
    ],
    coord: [],
  },
  {
    owner: "Muh. Tamrin",
    cert_no: "593.83.1644.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684589.373, E: 397626.189 },
      { N: 9684589.373, E: 397726.189 },
      { N: 9684477.801, E: 397741.61 },
      { N: 9684485.044, E: 397716.32 },
      { N: 9684495.989, E: 397644.437 },
    ],
    coord: [],
  },
  {
    owner: "Nur Afni N",
    cert_no: "593.83.1645.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684689.373, E: 397726.189 },
      { N: 9684689.373, E: 397826.189 },
      { N: 9684589.373, E: 397826.189 },
      { N: 9684589.373, E: 397726.189 },
    ],
    coord: [],
  },
  {
    owner: "Nikma",
    cert_no: "593.83.1646.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684889.373, E: 397426.189 },
      { N: 9684889.373, E: 397526.189 },
      { N: 9684789.373, E: 397526.189 },
      { N: 9684789.373, E: 397426.189 },
    ],
    coord: [],
  },
  {
    owner: "Naima",
    cert_no: "593.83.1647.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684789.373, E: 397426.189 },
      { N: 9684789.373, E: 397526.189 },
      { N: 9684689.373, E: 397526.189 },
      { N: 9684689.373, E: 397426.189 },
    ],
    coord: [],
  },
  {
    owner: "Nadima",
    cert_no: "593.83.1648.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684589.373, E: 397726.189 },
      { N: 9684589.373, E: 397826.189 },
      { N: 9684475.276, E: 397813.745 },
      { N: 9684468.458, E: 397784.48 },
      { N: 9684477.801, E: 397741.61 },
    ],
    coord: [],
  },
  {
    owner: "Rawina",
    cert_no: "593.83.1649.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685089.373, E: 397526.189 },
      { N: 9685089.373, E: 397626.189 },
      { N: 9684989.373, E: 397626.189 },
      { N: 9684989.373, E: 397526.189 },
    ],
    coord: [],
  },
  {
    owner: "Robin",
    cert_no: "593.83.1650.BHDP.VII.2022 ",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684889.373, E: 397726.189 },
      { N: 9684889.373, E: 397826.189 },
      { N: 9684789.373, E: 397826.189 },
      { N: 9684789.373, E: 397726.189 },
    ],
    coord: [],
  },
  {
    owner: "Rusno",
    cert_no: "593.83.1651.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684689.373, E: 397362.929 },
      { N: 9684689.373, E: 397426.189 },
      { N: 9684589.373, E: 397426.189 },
      { N: 9684589.373, E: 397497.144 },
      { N: 9684542.358, E: 397495.653 },
      { N: 9684568.764, E: 397399.578 },
      { N: 9684607.207, E: 397359.815 },
    ],
    coord: [],
  },
  {
    owner: "Ruiyana Udin",
    cert_no: "593.83.1652.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684989.373, E: 397526.189 },
      { N: 9684989.373, E: 397626.189 },
      { N: 9684889.373, E: 397626.189 },
      { N: 9684889.373, E: 397526.189 },
    ],
    coord: [],
  },
  {
    owner: "Saenudin",
    cert_no: "593.83.1653.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684989.373, E: 397826.189 },
      { N: 9684989.373, E: 397926.189 },
      { N: 9684889.373, E: 397926.189 },
      { N: 9684889.373, E: 397826.189 },
    ],
    coord: [],
  },
  {
    owner: "Sakir AR",
    cert_no: "593.83.1654.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684789.373, E: 397526.189 },
      { N: 9684789.373, E: 397626.189 },
      { N: 9684689.373, E: 397626.189 },
      { N: 9684689.373, E: 397526.189 },
    ],
    coord: [],
  },
  {
    owner: "Adrianus Gintu",
    cert_no: "593.83.1655.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685089.373, E: 398226.189 },
      { N: 9685089.373, E: 398326.189 },
      { N: 9684989.373, E: 398326.189 },
      { N: 9684989.373, E: 398226.189 },
    ],
    coord: [],
  },
  {
    owner: "Agus Salim",
    cert_no: "593.83.1656.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684889.373, E: 398226.189 },
      { N: 9684889.373, E: 398326.189 },
      { N: 9684789.373, E: 398326.189 },
      { N: 9684789.373, E: 398226.189 },
    ],
    coord: [],
  },
  {
    owner: "Amirudin",
    cert_no: "593.83.1657.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684689.373, E: 398026.189 },
      { N: 9684689.373, E: 398126.189 },
      { N: 9684589.373, E: 398126.189 },
      { N: 9684589.373, E: 398026.189 },
    ],
    coord: [],
  },
  {
    owner: "Martinus Kiding",
    cert_no: "593.83.1658.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684789.373, E: 398126.189 },
      { N: 9684789.373, E: 398226.189 },
      { N: 9684689.373, E: 398226.189 },
      { N: 9684689.373, E: 398126.189 },
    ],
    coord: [],
  },
  {
    owner: "Nurlin",
    cert_no: "593.83.1659.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684989.373, E: 398526.189 },
      { N: 9684989.373, E: 398589.503 },
      { N: 9684910.144, E: 398608.313 },
      { N: 9684875.503, E: 398594.266 },
      { N: 9684854.568, E: 398566.116 },
      { N: 9684822.65, E: 398533.699 },
      { N: 9684889.373, E: 398526.189 },
    ],
    coord: [],
  },
  {
    owner: "Samarudin Samendeo",
    cert_no: "593.83.1660.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685089.373, E: 398026.189 },
      { N: 9685089.373, E: 398126.189 },
      { N: 9684989.373, E: 398126.189 },
      { N: 9684989.373, E: 398026.189 },
    ],
    coord: [],
  },
  {
    owner: "Yulianis",
    cert_no: "593.83.1661.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684889.373, E: 398126.189 },
      { N: 9684889.373, E: 398226.189 },
      { N: 9684789.373, E: 398226.189 },
      { N: 9684789.373, E: 398126.189 },
    ],
    coord: [],
  },
  {
    owner: "Sultan",
    cert_no: "593.83.1662.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685089.373, E: 398126.189 },
      { N: 9685089.373, E: 398226.189 },
      { N: 9684989.373, E: 398226.189 },
      { N: 9684989.373, E: 398126.189 },
    ],
    coord: [],
  },
  {
    owner: "Marhadi",
    cert_no: "593.83.1663.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684589.373, E: 397926.189 },
      { N: 9684589.373, E: 398026.189 },
      { N: 9684502.586, E: 398048.892 },
      { N: 9684489.709, E: 398020.54 },
      { N: 9684491.895, E: 397982.03 },
      { N: 9684499.256, E: 397940.38 },
    ],
    coord: [],
  },
  {
    owner: "Salihuddin",
    cert_no: "593.83.1664.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684722.157, E: 398226.189 },
      { N: 9684721.525, E: 398334.903 },
      { N: 9684694.187, E: 398327.812 },
      { N: 9684632.608, E: 398294.536 },
      { N: 9684575.503, E: 398226.189 },
    ],
    coord: [],
  },
  {
    owner: "Arifuddin",
    cert_no: "593.83.1665.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684689.373, E: 397926.189 },
      { N: 9684689.373, E: 398026.189 },
      { N: 9684589.373, E: 398026.189 },
      { N: 9684589.373, E: 397926.189 },
    ],
    coord: [],
  },
  {
    owner: "Ferdy Johan Ruaw",
    cert_no: "593.83.1666.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684989.373, E: 398126.189 },
      { N: 9684989.373, E: 398226.189 },
      { N: 9684889.373, E: 398226.189 },
      { N: 9684889.373, E: 398126.189 },
    ],
    coord: [],
  },
  {
    owner: "Ekatusalam",
    cert_no: "593.83.1667.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684789.373, E: 397926.189 },
      { N: 9684789.373, E: 398026.189 },
      { N: 9684689.373, E: 398026.189 },
      { N: 9684689.373, E: 397926.189 },
    ],
    coord: [],
  },
  {
    owner: "Haryanto",
    cert_no: "593.83.1668.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684989.373, E: 398326.189 },
      { N: 9684989.373, E: 398426.189 },
      { N: 9684889.373, E: 398426.189 },
      { N: 9684889.373, E: 398326.189 },
    ],
    coord: [],
  },
  {
    owner: "Darto",
    cert_no: "593.83.1669.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685089.373, E: 398626.189 },
      { N: 9685089.373, E: 398693.581 },
      { N: 9684981.954, E: 398688.929 },
      { N: 9684981.605, E: 398668.39 },
      { N: 9684960.775, E: 398653.707 },
      { N: 9684939.055, E: 398628.731 },
      { N: 9684910.144, E: 398608.313 },
      { N: 9684989.373, E: 398589.503 },
      { N: 9684989.373, E: 398626.189 },
    ],
    coord: [],
  },
  {
    owner: "Rahmawati",
    cert_no: "593.83.1670.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684789.373, E: 398026.189 },
      { N: 9684789.373, E: 398126.189 },
      { N: 9684689.373, E: 398126.189 },
      { N: 9684689.373, E: 398026.189 },
    ],
    coord: [],
  },
  {
    owner: "Dominggus",
    cert_no: "593.83.1671.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684889.373, E: 398426.189 },
      { N: 9684889.373, E: 398526.189 },
      { N: 9684822.65, E: 398533.699 },
      { N: 9684796.199, E: 398505.81 },
      { N: 9684785.787, E: 398480.884 },
      { N: 9684789.373, E: 398426.189 },
    ],
    coord: [],
  },
  {
    owner: "Junaedi",
    cert_no: "593.83.1672.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684989.373, E: 398226.189 },
      { N: 9684989.373, E: 398326.189 },
      { N: 9684889.373, E: 398326.189 },
      { N: 9684889.373, E: 398226.189 },
    ],
    coord: [],
  },
  {
    owner: "Nasir Nuhun",
    cert_no: "593.83.1673.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684889.373, E: 397926.189 },
      { N: 9684889.373, E: 398026.189 },
      { N: 9684789.373, E: 398026.189 },
      { N: 9684789.373, E: 397926.189 },
    ],
    coord: [],
  },
  {
    owner: "Ridwan",
    cert_no: "593.83.1674.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684789.373, E: 398226.189 },
      { N: 9684785.452, E: 398480.081 },
      { N: 9684776.443, E: 398406.566 },
      { N: 9684753.068, E: 398343.45 },
      { N: 9684721.525, E: 398334.903 },
      { N: 9684722.157, E: 398226.189 },
    ],
    coord: [],
  },
  {
    owner: "Samrin Hasan",
    cert_no: "593.83.1675.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685089.373, E: 398426.189 },
      { N: 9685089.373, E: 398526.189 },
      { N: 9684989.373, E: 398526.189 },
      { N: 9684989.373, E: 398426.189 },
    ],
    coord: [],
  },
  {
    owner: "Anton Nurdin",
    cert_no: "593.83.1676.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684989.373, E: 398026.189 },
      { N: 9684989.373, E: 398126.189 },
      { N: 9684889.373, E: 398126.189 },
      { N: 9684889.373, E: 398026.189 },
    ],
    coord: [],
  },
  {
    owner: "Jhony Balalembang",
    cert_no: "593.83.1677.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684889.373, E: 398326.189 },
      { N: 9684889.373, E: 398426.189 },
      { N: 9684789.373, E: 398426.189 },
      { N: 9684789.373, E: 398326.189 },
    ],
    coord: [],
  },
  {
    owner: "Jumadi Somat",
    cert_no: "593.83.1678.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684989.373, E: 398426.189 },
      { N: 9684989.373, E: 398526.189 },
      { N: 9684889.373, E: 398526.189 },
      { N: 9684889.373, E: 398426.189 },
    ],
    coord: [],
  },
  {
    owner: "Ahiri",
    cert_no: "593.83.1709.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686089.373, E: 397126.189 },
      { N: 9686089.373, E: 397226.189 },
      { N: 9685989.373, E: 397226.189 },
      { N: 9685989.373, E: 397126.189 },
    ],
    coord: [],
  },
  {
    owner: "Amrullah",
    cert_no: "593.83.1710.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685989.373, E: 396926.189 },
      { N: 9685989.373, E: 397026.189 },
      { N: 9685889.373, E: 397026.189 },
      { N: 9685889.373, E: 396926.189 },
    ],
    coord: [],
  },
  {
    owner: "Armin Mohamad Ali",
    cert_no: "593.83.1711.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685789.373, E: 396526.189 },
      { N: 9685789.373, E: 396626.189 },
      { N: 9685689.373, E: 396626.189 },
      { N: 9685689.373, E: 396526.189 },
    ],
    coord: [],
  },
  {
    owner: "Hapsia",
    cert_no: "593.83.1712.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686131.558, E: 396988.479 },
      { N: 9686179.394, E: 397043.855 },
      { N: 9686231.541, E: 397124.746 },
      { N: 9686277.434, E: 397165.727 },
      { N: 9686189.373, E: 397175.87 },
      { N: 9686189.373, E: 397126.189 },
      { N: 9686135.451, E: 397126.189 },
    ],
    coord: [],
  },
  {
    owner: "Harjon",
    cert_no: "593.83.1713.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686089.373, E: 397026.189 },
      { N: 9686089.373, E: 397126.189 },
      { N: 9685989.373, E: 397126.189 },
      { N: 9685989.373, E: 397026.189 },
    ],
    coord: [],
  },
  {
    owner: "Hasrin",
    cert_no: "593.83.1714.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685789.373, E: 396926.189 },
      { N: 9685789.373, E: 397026.189 },
      { N: 9685689.373, E: 397026.189 },
      { N: 9685689.373, E: 396926.189 },
    ],
    coord: [],
  },
  {
    owner: "Haswan",
    cert_no: "593.83.1715.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685989.373, E: 397126.189 },
      { N: 9685989.373, E: 397226.189 },
      { N: 9685889.373, E: 397226.189 },
      { N: 9685889.373, E: 397126.189 },
    ],
    coord: [],
  },
  {
    owner: "H Amrin Arif",
    cert_no: "593.83.1716.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685889.373, E: 396826.189 },
      { N: 9685889.373, E: 396926.189 },
      { N: 9685789.373, E: 396926.189 },
      { N: 9685789.373, E: 396826.189 },
    ],
    coord: [],
  },
  {
    owner: "Nihaya",
    cert_no: "593.83.1717.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686277.434, E: 397165.727 },
      { N: 9686289.317, E: 397223.243 },
      { N: 9686305.698, E: 397268.817 },
      { N: 9686189.373, E: 397269.898 },
      { N: 9686189.373, E: 397175.87 },
    ],
    coord: [],
  },
  {
    owner: "Jabir P. Beluano",
    cert_no: "593.83.1718.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685701.02, E: 396285.248 },
      { N: 9685735.052, E: 396363.182 },
      { N: 9685735.528, E: 396526.189 },
      { N: 9685689.373, E: 396526.189 },
      { N: 9685689.373, E: 396326.189 },
      { N: 9685659.369, E: 396326.189 },
    ],
    coord: [],
  },
  {
    owner: "Jasrin",
    cert_no: "593.83.1719.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685789.373, E: 396726.189 },
      { N: 9685789.373, E: 396826.189 },
      { N: 9685689.373, E: 396826.189 },
      { N: 9685689.373, E: 396726.189 },
    ],
    coord: [],
  },
  {
    owner: "Laiwan",
    cert_no: "593.83.1720.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685789.373, E: 397226.189 },
      { N: 9685789.373, E: 397326.189 },
      { N: 9685689.373, E: 397326.189 },
      { N: 9685689.373, E: 397226.189 },
    ],
    coord: [],
  },
  {
    owner: "Lias",
    cert_no: "593.83.1721.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685847.732, E: 396647.546 },
      { N: 9685893.519, E: 396782.243 },
      { N: 9685789.373, E: 396777.344 },
      { N: 9685789.373, E: 396646.856 },
    ],
    coord: [],
  },
  {
    owner: "Mardi",
    cert_no: "593.83.1722.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685735.052, E: 396363.182 },
      { N: 9685783.669, E: 396469.07 },
      { N: 9685799.897, E: 396531.74 },
      { N: 9685825.098, E: 396581.553 },
      { N: 9685847.732, E: 396647.546 },
      { N: 9685789.373, E: 396646.856 },
      { N: 9685789.373, E: 396526.189 },
      { N: 9685735.528, E: 396526.189 },
    ],
    coord: [],
  },
  {
    owner: "Muh. Aswad Nunu",
    cert_no: "593.83.1723.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686189.373, E: 397125.87 },
      { N: 9686189.373, E: 397226.189 },
      { N: 9686089.373, E: 397226.189 },
      { N: 9686089.373, E: 397125.87 },
    ],
    coord: [],
  },
  {
    owner: "Muhrim Ahmad Hadi Supyin",
    cert_no: "593.83.1724.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685889.373, E: 397226.189 },
      { N: 9685889.373, E: 397326.189 },
      { N: 9685789.373, E: 397326.189 },
      { N: 9685789.373, E: 397226.189 },
    ],
    coord: [],
  },
  {
    owner: "Mustamin",
    cert_no: "593.83.1725.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686189.373, E: 397226.189 },
      { N: 9686189.373, E: 397326.189 },
      { N: 9686089.373, E: 397326.189 },
      { N: 9686089.373, E: 397226.189 },
    ],
    coord: [],
  },
  {
    owner: "Nahran",
    cert_no: "593.83.1726.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685789.373, E: 397126.189 },
      { N: 9685789.373, E: 397226.189 },
      { N: 9685689.373, E: 397226.189 },
      { N: 9685689.373, E: 397126.189 },
    ],
    coord: [],
  },
  {
    owner: "Nurdin",
    cert_no: "593.83.1727.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685989.373, E: 397226.189 },
      { N: 9685989.373, E: 397326.189 },
      { N: 9685889.373, E: 397326.189 },
      { N: 9685889.373, E: 397226.189 },
    ],
    coord: [],
  },
  {
    owner: "Nurhaena",
    cert_no: "593.83.1728.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685889.373, E: 396926.189 },
      { N: 9685889.373, E: 397026.189 },
      { N: 9685789.373, E: 397026.189 },
      { N: 9685789.373, E: 396926.189 },
    ],
    coord: [],
  },
  {
    owner: "Nuriyati",
    cert_no: "593.83.1729.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685889.373, E: 397026.189 },
      { N: 9685889.373, E: 397126.189 },
      { N: 9685789.373, E: 397126.189 },
      { N: 9685789.373, E: 397026.189 },
    ],
    coord: [],
  },
  {
    owner: "Rahman",
    cert_no: "593.83.1730.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685789.373, E: 396626.189 },
      { N: 9685789.373, E: 396726.189 },
      { N: 9685689.373, E: 396726.189 },
      { N: 9685689.373, E: 396626.189 },
    ],
    coord: [],
  },
  {
    owner: "Ridwan",
    cert_no: "593.83.1731.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685989.373, E: 397026.189 },
      { N: 9685989.373, E: 397126.189 },
      { N: 9685889.373, E: 397126.189 },
      { N: 9685889.373, E: 397026.189 },
    ],
    coord: [],
  },
  {
    owner: "Rosmin",
    cert_no: "593.83.1732.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685889.373, E: 397126.189 },
      { N: 9685889.373, E: 397226.189 },
      { N: 9685789.373, E: 397226.189 },
      { N: 9685789.373, E: 397126.189 },
    ],
    coord: [],
  },
  {
    owner: "Sabri",
    cert_no: "593.83.1733.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685946.954, E: 396871.352 },
      { N: 9685945.478, E: 396926.189 },
      { N: 9685889.373, E: 396926.189 },
      { N: 9685889.373, E: 396826.189 },
      { N: 9685789.373, E: 396826.189 },
      { N: 9685789.373, E: 396777.344 },
      { N: 9685893.579, E: 396782.243 },
    ],
    coord: [],
  },
  {
    owner: "Subaeda",
    cert_no: "593.83.1734.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686057.601, E: 396924.206 },
      { N: 9686057.571, E: 397026.189 },
      { N: 9685989.373, E: 397026.189 },
      { N: 9685989.373, E: 396926.189 },
      { N: 9685945.478, E: 396926.189 },
      { N: 9685946.954, E: 396871.352 },
      { N: 9685971.426, E: 396889.848 },
      { N: 9686036.847, E: 396907.494 },
    ],
    coord: [],
  },
  {
    owner: "Sudirman",
    cert_no: "593.83.1735.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686089.373, E: 397226.189 },
      { N: 9686089.373, E: 397326.189 },
      { N: 9685989.373, E: 397326.189 },
      { N: 9685989.373, E: 397226.189 },
    ],
    coord: [],
  },
  {
    owner: "Suhadin M",
    cert_no: "593.83.1736.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685789.373, E: 396826.189 },
      { N: 9685789.373, E: 396926.189 },
      { N: 9685689.373, E: 396926.189 },
      { N: 9685689.373, E: 396826.189 },
    ],
    coord: [],
  },
  {
    owner: "Suhardin",
    cert_no: "593.83.1737.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685789.373, E: 397026.189 },
      { N: 9685789.373, E: 397126.189 },
      { N: 9685689.373, E: 397126.189 },
      { N: 9685689.373, E: 397026.189 },
    ],
    coord: [],
  },
  {
    owner: "Abd. Salam",
    cert_no: "593.83.1738.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686131.558, E: 396988.479 },
      { N: 9686135.451, E: 397126.189 },
      { N: 9686089.373, E: 397126.189 },
      { N: 9686089.373, E: 397026.189 },
      { N: 9686057.571, E: 397026.189 },
      { N: 9686057.601, E: 396924.206 },
      { N: 9686096.945, E: 396949.654 },
    ],
    coord: [],
  },
  {
    owner: "Abd. Asis Katemba",
    cert_no: "593.83.1739.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686137.752, E: 398226.189 },
      { N: 9686137.723, E: 398272.215 },
      { N: 9686019.238, E: 398299.097 },
      { N: 9685991.279, E: 398356.533 },
      { N: 9685978.027, E: 398326.189 },
      { N: 9685989.373, E: 398326.189 },
      { N: 9685989.373, E: 398226.189 },
    ],
    coord: [],
  },
  {
    owner: "Abd. Majid Syamsudin",
    cert_no: "593.83.1740.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686089.373, E: 397826.189 },
      { N: 9686089.373, E: 397926.189 },
      { N: 9685989.373, E: 397926.189 },
      { N: 9685989.373, E: 397826.189 },
    ],
    coord: [],
  },
  {
    owner: "Aras",
    cert_no: "593.83.1741.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686189.373, E: 398126.189 },
      { N: 9686189.373, E: 398226.189 },
      { N: 9686089.373, E: 398226.189 },
      { N: 9686089.373, E: 398126.189 },
    ],
    coord: [],
  },
  {
    owner: "Asnar",
    cert_no: "593.83.1742.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686760.652, E: 397629.133 },
      { N: 9686759.019, E: 397726.189 },
      { N: 9686689.373, E: 397726.189 },
      { N: 9686689.373, E: 397626.189 },
      { N: 9686642.06, E: 397626.189 },
      { N: 9686630.513, E: 397589.55 },
      { N: 9686681.071, E: 397587.632 },
      { N: 9686725.62, E: 397611.691 },
    ],
    coord: [],
  },
  {
    owner: "Burhanudin",
    cert_no: "593.83.1743.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686089.373, E: 397926.189 },
      { N: 9686089.373, E: 398026.189 },
      { N: 9685989.373, E: 398026.189 },
      { N: 9685989.373, E: 397926.189 },
    ],
    coord: [],
  },
  {
    owner: "Edward",
    cert_no: "593.83.1744.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686389.373, E: 397926.189 },
      { N: 9686389.373, E: 398026.189 },
      { N: 9686289.373, E: 398026.189 },
      { N: 9686289.373, E: 397926.189 },
    ],
    coord: [],
  },
  {
    owner: "Feniati",
    cert_no: "593.83.1745.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686630.513, E: 397589.55 },
      { N: 9686642.06, E: 397626.189 },
      { N: 9686389.373, E: 397626.189 },
      { N: 9686389.373, E: 397577.169 },
      { N: 9686494.57, E: 397577.109 },
      { N: 9686525.565, E: 397597.965 },
      { N: 9686584.565, E: 397589.487 },
    ],
    coord: [],
  },
  {
    owner: " Hatir Satta Minggu",
    cert_no: "593.83.1749.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686289.373, E: 398126.189 },
      { N: 9686289.373, E: 398226.189 },
      { N: 9686189.373, E: 398226.189 },
      { N: 9686189.373, E: 398126.189 },
    ],
    coord: [],
  },
  {
    owner: " Hendrik",
    cert_no: "593.83.1750.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686389.373, E: 397626.189 },
      { N: 9686389.373, E: 397726.189 },
      { N: 9686289.373, E: 397726.189 },
      { N: 9686289.373, E: 397626.189 },
    ],
    coord: [],
  },
  {
    owner: " Ida Bagus Kade Darmaya",
    cert_no: "593.83.1751.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686289.373, E: 397826.189 },
      { N: 9686289.373, E: 397926.189 },
      { N: 9686189.373, E: 397926.189 },
      { N: 9686189.373, E: 397826.189 },
    ],
    coord: [],
  },
  {
    owner: " Moh. Rifal",
    cert_no: "593.83.1752.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686389.373, E: 397526.189 },
      { N: 9686389.373, E: 397626.189 },
      { N: 9686289.373, E: 397626.189 },
      { N: 9686289.373, E: 397526.189 },
    ],
    coord: [],
  },
  {
    owner: " Muh. Yamin Baddu",
    cert_no: "593.83.1753.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686689.373, E: 397626.189 },
      { N: 9686689.373, E: 397726.189 },
      { N: 9686589.373, E: 397726.189 },
      { N: 9686589.373, E: 397626.189 },
    ],
    coord: [],
  },
  {
    owner: " Risnawati",
    cert_no: "593.83.1754.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686289.373, E: 397926.189 },
      { N: 9686289.373, E: 398026.189 },
      { N: 9686189.373, E: 398026.189 },
      { N: 9686189.373, E: 397926.189 },
    ],
    coord: [],
  },
  {
    owner: " Roman Arief",
    cert_no: "593.83.1755.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686189.373, E: 397826.189 },
      { N: 9686189.373, E: 397926.189 },
      { N: 9686089.373, E: 397926.189 },
      { N: 9686089.373, E: 397826.189 },
    ],
    coord: [],
  },
  {
    owner: " Rusdianto",
    cert_no: "593.83.1756.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686339.373, E: 398226.189 },
      { N: 9686329.742, E: 398285.44 },
      { N: 9686297.381, E: 398287.778 },
      { N: 9686288.398, E: 398272.318 },
      { N: 9686238.726, E: 398271.839 },
      { N: 9686192.437, E: 398279.281 },
      { N: 9686137.723, E: 398272.215 },
      { N: 9686137.752, E: 398226.189 },
    ],
    coord: [],
  },
  {
    owner: " Safarudin",
    cert_no: "593.83.1757.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686289.373, E: 397626.189 },
      { N: 9686289.373, E: 397726.189 },
      { N: 9686189.373, E: 397726.189 },
      { N: 9686189.373, E: 397626.189 },
    ],
    coord: [],
  },
  {
    owner: " SahruI",
    cert_no: "593.83.1758.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686089.373, E: 398026.189 },
      { N: 9686089.373, E: 398126.189 },
      { N: 9685989.373, E: 398126.189 },
      { N: 9685989.373, E: 398026.189 },
    ],
    coord: [],
  },
  {
    owner: " Sapriyadi",
    cert_no: "593.83.1759.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686392.486, E: 397457.628 },
      { N: 9686430.408, E: 397512.744 },
      { N: 9686494.57, E: 397577.109 },
      { N: 9686389.373, E: 397577.169 },
      { N: 9686389.373, E: 397526.189 },
      { N: 9686289.373, E: 397526.189 },
      { N: 9686289.373, E: 397508.206 },
    ],
    coord: [],
  },
  {
    owner: " Sarmin",
    cert_no: "593.83.1760.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686389.373, E: 397726.189 },
      { N: 9686389.373, E: 397826.189 },
      { N: 9686289.373, E: 397826.189 },
      { N: 9686289.373, E: 397726.189 },
    ],
    coord: [],
  },
  {
    owner: " Sitti Aminah",
    cert_no: "593.83.1761.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686389.373, E: 398126.189 },
      { N: 9686389.373, E: 398226.189 },
      { N: 9686289.373, E: 398226.189 },
      { N: 9686289.373, E: 398126.189 },
    ],
    coord: [],
  },
  {
    owner: " Taufik",
    cert_no: "593.83.1762.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686489.373, E: 397626.189 },
      { N: 9686489.373, E: 397726.189 },
      { N: 9686389.373, E: 397726.189 },
      { N: 9686389.373, E: 397626.189 },
    ],
    coord: [],
  },
  {
    owner: " Yusri",
    cert_no: "593.83.1763.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686189.373, E: 398026.189 },
      { N: 9686189.373, E: 398126.189 },
      { N: 9686089.373, E: 398126.189 },
      { N: 9686089.373, E: 398026.189 },
    ],
    coord: [],
  },
  {
    owner: " Yusuf Ibrahim",
    cert_no: "593.83.1764.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686289.373, E: 398026.189 },
      { N: 9686289.373, E: 398126.189 },
      { N: 9686189.373, E: 398126.189 },
      { N: 9686189.373, E: 398026.189 },
    ],
    coord: [],
  },
  {
    owner: " Andi Mustaring",
    cert_no: "593.83.1765.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686289.373, E: 397726.189 },
      { N: 9686289.373, E: 397826.189 },
      { N: 9686189.373, E: 397826.189 },
      { N: 9686189.373, E: 397726.189 },
    ],
    coord: [],
  },
  {
    owner: " Andre Anggara",
    cert_no: "593.83.1766.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686399.373, E: 398226.189 },
      { N: 9686389.373, E: 398376.851 },
      { N: 9686332.983, E: 398382.319 },
      { N: 9686314.518, E: 398317.271 },
      { N: 9686297.381, E: 398287.778 },
      { N: 9686329.742, E: 398285.44 },
      { N: 9686339.373, E: 398226.189 },
    ],
    coord: [],
  },
  {
    owner: " Irman",
    cert_no: "593.83.1767.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686589.373, E: 397626.189 },
      { N: 9686589.373, E: 397726.189 },
      { N: 9686489.373, E: 397726.189 },
      { N: 9686489.373, E: 397626.189 },
    ],
    coord: [],
  },
  {
    owner: " Muh. Risal",
    cert_no: "593.83.1768.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686389.373, E: 397826.189 },
      { N: 9686389.373, E: 397926.189 },
      { N: 9686289.373, E: 397926.189 },
      { N: 9686289.373, E: 397826.189 },
    ],
    coord: [],
  },
  {
    owner: " Samra",
    cert_no: "593.83.1803.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686489.373, E: 398226.189 },
      { N: 9686489.373, E: 398326.189 },
      { N: 9686389.373, E: 398326.189 },
      { N: 9686389.373, E: 398226.189 },
    ],
    coord: [],
  },
  {
    owner: " Samir Salindo",
    cert_no: "593.83.1804.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686589.373, E: 397826.189 },
      { N: 9686589.373, E: 397926.189 },
      { N: 9686489.373, E: 397926.189 },
      { N: 9686489.373, E: 397826.189 },
    ],
    coord: [],
  },
  {
    owner: " Udin Kondo",
    cert_no: "593.83.1805.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686889.373, E: 397826.189 },
      { N: 9686889.373, E: 397926.189 },
      { N: 9686789.373, E: 397926.189 },
      { N: 9686789.373, E: 397826.189 },
    ],
    coord: [],
  },
  {
    owner: " Titin",
    cert_no: "593.83.1806.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686789.373, E: 397926.189 },
      { N: 9686789.373, E: 398026.189 },
      { N: 9686689.373, E: 398026.189 },
      { N: 9686689.373, E: 397926.189 },
    ],
    coord: [],
  },
  {
    owner: " Tima Lajumu",
    cert_no: "593.83.1807.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686689.373, E: 397826.189 },
      { N: 9686689.373, E: 397926.189 },
      { N: 9686589.373, E: 397926.189 },
      { N: 9686589.373, E: 397826.189 },
    ],
    coord: [],
  },
  {
    owner: " Suprianto D.",
    cert_no: "593.83.1808.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686789.373, E: 397826.189 },
      { N: 9686789.373, E: 397926.189 },
      { N: 9686689.373, E: 397926.189 },
      { N: 9686689.373, E: 397826.189 },
    ],
    coord: [],
  },
  {
    owner: " Sarifudin M.",
    cert_no: "593.83.1809.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686489.373, E: 397726.189 },
      { N: 9686489.373, E: 397826.189 },
      { N: 9686389.373, E: 397826.189 },
      { N: 9686389.373, E: 397726.189 },
    ],
    coord: [],
  },
  {
    owner: " Sidik Danudin",
    cert_no: "593.83.1810.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686589.373, E: 397926.189 },
      { N: 9686589.373, E: 398026.189 },
      { N: 9686489.373, E: 398026.189 },
      { N: 9686489.373, E: 397926.189 },
    ],
    coord: [],
  },
  {
    owner: " Kamaludin",
    cert_no: "593.83.1811.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686489.373, E: 398026.189 },
      { N: 9686489.373, E: 398126.189 },
      { N: 9686389.373, E: 398126.189 },
      { N: 9686389.373, E: 398026.189 },
    ],
    coord: [],
  },
  {
    owner: " Jabar",
    cert_no: "593.83.1812.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686689.373, E: 397726.189 },
      { N: 9686689.373, E: 397826.189 },
      { N: 9686589.373, E: 397826.189 },
      { N: 9686589.373, E: 397726.189 },
    ],
    coord: [],
  },
  {
    owner: " Illang Musa, S.Pd",
    cert_no: "593.83.1813.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686589.373, E: 398326.189 },
      { N: 9686589.373, E: 398426.189 },
      { N: 9686489.373, E: 398426.189 },
      { N: 9686489.373, E: 398326.189 },
    ],
    coord: [],
  },
  {
    owner: " Hendrik",
    cert_no: "593.83.1814.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686689.373, E: 398226.189 },
      { N: 9686689.373, E: 398326.189 },
      { N: 9686589.373, E: 398326.189 },
      { N: 9686589.373, E: 398226.189 },
    ],
    coord: [],
  },
  {
    owner: " Hendrik Januddin",
    cert_no: "593.83.1815.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686889.373, E: 397726.189 },
      { N: 9686889.373, E: 397826.189 },
      { N: 9686789.373, E: 397826.189 },
      { N: 9686789.373, E: 397726.189 },
    ],
    coord: [],
  },
  {
    owner: " Purwandi Umarwan, ST",
    cert_no: "593.83.1816.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686489.373, E: 398126.189 },
      { N: 9686489.373, E: 398226.189 },
      { N: 9686389.373, E: 398226.189 },
      { N: 9686389.373, E: 398126.189 },
    ],
    coord: [],
  },
  {
    owner: " Masber Musa, SH",
    cert_no: "593.83.1817.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686889.373, E: 397926.189 },
      { N: 9686889.373, E: 398026.189 },
      { N: 9686789.373, E: 398026.189 },
      { N: 9686789.373, E: 397926.189 },
    ],
    coord: [],
  },
  {
    owner: " Muh Nur",
    cert_no: "593.83.1818.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686489.373, E: 397926.189 },
      { N: 9686489.373, E: 398026.189 },
      { N: 9686389.373, E: 398026.189 },
      { N: 9686389.373, E: 397926.189 },
    ],
    coord: [],
  },
  {
    owner: " Najomuddin",
    cert_no: "593.83.1819.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686689.373, E: 398126.189 },
      { N: 9686689.373, E: 398226.189 },
      { N: 9686589.373, E: 398226.189 },
      { N: 9686589.373, E: 398126.189 },
    ],
    coord: [],
  },
  {
    owner: " Nasrudin",
    cert_no: "593.83.1820.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686646.409, E: 398426.189 },
      { N: 9686645.528, E: 398496.343 },
      { N: 9686609.505, E: 398526.704 },
      { N: 9686579.171, E: 398497.022 },
      { N: 9686539.069, E: 398489.315 },
      { N: 9686509.307, E: 398491.678 },
      { N: 9686518.582, E: 398426.189 },
    ],
    coord: [],
  },
  {
    owner: " Nursang",
    cert_no: "593.83.1821.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686807.684, E: 397633.438 },
      { N: 9686876.017, E: 397696.472 },
      { N: 9686906.578, E: 397715.058 },
      { N: 9686925.372, E: 397740.073 },
      { N: 9686889.373, E: 397753.904 },
      { N: 9686889.373, E: 397726.189 },
      { N: 9686759.019, E: 397726.189 },
      { N: 9686760.652, E: 397629.133 },
    ],
    coord: [],
  },
  {
    owner: " Erni",
    cert_no: "593.83.1822.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686589.373, E: 397726.189 },
      { N: 9686589.373, E: 397826.189 },
      { N: 9686489.373, E: 397826.189 },
      { N: 9686489.373, E: 397726.189 },
    ],
    coord: [],
  },
  {
    owner: " Ebit",
    cert_no: "593.83.1823.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686589.373, E: 398026.189 },
      { N: 9686589.373, E: 398126.189 },
      { N: 9686489.373, E: 398126.189 },
      { N: 9686489.373, E: 398026.189 },
    ],
    coord: [],
  },
  {
    owner: " Bahtiar",
    cert_no: "593.83.1824.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686489.373, E: 398326.189 },
      { N: 9686489.373, E: 398426.189 },
      { N: 9686389.373, E: 398426.189 },
      { N: 9686389.373, E: 398326.189 },
    ],
    coord: [],
  },
  {
    owner: " Arif DG. Sittuju",
    cert_no: "593.83.1825.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686689.373, E: 398026.189 },
      { N: 9686689.373, E: 398126.189 },
      { N: 9686589.373, E: 398126.189 },
      { N: 9686589.373, E: 398026.189 },
    ],
    coord: [],
  },
  {
    owner: " Asri",
    cert_no: "593.83.1826.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686789.373, E: 397726.189 },
      { N: 9686789.373, E: 397826.189 },
      { N: 9686689.373, E: 397826.189 },
      { N: 9686689.373, E: 397726.189 },
    ],
    coord: [],
  },
  {
    owner: " Arfan",
    cert_no: "593.83.1827.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686689.373, E: 398326.189 },
      { N: 9686689.373, E: 398426.189 },
      { N: 9686589.373, E: 398426.189 },
      { N: 9686589.373, E: 398326.189 },
    ],
    coord: [],
  },
  {
    owner: " Ahmadu",
    cert_no: "593.83.1828.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686689.373, E: 397926.189 },
      { N: 9686689.373, E: 398026.189 },
      { N: 9686589.373, E: 398026.189 },
      { N: 9686589.373, E: 397926.189 },
    ],
    coord: [],
  },
  {
    owner: " Azdhin Y.",
    cert_no: "593.83.1829.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686589.373, E: 398126.189 },
      { N: 9686589.373, E: 398226.189 },
      { N: 9686489.373, E: 398226.189 },
      { N: 9686489.373, E: 398126.189 },
    ],
    coord: [],
  },
  {
    owner: " Darmanto Bidolong",
    cert_no: "593.83.1830.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686589.373, E: 398226.189 },
      { N: 9686589.373, E: 398326.189 },
      { N: 9686489.373, E: 398326.189 },
      { N: 9686489.373, E: 398226.189 },
    ],
    coord: [],
  },
  {
    owner: " Ferdi",
    cert_no: "593.83.1831.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686518.582, E: 398426.189 },
      { N: 9686509.307, E: 398491.678 },
      { N: 9686442.552, E: 398476.942 },
      { N: 9686381.196, E: 398474.656 },
      { N: 9686332.983, E: 398382.317 },
      { N: 9686389.373, E: 398376.851 },
      { N: 9686389.373, E: 398426.189 },
    ],
    coord: [],
  },
  {
    owner: " Rahim",
    cert_no: "593.83.1875.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686489.373, E: 397826.189 },
      { N: 9686489.373, E: 397926.189 },
      { N: 9686389.373, E: 397926.189 },
      { N: 9686389.373, E: 397826.189 },
    ],
    coord: [],
  },
  {
    owner: " Ikrawati",
    cert_no: "593.83.1876.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685889.373, E: 398026.189 },
      { N: 9685889.373, E: 398126.189 },
      { N: 9685789.373, E: 398126.189 },
      { N: 9685789.373, E: 398026.189 },
    ],
    coord: [],
  },
  {
    owner: " Darsin Muksin",
    cert_no: "593.83.1877.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685889.373, E: 397826.189 },
      { N: 9685889.373, E: 397926.189 },
      { N: 9685789.373, E: 397926.189 },
      { N: 9685789.373, E: 397826.189 },
    ],
    coord: [],
  },
  {
    owner: " Fidel Rawton ",
    cert_no: "593.83.1878.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685496.239, E: 395933.595 },
      { N: 9685489.373, E: 396026.189 },
      { N: 9685389.373, E: 396026.189 },
      { N: 9685398.502, E: 395915.414 },
    ],
    coord: [],
  },
  {
    owner: " Rusdin",
    cert_no: "593.83.1879.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685489.373, E: 396126.189 },
      { N: 9685489.373, E: 396226.189 },
      { N: 9685389.373, E: 396226.189 },
      { N: 9685389.373, E: 396126.189 },
    ],
    coord: [],
  },
  {
    owner: " Ruslan",
    cert_no: "593.83.1880.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685296.449, E: 395944.964 },
      { N: 9685289.373, E: 396026.189 },
      { N: 9685289.373, E: 396126.189 },
      { N: 9685216.797, E: 396126.189 },
      { N: 9685210.787, E: 396053.229 },
      { N: 9685249.915, E: 396005.746 },
    ],
    coord: [],
  },
  {
    owner: " Syawaludin R.",
    cert_no: "593.83.1881.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685589.373, E: 396426.189 },
      { N: 9685589.373, E: 396526.189 },
      { N: 9685489.373, E: 396526.189 },
      { N: 9685489.373, E: 396426.189 },
    ],
    coord: [],
  },
  {
    owner: " Suardi Azis",
    cert_no: "593.83.1882.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685589.373, E: 396226.189 },
      { N: 9685589.373, E: 396326.189 },
      { N: 9685489.373, E: 396326.189 },
      { N: 9685489.373, E: 396226.189 },
    ],
    coord: [],
  },
  {
    owner: " Samad",
    cert_no: "593.83.1883.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685489.373, E: 396426.189 },
      { N: 9685489.373, E: 396526.189 },
      { N: 9685389.373, E: 396526.189 },
      { N: 9685389.373, E: 396426.189 },
    ],
    coord: [],
  },
  {
    owner: " Suryanti",
    cert_no: "593.83.1884.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685689.373, E: 396426.189 },
      { N: 9685689.373, E: 396526.189 },
      { N: 9685589.373, E: 396526.189 },
      { N: 9685589.373, E: 396426.189 },
    ],
    coord: [],
  },
  {
    owner: " Siti Maria Ulfa Musa",
    cert_no: "593.83.1885.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685289.373, E: 396326.189 },
      { N: 9685289.373, E: 396426.189 },
      { N: 9685189.373, E: 396426.189 },
      { N: 9685189.373, E: 396326.189 },
    ],
    coord: [],
  },
  {
    owner: " Udin Kudusia",
    cert_no: "593.83.1886.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685389.373, E: 396126.189 },
      { N: 9685389.373, E: 396226.189 },
      { N: 9685289.373, E: 396226.189 },
      { N: 9685289.373, E: 396126.189 },
    ],
    coord: [],
  },
  {
    owner: " Zubedi",
    cert_no: "593.83.1887.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685389.373, E: 396326.189 },
      { N: 9685389.373, E: 396426.189 },
      { N: 9685289.373, E: 396426.189 },
      { N: 9685289.373, E: 396326.189 },
    ],
    coord: [],
  },
  {
    owner: " Irwan",
    cert_no: "593.83.1888.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685489.373, E: 396026.189 },
      { N: 9685489.373, E: 396126.189 },
      { N: 9685389.373, E: 396126.189 },
      { N: 9685389.373, E: 396026.189 },
    ],
    coord: [],
  },
  {
    owner: " Moh Basar Salim",
    cert_no: "593.83.1889.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685189.373, E: 396326.189 },
      { N: 9685189.373, E: 396426.189 },
      { N: 9685089.373, E: 396426.189 },
      { N: 9685089.373, E: 396326.189 },
    ],
    coord: [],
  },
  {
    owner: " Isdar Roman",
    cert_no: "593.83.1890.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685189.373, E: 396426.189 },
      { N: 9685189.373, E: 396526.189 },
      { N: 9685089.373, E: 396526.189 },
      { N: 9685089.373, E: 396426.189 },
    ],
    coord: [],
  },
  {
    owner: " Jufri",
    cert_no: "593.83.1891.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685124.363, E: 396242.678 },
      { N: 9685189.373, E: 396245.462 },
      { N: 9685189.373, E: 396326.189 },
      { N: 9685089.373, E: 396326.189 },
      { N: 9685029.172, E: 396349.215 },
      { N: 9685033.391, E: 396322.335 },
      { N: 9685079.914, E: 396277.864 },
      { N: 9685107.221, E: 396266.491 },
    ],
    coord: [],
  },
  {
    owner: " Indra Jaya",
    cert_no: "593.83.1892.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685289.373, E: 396426.189 },
      { N: 9685289.373, E: 396526.189 },
      { N: 9685189.373, E: 396526.189 },
      { N: 9685189.373, E: 396426.189 },
    ],
    coord: [],
  },
  {
    owner: " Husnia",
    cert_no: "593.83.1893.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685559.03, E: 396148.841 },
      { N: 9685617.314, E: 396168.484 },
      { N: 9685656.785, E: 396185.511 },
      { N: 9685680.494, E: 396219.359 },
      { N: 9685589.373, E: 396226.189 },
      { N: 9685489.373, E: 396226.189 },
      { N: 9685489.373, E: 396185.314 },
    ],
    coord: [],
  },
  {
    owner: " Hasmun",
    cert_no: "593.83.1894.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685289.373, E: 396226.189 },
      { N: 9685289.373, E: 396326.189 },
      { N: 9685189.373, E: 396326.189 },
      { N: 9685189.373, E: 396226.189 },
    ],
    coord: [],
  },
  {
    owner: " H. Nawir",
    cert_no: "593.83.1895.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685210.715, E: 396035.208 },
      { N: 9685216.797, E: 396126.189 },
      { N: 9685189.373, E: 396126.189 },
      { N: 9685189.373, E: 396245.462 },
      { N: 9685124.363, E: 396242.678 },
      { N: 9685142.191, E: 396198.072 },
      { N: 9685161.982, E: 396073.658 },
    ],
    coord: [],
  },
  {
    owner: " Rosa Sulistati",
    cert_no: "593.83.1896.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685489.373, E: 396326.189 },
      { N: 9685489.373, E: 396426.189 },
      { N: 9685389.373, E: 396426.189 },
      { N: 9685389.373, E: 396326.189 },
    ],
    coord: [],
  },
  {
    owner: " Daeng Raja",
    cert_no: "593.83.1897.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685489.373, E: 396226.189 },
      { N: 9685489.373, E: 396326.189 },
      { N: 9685389.373, E: 396326.189 },
      { N: 9685389.373, E: 396226.189 },
    ],
    coord: [],
  },
  {
    owner: " Asnawi",
    cert_no: "593.83.1898.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685389.373, E: 396426.189 },
      { N: 9685389.373, E: 396526.189 },
      { N: 9685289.373, E: 396526.189 },
      { N: 9685289.373, E: 396426.189 },
    ],
    coord: [],
  },
  {
    owner: " Asri",
    cert_no: "593.83.1899.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685496.239, E: 395933.595 },
      { N: 9685527.478, E: 395947.424 },
      { N: 9685590.914, E: 396011.343 },
      { N: 9685584.317, E: 396064.749 },
      { N: 9685489.373, E: 396064.822 },
      { N: 9685489.373, E: 396026.189 },
    ],
    coord: [],
  },
  {
    owner: " Alfandi Tampeindo",
    cert_no: "593.83.1900.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685389.373, E: 396026.189 },
      { N: 9685389.373, E: 396126.189 },
      { N: 9685289.373, E: 396126.189 },
      { N: 9685289.373, E: 396026.189 },
    ],
    coord: [],
  },
  {
    owner: " Badawi",
    cert_no: "593.83.1901.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685398.429, E: 395915.394 },
      { N: 9685389.373, E: 396026.189 },
      { N: 9685289.373, E: 396026.189 },
      { N: 9685299.093, E: 395942.075 },
      { N: 9685374.847, E: 395916.757 },
    ],
    coord: [],
  },
  {
    owner: " Asmaun",
    cert_no: "593.83.1902.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685389.373, E: 396226.189 },
      { N: 9685389.373, E: 396326.189 },
      { N: 9685289.373, E: 396326.189 },
      { N: 9685289.373, E: 396226.189 },
    ],
    coord: [],
  },
  {
    owner: " Boine J",
    cert_no: "593.83.1903.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685689.373, E: 396326.189 },
      { N: 9685689.373, E: 396426.189 },
      { N: 9685589.373, E: 396426.189 },
      { N: 9685589.373, E: 396326.189 },
    ],
    coord: [],
  },
  {
    owner: " Amaludin Udin",
    cert_no: "593.83.1904.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685584.317, E: 396064.749 },
      { N: 9685590.03, E: 396148.841 },
      { N: 9685489.373, E: 396185.314 },
      { N: 9685489.373, E: 396064.822 },
    ],
    coord: [],
  },
  {
    owner: " Aris",
    cert_no: "593.83.1905.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685680.494, E: 396219.359 },
      { N: 9685701.02, E: 396285.248 },
      { N: 9685659.369, E: 396326.189 },
      { N: 9685589.373, E: 396326.189 },
      { N: 9685589.373, E: 396226.189 },
    ],
    coord: [],
  },
  {
    owner: " Alton Tampeindo",
    cert_no: "593.83.1906.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685289.373, E: 396126.189 },
      { N: 9685289.373, E: 396226.189 },
      { N: 9685189.373, E: 396226.189 },
      { N: 9685189.373, E: 396126.189 },
    ],
    coord: [],
  },
  {
    owner: " Baba Alimundin",
    cert_no: "593.83.1907.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685589.373, E: 396326.189 },
      { N: 9685589.373, E: 396426.189 },
      { N: 9685489.373, E: 396426.189 },
      { N: 9685489.373, E: 396326.189 },
    ],
    coord: [],
  },
  {
    owner: " Rony Mustafa",
    cert_no: "593.83.1908.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685489.373, E: 397526.189 },
      { N: 9685489.373, E: 397626.189 },
      { N: 9685389.373, E: 397626.189 },
      { N: 9685389.373, E: 397526.189 },
    ],
    coord: [],
  },
  {
    owner: " Ronald",
    cert_no: "593.83.1909.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685489.373, E: 397626.189 },
      { N: 9685489.373, E: 397726.189 },
      { N: 9685389.373, E: 397726.189 },
      { N: 9685389.373, E: 397626.189 },
    ],
    coord: [],
  },
  {
    owner: " Risman",
    cert_no: "593.83.1910.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685689.373, E: 397826.189 },
      { N: 9685689.373, E: 397926.189 },
      { N: 9685589.373, E: 397926.189 },
      { N: 9685589.373, E: 397826.189 },
    ],
    coord: [],
  },
  {
    owner: " Arwanto",
    cert_no: "593.83.1911.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685589.373, E: 397426.189 },
      { N: 9685589.373, E: 397526.189 },
      { N: 9685489.373, E: 397526.189 },
      { N: 9685489.373, E: 397426.189 },
    ],
    coord: [],
  },
  {
    owner: " Ruslin",
    cert_no: "593.83.1912.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685289.373, E: 397926.189 },
      { N: 9685289.373, E: 398026.189 },
      { N: 9685189.373, E: 398026.189 },
      { N: 9685189.373, E: 397926.189 },
    ],
    coord: [],
  },
  {
    owner: " Suaro",
    cert_no: "593.83.1913.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685389.373, E: 397526.189 },
      { N: 9685389.373, E: 397626.189 },
      { N: 9685289.373, E: 397626.189 },
      { N: 9685289.373, E: 397526.189 },
    ],
    coord: [],
  },
  {
    owner: " Syahrir",
    cert_no: "593.83.1914.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685689.373, E: 397526.189 },
      { N: 9685689.373, E: 397626.189 },
      { N: 9685589.373, E: 397626.189 },
      { N: 9685589.373, E: 397526.189 },
    ],
    coord: [],
  },
  {
    owner: " Tahir M.",
    cert_no: "593.83.1915.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685289.373, E: 397826.189 },
      { N: 9685289.373, E: 397926.189 },
      { N: 9685189.373, E: 397926.189 },
      { N: 9685189.373, E: 397826.189 },
    ],
    coord: [],
  },
  {
    owner: " Yohanis Y Tumuntuan",
    cert_no: "593.83.1916.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685589.373, E: 397526.189 },
      { N: 9685589.373, E: 397626.189 },
      { N: 9685489.373, E: 397626.189 },
      { N: 9685489.373, E: 397526.189 },
    ],
    coord: [],
  },
  {
    owner: " Rahman",
    cert_no: "593.83.1917.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685389.373, E: 397626.189 },
      { N: 9685389.373, E: 397726.189 },
      { N: 9685289.373, E: 397726.189 },
      { N: 9685289.373, E: 397626.189 },
    ],
    coord: [],
  },
  {
    owner: " Abd. Haris",
    cert_no: "593.83.1918.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685689.373, E: 397426.189 },
      { N: 9685689.373, E: 397526.189 },
      { N: 9685589.373, E: 397526.189 },
      { N: 9685589.373, E: 397426.189 },
    ],
    coord: [],
  },
  {
    owner: " Abdul Malik",
    cert_no: "593.83.1919.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685289.373, E: 397726.189 },
      { N: 9685289.373, E: 397826.189 },
      { N: 9685189.373, E: 397826.189 },
      { N: 9685189.373, E: 397726.189 },
    ],
    coord: [],
  },
  {
    owner: " Abas",
    cert_no: "593.83.1920.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685389.373, E: 397926.189 },
      { N: 9685389.373, E: 398026.189 },
      { N: 9685289.373, E: 398026.189 },
      { N: 9685289.373, E: 397926.189 },
    ],
    coord: [],
  },
  {
    owner: " Afdal",
    cert_no: "593.83.1921.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685689.373, E: 397726.189 },
      { N: 9685689.373, E: 397826.189 },
      { N: 9685589.373, E: 397826.189 },
      { N: 9685589.373, E: 397726.189 },
    ],
    coord: [],
  },
  {
    owner: " Aswan A.",
    cert_no: "593.83.1922.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685589.373, E: 397726.189 },
      { N: 9685589.373, E: 397826.189 },
      { N: 9685489.373, E: 397826.189 },
      { N: 9685489.373, E: 397726.189 },
    ],
    coord: [],
  },
  {
    owner: " Andi Muh. Arifin Baso",
    cert_no: "593.83.1923.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685689.373, E: 397626.189 },
      { N: 9685689.373, E: 397726.189 },
      { N: 9685589.373, E: 397726.189 },
      { N: 9685589.373, E: 397626.189 },
    ],
    coord: [],
  },
  {
    owner: " Rahman S.",
    cert_no: "593.83.1924.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685289.373, E: 397626.189 },
      { N: 9685289.373, E: 397726.189 },
      { N: 9685189.373, E: 397726.189 },
      { N: 9685189.373, E: 397626.189 },
    ],
    coord: [],
  },
  {
    owner: " Farid Pratama Putra",
    cert_no: "593.83.1925.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685189.373, E: 397826.189 },
      { N: 9685189.373, E: 397926.189 },
      { N: 9685089.373, E: 397926.189 },
      { N: 9685089.373, E: 397826.189 },
    ],
    coord: [],
  },
  {
    owner: " Dahyar",
    cert_no: "593.83.1926.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685489.373, E: 397826.189 },
      { N: 9685489.373, E: 397926.189 },
      { N: 9685389.373, E: 397926.189 },
      { N: 9685389.373, E: 397826.189 },
    ],
    coord: [],
  },
  {
    owner: " Haerudin Hamidu",
    cert_no: "593.83.1927.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685289.373, E: 397526.189 },
      { N: 9685289.373, E: 397626.189 },
      { N: 9685189.373, E: 397626.189 },
      { N: 9685189.373, E: 397526.189 },
    ],
    coord: [],
  },
  {
    owner: " Hatta Alimudin",
    cert_no: "593.83.1928.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685389.373, E: 397726.189 },
      { N: 9685389.373, E: 397826.189 },
      { N: 9685289.373, E: 397826.189 },
      { N: 9685289.373, E: 397726.189 },
    ],
    coord: [],
  },
  {
    owner: " Julaela",
    cert_no: "593.83.1929.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685189.373, E: 397726.189 },
      { N: 9685189.373, E: 397826.189 },
      { N: 9685089.373, E: 397826.189 },
      { N: 9685089.373, E: 397726.189 },
    ],
    coord: [],
  },
  {
    owner: " Mu_min",
    cert_no: "593.83.1930.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685489.373, E: 397926.189 },
      { N: 9685489.373, E: 398026.189 },
      { N: 9685389.373, E: 398026.189 },
      { N: 9685389.373, E: 397926.189 },
    ],
    coord: [],
  },
  {
    owner: " Masdir",
    cert_no: "593.83.1931.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685589.373, E: 397826.189 },
      { N: 9685589.373, E: 397926.189 },
      { N: 9685489.373, E: 397926.189 },
      { N: 9685489.373, E: 397826.189 },
    ],
    coord: [],
  },
  {
    owner: " Nurdin",
    cert_no: "593.83.1932.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685489.373, E: 397726.189 },
      { N: 9685489.373, E: 397826.189 },
      { N: 9685389.373, E: 397826.189 },
      { N: 9685389.373, E: 397726.189 },
    ],
    coord: [],
  },
  {
    owner: " Mince Astriani",
    cert_no: "593.83.1933.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685189.373, E: 397526.189 },
      { N: 9685189.373, E: 397626.189 },
      { N: 9685089.373, E: 397626.189 },
      { N: 9685089.373, E: 397526.189 },
    ],
    coord: [],
  },
  {
    owner: " Kadir",
    cert_no: "593.83.1934.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685189.373, E: 397926.189 },
      { N: 9685189.373, E: 398026.189 },
      { N: 9685089.373, E: 398026.189 },
      { N: 9685089.373, E: 397926.189 },
    ],
    coord: [],
  },
  {
    owner: " Hanija",
    cert_no: "593.83.1935.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685389.373, E: 397826.189 },
      { N: 9685389.373, E: 397926.189 },
      { N: 9685289.373, E: 397926.189 },
      { N: 9685289.373, E: 397826.189 },
    ],
    coord: [],
  },
  {
    owner: " Hasmin",
    cert_no: "593.83.1936.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685189.373, E: 397626.189 },
      { N: 9685189.373, E: 397726.189 },
      { N: 9685089.373, E: 397726.189 },
      { N: 9685089.373, E: 397626.189 },
    ],
    coord: [],
  },
  {
    owner: " Rahidin",
    cert_no: "593.83.1937.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685589.373, E: 397626.189 },
      { N: 9685589.373, E: 397726.189 },
      { N: 9685489.373, E: 397726.189 },
      { N: 9685489.373, E: 397626.189 },
    ],
    coord: [],
  },
  {
    owner: " Ahmad Hadi Supyin",
    cert_no: "593.83.1940.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685489.373, E: 396726.189 },
      { N: 9685489.373, E: 396826.189 },
      { N: 9685389.373, E: 396826.189 },
      { N: 9685389.373, E: 396726.189 },
    ],
    coord: [],
  },
  {
    owner: " Fitra",
    cert_no: "593.83.1941.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685089.373, E: 396826.189 },
      { N: 9685089.373, E: 396926.189 },
      { N: 9684989.373, E: 396926.189 },
      { N: 9684989.373, E: 396826.189 },
    ],
    coord: [],
  },
  {
    owner: " Dedi Purwanto",
    cert_no: "593.83.1942.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9684916.223, E: 396720.104 },
      { N: 9684912.525, E: 396826.189 },
      { N: 9684889.373, E: 396826.189 },
      { N: 9684889.373, E: 396897.886 },
      { N: 9684795.768, E: 396899.499 },
      { N: 9684841.512, E: 396824.217 },
      { N: 9684894.574, E: 396733.721 },
    ],
    coord: [],
  },
  {
    owner: " Arjun",
    cert_no: "593.83.1952.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685689.373, E: 398026.189 },
      { N: 9685689.373, E: 398126.189 },
      { N: 9685589.373, E: 398126.189 },
      { N: 9685589.373, E: 398026.189 },
    ],
    coord: [],
  },
  {
    owner: " Amin",
    cert_no: "593.83.1953.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685489.373, E: 398126.189 },
      { N: 9685489.373, E: 398226.189 },
      { N: 9685389.373, E: 398226.189 },
      { N: 9685389.373, E: 398126.189 },
    ],
    coord: [],
  },
  {
    owner: " Boine M.",
    cert_no: "593.83.1954.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685389.373, E: 398326.189 },
      { N: 9685389.373, E: 398426.189 },
      { N: 9685289.373, E: 398426.189 },
      { N: 9685289.373, E: 398326.189 },
    ],
    coord: [],
  },
  {
    owner: " Ilham",
    cert_no: "593.83.1955.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685289.373, E: 398526.189 },
      { N: 9685289.373, E: 398626.189 },
      { N: 9685189.373, E: 398626.189 },
      { N: 9685189.373, E: 398526.189 },
    ],
    coord: [],
  },
  {
    owner: " Amelia",
    cert_no: "593.83.1956.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685389.373, E: 398426.189 },
      { N: 9685389.373, E: 398526.189 },
      { N: 9685289.373, E: 398526.189 },
      { N: 9685289.373, E: 398426.189 },
    ],
    coord: [],
  },
  {
    owner: " Harpia",
    cert_no: "593.83.1957.BHDP.VII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685189.373, E: 398126.189 },
      { N: 9685189.373, E: 398226.189 },
      { N: 9685089.373, E: 398226.189 },
      { N: 9685089.373, E: 398126.189 },
    ],
    coord: [],
  },
  {
    owner: " Andi Moh. Darma",
    cert_no: "593.83.1958.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685289.373, E: 398226.189 },
      { N: 9685289.373, E: 398326.189 },
      { N: 9685189.373, E: 398326.189 },
      { N: 9685189.373, E: 398226.189 },
    ],
    coord: [],
  },
  {
    owner: " Hasnia",
    cert_no: "593.83.1959.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685489.373, E: 398026.189 },
      { N: 9685489.373, E: 398126.189 },
      { N: 9685389.373, E: 398126.189 },
      { N: 9685389.373, E: 398026.189 },
    ],
    coord: [],
  },
  {
    owner: " Asram",
    cert_no: "593.83.1960.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685389.373, E: 398526.189 },
      { N: 9685389.373, E: 398626.189 },
      { N: 9685289.373, E: 398626.189 },
      { N: 9685289.373, E: 398526.189 },
    ],
    coord: [],
  },
  {
    owner: " Anton Elia",
    cert_no: "593.83.1961.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685289.373, E: 398026.189 },
      { N: 9685289.373, E: 398126.189 },
      { N: 9685189.373, E: 398126.189 },
      { N: 9685189.373, E: 398026.189 },
    ],
    coord: [],
  },
  {
    owner: " Nurlija",
    cert_no: "593.83.1962.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685189.373, E: 398626.189 },
      { N: 9685189.373, E: 398726.189 },
      { N: 9685089.373, E: 398726.189 },
      { N: 9685089.373, E: 398626.189 },
    ],
    coord: [],
  },
  {
    owner: " Sarifudin N.",
    cert_no: "593.83.1963.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685689.373, E: 397926.189 },
      { N: 9685689.373, E: 398026.189 },
      { N: 9685589.373, E: 398026.189 },
      { N: 9685589.373, E: 397926.189 },
    ],
    coord: [],
  },
  {
    owner: " Syukur",
    cert_no: "593.83.1964.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685289.373, E: 398426.189 },
      { N: 9685289.373, E: 398526.189 },
      { N: 9685189.373, E: 398526.189 },
      { N: 9685189.373, E: 398426.189 },
    ],
    coord: [],
  },
  {
    owner: " Zulkifli Udin",
    cert_no: "593.83.1965.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685589.373, E: 398026.189 },
      { N: 9685589.373, E: 398126.189 },
      { N: 9685489.373, E: 398126.189 },
      { N: 9685489.373, E: 398026.189 },
    ],
    coord: [],
  },
  {
    owner: " Wijayanti",
    cert_no: "593.83.1966.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685289.373, E: 398626.189 },
      { N: 9685289.373, E: 398726.189 },
      { N: 9685189.373, E: 398726.189 },
      { N: 9685189.373, E: 398626.189 },
    ],
    coord: [],
  },
  {
    owner: " Rosmaida",
    cert_no: "593.83.1967.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685189.373, E: 398526.189 },
      { N: 9685189.373, E: 398626.189 },
      { N: 9685089.373, E: 398626.189 },
      { N: 9685089.373, E: 398526.189 },
    ],
    coord: [],
  },
  {
    owner: " Yefta Mangende",
    cert_no: "593.83.1968.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685389.373, E: 398126.189 },
      { N: 9685389.373, E: 398226.189 },
      { N: 9685289.373, E: 398226.189 },
      { N: 9685289.373, E: 398126.189 },
    ],
    coord: [],
  },
  {
    owner: " Sandi",
    cert_no: "593.83.1969.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685389.373, E: 398026.189 },
      { N: 9685389.373, E: 398126.189 },
      { N: 9685289.373, E: 398126.189 },
      { N: 9685289.373, E: 398026.189 },
    ],
    coord: [],
  },
  {
    owner: " Wahnilda",
    cert_no: "593.83.1970.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685289.373, E: 398326.189 },
      { N: 9685289.373, E: 398426.189 },
      { N: 9685189.373, E: 398426.189 },
      { N: 9685189.373, E: 398326.189 },
    ],
    coord: [],
  },
  {
    owner: " Novianti",
    cert_no: "593.83.1971.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685289.373, E: 398126.189 },
      { N: 9685289.373, E: 398226.189 },
      { N: 9685189.373, E: 398226.189 },
      { N: 9685189.373, E: 398126.189 },
    ],
    coord: [],
  },
  {
    owner: " Murni Arsad",
    cert_no: "593.83.1972.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685189.373, E: 398026.189 },
      { N: 9685189.373, E: 398126.189 },
      { N: 9685089.373, E: 398126.189 },
      { N: 9685089.373, E: 398026.189 },
    ],
    coord: [],
  },
  {
    owner: " Abd Muluk Saputra",
    cert_no: "593.83.1973.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685189.373, E: 398326.189 },
      { N: 9685189.373, E: 398426.189 },
      { N: 9685089.373, E: 398426.189 },
      { N: 9685089.373, E: 398326.189 },
    ],
    coord: [],
  },
  {
    owner: " Maman Suryana",
    cert_no: "593.83.1974.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685589.373, E: 397926.189 },
      { N: 9685589.373, E: 398026.189 },
      { N: 9685489.373, E: 398026.189 },
      { N: 9685489.373, E: 397926.189 },
    ],
    coord: [],
  },
  {
    owner: " Mujiono",
    cert_no: "593.83.1975.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685440.959, E: 398607.449 },
      { N: 9685440.959, E: 398654.915 },
      { N: 9685412.069, E: 398661.38 },
      { N: 9685347.882, E: 398704.182 },
      { N: 9685289.373, E: 398704.246 },
      { N: 9685289.373, E: 398626.189 },
      { N: 9685389.373, E: 398626.189 },
      { N: 9685389.373, E: 398608.51 },
    ],
    coord: [],
  },
  {
    owner: " Muh Rafli S.",
    cert_no: "593.83.1976.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685347.882, E: 398704.182 },
      { N: 9685272.171, E: 398762.164 },
      { N: 9685148.434, E: 398825.983 },
      { N: 9685154.907, E: 398726.189 },
      { N: 9685289.373, E: 398726.189 },
      { N: 9685289.373, E: 398704.246 },
    ],
    coord: [],
  },
  {
    owner: " Nasrudin",
    cert_no: "593.83.1977.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685189.373, E: 398226.189 },
      { N: 9685189.373, E: 398326.189 },
      { N: 9685089.373, E: 398326.189 },
      { N: 9685089.373, E: 398226.189 },
    ],
    coord: [],
  },
  {
    owner: " Nasrah Nuhun",
    cert_no: "593.83.1979.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685189.373, E: 398426.189 },
      { N: 9685189.373, E: 398526.189 },
      { N: 9685089.373, E: 398526.189 },
      { N: 9685089.373, E: 398426.189 },
    ],
    coord: [],
  },
  {
    owner: " Yerna",
    cert_no: "593.83.1980.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685389.373, E: 398226.189 },
      { N: 9685389.373, E: 398326.189 },
      { N: 9685289.373, E: 398326.189 },
      { N: 9685289.373, E: 398226.189 },
    ],
    coord: [],
  },
  {
    owner: " Maunia",
    cert_no: "593.83.1981.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685589.373, E: 398126.189 },
      { N: 9685589.373, E: 398226.189 },
      { N: 9685489.373, E: 398226.189 },
      { N: 9685489.373, E: 398126.189 },
    ],
    coord: [],
  },
  {
    owner: " Yusrisal",
    cert_no: "593.83.1982.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686289.373, E: 397326.189 },
      { N: 9686289.373, E: 397426.189 },
      { N: 9686189.373, E: 397426.189 },
      { N: 9686189.373, E: 397326.189 },
    ],
    coord: [],
  },
  {
    owner: " Mustakim",
    cert_no: "593.83.1983.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686189.373, E: 397726.189 },
      { N: 9686189.373, E: 397826.189 },
      { N: 9686089.373, E: 397826.189 },
      { N: 9686089.373, E: 397726.189 },
    ],
    coord: [],
  },
  {
    owner: " Malik Baco Laida",
    cert_no: "593.83.1984.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685789.373, E: 397326.189 },
      { N: 9685789.373, E: 397426.189 },
      { N: 9685689.373, E: 397426.189 },
      { N: 9685689.373, E: 397326.189 },
    ],
    coord: [],
  },
  {
    owner: " Nani Rosita",
    cert_no: "593.83.1985.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686189.373, E: 397426.189 },
      { N: 9686189.373, E: 397526.189 },
      { N: 9686089.373, E: 397526.189 },
      { N: 9686089.373, E: 397426.189 },
    ],
    coord: [],
  },
  {
    owner: " Nawir",
    cert_no: "593.83.1986.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685889.373, E: 397726.189 },
      { N: 9685889.373, E: 397826.189 },
      { N: 9685789.373, E: 397826.189 },
      { N: 9685789.373, E: 397726.189 },
    ],
    coord: [],
  },
  {
    owner: " Safia Abu",
    cert_no: "593.83.1987.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685789.373, E: 397726.189 },
      { N: 9685789.373, E: 397826.189 },
      { N: 9685689.373, E: 397826.189 },
      { N: 9685689.373, E: 397726.189 },
    ],
    coord: [],
  },
  {
    owner: " Rahida",
    cert_no: "593.83.1988.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686089.373, E: 397426.189 },
      { N: 9686089.373, E: 397526.189 },
      { N: 9685989.373, E: 397526.189 },
      { N: 9685989.373, E: 397426.189 },
    ],
    coord: [],
  },
  {
    owner: " Hasyim Halilu",
    cert_no: "593.83.1989.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685889.373, E: 397626.189 },
      { N: 9685889.373, E: 397726.189 },
      { N: 9685789.373, E: 397726.189 },
      { N: 9685789.373, E: 397626.189 },
    ],
    coord: [],
  },
  {
    owner: " Syamsiar",
    cert_no: "593.83.1990.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686359.262, E: 397356.489 },
      { N: 9686375.884, E: 397420.349 },
      { N: 9686392.486, E: 397457.628 },
      { N: 9686289.373, E: 397508.206 },
      { N: 9686289.373, E: 397361.596 },
    ],
    coord: [],
  },
  {
    owner: " Syahbuddin, S.Pd.",
    cert_no: "593.83.1991.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685989.373, E: 397526.189 },
      { N: 9685989.373, E: 397626.189 },
      { N: 9685889.373, E: 397626.189 },
      { N: 9685889.373, E: 397526.189 },
    ],
    coord: [],
  },
  {
    owner: " Syukur Jun Agus",
    cert_no: "593.83.1992.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685989.373, E: 397426.189 },
      { N: 9685989.373, E: 397526.189 },
      { N: 9685889.373, E: 397526.189 },
      { N: 9685889.373, E: 397426.189 },
    ],
    coord: [],
  },
  {
    owner: " Tati",
    cert_no: "593.83.1993.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685989.373, E: 397726.189 },
      { N: 9685989.373, E: 397826.189 },
      { N: 9685889.373, E: 397826.189 },
      { N: 9685889.373, E: 397726.189 },
    ],
    coord: [],
  },
  {
    owner: " Usman Jaelani",
    cert_no: "593.83.1994.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685789.373, E: 397526.189 },
      { N: 9685789.373, E: 397626.189 },
      { N: 9685689.373, E: 397626.189 },
      { N: 9685689.373, E: 397526.189 },
    ],
    coord: [],
  },
  {
    owner: " Ucok Nainggolan",
    cert_no: "593.83.1995.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686289.373, E: 397426.189 },
      { N: 9686289.373, E: 397526.189 },
      { N: 9686189.373, E: 397526.189 },
      { N: 9686189.373, E: 397426.189 },
    ],
    coord: [],
  },
  {
    owner: " Yusran",
    cert_no: "593.83.1996.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685889.373, E: 397526.189 },
      { N: 9685889.373, E: 397626.189 },
      { N: 9685789.373, E: 397626.189 },
      { N: 9685789.373, E: 397526.189 },
    ],
    coord: [],
  },
  {
    owner: " As_adi",
    cert_no: "593.83.1997.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686189.373, E: 397526.189 },
      { N: 9686189.373, E: 397626.189 },
      { N: 9686089.373, E: 397626.189 },
      { N: 9686089.373, E: 397526.189 },
    ],
    coord: [],
  },
  {
    owner: " Zaenab Aminudin, SE.",
    cert_no: "593.83.1998.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686305.698, E: 397268.817 },
      { N: 9686354.898, E: 397327.531 },
      { N: 9686359.262, E: 397356.489 },
      { N: 9686289.373, E: 397361.596 },
      { N: 9686289.373, E: 397326.189 },
      { N: 9686189.373, E: 397326.189 },
      { N: 9686189.373, E: 397269.898 },
    ],
    coord: [],
  },
  {
    owner: " Asnadi",
    cert_no: "593.83.1999.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686189.373, E: 397626.189 },
      { N: 9686189.373, E: 397726.189 },
      { N: 9686089.373, E: 397726.189 },
      { N: 9686089.373, E: 397626.189 },
    ],
    coord: [],
  },
  {
    owner: " Bakri",
    cert_no: "593.83.2000.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686089.373, E: 397526.189 },
      { N: 9686089.373, E: 397626.189 },
      { N: 9685989.373, E: 397626.189 },
      { N: 9685989.373, E: 397526.189 },
    ],
    coord: [],
  },
  {
    owner: " Faisal",
    cert_no: "593.83.2001.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685789.373, E: 397426.189 },
      { N: 9685789.373, E: 397526.189 },
      { N: 9685689.373, E: 397526.189 },
      { N: 9685689.373, E: 397426.189 },
    ],
    coord: [],
  },
  {
    owner: " Hanisa",
    cert_no: "593.83.2002.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686189.373, E: 397326.189 },
      { N: 9686189.373, E: 397426.189 },
      { N: 9686089.373, E: 397426.189 },
      { N: 9686089.373, E: 397326.189 },
    ],
    coord: [],
  },
  {
    owner: " Ilham Nuhun Samir",
    cert_no: "593.83.2003.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685989.373, E: 397326.189 },
      { N: 9685989.373, E: 397426.189 },
      { N: 9685889.373, E: 397426.189 },
      { N: 9685889.373, E: 397326.189 },
    ],
    coord: [],
  },
  {
    owner: " Lutfi",
    cert_no: "593.83.2004.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686089.373, E: 397726.189 },
      { N: 9686089.373, E: 397826.189 },
      { N: 9685989.373, E: 397826.189 },
      { N: 9685989.373, E: 397726.189 },
    ],
    coord: [],
  },
  {
    owner: " Jefri Tolekende",
    cert_no: "593.83.2005.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685889.373, E: 397326.189 },
      { N: 9685889.373, E: 397426.189 },
      { N: 9685789.373, E: 397426.189 },
      { N: 9685789.373, E: 397326.189 },
    ],
    coord: [],
  },
  {
    owner: " Abd Muh Arfa",
    cert_no: "593.83.2006.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686289.373, E: 397526.189 },
      { N: 9686289.373, E: 397626.189 },
      { N: 9686189.373, E: 397626.189 },
      { N: 9686189.373, E: 397526.189 },
    ],
    coord: [],
  },
  {
    owner: " Arisno Kalalo",
    cert_no: "593.83.2007.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685889.373, E: 397426.189 },
      { N: 9685889.373, E: 397526.189 },
      { N: 9685789.373, E: 397526.189 },
      { N: 9685789.373, E: 397426.189 },
    ],
    coord: [],
  },
  {
    owner: " Abd Malik",
    cert_no: "593.83.2008.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685789.373, E: 397626.189 },
      { N: 9685789.373, E: 397726.189 },
      { N: 9685689.373, E: 397726.189 },
      { N: 9685689.373, E: 397626.189 },
    ],
    coord: [],
  },
  {
    owner: " Alderit Hargans",
    cert_no: "593.83.2009.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686089.373, E: 397626.189 },
      { N: 9686089.373, E: 397726.189 },
      { N: 9685989.373, E: 397726.189 },
      { N: 9685989.373, E: 397626.189 },
    ],
    coord: [],
  },
  {
    owner: " Akbar",
    cert_no: "593.83.2010.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686089.373, E: 397326.189 },
      { N: 9686089.373, E: 397426.189 },
      { N: 9685989.373, E: 397426.189 },
      { N: 9685989.373, E: 397326.189 },
    ],
    coord: [],
  },
  {
    owner: " Alimin",
    cert_no: "593.83.2011.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9685989.373, E: 397626.189 },
      { N: 9685989.373, E: 397726.189 },
      { N: 9685889.373, E: 397726.189 },
      { N: 9685889.373, E: 397626.189 },
    ],
    coord: [],
  },
  {
    owner: "  Hamasia",
    cert_no: "593.83.1746.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686389.373, E: 398026.189 },
      { N: 9686389.373, E: 398126.189 },
      { N: 9686289.373, E: 398126.189 },
      { N: 9686289.373, E: 398026.189 },
    ],
    coord: [],
  },
  {
    owner: "  Hamid S",
    cert_no: "593.83.1747.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686089.373, E: 398126.189 },
      { N: 9686089.373, E: 398226.189 },
      { N: 9685989.373, E: 398226.189 },
      { N: 9685989.373, E: 398126.189 },
    ],
    coord: [],
  },
  {
    owner: "  Hamrun",
    cert_no: "593.83.1748.BHDP.VIII.2022",
    isVerified: true,
    isValid: true,
    NEcoord: [
      { N: 9686189.373, E: 397926.189 },
      { N: 9686189.373, E: 398026.189 },
      { N: 9686089.373, E: 398026.189 },
      { N: 9686089.373, E: 397926.189 },
    ],
    coord: [],
  },
];

export default dataCert;
