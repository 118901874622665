<template>
  <gantt-elastic :options="options" :tasks="tasks" @tasks-changed="tasksUpdate" @options-changed="optionsUpdate"
    @dynamic-style-changed="styleUpdate">
    <gantt-header slot="header"></gantt-header>
  </gantt-elastic>
</template>

<style></style>

<script>
import axios from '@/util/axios';
import { LoginURL, clientId, clientSecret, apiDomain, getHeader, getcurrentDate, } from "@/assets/js/config.js";
import moment from "moment";

import GanttElastic from "gantt-elastic";
import GanttHeader from "gantt-elastic-header";
import dayjs from "dayjs";

// just helper to get current dates
function getDate(hours) {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();
  const timeStamp = new Date(currentYear, currentMonth, currentDay, 0, 0, 0).getTime();
  return new Date(timeStamp + hours * 60 * 60 * 1000).getTime();
}

let tasks = [{
  id: 'loading',
  label: "loading",
  user: 'loading',
  start: getDate(1),
  // duration: 8 * 24 * 60 * 60 * 1000,
  duration: 0,
  percent: 100,
  type: "loading"
  //collapsed: true,
},
]

let options = {
  taskMapping: {
    progress: "percent"
  },
  maxRows: 100,
  maxHeight: 430,
  // maxHeight: 300,
  title: {
    label: "Map Dashboard",
    html: false
  },
  row: {
    height: 24
  },
  // times: {
  //   timeScale: 60 * 1000,
  //   timeZoom: 20,
  // },
  calendar: {
    hour: {
      display: true
    }
  },
  chart: {
    progress: {
      bar: false
    },
    expander: {
      display: true
    }
  },
  taskList: {
    expander: {
      straight: false
    },
    columns: [
      {
        id: 1,
        label: "Description",
        value: "label",
        width: 200,
        expander: true,
        html: true,
        // style: {
        //   // 'task-list-header-label': {
        //   //   'font-weight': 'bold',
        //   // },
        //   'task-list-item-value-container': {
        //     // 'text-align': 'center',
        //     'font-weight': 'bold'
        //   },
        // }
        // events: {
        //   click({ data, column }) {
        //     alert("description clicked!\n" + data.label);
        //   }
        // }
        // style: {
        //   base: {
        //     fill: '#1EBC61',
        //     stroke: '#0EAC51',
        //   },
        //   /*'tree-row-bar': {
        //     fill: '#1EBC61',
        //     stroke: '#0EAC51'
        //   },
        //   'tree-row-bar-polygon': {
        //     stroke: '#0EAC51'
        //   }*/
        // },
      },
      {
        id: 2,
        label: "Start",
        value: task => dayjs(task.start).format("YYYY-MM-DD"),
        width: 78
      },
      // {
      //   id: 3,
      //   label: "Type",
      //   value: "type",
      //   width: 68
      // }
    ],
  },
  locale: {
    name: "en",
    Now: "Now",
    "X-Scale": "Zoom-X",
    "Y-Scale": "Zoom-Y",
    "Task list width": "Task list",
    "Before/After": "Expand",
    "Display task list": "Task list"
  }
};

export default {
  name: "Gantt",
  components: {
    GanttElastic,
    GanttHeader,
  },
  data() {
    return {
      obj_project: {
        data_milestone: null,
      },
      tasks,
      options,
      dynamicStyle: {},
      lastId: 16,
      oDummy: {
        project_status_percentage_counter: 0,
        project_status_percentage: []
      }
    };
  },
  mounted() {
    // this.get_project_site_milestone();

    //_ setting dummy data for dashboard
    //  the above original method now is call from inside this method below
    this.getSiteAdditionalInformation('6276')
  },
  methods: {
    addTask() {
      this.tasks.push({
        id: this.lastId++,
        label:
          '<a href="https://images.pexels.com/photos/423364/pexels-photo-423364.jpeg?auto=compress&cs=tinysrgb&h=650&w=940" target="_blank" style="color:#0077c0;">Yeaahh! you have added a task bro!</a>',
        user:
          '<a href="https://images.pexels.com/photos/423364/pexels-photo-423364.jpeg?auto=compress&cs=tinysrgb&h=650&w=940" target="_blank" style="color:#0077c0;">Awesome!</a>',
        start: getDate(24 * 3),
        duration: 1 * 24 * 60 * 60 * 1000,
        percent: 50,
        type: "project"
      });
    },
    tasksUpdate(tasks) {
      this.tasks = tasks;
      // console.log(this.tasks);
    },
    optionsUpdate(options) {
      this.options = options;
    },
    styleUpdate(style) {
      this.dynamicStyle = style;
    },

    get_project_site_milestone() {
      //https://momensfile.sembcorp.com/project/project_site_milestone.show_by_site/7384
      // console.log("get_project_site_milestone");

      axios
        .get(
          apiDomain + "project/project_site_milestone.show_by_site/" + this.$route.params.id,
          { headers: getHeader() }
        )
        .then((response) => {
          if (response.status === 200) {
            // console.log('get_project_site_milestone')
            // console.log(response.data.data)

            this.obj_project.data_milestone = response.data.data;

            // // console.log(this.obj_project.data_milestone);

            // //_ all data loaded, now time to construct + populate the chart
            let tmpData = this.construct_chart_series();
            // console.log(tmpData);

            this.tasks = tmpData;

            // // this.chartOptions.series[0].push(tmpData);
            // // this.chartOptions.series = [tmpData];
            // this.chartOptions.series = tmpData;
            // // this.chartOptions.series_test = tmpData;
            // // console.log(this.chartOptions);  

            // // this.chartOptions.series.push(tmpData);

            // // console.log(this.chartOptions);
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.errorFunction(error, "Get Milestones by Site ID");
        })
        .finally(() => {
          //_
          // this.getFromDB();
          // console.log (this.tasks);
        });
    },
    construct_chart_series() {
      // console.log('construct_chart_series');
      // console.log(this.obj_project.data_milestone);

      let tmp_mile_data = [];         // <- this one we use to populate series

      //_ populate data series
      let dataMile = this.obj_project.data_milestone;
      if (dataMile.length > 1) {
        dataMile.forEach((itemMile, indexMile) => {
          //_ milestone entry
          tmp_mile_data.push(this.construct_chart_item('', itemMile))

          let dataTask = itemMile.milestone_tasks;
          dataTask.forEach((itemTask, indexTask) => {
            //_ subtask entry
            tmp_mile_data.push(this.construct_chart_item(itemMile.site_milestone_id, itemTask))
          });
        });
      }

      return tmp_mile_data;
    },
    construct_chart_item(idParent = '', dataSource) {
      /*
        id: 'test_1',
        parentId: 'xy_1',
        dependentOn: ['GG3'],
        label: "loading",
        user: 'loading',
        start: getDate(1),
        duration: 4 * 24 * 60 * 60 * 1000,
        percent: 100,
        type: "loading"
      */
      let retVal = null;
      if (idParent == '') {
        let milestone_dates = this.get_milestone_date_from_task(dataSource);
        // console.log(milestone_dates)

        retVal = {
          id: dataSource.site_milestone_id.toString(),
          label: '<b>' + dataSource.site_milestone_code + '</b>',
          start: milestone_dates.date_start,
          duration: milestone_dates.duration,
          // percent: 100,
          // percent: Math.floor(Math.random() * 100),
          percent: this.oDummy.project_status_percentage[this.oDummy.project_status_percentage_counter],
          type: 'milestone',
          collapsed: true,
          // style: {
          //   // 'task-list-header-label': {
          //   //   'font-weight': 'bold',
          //   // },
          //   'task-list-item-value-container': {
          //     // 'text-align': 'center',
          //     'font-weight': 'bold'
          //   },
          // }
          // style: {
          //   // 'task-list-header-label': {
          //   //   'text-align': 'center',
          //   //   width: '100%'
          //   // },
          //   // 'task-list-item-value-container': {
          //   //   'text-align': 'center',
          //   //   'font-weight': 'bold'
          //   // },
          //   base: {
          //     fill: "#0287D0",
          //     stroke: "#0077C0"
          //   },
          //   'tree-row-bar': {
          //     fill: '#1EBC61',
          //     stroke: '#0EAC51'
          //   },
          //   'tree-row-bar-polygon': {
          //     stroke: '#0EAC51'
          //   }
          // },
          style: {
            // fill: '#0287D0',
            // stroke: '#0077C0'
            base: {
              fill: "#0287D0",
              stroke: "#0077C0"
            },
          },
          progressBarStyle: {
            bar: {
              fill: '#0EAC51'
            }
          }
        }

        this.oDummy.project_status_percentage_counter++;
      } else {
        retVal = {
          id: idParent.toString() + '_' + dataSource.site_milestone_task_id.toString(),
          parentId: idParent.toString(),
          label: '&nbsp;&nbsp;&nbsp;' + dataSource.site_milestone_task_name,
          start: (dataSource.site_milestone_task_planned_start_date === null) ? getDate(1) : this.format_datetime_to_unix_timestamp(dataSource.site_milestone_task_planned_start_date),
          duration: this.duration_difference_between_date(this.format_datetime_to_unix_timestamp(dataSource.site_milestone_task_planned_start_date), this.format_datetime_to_unix_timestamp(dataSource.site_milestone_task_planned_end_date)),
          percent: 100,
          type: 'task',
          style: {
            base: {
              // fill: "#1EBC61",
              // stroke: "#0EAC51"
              fill: "#3ec1ff",
              stroke: "#0fb2ff"
            }
          }
        }
      }

      return retVal;
    },
    get_milestone_date_from_task(milestoneDataSource) {
      // console.log('get_milestone_date_from_task')
      // console.log (milestoneDataSource);

      //_ how many task on current milestone ?
      //  why ? cause we going to use the start date of the 1st task, and the end date of the last task as the milestone start and end date
      let iCountTask = milestoneDataSource.milestone_tasks.length;

      //_ initiate the temp start and end, if no date or null on the milestone, then we use current date (today, yes today as in NOW)
      //  otherwise, use the start date of the milestone
      let tmpDateStart = (milestoneDataSource.site_milestone_task_planned_start_date === null) ? getDate(1) : this.format_datetime_to_unix_timestamp(milestoneDataSource.site_milestone_task_planned_start_date);
      let tmpDateEnd = (milestoneDataSource.site_milestone_task_planned_end_date === null) ? getDate(1) : this.format_datetime_to_unix_timestamp(milestoneDataSource.site_milestone_task_planned_end_date);

      //_ now this is the process of using 1st task start date, and last task end date
      if (iCountTask > 0) {
        tmpDateStart = (milestoneDataSource.milestone_tasks[0].site_milestone_task_planned_start_date === null) ? tmpDateStart : this.format_datetime_to_unix_timestamp(milestoneDataSource.milestone_tasks[0].site_milestone_task_planned_start_date);

        tmpDateEnd = (milestoneDataSource.milestone_tasks[iCountTask - 1].site_milestone_task_planned_end_date === null) ? tmpDateEnd : this.format_datetime_to_unix_timestamp(milestoneDataSource.milestone_tasks[iCountTask - 1].site_milestone_task_planned_end_date);
      }

      //_ calculate the duration between those date
      let iDuration = this.duration_difference_between_date(tmpDateStart, tmpDateEnd);

      //_ then tadaaa return the value to be display on the gantt chart
      let retVal = {
        date_start: tmpDateStart,
        date_end: tmpDateEnd,
        duration: iDuration
      };

      // console.log(retVal)
      return retVal;
    },
    format_datetime_to_unix_timestamp(tmp_value) {
      // console.log('format_datetime_to_unix_timestamp');

      // IMPORTANT: highchart using months in this calculation are zero-based - 0 month is January.  <-- I'm so whattttt ???
      // so what we need to do, the month MINUS 1

      if (tmp_value === null) {
        return 0;
      } else {
        let tmpDD = moment(tmp_value).format('DD');
        let tmpMM = moment(tmp_value).format('MM');
        let tmpYY = moment(tmp_value).format('YYYY');

        return Date.UTC(tmpYY, tmpMM - 1, tmpDD);
      }

      // Date.UTC()
    },
    duration_difference_between_date(timestamp1, timestamp2) {
      // Example Unix timestamps (in milliseconds)
      // const timestamp1 = 1722556800000;
      // const timestamp2 = 1732924800000;

      /*
      // Calculate the difference in milliseconds
      const differenceInMs = timestamp2 - timestamp1;

      // Convert the difference to other units
      const differenceInSeconds = Math.floor(differenceInMs / 1000);
      const differenceInMinutes = Math.floor(differenceInMs / (1000 * 60));
      const differenceInHours = Math.floor(differenceInMs / (1000 * 60 * 60));
      const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));

      console.log(`Difference: ${differenceInDays} days, ${differenceInHours} hours, ${differenceInMinutes} minutes, ${differenceInSeconds} seconds`);      
      */

      if (timestamp1 === null && timestamp2 === null) {
        return 8 * 24 * 60 * 60 * 1000;
      } else {
        const differenceInMs = timestamp2 - timestamp1;
        const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
        return differenceInDays * 24 * 60 * 60 * 1000;
      }

    },


    //_ temporary use this method for setting dummy data percentage of the milestone
    getSiteAdditionalInformation(site_id) {
      axios
        .get(apiDomain + 'project/project_site_additional_info/' + site_id, { headers: getHeader() })
        .then(response => {
          // console.log('[MapDashboardDetail.vue] > func: getSiteAdditionalInformation')
          // console.log(response)

          this.oDummy.project_status_percentage = response.data.data[0].emergency_contact_pic.split("|");

          // console.log(this.oDummy.project_status_percentage)
        })
        .catch(error => {
          this.errorFunction(error, "Get project site additional information")
        })
        .finally(() => {
          this.get_project_site_milestone()
        });
    },
  },
};
</script>

<style scoped>
/deep/ .gantt-elastic__main-container {
  width: 100% !important;
}

/deep/ .gantt-elastic__header-title--text {
  color: #5d767a;
  display: none;
}

/deep/ .gantt-elastic__header-label {
  margin-left: 5px;
  margin-right: 5px;
}

/deep/ .gantt-elastic__task-list-header-column {
  background-color: #5d767a !important;
}

/deep/ .gantt-elastic__calendar-row-rect-child {
  background-color: #5d767a !important;
}

/deep/ .gantt-elastic__calendar-row-text {
  color: #ffffff !important;
}
</style>
