<template>
  <div class="bg-default-dashboard">
    <div class=" py-lg-8 pt-lg-5">
      <b-container fluid style="width: 90%;">
        <b-row class="mb-2">
          <b-col>
            <h1 class="text-white">Project Site List</h1>
          </b-col>
        </b-row>
        <b-row>
          <div class="d-flex flex-column h-100 w-100 p-2">
            <div class="d-flex flex-column h-100 w-100 flex-grow-1 flex-shrink-1">
              <div style="padding: 4px;">
                <div style="float: right" class="d-flex">
                  <input
                    class="mr-2 form-control d-inline-block"
                    @keyup="onQuickFilterChanged"
                    type="text"
                    id="quickFilterInput"
                    placeholder="Type text to filter..."
                  />
                </div>
                <div style="float: right" class="d-flex">
                  <base-input name="Year" v-model="turn_on_year" class="span_filter_developer">
                      <select class="form-control" v-model="turn_on_year" @change="changeYear()">
                        <option v-for="item in turn_on_years" :key="item.turn_on_year_id" :value="item.turn_on_year_id">
                          {{ item.turn_on_year }}
                        </option>
                      </select>
                    </base-input>
                </div>
              </div>
              <div style="clear: both;"></div>
              <div v-if="showGrid" class="d-flex flex-column flex-grow-1 flex-shrink-1">
                <div style="padding: 4px" class="btn-toolbar">
                  <span>
                    <button
                      class="btn btn-primary mx-1"
                      @click="api.selectAll()"
                    >
                      Select All
                    </button>
                    <button
                      class="btn btn-primary mx-1"
                      @click="api.deselectAll()"
                    >
                      Clear Selection
                    </button>
                    <button
                      class="btn btn-primary mx-1"
                      v-on:click="toggleSideBar()"
                    >
                      Show Side Bar
                    </button>
                    <!--<b-button size="md" variant="success" @click="openPlannerProject">Project List</b-button>-->
                    <b-button size="md" variant="success" @click="openWebPageView">Site List in Web View</b-button>
                  </span>
                    
                </div>
                <ag-grid-vue style="width: 100%; height: 100%" class="flex-grow-1 flex-shrink-1 ag-theme-alpine"
                  :columnDefs="columnDefs" :rowData="rowData" :sideBar="sideBar" :modules="modules" :defaultColDef="{
                    sortable: true,
                    resizable: true,
                    filter: true,
                    floatingFilter: true,
                  }"
                  :statusBar="statusBar"  
                  :groupHeaders="true" 
                  :suppressRowClickSelection="true"
                  :allowContextMenuWithControlKey="true" rowSelection="multiple" @grid-ready="onReady"
                  @cell-clicked="onCellClicked" @cell-double-clicked="onCellDoubleClicked"
                  @cell-context-menu="onCellContextMenu" @cell-value-changed="onCellValueChanged"
                  @cell-focused="onCellFocused" @row-selected="onRowSelected" @selection-changed="onSelectionChanged"
                  @filter-modified="onFilterModified" @virtual-row-removed="onVirtualRowRemoved"
                  @row-clicked="onRowClicked" @column-everything-changed="onColumnEvent"
                  @column-row-group-changed="onColumnEvent" @column-value-Changed="onColumnEvent"
                  @column-moved="onColumnEvent" @column-visible="onColumnEvent" @column-group-Opened="onColumnEvent"
                  @column-resized="onColumnEvent" @column-pinned-count-changed="onColumnEvent"
                  :detailRowHeight=100
                  :detailRowAutoHeight="true"
                  :masterDetail="true"
                  :detailCellRendererParams="detailCellRendererParams_milestone">
                </ag-grid-vue>
              </div>
            </div>
          </div>
        </b-row>

        <!-- Modal loading / in progress -->
        <b-modal ref="modal-loading-in-progress" size="lg" :no-close-on-backdrop="true" centered hide-footer hide-header title="">
          <div class="d-block">
            <!-- date | time | project name | site name -->
            <b-row class="mb-3">
              <b-col>
                <div class="text-center text-muted mb-4">
                  <h1 class="text-white"><b>Loading in progress</b></h1>
                </div>
                <div class="text-center">
                  <div style="color: #ffffff;">
                    <!-- <span class="fa fa-spinner fa-spin fa-3x"></span> -->
                    <span class="icon-spinner-cs"></span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-modal>  

      </b-container>

    </div>


  </div>
</template>

<script>
import { AgGridVue } from "@ag-grid-community/vue";
import HeaderGroupComponent from './HeaderGroupComponent.vue';
import axios from '@/util/axios'
import moment from "moment";

import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { SparklinesModule } from "@ag-grid-enterprise/sparklines";
import { GridChartsModule } from "@ag-grid-enterprise/charts";
import { MasterDetailModule } from "@ag-grid-enterprise/master-detail";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { MultiFilterModule } from "@ag-grid-enterprise/multi-filter";
import { RangeSelectionModule } from "@ag-grid-enterprise/range-selection";
import { RichSelectModule } from "@ag-grid-enterprise/rich-select";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { SideBarModule } from "@ag-grid-enterprise/side-bar";
import { StatusBarModule } from "@ag-grid-enterprise/status-bar";
import { ViewportRowModelModule } from "@ag-grid-enterprise/viewport-row-model";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { apiDomain, getHeader } from '@/assets/js/config.js';
import "@ag-grid-enterprise/core";
import { isNull } from "lodash";

// import DisplaySiteDetailRenderer from "./cell-renderers/displaySiteDetailRenderer.vue";
// import CustomButtonComponent from './cell-renderers/customButtonComponentVue.js';


export default {
  data() {
    return {
      myUserID : null,
      detailCellRendererParams_milestone: null,
      obj_selection: {
        //_ we can change this later, if there is an API for get parameterize setting value
        ms_critical: ['Yes', 'No'],
        ms_status: ['NOT_STARTED', 'IN_PROGRESS', 'COMPLETED'],
      },
      obj_project: {
        id_project: null,
        tx_project: null,
        id_site: null,
        tx_site: null,
        id_milestone: null,
        id_milestone_task: null,
      },
      obj_user: {
        info: null,
        is_pm_leader: false,
        pm_group_id: null,
        pm_members: [],
      },
      obj_hit_api: {
        id_site: null,
        id_task: null,
        // selected_row_data: null,   //_ not needed anymore, it was use when using my own script (when cannot use onCellValueChanged )
        // updated_field: null,       //_ not needed anymore, it was use when using my own script (when cannot use onCellValueChanged )
        to_be_update_data: {},
      },
      model: {
        developers: [],
        project_name: null,
        project_ppa_sign_date_from_date: null,
        project_ppa_sign_date_to_date: null,
        project_ppa_capacity_greater_equal: null,
        project_ppa_capacity_lesser_equal: null,

        project_type: [],
        project_managers: [],
        project_engineers: [],
        project_business_development: [],
        project_safety: [],

        site_name: null,
        project_site_contractors: [],
        project_site_statuses: [],
        site_postal_code: null,
        site_code: null,
        roof_handover_date_from_date: null,
        roof_handover_date_to_date: null,
        site_cod_date_from_date: null,
        site_cod_date_to_date: null,
        site_target_turn_on_date_from_date: null,
        site_target_turn_on_date_to_date: null,
        site_actual_turn_on_date_from_date: null,
        site_actual_turn_on_date_to_date: null,
        site_total_capacity_greater_equal: null,
        site_total_capacity_lesser_equal: null,
        site_as_built_capacity_greater_equal: null,
        site_as_built_capacity_lesser_equal: null,
        site_module_brand_ids: [],
        site_module_quantity_greater_equal: null,
        site_module_quantity_lesser_equal: null,
        site_module_capacity_greater_equal: null,
        site_module_capacity_lesser_equal: null,
        site_module_allocation_total_capacity_greater_equal: null,
        site_module_allocation_total_capacity_lesser_equal: null,
        site_drawing_module_brand_ids: [],
        site_drawing_module_quantity_greater_equal: null,
        site_drawing_module_quantity_lesser_equal: null,
        site_drawing_module_capacity_greater_equal: null,
        site_drawing_module_capacity_lesser_equal: null,
        site_drawing_total_capacity_greater_equal: null,
        site_drawing_total_capacity_lesser_equal: null,
        site_drawing_ifa_date_from_date: null,
        site_drawing_ifa_date_to_date: null,
        site_drawing_ifc_date_from_date: null,
        site_drawing_ifc_date_to_date: null,
        ntp_applicable: null,
        ntp_issued: null,
        ntp_issue_date_from_date: null,
        ntp_issue_date_to_date: null,
        internal_project_handover_completed: null,
        internal_project_handover_date_from_date: null,
        internal_project_handover_date_to_date: null,
        kick_off_meeting_completed: null,
        kick_off_meeting_date_from_date: null,
        kick_off_meeting_date_to_date: null,
        site_completed_capacity:null,
        site_type_of_tension:null,
      },
      showFilters: false,
      api: null,
      columnDefs: null,
      showGrid: false,
      sideBar: false,
      rowCount: null,
      modules: [
        ClientSideRowModelModule,
        ColumnsToolPanelModule,
        ExcelExportModule,
        FiltersToolPanelModule,
        SparklinesModule,
        GridChartsModule,
        MasterDetailModule,
        MenuModule,
        MultiFilterModule,
        RangeSelectionModule,
        RichSelectModule,
        RowGroupingModule,
        ServerSideRowModelModule,
        SetFilterModule,
        SideBarModule,
        StatusBarModule,
        ViewportRowModelModule,
        ClipboardModule
      ],
      rowData: [],
      statusBar:
          {
            statusPanels: [
              { statusPanel: "agTotalAndFilteredRowCountComponent" },
              {statusPanel: "agTotalRowCountComponent" },
              {statusPanel: "agFilteredRowCountComponent" },
              {statusPanel: "agSelectedRowCountComponent" },
              {statusPanel: "agAggregationComponent" },
            ],
          },
      site_additional_info: null,
      rowProjectData: [],
      rowSiteAddInfoData:[],
      selectedProject:[],
      selectedSite:[],
      site_status:[],
      site_status_index:[],
      contractors:[],
      contractors_index:[],
      module_brands :[],
      module_brands_index:[],
      CAT_Ladder_Status:['Incomplete','Completed','NA'],
      CAT_Ladder_Status_Index : [
        {
          id:0,
          value:'Incomplete',
        },
        {
          id:1,
          value:'Completed',
        },
        {
          id:2,
          value:'NA',
        },

      ],
      SOC_Status:['NO','YES'],
      SOC_Status_Index : [
        {
          id:0,
          value:'NO',
        },
        {
          id:1,
          value:'YES',
        },
      ],
      COPS_Status:['NO','YES'],
      COPS_Status_Index : [
        {
          id:0,
          value:'NO',
        },
        {
          id:1,
          value:'YES',
        },
      ],
      TNC_Status:['NO','YES'],
      TNC_Status_Index : [
        {
          id:0,
          value:'NO',
        },
        {
          id:1,
          value:'YES',
        },
      ],
      Insurance_Status:['Not Receive','Received','NA'],
      Insurance_Status_Index : [
        {
          id:0,
          value:'Not Receive',
        },
        {
          id:1,
          value:'Received',
        },
        {
          id:2,
          value:'NA',
        },

      ],
      filter: {
        developers: [],
        project_managers: [],
        project_type: [],
        project_name:null,
        project_site_statuses:[],
        site_actual_turn_on_date_from_date:null,
        site_actual_turn_on_date_to_date:null,
        project_ppa_sign_date_to_date:null, 
      },
      turn_on_year:2025,
      turn_on_years:[
        {
          turn_on_year_id:2025,
          turn_on_year:"2025",
        },
        {
          turn_on_year_id:2024,
          turn_on_year:"2024",
        },
        {
          turn_on_year_id:2023,
          turn_on_year:"2023",
        },
        {
          turn_on_year_id:2022,
          turn_on_year:"2022",
        },
        {
          turn_on_year_id:2021,
          turn_on_year:"2021",
        },
        {
          turn_on_year_id:2020,
          turn_on_year:"2020",
        },
        {
          turn_on_year_id:9999,
          turn_on_year:"All data",
        },
      ],
      status_bar:
          {
            statusPanels: [
              { statusPanel: "agTotalAndFilteredRowCountComponent" },
              {statusPanel: "agTotalRowCountComponent" },
              {statusPanel: "agFilteredRowCountComponent" },
              {statusPanel: "agSelectedRowCountComponent" },
              {statusPanel: "agAggregationComponent" },
            ],
          },
      site_milestones: [],
      myUserDetail : null,

      project_types: [],
      project_managers: [],
      project_managers_index: [],
      project_engineers: [],
      project_engineers_index: [],
      project_business_development: [],
      project_business_development_index: [],
      project_safety: [],
      project_safety_index: [],
      project_business_development_options: [],
      project_safety_options: [],
      developer_options:[],
      developer_index:[],
      milestone_sg : 
      {
        imported:false,
        mobilization_planned_date : null,
        mobilization_actual_date : null,
        hoisting_planned_date : null,
        hoisting_actual_date : null,
        tc_planned_date : null,
        tc_actual_date : null,
        tiein_planned_date : null,
        tiein_actual_date : null,
        turnon_planned_date : null,
        turnon_actual_date : null,
        pac_planned_date : null,
        pac_actual_date : null,
      }
    }
  },
  
created() {
    const LoginUser = JSON.parse(window.localStorage.getItem("loginUser"));

    this.myUserID = LoginUser;
    var project_site_access = JSON.parse(window.localStorage.getItem("project_site_access"));
    this.obj_project.id_site = '7384';

    var project_site_access = JSON.parse(
    window.localStorage.getItem("project_site_access")
);

},  
  components: {
    AgGridVue,
    HeaderGroupComponent,
  },
  setup() { },
  methods: {
    openPlannerLandingPage()
        {
            this.$router.push('plannerLandingPage');
        },
    openPlannerSiteWebPage()
        {
            this.$router.push('plannerweb');
        },
    openWebPageView()
    {
      this.$router.push('ProjectLandingPage');
    },
    testingLogUser(theUser = 0) {
      switch (theUser) {
        case 1:
          //_ user: planner
          this.obj_user.info = {
            email: "mylinh.bui@sembcorp.com",
            id: 82,
            name: "Bui My Linh",
            role_id: 19
          };
          break;
        case 2:
          //_ user: project manager + leader
          this.obj_user.info = {
            email: "janet.so@sembcorp.com",
            id: 11,
            name: "Janet So",
            role_id: 9
          };
          break;
        case 3:
          //_ user: project manager
          this.obj_user.info = {
            email: "kishen.s@sembcorp.com",
            id: 346,
            name: "Kishen S.",
            role_id: 9
          };
          break;
        case 4:
          //_ user: project manager + leader
          this.obj_user.info = {
            email: "eddie.tan@sembcorp.com",
            id: 4,
            name: "Eddie Tan",
            role_id: 9
          };
          break;
        case 5:
          //_ user: project manager
          this.obj_user.info = {
            email: "robertus.soeyono@sembcorp.com",
            id: 281,
            name: "Robertus Sidiq Soeyono",
            role_id: 9
          };
          break;
        case 6:
          //_ user: project manager
          this.obj_user.info = {
            email: "compe@sembcorp.com",
            id: 218,
            name: "COMPLETED - PE",
            role_id: 9
          };
          break;
        default:
          this.obj_user.info = JSON.parse(window.localStorage.getItem("loginUser"));
      }
    },

    changeYear()
    {
      this.$refs['modal-loading-in-progress'].show();
      this.rowData = [];
      this.getDataToShow();
      this.createColumnDefs();
      this.initiateSubGrid_milestone();
    // this.initiateSubGrid_task();

      this.showGrid = true;
    },

    errorFunction(err,info) {
            this.error = `An error occurred: ${err.message}`;
            console.error(err, info);
            return false; // prevents the error from propagating further
        },

    
        getBusinessDevelopmentListing() {
        axios.get(apiDomain + 'lookup/user_list/Business_Development', { headers: getHeader() })
          .then ( response => {
              if (response.status === 200)
              {
                for (let i = 0; i < response.data.data.length; i++) {
                  this.project_business_development_options.push(response.data.data[i].name);
                  this.project_business_development_index.push({id:response.data.data[i].id,value:response.data.data[i].name})
                }
                //console.log(this.project_business_development_options);
              }
          })
          .catch ( error => {
              console.log(error.response.status)
              this.errorFunction(error, "Business Developer Information")
          } );
      },

    getDataToShow() {
      //_ role planner display all project and sites
      //_ role project manager leader - list all project and site for all project manager under him/her
      //_ role project manager - list all project and site only for his/her own
      // console.log('>>> func getDataToShow');
      // console.log(this.obj_user.info);

      axios.get(apiDomain+'api/get_user_project_developer/'+this.myUserID.id,{headers: getHeader()})
            .then ( response => {
                this.myUserDetail = response.data.data;
                for(let i = 0; i < this.myUserDetail.developers.length; i++)
                {
                  this.filter.developers.push(this.myUserDetail.developers[i].developer.developer_id);
                }
                // console.log('Filter developer');
                // console.log(this.filter.developers);

              })

            .catch(error => {
              this.errorFunction(error, this.title)
            })

            .finally(() => {

              this.filter.project_type.push(1);

              if (this.myUserID.role_id == 19) 
              {
                this.createRowData();
              } else if (this.obj_user.info.role_id == 9) {
                this.obj_user.pm_members.push(this.obj_user.info.id);
                this.getPMLeader();
              } else 
              {
                this.createRowData();
              }
        });
    },
    getPMLeader() {
      //_ check the user is project manager leader or not
      let arr_engineer_group = [];
      axios
        .get(apiDomain + 'setup/project_engineer_group', { headers: getHeader() })
        .then(response => {
          if (response.status === 200) {
            arr_engineer_group = response.data.data;
          }
        })
        .catch(error => {
          this.errorFunction(error, "Project Engineer Groups Listing")
        })
        .finally(() => {
          // console.log('>>> arr_engineer_group');
          // console.log(arr_engineer_group);

          //_ simplify using filter
          let tmpID = this.obj_user.info.id;
          
          var arr_filtered = arr_engineer_group.filter(function (value, index, arr) {
            return value.engineer_group_leader.id === tmpID;
          });

          // if ( arr_engineer_group.length > 0 ) {
          if (arr_filtered.length > 0) {
            this.obj_user.is_pm_leader = true;
            this.obj_user.pm_group_id = arr_filtered[0].engineer_group_id;
          }

          if (this.obj_user.is_pm_leader) 
          {
            this.getPMMembers();
          } 
          else 
          {
            this.filter.project_managers.push(this.obj_user.info.id);
            this.createRowData();
          }
        });
    },

    getPMMembers() {
      //_ get project members
      let arr_pm_members = [];
      axios
        .get(apiDomain + 'setup/project_engineer_group.show_by_group/' + this.obj_user.pm_group_id, { headers: getHeader() })
        .then(response => {
          if (response.status === 200) {
            arr_pm_members = response.data.data.map((item) => {
              return item.engineer_group_member.id;
            });
          }
        })
        .catch(error => {
          console.log(error.response.status)
          this.errorFunction(error, "Project Engineer Group Member")
        })
        .finally(() => {
          this.obj_user.pm_members = this.obj_user.pm_members.concat(arr_pm_members);
          this.filter.project_managers = this.obj_user.pm_members;
          this.createRowData();
        });
    },

    getContractorListing() {
                axios.get(apiDomain + 'setup/contractor', { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                              for(let i = 0;i<response.data.data.length;i++)
                              {
                                if(response.data.data[i].active_status == 'ACTIVE' && response.data.data[i].contractor_name.includes('UAM') == false && response.data.data[i].contractor_name.includes('CCP3') == false)
                                {

                                  this.contractors.push(response.data.data[i].contractor_name);
                                  this.contractors_index.push({id:response.data.data[i].contractor_id,value:response.data.data[i].contractor_name});                                
                              
                                }
                              }
                        }
                    })
                    
                    .catch ( error => {
                        console.log(error.response.status)
                    })

                    .finally(() => {
                      //console.log("EPC:");
                      //console.log(this.contractors);
                      //console.log(this.contractors_index);
                    })                    
            },

    getProjectSiteStatusListing() 
    {
                axios.get(apiDomain + 'lookup/site_status.singapore_v2', { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            for(let i = 0;i<response.data.data.length;i++)
                            {
                              this.site_status.push(response.data.data[i].status_code);
                              this.site_status_index.push({id:response.data.data[i].status_id,value:response.data.data[i].status_code});                                
                            }
                            // console.log("SITE STATUS");
                            // console.log(this.site_status);
                            // console.log(this.site_status_index);
                        }
                    })
                    .catch ( error => {
                        if (typeof error.response !== "undefined") {
                            this.errorFunction(error, "Site Status Information")
                        }
                    } );
    },

    getProjectManagerListing() {
          axios.get(apiDomain + 'lookup/user_list/Project_Manager', { headers: getHeader() })
              .then(response => {
                  if (response.status === 200) {
                      for (let i = 0; i < response.data.data.length; i++) {
                          this.project_managers.push(response.data.data[i].name);
                          this.project_managers_index.push({id:response.data.data[i].id,value:response.data.data[i].name})
                      }
                      // console.log("Project Managers");
                      // console.log(this.project_managers_index);
                  }
              })
              .catch(error => {
                  console.log(error.response.status)
                  this.errorFunction(error, "Project Manager")
              });
      },

          //01.05 GET ENGINEER LIST
          getProjectEngineerListing() {
          axios.get(apiDomain + 'lookup/user_list/Engineer', { headers: getHeader() })
              .then(response => {
                  if (response.status === 200) {
                      for (let i = 0; i < response.data.data.length; i++) {
                          this.project_engineers.push(response.data.data[i].name);
                          this.project_engineers_index.push({id:response.data.data[i].id,value:response.data.data[i].name});
                      }
                      //console.log(this.project_engineers);
                  }
              })
              .catch(error => {
                  console.log(error.response.status)
                  this.errorFunction(error, "Engineer Information")
              });
      },

      //01.06 GET SAFETY OFFICER LIST
      getSafetyOfficerListing() {
        axios.get(apiDomain + 'lookup/user_list/Safety', { headers: getHeader() })
        .then ( response => {
            if (response.status === 200)
            {
              this.project_safety = response.data.data;
              for (let i = 0; i < this.project_safety.length; i++) {
                this.project_safety_options.push(this.project_safety[i].name);
                this.project_safety_index.push({id:response.data.data[i].id,value:response.data.data[i].name});
              }
              //console.log(this.project_safety_options);
            }
        })
        .catch ( error => {
            console.log(error.response.status)
            this.errorFunction(error, "Safety Officer Information")
        } );
      },

    getModuleBrandListing(){
                axios.get(apiDomain + "setup/module_brand", { headers: getHeader() })
                    .then((response) => {
                        if (response.status === 200) {
                            for(let i = 0;i<response.data.data.length;i++)
                            {
                              this.module_brands.push(response.data.data[i].module_brand_id_code);
                              this.module_brands_index.push({id:response.data.data[i].module_brand_id,value:response.data.data[i].module_brand_id_code,other:response.data.data[i].watt_class});                                
                            }
                            // console.log("Module Brand");
                            // console.log(this.module_brands);
                            // console.log(this.module_brands_index);
                        }
                    })
                    .catch((error) => {
                        this.errorFunction(error, "Module Brand Information");
                    });
            },

    testingFilter() {
      //console.log('testingFilter');
      //console.log(this.obj_user);

      this.filter.project_managers = this.obj_user.pm_members;
      // this.filter.project_name = 'sharp';

      //console.log(this.filter);

      //_ project listing
      // axios.post(apiDomain + "project/get_project_listing", this.filter, { headers: getHeader() })
      // .then(response => {
      //     if (response.status === 200) {
      //         console.log('getDataToShow');
      //         console.log(response);
      //     }
      // })
      // .catch(error => {
      //     this.errorFunction(error, "Search Project Site Listing")
      // });

      //_ site listing
      axios
        .post('project/project_site.show_by_filter', this.filter, { headers: getHeader(), })
        .then(response => {
          this.rowData = response.data.data
          //console.log(this.rowData);
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },    

    //_ original code
    createRowData() {
      //console.log('createRowData');

      //this.model.project_name = 'repo';
      this.filter.project_site_statuses = [];
      this.filter.project_site_statuses.push(2);
      this.filter.project_site_statuses.push(8);
      this.filter.project_site_statuses.push(9);
      this.filter.project_site_statuses.push(10);
      this.filter.project_site_statuses.push(11);
      this.filter.project_site_statuses.push(12);
      this.filter.project_site_statuses.push(13);
      this.filter.project_site_statuses.push(14);
      this.filter.project_site_statuses.push(15);
      this.filter.site_actual_turn_on_date_from_date = null;
      this.filter.site_actual_turn_on_date_to_date = null;    
      this.filter.project_ppa_sign_date_to_date = this.turn_on_year + "-12-31"
      axios
        .post('project/project_site.show_by_filter', this.filter, {
        headers: getHeader(),
        })
        .then(response => {
          this.rowData = response.data.data
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
        .finally(() => {
          this.createRowDataTurnOn();
        })
    },

    createRowDataTurnOn()
    {
      this.filter.project_site_statuses = [];
      this.filter.project_site_statuses.push(16);
      this.filter.project_site_statuses.push(17);
      if(this.turn_on_year==9999)
      {
        this.filter.site_actual_turn_on_date_from_date = null;
        this.filter.site_actual_turn_on_date_to_date = null;
      }
      else
      {
        this.filter.site_actual_turn_on_date_from_date = this.turn_on_year + "-01-01";
        this.filter.site_actual_turn_on_date_to_date = this.turn_on_year + "-12-31";
      }
      this.filter.project_ppa_sign_date_to_date = null;      
      axios
        .post('project/project_site.show_by_filter', this.filter, {
        headers: getHeader(),
        })
        .then(response => {
          for(let i = 0; i< response.data.data.length; i++)
          {
            this.rowData.push(response.data.data[i]);
          }
        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
        .finally(() => {
          this.getMilestone();
        })
    },

    getMilestone()
    {
      //console.log('TOTAL RECORD');
      //console.log(this.newrowData.length);
            
      for(let i=0; i < this.rowData.length; i++)
        { 
          //console.log("musk sini : i");
          axios.get(apiDomain + 'project/project_site_milestone.show_by_site/' + this.rowData[i].site_id, { headers: getHeader() })
            .then ( result => {
                if (result.status === 200)
                {
                    //console.log(result.data.data[0].contractor.contractor_name);
                    //this.contractor = result.data.data[0].contractor.contractor_name;
                    //this.rowData[i] = {...this.rowData[i], milestone_tasks:result.data.data};
                    if(this.rowData[i].site_contractor==null) 
                    {
                        if(result.data.data.length > 0)
                        {
                          this.rowData[i] = {...this.rowData[i], 
                          milestone_tasks:result.data.data,
                          EPC_Name:'',
                          EPC_ID:'',
                          milestone_sg:true,
                          mobilization_planned_date:result.data.data[0].planned_end_date,
                          mobilization_actual_date:result.data.data[0].actual_end_date,
                          hoisting_planned_date:result.data.data[1].planned_end_date,
                          hoisting_actual_date:result.data.data[1].actual_end_date,
                          tc_planned_date:result.data.data[2].planned_end_date,
                          tc_actual_date:result.data.data[2].actual_end_date,
                          tiein_planned_date:result.data.data[3].planned_end_date,
                          tiein_actual_date:result.data.data[3].actual_end_date,
                          turnon_planned_date:result.data.data[4].planned_end_date,
                          turnon_actual_date:result.data.data[4].actual_end_date,
                          pac_planned_date:result.data.data[5].planned_end_date,
                          pac_actual_date:result.data.data[5].actual_end_date}
                        }
                        else
                        {
                          this.rowData[i] = {...this.rowData[i], 
                          milestone_tasks:result.data.data,
                          EPC_Name:'',
                          EPC_ID:'',
                          milestone_sg:false,
                          mobilization_planned_date:null,
                          mobilization_actual_date:null,
                          hoisting_planned_date:null,
                          hoisting_actual_date:null,
                          tc_planned_date:null,
                          tc_actual_date:null,
                          tiein_planned_date:null,
                          tiein_actual_date:null,
                          turnon_planned_date:null,
                          turnon_actual_date:null,
                          pac_planned_date:null,
                          pac_actual_date:null}
                        }

                    }
                    else
                    {
                      if(result.data.data.length > 0)
                      {
                        this.rowData[i] = {...this.rowData[i], milestone_tasks:result.data.data,
                        EPC_Name:this.rowData[i].site_contractor.contractor.contractor_name,
                        EPC_ID:this.rowData[i].site_contractor.contractor.contractor_id,
                        milestone_sg:true,
                        mobilization_planned_date:result.data.data[0].planned_end_date,
                        mobilization_actual_date:result.data.data[0].actual_end_date,
                        hoisting_planned_date:result.data.data[1].planned_end_date,
                        hoisting_actual_date:result.data.data[1].actual_end_date,
                        tc_planned_date:result.data.data[2].planned_end_date,
                        tc_actual_date:result.data.data[2].actual_end_date,
                        tiein_planned_date:result.data.data[3].planned_end_date,
                        tiein_actual_date:result.data.data[3].actual_end_date,
                        turnon_planned_date:result.data.data[4].planned_end_date,
                        turnon_actual_date:result.data.data[4].actual_end_date,
                        pac_planned_date:result.data.data[5].planned_end_date,
                        pac_actual_date:result.data.data[5].actual_end_date}
                      }
                      else
                      {
                        this.rowData[i] = {...this.rowData[i], milestone_tasks:result.data.data,
                        EPC_Name:this.rowData[i].site_contractor.contractor.contractor_name,
                        EPC_ID:this.rowData[i].site_contractor.contractor.contractor_id,
                        milestone_sg:false,
                        mobilization_planned_date:null,
                        mobilization_actual_date:null,
                        hoisting_planned_date:null,
                        hoisting_actual_date:null,
                        tc_planned_date:null,
                        tc_actual_date:null,
                        tiein_planned_date:null,
                        tiein_actual_date:null,
                        turnon_planned_date:null,
                        turnon_actual_date:null,
                        pac_planned_date:null,
                        pac_actual_date:null}
                      }
                    }
                    this.api.updateGridOptions({rowData:this.rowData});
     
                }
            })
            .catch ( error => {
                if (typeof error.response !== "undefined") {
                    this.errorFunction(error, "Site Contractor Information")
                }
            })
            
            .finally(() => {
              if(i== this.rowData.length-1)
              {
                this.createProjectRowData();
                this.createSiteAddedInfo();
                //console.log(this.rowProjectData);
              }
            })
            
        }       
    },    

    async createSiteAddedInfo()
    {
      try {
         const response = await axios.post('project/get_project_site_additional_info_listing_with_filter',this.filter,
          {
            headers: getHeader(),
          });

        this.rowSiteAddInfoData = response.data.data;
        //console.log("Site Added Data loaded:", this.rowSiteAddInfoData);

        }

        catch (error) {
         this.errorFunction(error, this.title);
       } 
    },

    async createProjectRowData() 
    {
        try {
         const response = await axios.post('project/get_project_listing',this.filter,
          {
            headers: getHeader(),
          });

        const data = response.data.data;
        //console.log("Data loaded:", data);

        this.rowProjectData = [];
        if (data && data.length > 0) {
          //console.log("Processing data...");

          // Process each item in the data array
          for (let i = 0; i < data.length; i++) {
            let parsedValue;

            const item = data[i];
              // Check and convert project_total_capacity field
              if (typeof item.project_ppa_approved_capacity === 'string') {
                // Remove commas and parse to number
                parsedValue = Number(item.project_ppa_approved_capacity.replace(/,/g, ''))/1000;
              } else {
                parsedValue = Number(item.project_ppa_approved_capacity)/1000;
              }

              // Ensure parsedValue is a valid number
              if (isNaN(parsedValue)) {
                parsedValue = 0; // Default to 0 if NaN
              }

              parsedValue = Number(parsedValue.toFixed(3));
              // Add processed item to rowData
              this.rowProjectData.push({
                ...item,
                project_ppa_approved_capacity: parsedValue  // Round and set the integer value
              });
          }

        //console.log("Final rowData:", this.rowProjectData);
        } 
         this.getProjectInfo()
        }
         
       catch (error) {
         this.errorFunction(error, this.title);
       } 
      },


      async getSiteAdditionalInfo(tempsiteID)
      {
        try {
         const response = await axios.get('project/project_site_additional_info/' + tempsiteID,
            {
                headers: getHeader(),
            });

            this.site_additional_info = response.data.data;
            //console.log ("Additional Info : " + tempsiteID);
            //console.log (this.site_additional_info)
        }

        catch (error) {
            this.errorFunction(error, this.title);
        }
      },

    getProjectInfo()
    {
      var temp_CAT_Ladder_Status_Index = 2;  
      var temp_Insurance_Status_Index = 2;
      var temp_SOC_Status_Index = 0;
      var temp_COPS_Status_Index = 0;
      var temp_TNC_Status_Index = 0;      
      for(let i=0; i < this.rowData.length; i++)
        { 
          //site_completed_document
          if(this.rowData[i].site_total_document < 3)
          {
            temp_CAT_Ladder_Status_Index = this.rowData[i].site_total_document;
          }
          if(this.rowData[i].site_completed_document < 3 && !isNull(this.rowData[i].site_completed_document))
          {
            temp_Insurance_Status_Index = this.rowData[i].site_completed_document;
          }
          if(this.rowData[i].site_completed_task < 2 && !isNull(this.rowData[i].site_completed_task))
          {
            temp_SOC_Status_Index = this.rowData[i].site_completed_task;
          }
          //console.log('Project ID :' + this.rowData[i].project.project_id + ' and Temp Insurance Status' + temp_Insurance_Status_Index);
          this.selectedProject = this.rowProjectData.filter(x=> x.project_id == this.rowData[i].project.project_id)
          if(this.selectedProject.length > 0)
          {
            //this.getSiteAdditionalInfo(this.rowData[i].site_id);
            this.rowData[i] = {...this.rowData[i], 
              project_ppa_sign_date:this.selectedProject[0].project_ppa_start_date,
              project_project_engineer:this.selectedProject[0].project_engineer,
              project_project_business_development:this.selectedProject[0].project_business_development,
              project_project_safety:this.selectedProject[0].project_safety,
              project_ppa_start_date : this.selectedProject[0].project_ppa_start_date,
              project_ppa_approved_capacity : this.selectedProject[0].project_ppa_approved_capacity,
              project_developer : this.selectedProject[0].developer,
              project_type : this.selectedProject[0].project_type,
              project_ppa_start_date : this.selectedProject[0].project_ppa_start_date,
              project_ppa_approved_capacity : this.selectedProject[0].project_ppa_approved_capacity,
              site_CAT_Ladder_Status : this.CAT_Ladder_Status[temp_CAT_Ladder_Status_Index],
              site_CAT_Ladder_Status_Index : temp_CAT_Ladder_Status_Index,
              site_Insurance_Status : this.Insurance_Status[temp_Insurance_Status_Index],
              site_Insurance_Status_Index : temp_Insurance_Status_Index,
              site_SOC_Status : this.SOC_Status[temp_SOC_Status_Index],
              site_SOC_Status_Index : temp_SOC_Status_Index,
            }
          }

          this.selectedSite = this.rowSiteAddInfoData.filter(x=> x.site_id == this.rowData[i].site_id)
          if(this.selectedSite.length > 0)
          {
            if(this.selectedSite[0].cops_tnc_checked < 2)
            {
              temp_COPS_Status_Index = this.selectedSite[0].cops_tnc_checked;
            }
            if(this.selectedSite[0].cops_tnc_issue < 2)
            {
              temp_TNC_Status_Index = this.selectedSite[0].cops_tnc_issue;
            }
            this.rowData[i] = {...this.rowData[i],
            site_add_info_project_site_additional_info_id : this.selectedSite[0].project_site_additional_info_id,
            site_add_info_kick_off_meeting_date : this.selectedSite[0].kick_off_meeting_date,
            site_add_info_NTP_issue_date : this.selectedSite[0].ntp_issue_date,
            site_add_info_onm_handover_remarks : this.selectedSite[0].onm_handover_remarks,
            site_add_info_cops_status : this.COPS_Status[temp_COPS_Status_Index],
            site_add_info_tnc_status : this.TNC_Status[temp_TNC_Status_Index],
            site_add_info_internal_project_handover_date : this.selectedSite[0].internal_project_handover_date,
            site_add_info_cops_tnc_remarks : this.selectedSite[0].cops_tnc_remarks,
            site_add_info_onm_handover_remarks : this.selectedSite[0].onm_handover_remarks,
            site_add_info_roof_handover_date : this.selectedSite[0].roof_handover_date,
            site_add_info_turn_on_checking_approval_date : this.selectedSite[0].turn_on_checking_approval_date,
            site_add_info_pv1_letter_date : this.selectedSite[0].pv1_letter_date,
            site_add_info_pv2_letter_date : this.selectedSite[0].pv2_letter_date,
            site_add_info_maa_submission_date : this.selectedSite[0].maa_submission_date,
            site_add_info_maa_approval_date : this.selectedSite[0].maa_approval_date,
            site_add_info_pc_planned_date : this.selectedSite[0].pc_planned_date,
            site_add_info_pc_status : this.selectedSite[0].pc_status,
            site_add_info_pso_submission_date : this.selectedSite[0].pso_submission_date,
            site_add_info_pso_approval_date : this.selectedSite[0].pso_approval_date,
            site_add_info_igf_submission_date : this.selectedSite[0].igf_submission_date,
            site_add_info_igf_approval_date : this.selectedSite[0].igf_approval_date,
            site_add_info_sa_received_date : this.selectedSite[0].sa_received_date,
            site_add_info_sa_status : this.selectedSite[0].sa_status,
            site_add_info_planning_start_date : this.selectedSite[0].planning_start_date,
            site_add_info_planning_remarks : this.selectedSite[0].planning_remarks,
            site_add_info_pb_start_date : this.selectedSite[0].pb_start_date,
            site_add_info_pb_end_date : this.selectedSite[0].pb_end_date,
            site_add_info_pb_remarks : this.selectedSite[0].pb_remark,
            site_add_info_cs1_submission_date : this.selectedSite[0].cs1_submission_date,
            site_add_info_cs1_submission_app_number : this.selectedSite[0].cs1_submission_app_number,
            site_add_info_cs1_submission_approval_number : this.selectedSite[0].cs1_submission_approval_number,
            site_add_info_cod_formula_date : this.selectedSite[0].cod_formula_date,
            }
          }
        }

        const sortrowData = this.rowData
        .sort((a, b) => new Date(b.project_ppa_start_date) - new Date(a.project_ppa_start_date));
        
        //console.log("FINAL Data");
        // console.log (sortrowData);    
        this.rowData = sortrowData;
        this.api.updateGridOptions({rowData:this.rowData});
        
        //console.log(this.rowData);
        this.$refs['modal-loading-in-progress'].hide();                   
    },    


    searchData() {
      axios.post('project/project_site.show_by_filter', this.model, {
        headers: getHeader(),
      })
        .then(response => {
          this.rowData = response.data.data

        })
        .catch(error => {
          this.errorFunction(error, this.title)
        })
    },



  //01.01 BUILD THE GRID - CONFIGURE THE COLUMN
  createColumnDefs() {
      this.columnDefs = [
        {
          headerName: '#', minWidth: 60, width: 60, checkboxSelection: true, sortable: false,
          headerClass: 'GeneralDetails',
          pinned: true, suppressHeaderMenuButton: true,
          cellClass:'ProjectColumn'
        },
        {
          headerName: "Site Name",
                headerClass: 'GeneralDetails',
                field: "site_name",
                //flex: 1,
                width: 250,
                floatingFilter: true,
                cellRenderer: "agGroupCellRenderer",
                pinned:true,
                cellClass:'ProjectColumn',
                enableRowGroup: true,
                enableValue:true,
        },
        {
          headerName: 'General Details',
          headerGroupComponent: 'HeaderGroupComponent',
          headerClass: 'GeneralDetails', 
          children: [
              {
                headerName: "Developer",
                headerClass: 'GeneralDetails',
                field: "project_developer.developer_name",
                width: 250,
                floatingFilter: true,
                enableRowGroup: true,
                enableValue:true,
                hide:true,
              },
              {
                headerName: "Project Name",
                headerClass: 'GeneralDetails',
                field: "project.project_name",
                width: 300,
                editable: false,
                hide:true,
              },
              { headerName: "Address",headerClass: 'GeneralDetails',field: "site_address", width: 150, floatingFilter: true, suppressHeaderMenuButton: true,editable: true},
              { headerName: "Postal Code",headerClass: 'GeneralDetails',field: "site_postal_code", width: 150, floatingFilter: true, suppressHeaderMenuButton: true,editable: true,},
          ]
        },
        {
          headerName: 'Key Stakeholders',
          headerGroupComponent: 'HeaderGroupComponent',
          headerClass: "KeyStakeholders", 
          children: [
          { 
                headerName: "BD",
                headerClass : "KeyStakeholders",
                field: "project_project_business_development.name", 
                width: 150, 
                floatingFilter: true, 
                suppressHeaderMenuButton: true,
                cellEditor: "agSelectCellEditor", 
                cellEditorParams: { values: this.project_business_development_options, valueListMaxHeight: 150 },
                enableValue:true,
                enableRowGroup:true,
                filter: 'agSetColumnFilter',
                editable:true,   
          },
          { 
                headerName: "Project Engineer",
                headerClass : "KeyStakeholders",
                field: "project.project_manager.name", 
                width: 150, 
                floatingFilter: true, 
                suppressHeaderMenuButton: true,
                cellEditor: "agSelectCellEditor", 
                cellEditorParams: { values: this.project_managers, valueListMaxHeight: 150 },
                enableValue:true,
                enableRowGroup:true,
                filter: 'agSetColumnFilter',
                editable:true,   
 
          },
          { 
                headerName: "Team Lead",
                headerClass : "KeyStakeholders",
                field: "", 
                width: 150, 
                floatingFilter: true, 
                suppressHeaderMenuButton: true,
                hide:true,
                cellClass: { backgroundColor: '#c9daf8' }, 
          },
          { 
              headerName: "EPC", 
              headerClass : "KeyStakeholders",
              field: "EPC_Name", 
              width: 150, suppressHeaderMenuButton:true,
              floatingFilter: true,
              suppressHeaderMenuButton: true,
              cellEditor: "agSelectCellEditor", 
              cellEditorParams: { values: this.contractors,valueListMaxHeight: 150, }, 
              filter: 'agSetColumnFilter',
              enableValue:true,
              enableRowGroup:true, 
              editable: true, 
            },
          { 
                headerName: "Engineering",
                headerClass : "KeyStakeholders",
                field: "project_project_engineer.name", 
                width: 150, 
                floatingFilter: true, 
                suppressHeaderMenuButton: true,
                cellEditor: "agSelectCellEditor", 
                cellEditorParams: { values: this.project_engineers,valueListMaxHeight: 150, }, 
                filter: 'agSetColumnFilter',
                enableValue:true,
                enableRowGroup:true,
                editable: true,
          },
          { 
                headerName: "Safety",
                headerClass : "KeyStakeholders",
                field: "project_project_safety.name", 
                width: 150, 
                floatingFilter: true, 
                suppressHeaderMenuButton: true,
                cellEditor: "agSelectCellEditor", 
                cellEditorParams: { values: this.project_safety_options ,valueListMaxHeight: 150, }, 
                enableValue:true,
                enableRowGroup:true,
                editable: true,
              
          },

        ]
        },
        {
          headerName: 'PPA Key Info',
          headerClass : "PPAKeyInfo",
          children: [
          {
                  headerName: "PPA Sign Date",
                  headerClass : "PPAKeyInfo", 
                  field: "project_ppa_start_date", 
                  width: 150,
                  suppressHeaderMenuButton: true,
                  enableRowGroup:true,
                  cellRenderer: (data) => { return (data.value) ? moment(data.value).format('DD MMM YYYY') : '' }
          },
          {
                  headerName: "Capacity(MWp)", 
                  headerClass : "PPAKeyInfo",
                  field: "project_ppa_approved_capacity", 
                  type: 'rightAligned', 
                  width: 150, 
                  suppressHeaderMenuButton: true,
                  editable: true, 
                  cellClass: 'agColumnEditable ag-right-aligned-cell',
                  enableValue: true,
          },
          { 
              headerName: "Roof Handover Date",
              headerClass : "PPAKeyInfo",
              children:[
                {
                  headerName: "Planned",
                  headerClass : "PPAKeyInfo",
                  field: "site_contractor_start_date", 
                  width: 150, 
                  editable: true, 
                  cellDataType: 'dateString',
                  cellEditor: "agDateStringCellEditor",
                  cellRenderer: (params) => { return this.format_datetime(params.data.site_contractor_start_date) },
                },
                {
                  headerName: "Actual",
                  headerClass : "PPAKeyInfo",
                  field: "site_contractor_end_date", 
                  width: 150, 
                  editable: true, 
                  cellDataType: 'dateString',
                  cellEditor: "agDateStringCellEditor",
                  cellRenderer: (params) => { return this.format_datetime(params.data.site_contractor_end_date) },
                }
              ]
          },
          { 
              headerName: "CODFormula",
              headerClass : "PPAKeyInfo",
              field: "site_el_expiry_datee", 
              cellDataType: 'dateString',
              width: 150, 
              suppressHeaderMenuButton: true,
              editable:false,
              cellRenderer: (params) => { return this.format_datetime(params.data.site_el_expiry_date) }, 
          },
          { 
              headerName: "COD",
              headerClass : "PPAKeyInfo",
              cellDataType: 'dateString',
              field: "site_cod_date", 
              width: 150, 
              suppressHeaderMenuButton: true,
              editable:true,
              cellRenderer: (params) => { return this.format_datetime(params.data.site_cod_date) }, 
          },
          { 
              headerName: "COD Remarks",
              headerClass : "CODRemarks",
              field: "site_plant_name", 
              width: 150, 
              editable:true,
              suppressHeaderMenuButton: true,
          },

        ]
        },
        {
          headerName: 'Technical Details',
          headerClass : "TechnicalDetails",
          children: [
          { 
              headerName: "Number of Module",
              headerClass : "TechnicalDetails",
              field: "site_module_quantity", 
              width: 150, 
              floatingFilter: true, 
              suppressHeaderMenuButton: true,
              cellRenderer: data => {
                return Math.floor(data.value);
              },
              editable:true,
              hide:false,
          },
          { 
              headerName: "Brand", 
              field: "site_module_brand.module_brand_id_name", 
              headerClass : "TechnicalDetails",
              width: 150, 
              floatingFilter: true, 
              suppressHeaderMenuButton: true,
              cellEditor: "agSelectCellEditor", 
              cellEditorParams: { values: this.module_brands,valueListMaxHeight: 150, }, 
              filter: 'agSetColumnFilter', 
              editable: true, 
              hide:false,
          },
          { 
              headerName: "Brand Remarks",
              headerClass : "CODRemarks",
              field: "site_ifc_ifa_module_remarks", 
              width: 150, 
              suppressHeaderMenuButton: true,
              editable:true,
          },
          { 
              headerName: "Watt Class (Wp)",
              headerClass : "TechnicalDetails",
              field: "site_module_capacity", 
              width: 150, 
              suppressHeaderMenuButton: true,
              editable:false,
          },
          {
              headerName: "Capacity(kWp)",
              headerClass : "TechnicalDetails", 
              field: "site_module_allocation_total_capacity", 
              width: 150, 
              floatingFilter: true, 
              suppressHeaderMenuButton: true,
              editable:false,
          },
          {
              headerName: "Capacity(kWAC)", 
              headerClass : "TechnicalDetails",
              field: "site_completed_capacity", 
              width: 150, 
              floatingFilter: true,
              editable:true, 
              suppressHeaderMenuButton: true,
          },
          { 
              headerName: "Electricity Supply",
              headerClass : "TechnicalDetails",
              field: "site_type_of_tension", 
              width: 150, 
              suppressHeaderMenuButton: true,
              editable:true,
          },
          { 
              headerName: "Metering Scheme",
              headerClass : "TechnicalDetails",
              field: "site_approved_load", 
              width: 150, 
              suppressHeaderMenuButton: true,
              editable:true,
          },
          { 
              headerName: "Contestability Status",
              headerClass : "TechnicalDetails",
              field: "site_mssl_account_number", 
              width: 150, 
              suppressHeaderMenuButton: true,
              editable:true,
          },
          ]
        },
        {
          headerName: 'QP Infor',
          headerClass : "QPInfo",
          children: [
          { 
              headerName: "PV",
              headerClass : "QPInfo",
              field: "site_folder_in_sftp_server", 
              width: 150, 
              suppressHeaderMenuButton: true,
              editable:true,
          },
          { 
              headerName: "BLEW",
              headerClass : "QPInfo",
              field: "site_monitoring_application", 
              width: 150, 
              suppressHeaderMenuButton: true,
              editable:true,
          },
          { 
              headerName: "Structural",
              headerClass : "QPInfo",
              field: "site_elu_license_number", 
              width: 150, 
              suppressHeaderMenuButton: true,
              editable:true,
          },
          ]
        },
            {
              headerName: 'Authorities Submissions/Approval Date',
              headerClass : "AuthoritiesSubmission",
              children:[
                {
                  headerName : 'CS1 PV1 and/or PV2',
                  headerClass : 'AuthoritiesSubmission',
                  children :
                  [
                    {
                      headerName: "CS1 Submission Date",
                      headerClass : "AuthoritiesSubmission",
                      field: "site_add_info_cs1_submission_date", 
                      cellDataType: 'dateString',
                      width: 150, 
                      suppressHeaderMenuButton: true,
                      editable:true,
                      cellRenderer: (params) => { return this.format_datetime(params.data.site_add_info_cs1_submission_date) },
                    },
                    {
                      headerName: "Application Number",
                      headerClass : "AuthoritiesSubmission",
                      field: "site_add_info_cs1_submission_app_number", 
                      width: 150, 
                      suppressHeaderMenuButton: true,
                      editable:true,
                    },
                    {
                      headerName: "Approval Number",
                      headerClass : "AuthoritiesSubmission",
                      field: "site_add_info_cs1_submission_approval_number", 
                      width: 150, 
                      suppressHeaderMenuButton: true,
                      editable:true,
                    },
                    {
                      headerName: "PV1 Letter Date",
                      headerClass : "AuthoritiesSubmission",
                      cellDataType: 'dateString',
                      field: "site_add_info_pv1_letter_date", 
                      width: 150, 
                      suppressHeaderMenuButton: true,
                      editable:true,
                      cellRenderer: (params) => { return this.format_datetime(params.data.site_add_info_pv1_letter_date) },
                    },
                    {
                      headerName: "PV2 Letter Date",
                      headerClass : "AuthoritiesSubmission",
                      cellDataType: 'dateString',
                      field: "site_add_info_pv2_letter_date", 
                      width: 150, 
                      suppressHeaderMenuButton: true,
                      editable:true,
                      cellRenderer: (params) => { return this.format_datetime(params.data.site_add_info_pv2_letter_date) },
                    },
                  ]

                },
                {
                  headerName : 'SSN/non-SSN Meter',
                  headerClass :'TechnicalDetails',
                  field: "site_service_location_id", 
                  width: 150, 
                  suppressHeaderMenuButton: true,
                  editable:true,
                },
                {
                  headerName : 'MAA - SCDF IPA',
                  headerClass : 'AuthoritiesSubmission',
                  children:
                  [
                    {
                      headerName: "MAA Submission Date",
                      headerClass : "AuthoritiesSubmission",
                      cellDataType: 'dateString',
                      field: "site_add_info_maa_submission_date", 
                      width: 150, 
                      suppressHeaderMenuButton: true,
                      editable:true,
                      cellRenderer: (params) => { return this.format_datetime(params.data.site_add_info_maa_submission_date) },
                    },
                    {
                      headerName: "MAA Approval Date",
                      headerClass : "AuthoritiesSubmission",
                      cellDataType: 'dateString',
                      field: "site_add_info_maa_approval_date", 
                      width: 150, 
                      suppressHeaderMenuButton: true,
                      editable:true,
                      cellRenderer: (params) => { return this.format_datetime(params.data.site_add_info_maa_approval_date) },
                    }
                  ]
                },
                {
                  headerName : 'Pre-turn on 7 days PQ (on site meter measurement)',
                  headerClass : 'AuthoritiesSubmission',
                  children :
                  [
                  {
                      headerName: "Planned Date (3months before turn on)",
                      headerClass : "AuthoritiesSubmission",
                      cellDataType: 'dateString',
                      field: "site_add_info_pc_planned_date", 
                      width: 150, 
                      suppressHeaderMenuButton: true,
                      editable:true,
                      cellRenderer: (params) => { return this.format_datetime(params.data.site_add_info_pc_planned_date) },
                    }, 
                    {
                      headerName : 'Status(Completed/On-going)',
                      headerClass :'AuthoritiesSubmission',
                      field: "site_add_info_pc_status", 
                      width: 150, 
                      suppressHeaderMenuButton: true,
                      editable:true,
                    },                   
                  ]
                },
                {
                  headerName : 'PSO (7-day availbility)',
                  headerClass: 'AuthoritiesSubmission',
                  children:
                  [
                  {
                      headerName: "PSO Submission Date",
                      headerClass : "AuthoritiesSubmission",
                      cellDataType: 'dateString',
                      field: "site_add_info_pso_submission_date", 
                      width: 150, 
                      suppressHeaderMenuButton: true,
                      editable:true,
                      cellRenderer: (params) => { return this.format_datetime(params.data.site_add_info_pso_submission_date) },
                    },
                    {
                      headerName: "PSO Approval Date",
                      headerClass : "AuthoritiesSubmission",
                      cellDataType: 'dateString',
                      field: "site_add_info_pso_approval_date", 
                      width: 150, 
                      suppressHeaderMenuButton: true,
                      editable:true,
                      cellRenderer: (params) => { return this.format_datetime(params.data.site_add_info_pso_approval_date) },
                    },
                  ]
                },
                {
                  headerName : 'EMA PSO (IGF Approval)',
                  headerClass: 'AuthoritiesSubmission',
                  children:
                  [
                  {
                      headerName: "IGF Submission Date",
                      headerClass : "AuthoritiesSubmission",
                      cellDataType: 'dateString',
                      field: "site_add_info_igf_submission_date", 
                      width: 150, 
                      suppressHeaderMenuButton: true,
                      editable:true,
                      cellRenderer: (params) => { return this.format_datetime(params.data.site_add_info_igf_submission_date) },
                    },
                    {
                      headerName: "IGF Approval Date",
                      headerClass : "AuthoritiesSubmission",
                      cellDataType: 'dateString',
                      field: "site_add_info_igf_approval_date", 
                      width: 150, 
                      suppressHeaderMenuButton: true,
                      editable:true,
                      cellRenderer: (params) => { return this.format_datetime(params.data.site_add_info_igf_approval_date) },
                    },
                  ]
                },
                {
                  headerName : 'SA (if applicable)',
                  headerClass: 'AuthoritiesSubmission',
                  children:
                  [
                  {
                      headerName: "SA Receive Date",
                      headerClass : "AuthoritiesSubmission",
                      cellDataType: 'dateString',
                      field: "site_add_info_sa_received_date", 
                      width: 150, 
                      suppressHeaderMenuButton: true,
                      editable:true,
                      cellRenderer: (params) => { return this.format_datetime(params.data.site_add_info_sa_received_date) },
                    },
                    {
                      headerName: "Status (Completed/On-going)",
                      headerClass : "AuthoritiesSubmission",
                      field: "site_add_info_sa_status", 
                      width: 150, 
                      suppressHeaderMenuButton: true,
                      editable:true,
                    },
                  ]
                },
              ]
            },
        { 
          headerName:'Project Status',
          headerClass: 'ProjectStatus01',
          children:
          [
          { 
              headerName: "Site Status", 
              field: "status.status_code",
              headerClass: 'ProjectStatus01', 
              width: 150, 
              floatingFilter: true, 
              suppressHeaderMenuButton: true,
              cellEditor: "agSelectCellEditor", 
              cellEditorParams: { values: this.site_status, valueListMaxHeight: 150, }, 
              filter: 'agSetColumnFilter', 
              editable: true,
              enableRowGroup:true, 
            },
          ]
        },
        {
          headerName:'Project Status',
          headerClass: 'ProjectStatus02',
          children:
          [
                    {
                      headerName:'Kick-off Meeting Date',
                      headerClass: 'ProjectStatus02',
                      children:
                      [
                        {
                          headerName: "Actual Date",
                          headerClass : "ProjectStatus02",
                          cellDataType: 'dateString',
                          field: "site_add_info_kick_off_meeting_date", 
                          width: 150, 
                          suppressHeaderMenuButton: true,
                          editable:true,
                          cellRenderer: (params) => { return this.format_datetime(params.data.site_add_info_kick_off_meeting_date) },
                        },
                      ]
                    },
                    {
                      headerName:'NTP Issue Date (if any)',
                      headerClass: 'ProjectStatus02',
                      children:
                      [
                        {
                          headerName: "NTP Issue to EPC Contractor",
                          headerClass : "ProjectStatus02",
                          cellDataType: 'dateString',
                          field: "site_add_info_NTP_issue_date", 
                          width: 150, 
                          suppressHeaderMenuButton: true,
                          editable:true,
                          cellRenderer: (params) => { return this.format_datetime(params.data.site_add_info_NTP_issue_date) },
                        },
                      ]
                    },
                    {
                      headerName: "Insurance Coverage",
                      headerClass : "ProjectStatus02",
                      field: "site_Insurance_Status", 
                      width: 150, 
                      suppressHeaderMenuButton: true,
                      editable:true,
                      enableRowGroup: true,
                      enableValue:true,
                      cellEditor: "agSelectCellEditor", 
                      cellEditorParams: { values: this.Insurance_Status,valueListMaxHeight: 150, }, 
                    },
                    {
                      headerName:'Mobilization Date (CRZ Installation Start Date)',
                      headerClass: 'ProjectStatus02',
                      children:
                      [
                        {
                          headerName: "Planned",
                          headerClass : "ProjectStatus03",
                          cellDataType: 'dateString',
                          field: "mobilization_planned_date", 
                          width: 150, 
                          suppressHeaderMenuButton: true,
                          editable:(params) => {return params.data.milestone_sg;},
                          cellClass:'planned',
                          cellRenderer: (params) => { return this.format_datetime(params.data.mobilization_planned_date) },
                        },
                        {
                          headerName: "Actual",
                          headerClass : "ProjectStatus02",
                          cellDataType: 'dateString',
                          field: "mobilization_actual_date", 
                          width: 150, 
                          suppressHeaderMenuButton: true,
                          editable:(params) => {return params.data.milestone_sg;},
                          cellRenderer: (params) => { return this.format_datetime(params.data.mobilization_actual_date) },
                        },
                      ]
                    },
                    {
                      headerName:'Hoisting Start Date',
                      headerClass: 'ProjectStatus02',
                      children:
                      [
                        {
                          headerName: "Planned",
                          headerClass : "ProjectStatus03",
                          cellDataType: 'dateString',
                          field: "hoisting_planned_date", 
                          width: 150, 
                          suppressHeaderMenuButton: true,
                          editable:(params) => {return params.data.milestone_sg;},
                          cellClass:'planned',
                          cellRenderer: (params) => { return this.format_datetime(params.data.hoisting_planned_date) },
                        },
                        {
                          headerName: "Actual",
                          headerClass : "ProjectStatus02",
                          cellDataType: 'dateString',
                          field: "hoisting_actual_date", 
                          width: 150, 
                          suppressHeaderMenuButton: true,
                          editable:(params) => {return params.data.milestone_sg;},
                          cellRenderer: (params) => { return this.format_datetime(params.data.hoisting_actual_date) },
                        },
                      ]
                    },
                    {
                      headerName:'T&C Date',
                      headerClass: 'ProjectStatus02',
                      children:
                      [
                        {
                          headerName: "Planned",
                          headerClass : "ProjectStatus03",
                          cellDataType: 'dateString',
                          field: "tc_planned_date", 
                          width: 150, 
                          suppressHeaderMenuButton: true,
                          editable:(params) => {return params.data.milestone_sg;},
                          cellClass:'planned',
                          cellRenderer: (params) => { return this.format_datetime(params.data.tc_planned_date) },
                        },
                        {
                          headerName: "Actual",
                          headerClass : "ProjectStatus02",
                          cellDataType: 'dateString',
                          field: "tc_actual_date", 
                          width: 150, 
                          suppressHeaderMenuButton: true,
                          editable:(params) => {return params.data.milestone_sg;},
                          cellRenderer: (params) => { return this.format_datetime(params.data.tc_actual_date) },
                        },
                      ]
                    },
                    {
                      headerName:'Tie-in Date',
                      headerClass: 'ProjectStatus02',
                      children:
                      [
                        {
                          headerName: "Planned",
                          headerClass : "ProjectStatus03",
                          cellDataType: 'dateString',
                          field: "tiein_planned_date", 
                          width: 150, 
                          suppressHeaderMenuButton: true,
                          editable:(params) => {return params.data.milestone_sg;},
                          cellClass:'planned',
                          cellRenderer: (params) => { return this.format_datetime(params.data.tiein_planned_date) },
                        },
                        {
                          headerName: "Actual",
                          headerClass : "ProjectStatus02",
                          cellDataType: 'dateString',
                          field: "tiein_actual_date", 
                          width: 150, 
                          suppressHeaderMenuButton: true,
                          editable:(params) => {return params.data.milestone_sg;},
                          cellRenderer: (params) => { return this.format_datetime(params.data.tiein_actual_date) },
                        },
                      ]
                    },
                    {
                      headerName:'Turn On',
                      headerClass: 'ProjectStatus02',
                      children:
                      [
                        {
                          headerName: "Planned Original",
                          headerClass : "ProjectStatus03",
                          cellDataType: 'dateString',
                          field: "site_end_date", 
                          width: 150, 
                          suppressHeaderMenuButton: true,
                          editable:true,
                          cellClass:'planned',
                          cellRenderer: (params) => { return this.format_datetime(params.data.site_end_date) },
                        },
                        {
                          headerName: "Planned",
                          headerClass : "ProjectStatus03",
                          cellDataType: 'dateString',
                          field: "turnon_planned_date", 
                          width: 150, 
                          suppressHeaderMenuButton: true,
                          editable:(params) => {return params.data.milestone_sg;},
                          cellClass:'planned',
                          cellRenderer: (params) => { return this.format_datetime(params.data.turnon_planned_date) },
                        },
                        {
                          headerName: "Actual",
                          headerClass : "ProjectStatus02",
                          cellDataType: 'dateString',
                          field: "turnon_actual_date", 
                          width: 150, 
                          suppressHeaderMenuButton: true,
                          editable:(params) => {return params.data.milestone_sg;},
                          cellRenderer: (params) => { return this.format_datetime(params.data.turnon_actual_date) },
                        },
                      ]
                    },
                    {
                      headerName:'PAC Date',
                      headerClass: 'ProjectStatus02',
                      children:
                      [
                        {
                          headerName: "Planned",
                          headerClass : "ProjectStatus03",
                          cellDataType: 'dateString',
                          field: "pac_planned_date", 
                          width: 150, 
                          suppressHeaderMenuButton: true,
                          editable:true,
                          cellClass:'planned',
                          cellRenderer: (params) => { return this.format_datetime(params.data.pac_planned_date) },
                        },
                        {
                          headerName: "Actual",
                          headerClass : "ProjectStatus02",
                          cellDataType: 'dateString',
                          field: "pac_actual_date", 
                          width: 150, 
                          suppressHeaderMenuButton: true,
                          editable:true,
                          cellRenderer: (params) => { return this.format_datetime(params.data.pac_actual_date) },
                        },
                      ]
                    },

          ]
        },
        {
          headerName: "Site Situation",
          headerClass : "SiteSituation",
          children :
          [
            {
              headerName:'CAT Ladder Installation',
              headerClass: 'SiteSituation',
              children:
              [
                {
                  headerName: "Status",
                  headerClass : "SiteSituation",
                  field: "site_CAT_Ladder_Status", 
                  width: 150, 
                  suppressHeaderMenuButton: true,
                  editable:true,
                  enableRowGroup: true,
                  enableValue:true,
                  cellEditor: "agSelectCellEditor", 
                  cellEditorParams: { values: this.CAT_Ladder_Status,valueListMaxHeight: 150, }, 
                },
              ]
            },
            {
              headerName:'Skylight Quantity',
              headerClass: 'SiteSituation',
              children:
              [
                {
                  headerName: "nos.",
                  headerClass : "SiteSituation",
                  field: "site_total_task", 
                  width: 150, 
                  suppressHeaderMenuButton: true,
                  editable:true,
                },
              ]
            },
            {
              headerName:'Require SOC? (more than 16)',
              headerClass: 'SiteSituation',
              children:
              [
                {
                  headerName: "YES/NO",
                  headerClass : "SiteSituation",
                  field: "site_SOC_Status", 
                  width: 150, 
                  suppressHeaderMenuButton: true,
                  editable:true,
                  enableRowGroup: true,
                  enableValue:true,
                  cellEditor: "agSelectCellEditor", 
                  cellEditorParams: { values: this.SOC_Status,valueListMaxHeight: 150, }, 
                },
              ]
            },
          ]
        },
        {
           headerName: 'As-built Capacity',
           headerClass: 'AsBuilt',
           children:
           [
           { 
              headerName: "No. of Module",
              headerClass : "AsBuilt",
              field: "site_drawing_module_quantity", 
              width: 150, 
              floatingFilter: true, 
              suppressHeaderMenuButton: true,
              cellRenderer: data => {
                return Math.floor(data.value);
              },
              editable:true,
          },
          { 
              headerName: "Brand", 
              field: "site_drawing_module_brand.module_brand_id_name", 
              headerClass : "AsBuilt",
              width: 150, 
              floatingFilter: true, 
              suppressHeaderMenuButton: true,
              cellEditor: "agSelectCellEditor", 
              cellEditorParams: { values: this.module_brands,valueListMaxHeight: 150, }, 
              filter: 'agSetColumnFilter', 
              editable: true, 
          },
          { 
              headerName: "Watt Class (Wp)",
              headerClass : "AsBuilt",
              field: "site_drawing_module_capacity", 
              width: 150, 
              suppressHeaderMenuButton: true,
              editable:false,
          },
          {
              headerName: "Capacity(kWp)",
              headerClass : "AsBuilt", 
              field: "site_drawing_total_capacity", 
              width: 150, 
              floatingFilter: true, 
              suppressHeaderMenuButton: true,
          },
          {
              headerName: "Capacity(kWAC)", 
              headerClass : "AsBuilt",
              field: "site_ppa_approved_capacity", 
              width: 150, 
              floatingFilter: true, 
              suppressHeaderMenuButton: true,
              editable:true,
          },
          ]  
        },
        {
          headerName : "COPS",
          headerClass : "COPS",
          children : 
          [
            {
              headerName:'Received',
              headerClass: 'COPS',
              children:
              [
                {
                  headerName: "YES or NO",
                  headerClass : "COPS",
                  field: "site_add_info_cops_status",
                  cellEditor: "agSelectCellEditor", 
                  cellEditorParams: { values: this.COPS_Status,valueListMaxHeight: 150, }, 
                  filter: 'agSetColumnFilter',  
                  width: 150, 
                  suppressHeaderMenuButton: true,
                  editable: true,
                },
              ]
            },
            {
              headerName:'Date Received',
              headerClass: 'COPS',
              headerClass : "COPS",
              field: "site_add_info_internal_project_handover_date",
              cellDataType: 'dateString',
              editable:true,
              cellRenderer: (params) => { return this.format_datetime(params.data.site_add_info_internal_project_handover_date) }, 
              width: 150, 
              suppressHeaderMenuButton: true,
              editable: true,
            },
            {
              headerName:'Same as T&C',
              headerClass: "COPS",
              children:
              [
                {
                  headerName: "YES or NO",
                  headerClass : "COPS",
                  field: "site_add_info_tnc_status", 
                  cellEditor: "agSelectCellEditor", 
                  cellEditorParams: { values: this.TNC_Status,valueListMaxHeight: 150, }, 
                  filter: 'agSetColumnFilter', 
                  width: 150, 
                  suppressHeaderMenuButton: true,
                  editable: true,
                },
              ]
            },
            {
              headerName: "Capacity(kWp)",
              headerClass : "COPS", 
              field: "site_drawing_total_capacity", 
              width: 150, 
              floatingFilter: true, 
              suppressHeaderMenuButton: true,
          },
          {
              headerName: "Capacity(kWAC)", 
              headerClass : "COPS",
              field: "site_ppa_approved_capacity", 
              width: 150, 
              floatingFilter: true, 
              suppressHeaderMenuButton: true,
          },
          ]
        }, 
        {
              headerName:'Remarks',
              headerClass: 'Remarks',
              field: "site_add_info_cops_tnc_remarks", 
              width: 150, 
              suppressHeaderMenuButton: true,
              editable: true,
        },
        {
              headerName:'Planning Purpose',
              headerClass: 'Planning',
              children:
              [
                {
                  headerName: "Month",
                  headerClass : "Planning",
                  cellDataType: 'dateString',
                  field: "site_add_info_planning_start_date", 
                  width: 150, 
                  suppressHeaderMenuButton: true,
                  editable:true,
                  cellRenderer: (params) => { return this.format_datetime(params.data.site_add_info_planning_start_date) },
                },
                {
                  headerName: "Remarks",
                  headerClass : "Planning",
                  field: "site_add_info_planning_remarks", 
                  width: 150, 
                  suppressHeaderMenuButton: true,
                  editable: true,
                },
              ]
          },
          {
              headerName:'Performance Bond',
              headerClass: 'PerformanceBond',
              children:
              [
                {
                  headerName: "PB Start Date",
                  headerClass : "PerformanceBond",
                  cellDataType: 'dateString',
                  field: "site_add_info_pb_start_date", 
                  width: 150, 
                  suppressHeaderMenuButton: true,
                  editable:true,
                  cellRenderer: (params) => { return this.format_datetime(params.data.site_add_info_pb_start_date) },
                },
                {
                  headerName: "PB End Date",
                  headerClass : "PerformanceBond",
                  cellDataType: 'dateString',
                  field: "site_add_info_pb_end_date", 
                  width: 150, 
                  suppressHeaderMenuButton: true,
                  editable:true,
                  cellRenderer: (params) => { return this.format_datetime(params.data.site_add_info_pb_end_date) },
                },
                {
                  headerName: "PB",
                  headerClass : "PerformanceBond",
                  field: "site_add_info_pb_remarks", 
                  width: 150, 
                  suppressHeaderMenuButton: true,
                  editable: true,
                },
              ]
          },
          {
            headerName : "Others",
            headerClass : "Others",
            children :[
              {
                  headerName: "Latitude",
                  headerClass : "Others",
                  field: "site_latitude", 
                  width: 150, 
                  suppressHeaderMenuButton: true,
                  editable: true,
              },
              {
                  headerName: "Longitude",
                  headerClass : "Others",
                  field: "site_longitude", 
                  width: 150, 
                  suppressHeaderMenuButton: true,
                  editable: true,
              },



            ],

          }
      ];
    },

    initiateSubGrid_milestone() {
      this.detailCellRendererParams_milestone = {
        detailGridOptions: {
          columnDefs: [
            { headerName: "Task Name", field: "site_milestone_code", flex: 1.5, cellRenderer: "agGroupCellRenderer"  },
            { headerName: "Planned Start Date", field: "planned_start_date", width: 200,
              editable: true, headerClass: 'agisEditable', 
              cellDataType: 'dateString',
              cellEditor: "agDateStringCellEditor",
              cellRenderer: (params) => { return this.format_datetime(params.data.planned_start_date) },
            },
            { headerName: "Planned End Date", field: "planned_end_date", width: 200,
              editable: true, headerClass: 'agisEditable',
              // cellEditor: "agDateCellEditor",
              cellDataType: 'dateString',
              cellEditor: "agDateStringCellEditor",
              cellRenderer: (params) => { return this.format_datetime(params.data.planned_end_date) },
            },
            { headerName: "Actual Start Date", field: "actual_start_date", width: 200,
              editable: true, headerClass: 'agisEditable', 
              cellDataType: 'dateString',
              cellEditor: "agDateStringCellEditor",
              cellRenderer: (params) => { return this.format_datetime(params.data.actual_start_date) },
            },
            { headerName: "Actual End Date", field: "actual_end_date", width: 200,
              editable: true, headerClass: 'agisEditable',
              // cellEditor: "agDateCellEditor",
              cellDataType: 'dateString',
              cellEditor: "agDateStringCellEditor",
              cellRenderer: (params) => { return this.format_datetime(params.data.actual_end_date) },
            },
            { headerName: "Remarks", headerClass: 'agisEditable',field: "remarks", width: 200,  editable: true },
          ],
          headerHeight: 38,
          onCellValueChanged: ($event) => {
            //_ this one is for sub grid milestone 
            this.populate_data_milestone_to_be_submit_into_db($event);
          },
          defaultColDef: {
            flex: 1,
          },
          // groupDefaultExpanded: 1,
          detailRowHeight: 50,
          detailRowAutoHeight: true,
          masterDetail: true,
          detailCellRendererParams: (params) => {
            var task = {};
            task.detailGridOptions = {
              columnDefs: [
                { headerName: "Task Name", field: "site_milestone_task_name", flex: 1.5, cellRenderer: "agGroupCellRenderer" },
                {
                  headerName: "Planned Start Date", field: "site_milestone_task_planned_start_date", width: 200,
                  cellRenderer: (params) => { return this.format_datetime(params.data.site_milestone_task_planned_start_date) }
                },
                {
                  headerName: "Planned End Date", field: "site_milestone_task_planned_end_date", width: 200,
                  cellRenderer: (params) => { return this.format_datetime(params.data.site_milestone_task_planned_end_date) }
                },
                {
                  headerName: "Actual Start Date", field: "site_milestone_task_actual_start_date", width: 200,
                  editable: true, headerClass: 'agisEditable',
                  cellDataType: 'dateString',
                  cellEditor: "agDateStringCellEditor",
                  cellRenderer: (params) => { return this.format_datetime(params.data.site_milestone_task_actual_start_date) },
                },
                {
                  headerName: "Actual End Date", field: "site_milestone_task_actual_end_date", width: 200,
                  editable: true, headerClass: 'agisEditable',
                  // cellEditor: "agDateCellEditor",
                  cellDataType: 'dateString',
                  cellEditor: "agDateStringCellEditor",
                  cellRenderer: (params) => { return this.format_datetime(params.data.site_milestone_task_actual_end_date) },
                },
                {
                  headerName: "Critical", field: "site_milestone_task_critical", width: 150,
                  editable: true, headerClass: 'agisEditable',
                  cellEditor: "agSelectCellEditor",
                  cellEditorParams: { values: this.obj_selection.ms_critical },
                  // cellEditorParams: { values: this.bind_selection_field_critical() }
                },
                {
                  headerName: "Status", field: "site_milestone_task_status", width: 200,
                  editable: true, headerClass: 'agisEditable',
                  cellEditor: "agSelectCellEditor",
                  cellEditorParams: { values: this.obj_selection.ms_status },
                },
              ],
              context: {
                params: params,   // parent grid reference
              },
              headerHeight: 38,
              onCellValueChanged: ($event) => {
                this.populate_data_task_to_be_submit_into_db($event);
              }
            };
            task.getDetailRowData = (params) => {
              //_ this one is sub grid for task
              // console.log('child2');
              // console.log(params);
              params.successCallback(params.data.milestone_tasks);
            };
            
            return task;
          }
        },
        getDetailRowData: (params) => {
          //_ this one is sub grid for milestone
          // console.log('child1');
          // console.log(params);
                    
          params.successCallback(params.data.milestone_tasks);

          // this.sub_data_milestone = this.get_milestone_by_site(params.data.site_id);
          // console.log(tmpData);
          // params.successCallback(tmpData);
        },
      };
    },


    populate_data_milestone_to_be_submit_into_db( AgEvent ) {
      console.log('populate_data_milestone_to_be_submit_into_db');
      console.log(AgEvent);
      //console.log(this.rowData);

      const site_data = this.rowData.find(obj => obj.site_id === AgEvent.data.site_id);
      let id_site = AgEvent.data.site_id;
      let tx_site = site_data.site_name;
      let tx_milestone = AgEvent.data.site_milestone_info;
      // console.log('Find the row data index');
      // console.log(site_data.milestone_tasks);


      this.site_milestones = site_data.milestone_tasks;

      // console.log('Transfer to this.site_milestones');
      // console.log(this.site_milestones);
      
      // console.log('Check the changes is in whichrow');
      // console.log(AgEvent.rowIndex);

      const updated_index = AgEvent.rowIndex;

      // console.log('show the new set of data');
      // console.log(AgEvent.data);

      

      this.site_milestones[updated_index] = AgEvent.data;

      // console.log ("New Milestone");
      // console.log (this.site_milestones);

      axios
        .post(apiDomain + 'project/project_site_milestone.update_site_milestones/' + id_site, this.site_milestones, { headers: getHeader() })
        .then(response => {
            if (response.status === 200) {
              this.$notify({
                message:
                  '<b>Update Project Site :' + tx_site + '<br>Milestone: ' + tx_milestone + '</b><br><br> Successfully Updated.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
              });
              //   this.$router.back()

            }
          })
        .catch(error => {
          console.log(error.response.data.errors)
          this.errorFunction(error, "Project Information")
        });
    },


    onCellValueChangedMilestone(AgEvent,updated_index,updated_field) {
      console.log('onCellValueChangedMilestone');
      console.log(AgEvent);
      //console.log(this.rowData);

      let id_site = AgEvent.data.site_id;
      let tx_site = AgEvent.data.site_name;

      this.site_milestones = AgEvent.data.milestone_tasks;
      let tx_milestone = this.site_milestones[updated_index].site_milestone_info;

      if(updated_field == 0)
      {
        this.site_milestones[updated_index].planned_end_date = AgEvent.newValue;
      }
      else
      {
        this.site_milestones[updated_index].actual_end_date = AgEvent.newValue;
      }
      // console.log ("New Milestone");
      // console.log (this.site_milestones);

      axios
        .post(apiDomain + 'project/project_site_milestone.update_site_milestones/' + id_site, this.site_milestones, { headers: getHeader() })
        .then(response => {
            if (response.status === 200) {
              this.$notify({
                message:
                  '<b>Update Project Site :' + tx_site + '<br>Milestone: ' + tx_milestone + '</b><br><br> Successfully Updated.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
              });
              //   this.$router.back()

            }
          })
        .catch(error => {
          console.log(error.response.data.errors)
          this.errorFunction(error, "Project Information")
        });
    },

    populate_data_task_to_be_submit_into_db(AgEvent) {
      // console.log('populate_data_task_to_be_submit_into_db');
      // console.log(AgEvent);
      // console.log(this.rowData);
      
      const site_data = this.rowData.find(obj => obj.site_id === AgEvent.context.params.data.site_id);
      let id_site = site_data.site_id;
      let tx_site = site_data.site_name;
      let tx_milestone = AgEvent.context.params.data.site_milestone_info;
      let id_task = AgEvent.data.site_milestone_task_id;
      let tx_task = AgEvent.data.site_milestone_task_name;

      // console.log(site_data);
      // console.log('id_site: ' + id_site);
      // console.log('tx_site: ' + tx_site);
      // console.log('id_task: ' + id_task);
      

      //_ initiate un-change required field
      let obj_update = {
        site_milestone_task_name: AgEvent.data.site_milestone_task_name,
        site_milestone_task_planned_start_date: this.format_datetime(AgEvent.data.site_milestone_task_planned_start_date, 'db'),
        site_milestone_task_planned_end_date: this.format_datetime(AgEvent.data.site_milestone_task_planned_end_date, 'db'),
      };

      //_ is status the one that being update or not ? because it's one of required field need to be post to API
      //  if it not status, then we use the status as is from the db (or what we got from the API)
      let updated_field = AgEvent.colDef.field;
      let updated_value = '';
      if (updated_field == 'site_milestone_task_actual_start_date' || updated_field == 'site_milestone_task_actual_end_date') {
        updated_value = this.format_datetime(AgEvent.newValue, 'db');
      } else {
        updated_value = AgEvent.newValue
      }
      if (updated_field != 'site_milestone_task_status') {
        obj_update = { ...obj_update, site_milestone_task_status: AgEvent.data.site_milestone_task_status, [updated_field]: updated_value }
      } else {
        obj_update = { ...obj_update, site_milestone_task_status: AgEvent.data.site_milestone_task_status }
      }

      // this.obj_hit_api.to_be_update_data = { ...obj_update }

      //console.log(obj_update);

      axios
        .post(apiDomain + 'task/edit_site_milestone_task/' + id_site + "/" + id_task, obj_update, { headers: getHeader() })
        .then(response => {
          if (response.status === 200) {
            this.$notify({
              message:
                '<b>Update Project Site :' + tx_site + '<br>Milestone: ' + tx_milestone + '<br>Task: ' + tx_task + '</b><br><br> Successfully Updated.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
          }
        })
        .catch(error => {
          console.log(error.response.data.errors)
          this.errorFunction(error, "Project Information")
        });      
    },

    get_milestone_by_site(id_site=0) {
      //console.log('get_milestone_by_site');
      //console.log(id_site);

      axios
        .get(apiDomain + 'project/project_site_milestone.show_by_site/' + id_site, { headers: getHeader() })
        .then ( result => {
            if (result.status === 200) {
                //console.log(result.data.data[0].contractor.contractor_name);
                //this.contractor = result.data.data[0].contractor.contractor_name;
              // this.rowData[i] = {...this.rowData[i], milestone_tasks:result.data.data};
              // this.api.updateGridOptions({rowData:this.rowData});
              //console.log("DATA NO:" + i);
              //console.log(this.newrowData);       

              //console.log(result.data.data);
              return result.data.data;
            }
        })
        .catch ( error => {
          if (typeof error.response !== "undefined") {
              this.errorFunction(error, "get_milestone_by_site")
          }
        })
    },


    pad(num, totalStringSize) {
      let asString = num + "";
      while (asString.length < totalStringSize) asString = "0" + asString;
      return asString;
    },
    onReady(params) {
      //console.log('onReady');

      this.api = params.api;
      this.api.sizeColumnsToFit();
    },
    onCellValueChanged(event) {
      switch(event.colDef.field)
      {
        case 'mobilization_planned_date' :
        {
          this.onCellValueChangedMilestone(event,0,0);
          break;
        }
        case 'mobilization_actual_date' :
        {
          this.onCellValueChangedMilestone(event,0,1);
          break;
        }
        case 'hoisting_planned_date' :
        {
          this.onCellValueChangedMilestone(event,1,0);
          break;
        }
        case 'hoisting_actual_date' :
        {
          this.onCellValueChangedMilestone(event,1,1);
          break;
        }
        case 'tc_planned_date' :
        {
          this.onCellValueChangedMilestone(event,2,0);
          break;
        }
        case 'tc_actual_date' :
        {
          this.onCellValueChangedMilestone(event,2,1);
          break;
        }
        case 'tiein_planned_date' :
        {
          this.onCellValueChangedMilestone(event,3,0);
          break;
        }
        case 'tiein_actual_date' :
        {
          this.onCellValueChangedMilestone(event,3,1);
          break;
        }
        case 'turnon_planned_date' :
        {
          this.onCellValueChangedMilestone(event,4,0);
          this.onCellValueChangedSite(event);
          break;
        }
        case 'turnon_actual_date' :
        {
          this.onCellValueChangedMilestone(event,4,1);
          this.onCellValueChangedSite(event);
          break;
        }
        case 'pac_planned_date' :
        {
          this.onCellValueChangedMilestone(event,5,0);
          break;
        }
        case 'pac_actual_date' :
        {
          this.onCellValueChangedMilestone(event,5,1);
          break;
        }
        case 'site_add_info_planning_start_date':
         {
           this.onCellValueChangedAddSiteInfo(event);
           break;  
         }  
        case 'site_add_info_planning_remarks':
         {
           this.onCellValueChangedAddSiteInfo(event);
           break;  
         }  
        case 'site_add_info_pb_start_date':
         {
           this.onCellValueChangedAddSiteInfo(event);
           break;  
         }  
        case 'site_add_info_pb_end_date':
         {
           this.onCellValueChangedAddSiteInfo(event);
           break;  
         }  
         case 'site_add_info_pb_remarks':
         {
           this.onCellValueChangedAddSiteInfo(event);
           break;  
         }  
        case 'site_add_info_maa_submission_date':
         {
           this.onCellValueChangedAddSiteInfo(event);
           break;  
         }  
        case 'site_add_info_maa_approval_date':
         {
           this.onCellValueChangedAddSiteInfo(event);
           break;  
         }  
        case 'site_add_info_pc_planned_date':
         {
           this.onCellValueChangedAddSiteInfo(event);
           break;  
         }  
        case 'site_add_info_pc_status':
         {
           this.onCellValueChangedAddSiteInfo(event);
           break;  
         }  
         case 'site_add_info_pso_submission_date':
         {
           this.onCellValueChangedAddSiteInfo(event);
           break;  
         }  
        case 'site_add_info_pso_approval_date':
         {
           this.onCellValueChangedAddSiteInfo(event);
           break;  
         }  
        case 'site_add_info_igf_submission_date':
         {
           this.onCellValueChangedAddSiteInfo(event);
           break;  
         }  
        case 'site_add_info_igf_approval_date':
        {
          this.onCellValueChangedAddSiteInfo(event);
          break;  
        }
        case 'site_add_info_sa_received_date':
        {
          this.onCellValueChangedAddSiteInfo(event);
          break;  
        }
        case 'site_add_info_sa_status':
         {
           this.onCellValueChangedAddSiteInfo(event);
           break;  
         }  
        case 'site_add_info_pv1_letter_date':
         {
           this.onCellValueChangedAddSiteInfo(event);
           break;  
         }  
        case 'site_add_info_pv2_letter_date':
         {
           this.onCellValueChangedAddSiteInfo(event);
           break;  
         }  
        case 'site_add_info_cs1_submission_date':
         {
           this.onCellValueChangedAddSiteInfo(event);
           break;  
         }  
        case 'site_add_info_cs1_submission_app_number':
         {
           this.onCellValueChangedAddSiteInfo(event);
           break;  
         }  
         case 'site_add_info_cs1_submission_approval_number':
         {
           this.onCellValueChangedAddSiteInfo(event);
           break;  
         }  
        case 'site_add_info_cod_formula_date':
         {
           this.onCellValueChangedAddSiteInfo(event);
           break;  
         }  
        case 'site_add_info_cops_status':
         {
           this.onCellValueChangedAddSiteInfo(event);
           break;  
         }  
        case 'site_add_info_tnc_status':
        {
          this.onCellValueChangedAddSiteInfo(event);
          break;  
        }
        case 'site_add_info_internal_project_handover_date':
        {
          this.onCellValueChangedAddSiteInfo(event);
          break;  
        }
        case 'site_add_info_cops_tnc_remarks':
         {
           this.onCellValueChangedAddSiteInfo(event);
           break;  
         }  
        case 'site_add_info_roof_handover_date':
        {
          this.onCellValueChangedAddSiteInfo(event);
          break;  
        }
        case 'site_add_info_onm_handover_remarks':
         {
           this.onCellValueChangedAddSiteInfo(event);
           break;  
         }  
        case 'site_add_info_turn_on_checking_approval_date':
        {
          this.onCellValueChangedAddSiteInfo(event);
          break;  
        }
        case 'site_add_info_kick_off_meeting_date':
        {
          this.onCellValueChangedAddSiteInfo(event);
          break;  
        }
        case 'site_add_info_NTP_issue_date':
        {
          this.onCellValueChangedAddSiteInfo(event);
          break;  
        }
        case 'project_ppa_approved_capacity':
        {
          this.onCellValueChangedProject(event);
          break;  
        }
        case 'project_project_safety.name':
        {
            this.onCellValueChangedProject(event);
            break;  
        }
        case 'project_project_engineer.name':
          {
            this.onCellValueChangedProject(event);
            break;  
          }
        case 'project.project_manager.name':
          {
            this.onCellValueChangedProject(event);
            break;
          }
        case 'project_project_business_development.name':
          {
            this.onCellValueChangedProject(event);
            break;
          }
        case 'EPC_Name': 
          {
            this.onCellValueChangedContractor(event);
            break;
          }
        case 'status.status_code' :
          {
            if(event.newValue == '09.Turned On' || event.newValue == '10.PAC Issued')
            {
              if(event.data.turnon_actual_date =='' || isNull(event.data.turnon_actual_date))
              { 
                this.$notify({
                message:
                  '<b>Not able to update Project Site status:' + event.data.site_name + '<br>Please update the Actual Turn On field before update the status',
                  timeout: 10000,
                  icon: 'ni ni-fat-remove',
                  type: 'danger',
                });
              }
              else
              {
                this.onCellValueChangedSite(event); 
              }
            }
            else
            {
              this.onCellValueChangedSite(event); 
            }
            break;
          }
        default:
          {
            this.onCellValueChangedSite(event);    
          }
      }
      // if(event.colDef.field = 'site_contractor.contractor.contractor_name')
      // {
      //   console.log('Update contractors');
      //   this.onCellValueChangedContractor(event);
      // }
      // else
      // {
      //   this.onCellValueChangedSite(event);
      // }
    },

    onCellValueChangedAddSiteInfo(event) {
          const add_info_model = 
          {
            project_site_additional_info_id : event.data.site_add_info_project_site_additional_info_id,
            site_id : event.data.site_id,
            kick_off_meeting_date : event.data.site_add_info_kick_off_meeting_date,
            ntp_issue_date : event.data.site_add_info_NTP_issue_date,
            onm_handover_remarks : event.data.site_add_info_onm_handover_remarks,
            cops_tnc_checked : this.searchKeyword(event.data.site_add_info_cops_status,this.COPS_Status_Index),
            cops_tnc_issue : this.searchKeyword(event.data.site_add_info_tnc_status,this.TNC_Status_Index),
            internal_project_handover_date : event.data.site_add_info_internal_project_handover_date,
            cops_tnc_remarks : event.data.site_add_info_cops_tnc_remarks,
            roof_handover_date : event.data.site_add_info_roof_handover_date,
            turn_on_checking_approval_date : event.data.site_add_info_turn_on_checking_approval_date,
            cod_formula_date : event.data.site_add_info_cod_formula_date,
            cs1_submission_date: event.data.site_add_info_cs1_submission_date,
            cs1_submission_app_number: event.data.site_add_info_cs1_submission_app_number,
            cs1_submission_approval_number: event.data.site_add_info_cs1_submission_approval_number,
            pv1_letter_date: event.data.site_add_info_pv1_letter_date,
            pv2_letter_date: event.data.site_add_info_pv2_letter_date,
            maa_submission_date: event.data.site_add_info_maa_submission_date,
            maa_approval_date: event.data.site_add_info_maa_approval_date ,
            pc_planned_date: event.data.site_add_info_pc_planned_date ,
            pc_status: event.data.site_add_info_pc_status ,
            pso_submission_date: event.data.site_add_info_pso_submission_date ,
            pso_approval_date: event.data.site_add_info_pso_approval_date ,
            igf_submission_date: event.data.site_add_info_igf_submission_date ,
            igf_approval_date: event.data.site_add_info_igf_approval_date ,
            sa_received_date: event.data.site_add_info_sa_received_date ,
            sa_status: event.data.site_add_info_sa_status ,
            planning_start_date:event.data.site_add_info_planning_start_date,
            planning_remarks:event.data.site_add_info_planning_remarks,
            pb_start_date:event.data.site_add_info_pb_start_date,
            pb_end_date:event.data.site_add_info_pb_end_date,
            pb_remarks:event.data.site_add_info_pb_remarks,
          }

          //console.log (add_info_model);

          axios.post(apiDomain + 'project/update_project_site_additional_info/' + event.data.site_id, add_info_model, { headers: getHeader() })
                    .then ( response => {
                        if (response.status === 200)
                        {
                            this.$notify({
                                message:
                                '<b>Update Project Site : ' + this.model.site_name + '</b> - Successfully Updated.',
                                timeout: 10000,
                                icon: 'ni ni-bell-55',
                                type: 'default',
                            });
                        }
                    })
                    .catch ( error => {
                        this.errorFunction(error, "Update Project Site Additional Information")
                    });           
      },


    onCellValueChangedProject(event) {
          const project_model = 
          {
            project_id : event.data.project.project_id,
            project_name: event.data.project.project_name,
            project_business_development : this.searchKeyword(event.data.project_project_business_development.name,this.project_business_development_index),
            project_type_id : event.data.project_type.project_type_id,
            developer_id : event.data.project_developer.developer_id,
            project_ppa_approved_capacity : event.data.project_ppa_approved_capacity * 1000,
            project_ppa_start_date : event.data.project_ppa_start_date,
            project_manager : this.searchKeyword(event.data.project.project_manager.name,this.project_managers_index),
            project_engineer : this.searchKeyword(event.data.project_project_engineer.name,this.project_engineers_index),          
            project_safety : this.searchKeyword(event.data.project_project_safety.name,this.project_safety_index),
            
          }

          

          //this.model.project_id = event.data.project_id;
          //this.model.project_name = event.data.project_name;
          //this.model.project_manager = this.searchKeyword(event.data.project_manager.name,this.project_managers_index);
          //this.model.project_engineer = this.searchKeyword(event.data.project_engineer.name,this.project_engineers_index);
          
          //this.model.project_type_id = event.data.project_type.project_type_id;
          //this.model.developer_id = event.data.developer.developer_id;
          //this.model.project_ppa_start_date = event.data.project_ppa_start_date;
          //this.model.project_ppa_approved_capacity = event.data.project_ppa_approved_capacity;
          //this.model.project_ppa_approved_capacity = parseFloat(event.data.project_ppa_approved_capacity.replace(/,/g, ""));
          //this.model.project_industry_category_id = event.data.project_industry_category_id;
          //if (event.data.project_watt_class !== null) {
          //    this.model.project_watt_class = parseFloat(event.data.project_watt_class.replace(/,/g, ""));
          //}

          axios.put(apiDomain + 'project/project/' + event.data.project.project_id, project_model, { headers: getHeader() })
              .then(response => {
                  if (response.status === 200) {
                      this.$notify({
                          message:
                              '<b>Update Project : ' + event.data.project.project_name + '</b> - Successfully Updated.',
                          timeout: 10000,
                          icon: 'ni ni-bell-55',
                          type: 'default',
                      });
                      //   this.$router.back()
                  }
              })

              .catch(error => {
                  console.log(error.response.data.errors)
                  this.errorFunction(error, "Project Information")
              });

      },

    onCellValueChangedContractor(event)
    {
        const site_contractor_model = {
        contractor_id: this.searchKeyword(event.data.EPC_Name,this.contractors_index),
        site_id: event.data.site_id,
        active_status: 1
        };

        axios.post(apiDomain + 'project/site_contractor.set_active_contractor/' + event.data.site_id, site_contractor_model, { headers: getHeader() })
          .then(response => {
            if (response.status === 200) {
              this.$notify({
                message:
                  '<b>Update Project Site : ' + this.model.site_name + '</b> - Successfully Updated.',
                timeout: 10000,
                icon: 'ni ni-bell-55',
                type: 'default',
              });
            }
          })
          .catch(error => {
            console.log(error.response.data.errors)
            this.errorFunction(error, "Project Information")
          });
    },

    onCellValueChangedSite(event) {
      this.model.site_id = event.data.site_id;
      this.model.status_id = this.searchKeyword(event.data.status.status_code,this.site_status_index);
      this.model.site_name = event.data.site_name;
      this.model.site_latitude = event.data.site_latitude;
      this.model.site_longitude = event.data.site_longitude;
      this.model.site_code = event.data.site_code;
      this.model.site_address = event.data.site_address;
      this.model.site_postal_code = event.data.site_postal_code;
      this.model.site_cod_date = event.data.site_cod_date;
      this.model.site_actual_turn_on_date = event.data.turnon_actual_date;
      this.model.site_target_turn_on_date = event.data.turnon_planned_date;
      this.model.site_as_built_capacity = event.data.site_as_built_capacity;
      this.model.site_module_brand_id = this.searchKeyword(event.data.site_module_brand.module_brand_id_name,this.module_brands_index);
      this.model.site_module_capacity = this.searchKeywordOther(event.data.site_module_brand.module_brand_id_name,this.module_brands_index);
      this.model.site_module_quantity = event.data.site_module_quantity;
      this.model.site_module_allocation_total_capacity = (this.model.site_module_capacity * this.model.site_module_quantity)/1000
      this.model.site_drawing_module_brand_id = this.searchKeyword(event.data.site_drawing_module_brand.module_brand_id_name,this.module_brands_index);
      this.model.site_drawing_module_capacity = this.searchKeywordOther(event.data.site_drawing_module_brand.module_brand_id_name,this.module_brands_index);
      this.model.site_drawing_module_quantity = event.data.site_drawing_module_quantity;
      this.model.site_drawing_total_capacity = (this.model.site_drawing_module_capacity * this.model.site_drawing_module_quantity)/1000
      this.model.site_ppa_approved_capacity = event.data.site_ppa_approved_capacity;
      this.model.site_contractor_start_date = event.data.site_contractor_start_date;
      this.model.site_contractor_end_date = event.data.site_contractor_end_date;
      
      if(isNull(event.data.site_contractor_end_date) || event.data.site_contractor_end_date == '')
      {
        if(isNull(event.data.site_contractor_start_date) || event.data.site_contractor_start_date == '')
        {
          //this.model.site_el_expiry_date = event.data.site_el_expiry_date;
        }
        else
        {
          const plannedturnOnDate = new Date(event.data.site_contractor_start_date);
          plannedturnOnDate.setDate(plannedturnOnDate.getDate() + 180);
          this.model.site_el_expiry_date = plannedturnOnDate.toISOString().split('T')[0];
        }
      }
      else
      {
        const turnOnDate = new Date(event.data.site_contractor_end_date);
        turnOnDate.setDate(turnOnDate.getDate() + 180);
        this.model.site_el_expiry_date = turnOnDate.toISOString().split('T')[0];
      }
      this.model.site_elu_end_date = event.data.site_elu_end_date;
      this.model.site_cod_date = event.data.site_cod_date;
      //console.log ('site_plant_name = *' + event.data.site_plant_name + '*');
      this.model.site_plant_name = event.data.site_plant_name;
      this.model.site_ifc_ifa_module_remarks = event.data.site_ifc_ifa_module_remarks;
      this.model.site_completed_capacity = event.data.site_completed_capacity;
      this.model.site_type_of_tension = event.data.site_type_of_tension;
      this.model.site_approved_load = event.data.site_approved_load;
      this.model.site_mssl_account_number = event.data.site_mssl_account_number;
      this.model.site_folder_in_sftp_server = event.data.site_folder_in_sftp_server;
      this.model.site_monitoring_application = event.data.site_monitoring_application;
      this.model.site_elu_license_number = event.data.site_elu_license_number;
      this.model.site_start_date = event.data.site_start_date;
      this.model.site_elu_status = event.data.site_elu_status;
      this.model.site_country = event.data.site_country;
      this.model.est_turn_on_date = event.data.est_turn_on_date;
      this.model.site_end_date = event.data.site_end_date;
      this.model.site_service_location_id = event.data.site_service_location_id;
      //this.model.site_el_expiry_date = event.data.site_el_expiry_date;
      this.model.site_drawing_ifa_date = event.data.site_drawing_ifa_date;
      this.model.site_drawing_ifc_date = event.data.site_drawing_ifc_date;
      this.model.site_power_premise_type = event.data.site_power_premise_type;
      this.model.site_total_task = event.data.site_total_task;
      this.model.site_total_document = this.searchKeyword(event.data.site_CAT_Ladder_Status,this.CAT_Ladder_Status_Index);
      this.model.site_completed_document = this.searchKeyword(event.data.site_Insurance_Status,this.Insurance_Status_Index);
      this.model.site_completed_task = this.searchKeyword(event.data.site_SOC_Status,this.SOC_Status_Index);
      








      //site_service_location_id





      axios.post(apiDomain + 'project/update_project_site_details/' + event.data.site_id, this.model, { headers: getHeader() })
      //axios.put(apiDomain + 'project/project_site/' + event.data.site_id, this.model, { headers: getHeader() })
        .then(response => {
          if (response.status === 200) {
            this.$notify({
              message:
                '<b>Update Project Site : ' + this.model.site_name + '</b> - Successfully Updated.',
              timeout: 10000,
              icon: 'ni ni-bell-55',
              type: 'default',
            });
            //   this.$router.back()

          }
        })
        .catch(error => {
          console.log(error.response.data.errors)
          this.errorFunction(error, "Project Information")
        });

    },

    
    
    searchKeyword(strSearch,arrToSearch) 
      {

        const result = arrToSearch.find(item =>
          item.value.includes(strSearch)
        );
        let foundIndex = result ? result.id : null;
        return foundIndex;
      },

      searchKeywordOther(strSearch,arrToSearch) 
      {
        const result = arrToSearch.find(item =>
          item.value.includes(strSearch)
        );
        let foundIndex = result ? result.other : null;
        return foundIndex;
      },


    onCellDoubleClicked(event) {
      // this.$router.push({path: '/RichGridExample/Edit/' + event.data.project_id})
      //console.log(event.data.project_id);
      //console.log('onCellDoubleClicked: ' + event.rowIndex + ' ' + event.colDef.field);
    },
    onCellContextMenu(event) {
      //console.log('onCellContextMenu: ' + event.rowIndex + ' ' + event.colDef.field);
    },
    onCellFocused(event) {
      //console.log('onCellFocused: (' + event.rowIndex + ',' + event.colIndex + ')');
    },
    // taking out, as when we 'select all', it prints to much to the console!!
    // eslint-disable-next-line
    onRowSelected(event) {
      // console.log('onRowSelected: ' + event.node.data.name);
    },
    onSelectionChanged() {
      //console.log('selectionChanged');
    },
    onFilterModified() {
      //console.log('onFilterModified');
    },
    // eslint-disable-next-line
    onVirtualRowRemoved(event) {
      // because this event gets fired LOTS of times, we don't print it to the
      // console. if you want to see it, just uncomment out this line
      // console.log('onVirtualRowRemoved: ' + event.rowIndex);
    },
    onRowClicked(event) {
      //console.log('onRowClicked: ' + event.node.data.name);
    },
    onQuickFilterChanged(event) {
      this.api.setQuickFilter(event.target.value);
    },

    // here we use one generic event to handle all the column type events.
    // the method just prints the event name
    onColumnEvent(event) {
      //console.log('onColumnEvent: ' + event);
    },
    toggleSideBar() {
      this.sideBar = !this.sideBar;
      // this.api.setSideBar(this.sideBar);
      this.api.setSideBarVisible(this.sideBar);
    },

    /* CUSTOM library, make it DRY and clean code */
    format_datetime( tmp_value, isFor='ui' ) {
      //_ default from db/api datetime format in iso8601, ex.2024-06-08T00:00:00.000000Z
      //  so isFor='ui' we format it into readable format DD Mmm YYYY
      //  the default is for frontend UI, if we didn't pass the 2nd parameter
      //  to save into db, change isFor='db', so it's formatted into YYYY-MM-DD H:i:s  <- this is the value accepted, tried YYYY-MM-DD only, and failed
      if ( isFor == 'ui' ) {
        return ( tmp_value === null ) ? '' : moment(tmp_value).format('DD MMM YYYY');
      } else if ( isFor == 'db' ) {
        return ( tmp_value === null ) ? '' : moment(tmp_value).format('YYYY-MM-DD HH:mm:ss');
      } else {
        return '';
      }
      
    },

  },

  mounted() {
      this.$refs['modal-loading-in-progress'].show();
      // this.generateData()
      // console.table(this.data)

    },

  beforeMount() {
    this.api = {};
    this.obj_user.info = this.myUserID;
    this.getBusinessDevelopmentListing();
    this.getProjectManagerListing();
    this.getProjectEngineerListing();
    this.getSafetyOfficerListing();
    this.getProjectSiteStatusListing();
    this.getContractorListing();
    this.getModuleBrandListing();
    this.getDataToShow();
    this.createColumnDefs();
    this.initiateSubGrid_milestone();
    // this.initiateSubGrid_task();

    this.showGrid = true;
  },


}
</script>

<style scoped>
/deep/ .span_filter_developer fieldset {
  margin-bottom: 0;
}
</style>

<style>

.Others {
  background-color: #ffcccc;
  justify-content: center;
}

.PerformanceBond {
  background-color: #ffc000;
  justify-content: center;
}

.Planning {
  background-color: #c9c9c9;
  justify-content: center;
}

.Remarks {
  background-color: #dce6f1;
  justify-content: center;
}

.COPS {
  background-color: #ffe699;
  justify-content: center;
}

.AsBuilt {
  background-color: #fcd5b4;
  justify-content: center;
}

.SiteSituation {
  background-color: #eab8fa;
  justify-content: center;
}

.ProjectStatus02 {
  background-color: #f2dcdb;
  justify-content: center;
}

.ProjectStatus03 {
  background-color: #bfbfbf;
  justify-content: center;
}

.planned {
  background-color: #bfbfbf;
}

.ProjectStatus01 {
  background-color: #92d050;
  justify-content: center;
}

.AuthoritiesSubmission {
  background-color: #fde9d9;
  justify-content: center;
}

.QPInfo {
  background-color: #b7dee8;
  justify-content: center;
}

.TechnicalDetails {
  background-color: #ffff00;
  justify-content: center;
}

.CODRemarks {
  background-color: #c00000;
  justify-content: center;
}

.PPAKeyInfo {
  background-color: #e4dfec;
  justify-content: center;
}

.GeneralDetails {
  background-color: #92d050;
  justify-content: center;
}

.KeyStakeholders{
  background-color: #bdd7ee;
  justify-content: center;
}

.ProjectColumn {
  background-color: #fce4d6;
}

.NoDataColumn {
  background-color: #777777;
}

.column-2 {
  background-color: #c9daf8; /* light blue */
}

.column-3 {
  background-color: #d9ead3; /* light green */
}

.ag-root-wrapper-body.ag-layout-normal {
  height: 700px
}

.ag-cell {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

label {
  font-weight: normal !important;
}

.bg-default-dashboard {
  background-color: #082A2F;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.div-percent-bar {
  display: inline-block;
  height: 100%;
  position: relative;
  z-index: 0;
}

.div-percent-value {
  position: absolute;
  padding-left: 4px;
  font-weight: bold;
  font-size: 13px;
  z-index: 100;
}

.div-outer-div {
  display: inline-block;
  height: 100%;
  width: 100%;
}

.ag-menu {
  z-index: 200;
}

.toolbar button {
  margin-left: 5px;
  margin-right: 5px;
  padding: 2px;
}
</style>